<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <div [innerHTML]="data.question"></div>
   <div *ngIf="hasWEBChannel" class="row">
    <b class="slide-text"  matTooltipPosition="below">Disable user input
     <mat-icon class = "icon" [matTooltip]="textHideFreeText" [inline]="true" id="freeTextHelp" data-testid="HideFreeTextHelpIcon" matSuffix >
      help_outline
     </mat-icon>
    </b>
    <mat-slide-toggle  [labelPosition]="labelPosition" [(ngModel)]="hideFreeText">:
    </mat-slide-toggle>
   </div>

  <div class="row">
    <b class="slide-text-radio" matTooltipPosition="below">Schedule campaign
     <mat-icon class = "icon" [inline]="true" id="scheduleHelp" data-testid="ScheduleCampaignHelpIcon" matSuffix [matTooltip]="textHelpScheduleCampaign" >
      help_outline
     </mat-icon>
    </b>:
  <mat-radio-group (change)="scheduleOptionChanged($event)" class="radio-box" 
     [(ngModel)]="startNow">
    <mat-radio-button [value]="true">
      Run my campaign continuously starting today
    </mat-radio-button>
    <mat-radio-button [value]="false">
      Set a start date 
   </mat-radio-button>
  </mat-radio-group>
 
  </div>
  <div class="row" *ngIf="!startNow">
    <div class="slide-text-calendar">
      <label class="calendar-description">
        Start date
      </label>
    </div>
    <mat-form-field class="width-50">
      <input matInput [min]="minDate" [max]="maxDate" [(ngModel)]="scheduledDate"
          (dateChange)="this.localDateTime = onDateChanged($event, this.localDateTime);this.validate()"
          [matDatepicker]="picker" placeholder="Date" type="text" data-testid="scheduledDate" />
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle [for]="picker" matSuffix data-testid="calendar-button"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="width-50">
      <input matInput [(ngModel)]="scheduledTime"
          (change)="this.localDateTime = onTimeChange($event, this.localDateTime);this.validate()" placeholder="Time"
          type="time" maxlength="5" data-testid="scheduledTime" />
  </mat-form-field>
  
  <div class="time-zone">
    (UTC {{zone}})
  </div>
</div>
<div class="calendar-error" *ngIf="!startNow && !validDate">
  <mat-error>{{errorMessage}}</mat-error>
 </div>

<div *ngIf="hasForceOption || !startNow" class="row">
  <b class="slide-text" matTooltipPosition="below">Enforce campaign
   <mat-icon class = "icon" [inline]="true" id="inputHelp" data-testid="ForceStartCampaignHelpIcon" matSuffix [matTooltip]="textHelpForceStartCampaign" >
    help_outline
   </mat-icon>
  </b>
 <mat-slide-toggle [disabled]="!startNow" [labelPosition]="labelPosition" [(ngModel)]="forceStart">:
 </mat-slide-toggle>
</div>
<p *ngIf="forceStart && (hasForceOption || !startNow)">
 Optionally, you can provide a final message that is sent out to all
 stopped conversations.</p>
<p *ngIf="forceStart && hasWAChannel && (hasForceOption || !startNow)">
 <b>Warning: </b>
 You have WhatsApp channel enabled. Please note that an initial template message will be sent before the final message to all recipients, who are not in an active WhatsApp session
 <mat-icon class = "icon" [inline]="true" id="inputHelp" data-testid="ForceStartCampaignHelpIcon" matSuffix [matTooltip]="tooltip">
   help_outline
 </mat-icon>
</p>
<mat-form-field *ngIf="forceStart && (hasForceOption || !startNow)" class="text-input">
 <textarea matInput
       cdkTextareaAutosize
       placeholder="Stop Conversation Message Text" [(ngModel)]="text">
 </textarea>
</mat-form-field>
 
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button
          [color]="data.buttonStyles ? data.buttonStyles[0] : 'primary'"
          [mat-dialog-close]="false">
    Cancel
  </button>
  <button [disabled]="!startNow && !validDate" mat-button data-testid="launchCampaignBtn"
          [color]="data.buttonStyles ? data.buttonStyles[1] : 'primary'"
          (click)="launch()">
    {{data.confirmText}}
  </button>
</mat-dialog-actions>