import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpService } from './http.service';
import { ChannelFeatureset, MobileInvoice } from 'conversation-domain';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { MobileInvoiceInfoSettings, AccountSettings } from 'flow-model';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private static MOBILE_INVOICE_ENABLED = 'MOBILE_INVOICE_ENABLED';
  private static SELECTED_CHANNELS = 'SELECTED_CHANNELS';
  public static ITEMS_LIMIT = 25;

  private featureSetSubject: BehaviorSubject<ChannelFeatureset>;
  featureSet$: Observable<ChannelFeatureset>;
  private channelsSubject: BehaviorSubject<String[]>;
  channels$: Observable<String[]>;
  private userRoleSubject: BehaviorSubject<String>;
  userRole$: Observable<String>;
  private mobileInvoiceInfoSettingsSubject: BehaviorSubject<MobileInvoiceInfoSettings>;
  mobileInvoiceInfoSettings$: Observable<MobileInvoiceInfoSettings>;
  private accountSettingsSubject: BehaviorSubject<AccountSettings>;
  accountSettings$: Observable<AccountSettings>;
  private loadedChannels: String[];

  @Output()
  updateFeatureset = new EventEmitter<ChannelFeatureset>();

  constructor(private httpService: HttpService) {
    this.featureSetSubject = new BehaviorSubject<ChannelFeatureset>(null);
    this.featureSet$ = this.featureSetSubject.asObservable();
    this.fetchFeatureset();

    this.channelsSubject = new BehaviorSubject<String[]>(null);
    this.channels$ = this.channelsSubject.asObservable();
    this.fetchChannels();

    this.accountSettingsSubject = new BehaviorSubject<AccountSettings>(null);
    this.accountSettings$ = this.accountSettingsSubject.asObservable();
    this.fetchAccountSettings();

    this.userRoleSubject = new BehaviorSubject<String>(null);
    this.userRole$ = this.userRoleSubject.asObservable();
    this.fetchUserRole();

    this.mobileInvoiceInfoSettingsSubject = new BehaviorSubject<MobileInvoiceInfoSettings>(null);
    this.mobileInvoiceInfoSettings$ = this.mobileInvoiceInfoSettingsSubject.asObservable();

    if (this.isMobileInvoiceActive()) {
        this.fetchMobileInvoiceSettings();
    }

  }

  fetchFeatureset(): void {
    this.httpService.loadFeatureset().subscribe(
      featureSet => {
        this.featureSetSubject.next(featureSet);
        this.updateFeatureset.emit(featureSet);
      },
      (response: HttpErrorResponse) => {
        if (response.status !== 401) {
          throw response;
        }
      });
  }

  fetchSelectedFeatureset(channels: string): void {
    this.featureSetSubject = new BehaviorSubject<ChannelFeatureset>(null);
    this.featureSet$ = this.featureSetSubject.asObservable();

    this.httpService.loadSelectedFeatureset(channels).subscribe(
      featureSet => {
        this.featureSetSubject.next(featureSet);
        this.updateFeatureset.emit(featureSet);
      },
      (response: HttpErrorResponse) => {
        if (response.status !== 401) {
          throw response;
        }
      });
  }

  fetchChannels(): void {
    this.httpService.loadChannels().subscribe(
      channels => {
        this.channelsSubject.next(channels);
        this.loadedChannels = channels;
      },
      (response: HttpErrorResponse) => {
        if (response.status !== 401) {
           throw response;
        }
      });
  }

  fetchAccountSettings(): void {
    this.httpService.loadAccountSettings().subscribe(
      accountSettings => {
        this.accountSettingsSubject.next(accountSettings);
      },
      (response: HttpErrorResponse) => {
        this.accountSettingsSubject.next(null);
        if (response.status !== 401) {
           throw response;
        }
      });
  }

  fetchUserRole(): void {
    this.httpService.loadUserRole().subscribe(
      role => {
        this.userRoleSubject.next(role);
      },
      (response: HttpErrorResponse) => {
        if (response.status !== 401) {
           throw response;
        }
      });
  }

  fetchMobileInvoiceSettings() {
    this.httpService.loadMobileInvoiceInfoSettings().subscribe(
        mobileInvoiceInfoSettings => {
          this.mobileInvoiceInfoSettingsSubject.next(mobileInvoiceInfoSettings);
        },
        (response: HttpErrorResponse) => {
          this.mobileInvoiceInfoSettingsSubject.next(null);
          if (response.status !== 401) {
            throw response;
          }
        }
      );
  }

  public isMobileInvoiceActive(): boolean {
    let mobileInvoiceEnabled = false;
    const mobileInvoice = localStorage.getItem(UserProfileService.MOBILE_INVOICE_ENABLED);
    if (mobileInvoice) {
      if (mobileInvoice === 'true') {
        mobileInvoiceEnabled = true;
      }
    }
    return mobileInvoiceEnabled;
  }


  public getConfiguredChannels(): string {
    const selectedChannels = localStorage.getItem(UserProfileService.SELECTED_CHANNELS);
    if (!selectedChannels) {
      return this.loadedChannels.join(',');
    }
    return selectedChannels;
  }

  public loadSelectedChannels(): string {
    const selectedChannels = localStorage.getItem(UserProfileService.SELECTED_CHANNELS);
    return selectedChannels;
  }
}
