import { EndNodeComponent } from './../../editor/renderer/node/end-node/end-node.component';
import { NodeDetailsService } from './../../../services/node-details.service';
import { EndNode } from 'flow-model';
import { Component, OnInit } from '@angular/core';
import { BasicNode } from '../../editor/renderer/node/basic-node';

@Component({
  selector: 'app-details-end',
  templateUrl: './details-end.component.html',
  styleUrls: ['./details-end.component.css']
})
export class DetailsEndComponent implements OnInit {

  basicNode: BasicNode;
  model: EndNode = null;

  constructor(private nodeDetailsService: NodeDetailsService) { }

  ngOnInit(): void {
    this.nodeDetailsService.node$.subscribe(
      nodeComponent => {
        this.basicNode = nodeComponent;
        this.model = (nodeComponent instanceof EndNodeComponent) ? nodeComponent.model : null;
      });
  }
}
