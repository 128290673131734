import { FreeTextComponent } from '../../node-shared-components/free-text/free-text.component';
import { NodeHelper } from 'src/app/services/node-helper.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractNodeUnion, MediaMessageNode } from 'flow-model';
import { UserProfileService } from '../../../../../services/user-profile.service';
import { SuggestionEnabledNode } from '../suggestion-enabled-node';
import { ChannelFeatureset, MediaFeatureset, SuggestionsNumberFeatureset } from 'conversation-domain';

import { MediaComponent } from '../../node-shared-components/media-component/media.component';
import { FlowEditorService } from 'src/app/services/flow-editor.service';

@Component({
  selector: 'app-media-node',
  templateUrl: './media-message-node.component.html',
  styleUrls: ['./media-message-node.component.css']
})
export class MediaMessageNodeComponent extends SuggestionEnabledNode implements OnInit {

  private SUGGESTION_AUDIO_NOT_SUPPORTED = 'Your channel configuration does not allow a media with audio format to has any suggestions';

  @ViewChild(MediaComponent)
  mediaComponent: MediaComponent;
  @ViewChild(FreeTextComponent)
  freeTextComponent: FreeTextComponent;

  model: MediaMessageNode = null;
  isMediaUrlRequired: true;
  mediaTypeIcon = 'image';

  private channels: string;

  constructor(@Inject(UserProfileService) userProfileService: UserProfileService) {
    super(userProfileService);

    userProfileService.channels$.subscribe(channels => {
      if (channels && !this.channels) {
        for (const channel of channels) {
          if (this.channels) {
            this.channels += ',' + channel.toString();
          } else {
            this.channels = channel.toString();
          }
        }
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addDirectOutput();
    this.model = NodeHelper.newMediaMessageNode(this.getId(), this.getPosition());

    this.mediaComponent.verifyFinished.subscribe(() => {
      this.verifyAudioMediaSuggestions();
    });
  }

  protected withFeatureset(featureSet: ChannelFeatureset) {
    super.withFeatureset(featureSet);
  }

  protected getSuggestionsNumberFeatureset(): SuggestionsNumberFeatureset {
    if (this.featureSet && this.featureSet.mediaMessageFeatureset) {
      return this.featureSet.mediaMessageFeatureset.suggestionsNumberFeatureset;
    }
  }

  loadFromModel(node: AbstractNodeUnion) {
    this.model = node as MediaMessageNode;
    this.loadSuggestions(this.model.suggestions);
    this.freeTextComponent.loadFromModel(this.model.onFreeText);
  }

  getModelObject(): AbstractNodeUnion {
    this.model.position = this.getPosition();
    this.model.nextMessageId = this.getDirectlyConnectedNodeId();
    this.model.onFreeText = this.getOnFreeText();
    this.model.suggestions = this.getSuggestionModel();
    return this.model;
  }

  addValidationErrors(errorReasons: Array<string>) {
    errorReasons.forEach((msg) => this.addErrorReason(msg));
    if (errorReasons && errorReasons.length > 0) {
      this.editor.trigger('flowchanged');
      this.updateReteNode();
    }
  }

  verifyNodeSpecific() {
    super.verifyNodeSpecific();
    if (this.mediaComponent) {
      this.mediaComponent.withMediaFeaturset(this.getMediaFeatureset());
      this.mediaComponent.mediaUrl = this.model.mediaUrl;
      this.mediaComponent.thumbnailUrl = this.model.thumbnailUrl;
      this.mediaComponent.verify();
      this.mediaTypeIcon = this.mediaComponent.getMediaTypeIcon();
      this.verifyAudioMediaSuggestions();
    }
  }

  private verifyAudioMediaSuggestions(): void {
    this.removeErrorReason(this.SUGGESTION_AUDIO_NOT_SUPPORTED);
    if (this.suggestions && this.suggestions.length > 0 && this.mediaComponent.mediaUrlInfo != null && this.mediaComponent.mediaUrlInfo.isMediaTypeAudio()) {
      const selectedChannels = this.userProfileService.loadSelectedChannels();
      const channels = selectedChannels ? selectedChannels : this.channels;
      if (channels && channels.indexOf('WAM') > -1) {
        this.addErrorReason(this.SUGGESTION_AUDIO_NOT_SUPPORTED);
      }
    }
  }

  private getMediaFeatureset(): MediaFeatureset {
    if (this.featureSet) {
      return this.featureSet.mediaMessageFeatureset.mediaFeatureset;
    }
    return null;
  }
}
