import { ChannelFeatureset } from 'conversation-domain';
import { FreeTextComponent } from './../../node-shared-components/free-text/free-text.component';
import { ReteOutput } from './../../../rete/controls/extended-output';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractNodeUnion, CarouselNode } from 'flow-model';
import { BasicNode } from '../basic-node';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { NodeHelper } from '../../../../../services/node-helper.service';
import { RichCardNodeComponent } from '../rich-card-node/rich-card-node.component';

@Component({
  selector: 'app-carousel-node',
  templateUrl: './carousel-node.component.html',
  styleUrls: ['./carousel-node.component.css']
})
export class CarouselNodeComponent extends BasicNode implements OnInit {

  private NO_CARDS = 'Your channel configuration does not allow any cards';
  private TOO_MANY_CARDS = 'Your channel configuration does not allow more than %d cards';
  private TOO_LESS_CARDS = 'Your channel configuration does not allow less than %d cards';
  private COUNT_OF_PREADDED_OUTPUTS = 2;

  @ViewChild(FreeTextComponent)
  freeTextComponent: FreeTextComponent;

  @Input()
  showNewCardField = true;

  model: CarouselNode = null;

  cardOutputs = new Array<ReteOutput>();
  showAddCardConnectionButton = true;

  private cardConnectionIndexCounter = 0;
  private maximumCards: number;

  constructor(@Inject(UserProfileService) public userProfileService: UserProfileService) {
    super(userProfileService);
  }

  protected onDisabledFreeText() {
    // override default behaviour
    this.addDirectOutput();
  }

  protected withFeatureset(featureSet: ChannelFeatureset) {
    super.withFeatureset(featureSet);
    this.maximumCards = featureSet.carouselCardFeatureset.maxCardNumbers;
    this.updateAddCardButton();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addDirectOutput();
    this.model = NodeHelper.newCarouselNode(this.getId(), this.getPosition());
    for (let i = 0; i < this.COUNT_OF_PREADDED_OUTPUTS; i++) {
      this.addCardOutput();
    }
    this.updateAddCardButton();
  }

  loadFromModel(node: AbstractNodeUnion) {
    this.model = node as CarouselNode;
    let countOfPreAddedOutputs = this.COUNT_OF_PREADDED_OUTPUTS;
    if (this.model.cardIDs) {
      this.model.cardIDs.forEach(cardNodeId => {
        if (countOfPreAddedOutputs-- <= 0) {
          this.addCardOutput();
        }
      });
    }
    this.freeTextComponent.loadFromModel(this.model.onFreeText);
  }

  getModelObject(): AbstractNodeUnion {
    this.model.position = this.getPosition();
    this.model.nextMessageId = this.getDirectlyConnectedNodeId();
    this.model.onFreeText = this.getOnFreeText();
    const cards = new Array<number>();
    this.cardOutputs.forEach(output => {
      if (output.hasConnection()) {
        cards.push(output.getConnectedNodeId());
      } else {
        cards.push(null);
      }
    });
    this.model.cardIDs = cards;
    return this.model;
  }

  addCardOutput(): ReteOutput {
    const cardConnectionOutput = ReteOutput.createCarouselRichCardOutput(this.cardConnectionIndexCounter++);
    this.cardOutputs.push(cardConnectionOutput);
    this.addOutput(cardConnectionOutput);
    this.updateAddCardButton();
    return cardConnectionOutput;
  }

  private updateAddCardButton() {
    if (this.cardOutputs && this.maximumCards != null) {
      this.showAddCardConnectionButton = this.cardOutputs.length < this.maximumCards;
    }
  }

  removeCardOutput(output: ReteOutput) {
    this.cardOutputs = this.cardOutputs.filter(obj => obj !== output);
    this.removeOutputWithConnections(output);
    this.updateAddCardButton();
    if (this.directOutput && this.cardOutputs.length === 0) {
      this.directOutput.optional = false;
    }
  }

  verifyNodeSpecific() {
    this.verifyFeatureSet();
    this.cardOutputs.forEach(outout => {
      if (!outout.hasConnection()) {
        this.addErrorReason('Missing rich card connection.');
      }
    });
  }

  verifyFeatureSet() {
    if (this.featureSet) {
      const carouselCardFeatureset = this.featureSet.carouselCardFeatureset;
      if (carouselCardFeatureset.maxCardNumbers === 0) {
        this.addErrorReason(this.NO_CARDS);
        return;
      }
      if (this.cardOutputs.length > carouselCardFeatureset.maxCardNumbers) {
        this.addErrorReason(this.TOO_MANY_CARDS, carouselCardFeatureset.maxCardNumbers);
        return;
      }
      if (this.cardOutputs.length < carouselCardFeatureset.minCardNumbers) {
        this.addErrorReason(this.TOO_LESS_CARDS, carouselCardFeatureset.minCardNumbers);
      }
    }
  }

  suggestionsChanged() {
    let anyRichCardContainsSuggestions = false;
    this.cardOutputs.forEach(output => {
      if (output.hasConnection() && (output.connections[0].input.node.data['nodeComponent'] as RichCardNodeComponent).hasSuggestions()) {
        anyRichCardContainsSuggestions = true;
      }
    });

    if (this.directOutput) {
      this.directOutput.optional = anyRichCardContainsSuggestions;
    }
    this.onChange();
  }

  public outputConnectionUpdated() {
    this.suggestionsChanged();
  }

  public inputConnectionUpdated() {
    this.suggestionsChanged();
  }
}
