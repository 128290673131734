import { EventEmitter } from '@angular/core';
import { HttpService } from './http.service';
import { ChannelFeatureset } from 'conversation-domain';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
export class UserProfileService {
    constructor(httpService) {
        this.httpService = httpService;
        this.updateFeatureset = new EventEmitter();
        this.featureSetSubject = new BehaviorSubject(null);
        this.featureSet$ = this.featureSetSubject.asObservable();
        this.fetchFeatureset();
        this.channelsSubject = new BehaviorSubject(null);
        this.channels$ = this.channelsSubject.asObservable();
        this.fetchChannels();
        this.accountSettingsSubject = new BehaviorSubject(null);
        this.accountSettings$ = this.accountSettingsSubject.asObservable();
        this.fetchAccountSettings();
        this.userRoleSubject = new BehaviorSubject(null);
        this.userRole$ = this.userRoleSubject.asObservable();
        this.fetchUserRole();
        this.mobileInvoiceInfoSettingsSubject = new BehaviorSubject(null);
        this.mobileInvoiceInfoSettings$ = this.mobileInvoiceInfoSettingsSubject.asObservable();
        if (this.isMobileInvoiceActive()) {
            this.fetchMobileInvoiceSettings();
        }
    }
    fetchFeatureset() {
        this.httpService.loadFeatureset().subscribe(featureSet => {
            this.featureSetSubject.next(featureSet);
            this.updateFeatureset.emit(featureSet);
        }, (response) => {
            if (response.status !== 401) {
                throw response;
            }
        });
    }
    fetchSelectedFeatureset(channels) {
        this.featureSetSubject = new BehaviorSubject(null);
        this.featureSet$ = this.featureSetSubject.asObservable();
        this.httpService.loadSelectedFeatureset(channels).subscribe(featureSet => {
            this.featureSetSubject.next(featureSet);
            this.updateFeatureset.emit(featureSet);
        }, (response) => {
            if (response.status !== 401) {
                throw response;
            }
        });
    }
    fetchChannels() {
        this.httpService.loadChannels().subscribe(channels => {
            this.channelsSubject.next(channels);
            this.loadedChannels = channels;
        }, (response) => {
            if (response.status !== 401) {
                throw response;
            }
        });
    }
    fetchAccountSettings() {
        this.httpService.loadAccountSettings().subscribe(accountSettings => {
            this.accountSettingsSubject.next(accountSettings);
        }, (response) => {
            this.accountSettingsSubject.next(null);
            if (response.status !== 401) {
                throw response;
            }
        });
    }
    fetchUserRole() {
        this.httpService.loadUserRole().subscribe(role => {
            this.userRoleSubject.next(role);
        }, (response) => {
            if (response.status !== 401) {
                throw response;
            }
        });
    }
    fetchMobileInvoiceSettings() {
        this.httpService.loadMobileInvoiceInfoSettings().subscribe(mobileInvoiceInfoSettings => {
            this.mobileInvoiceInfoSettingsSubject.next(mobileInvoiceInfoSettings);
        }, (response) => {
            this.mobileInvoiceInfoSettingsSubject.next(null);
            if (response.status !== 401) {
                throw response;
            }
        });
    }
    isMobileInvoiceActive() {
        let mobileInvoiceEnabled = false;
        const mobileInvoice = localStorage.getItem(UserProfileService.MOBILE_INVOICE_ENABLED);
        if (mobileInvoice) {
            if (mobileInvoice === 'true') {
                mobileInvoiceEnabled = true;
            }
        }
        return mobileInvoiceEnabled;
    }
    getConfiguredChannels() {
        const selectedChannels = localStorage.getItem(UserProfileService.SELECTED_CHANNELS);
        if (!selectedChannels) {
            return this.loadedChannels.join(',');
        }
        return selectedChannels;
    }
    loadSelectedChannels() {
        const selectedChannels = localStorage.getItem(UserProfileService.SELECTED_CHANNELS);
        return selectedChannels;
    }
}
UserProfileService.MOBILE_INVOICE_ENABLED = 'MOBILE_INVOICE_ENABLED';
UserProfileService.SELECTED_CHANNELS = 'SELECTED_CHANNELS';
UserProfileService.ITEMS_LIMIT = 25;
UserProfileService.ngInjectableDef = i0.defineInjectable({ factory: function UserProfileService_Factory() { return new UserProfileService(i0.inject(i1.HttpService)); }, token: UserProfileService, providedIn: "root" });
