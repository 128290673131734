<mat-radio-group [(ngModel)]="selectedOption" (change)="onRadioChange($event)">
  <mat-radio-button value="query" data-testid="viewLocationQueryOption">Query</mat-radio-button>
  <mat-radio-button value="latlng" data-testid="viewLocationLatLngOption">LatLng</mat-radio-button>
  <mat-radio-button value="latlngvariables" data-testid="viewLocationLatLngVariablesOption">Vars</mat-radio-button>
</mat-radio-group>

<div class="row" *ngIf="selectedOption == 'query'">
  <mat-form-field class="full-width">
    <input (keyup)="change.emit()" [(ngModel)]="location.searchQuery" matInput placeholder="Google map search query" type="text" data-testid="viewLocationQuery"/>
  </mat-form-field>
</div>

<div class="row" *ngIf="selectedOption != 'query'">
  <mat-form-field class="full-width">
    <input (keyup)="change.emit()" [(ngModel)]="location.label" data-testid="viewLocationLabel" matInput placeholder="Label" type="text"/>
  </mat-form-field>
</div>
<div class="row" *ngIf="selectedOption == 'latlng'">
  <mat-form-field class="half-width">
    <input (keyup)="change.emit()" [(ngModel)]="location.latitude" class="disable-spinner" step="0.00001" matInput placeholder="Latitude" type="number"
           data-testid="viewLocationLatitude"/>
  </mat-form-field>
  <mat-form-field class="half-width">
    <input (keyup)="change.emit()" [(ngModel)]="location.longitude" class="disable-spinner" step="0.00001" matInput placeholder="Longitude" type="number"
           data-testid="viewLocationLongitude"/>
  </mat-form-field>
</div>
<div class="row" *ngIf="selectedOption == 'latlngvariables'">
  <mat-form-field class="half-width">
    <span class="variable-input-addon" matPrefix>${{ '{' }}</span>
    <input (keyup)="change.emit()"
           [(ngModel)]="location.latitudeVariable"
           matInput
           placeholder="Lat. Variable"
           class="variable-input"
           data-testid="viewLocationLatitudeVar"/>
    <span class="variable-input-addon" matSuffix>}</span>
  </mat-form-field>
  <mat-form-field class="half-width">
    <span class="variable-input-addon" matPrefix>${{ '{' }}</span>
    <input (keyup)="change.emit()"
           [(ngModel)]="location.longitudeVariable"
           matInput
           placeholder="Lng. Variable"
           class="variable-input"
           data-testid="viewLocationLongitudeVar"/>
    <span class="variable-input-addon" matSuffix>}</span>
  </mat-form-field>
</div>
