import * as tslib_1 from "tslib";
import { NodeHelper } from '../../../../services/node-helper.service';
import { Component } from 'rete';
export class ReteEndNode extends Component {
    constructor() {
        super(ReteEndNode.nodeName);
    }
    builder(node) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            node.addInput(NodeHelper.createInput());
        });
    }
    worker(node, inputs, outputs, ...args) {
    }
}
ReteEndNode.nodeName = 'EndNode';
