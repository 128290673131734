import { MediaFeatureset } from 'conversation-domain';
import { MediaUrlInfo } from '../flow-application/editor/renderer/node-shared-components/media-component/media.component';
import { Injectable } from '@angular/core';
import { MediaInfo } from 'flow-model';

@Injectable({
  providedIn: 'root'
})

export class MediaValidationService {
  private MEDIA_NOT_ALLOWED = 'Your channel configuration does not allow usage of media';
  private MEDIA_URL_IS_MISSING = 'The media URL is required';
  private MEDIA_URL_NO_PROTOCOL = 'The media url must start with https://';
  private MEDIA_URL_DEAD_LINK = 'No valid media could be found using the given URL';
  private MEDIA_URL_INVALID_MIME_TYPE = 'The media must be of type: ';
  private MEDIA_SIZE_EXCEEDS_MAX_ALLOWED = 'The media size exceeds the maximum allowed size (in bytes) of : ';
  private THUMBNAIL_NOT_ALLOWED = 'Thumbnails are not supported by WhatsApp';
  private THUMBNAIL_URL_NO_PROTOCOL = 'The thumbnail url must start with https://';
  private THUMBNAIL_URL_DEAD_LINK = 'No valid thumbnail image could be found using the given URL';
  private THUMBNAIL_URL_INVALID_MIME_TYPE = 'The thumbnail must be of type: ';
  private THUMBNAIL_SIZE_EXCEEDS_MAX_ALLOWED = 'The thumbnail size exceeds the maximum allowed size (in bytes) of : ';
  private THUMBNAIL_REQUIRED = 'A thumbnail is required for your selected type of media';

  constructor() { }

  public validate(mediaUrlInfo: MediaUrlInfo, thumbnailMediaUrlInfo: MediaUrlInfo, mediaUrlRequired: boolean = true, mediaFeatureset: MediaFeatureset): Array<string> {
    const errorMessages = new Array<string>();
    if (mediaFeatureset === null) {
      return errorMessages;
    }
    if (!this.isMediaAllowedAtAll(mediaFeatureset)) {
      errorMessages.push(this.MEDIA_NOT_ALLOWED);
    } else if (mediaUrlRequired && !mediaUrlInfo.hasUrl()) {
      errorMessages.push(this.MEDIA_URL_IS_MISSING);
    } else if (mediaUrlInfo.hasExceptionError()) {
      errorMessages.push(mediaUrlInfo.mediaInfo.exceptionError);
    } else if (mediaUrlInfo.hasUrl() && !mediaUrlInfo.isLinkValid()) {
      errorMessages.push(this.MEDIA_URL_DEAD_LINK);
    } else if (mediaUrlInfo.hasUrl() && !this.isUrlProtocolValid(mediaUrlInfo.url)) {
      errorMessages.push(this.MEDIA_URL_NO_PROTOCOL);
    } else if (mediaUrlInfo.hasUrl() && !this.isMediaMimeTypeValid(mediaFeatureset, mediaUrlInfo.mediaInfo)) {
      errorMessages.push(this.MEDIA_URL_INVALID_MIME_TYPE + this.getAllowedMediaTypes(mediaFeatureset).join(', ').toLowerCase());
    } else if (mediaUrlInfo.hasUrl() && !this.isMediaSizeValid(mediaUrlInfo.mediaInfo.sizeInBytes, mediaFeatureset)) {
      errorMessages.push(this.MEDIA_SIZE_EXCEEDS_MAX_ALLOWED + this.getAllowedMaxMediaSizeInBytes(mediaFeatureset));
    } else {
      if (thumbnailMediaUrlInfo && thumbnailMediaUrlInfo.url) {
        // thumbnail is set
        if (!this.isThumbnailAllowedAtAll(mediaFeatureset)) {
          errorMessages.push(this.THUMBNAIL_NOT_ALLOWED);
        } else if (!this.isUrlProtocolValid(thumbnailMediaUrlInfo.url)) {
          errorMessages.push(this.THUMBNAIL_URL_NO_PROTOCOL);
        } else if (!thumbnailMediaUrlInfo.mediaInfo.linkValid) {
          errorMessages.push(this.THUMBNAIL_URL_DEAD_LINK);
        } else if (!this.isThumbnailMimeTypeValid(mediaFeatureset, thumbnailMediaUrlInfo.mediaInfo)) {
          errorMessages.push(this.THUMBNAIL_URL_INVALID_MIME_TYPE + this.getAllowedThumbnailTypes(mediaFeatureset).join(', ').toLowerCase());
        } else if (!this.isMediaSizeValid(thumbnailMediaUrlInfo.mediaInfo.sizeInBytes, mediaFeatureset)) {
          errorMessages.push(this.THUMBNAIL_SIZE_EXCEEDS_MAX_ALLOWED + this.getAllowedMaxMediaSizeInBytes(mediaFeatureset));
        }
      } else {
        // thumbnail is not set
        if (this.isThumbnailRequiredForSelectedMediaType(mediaFeatureset, mediaUrlInfo.mediaInfo)) {
          errorMessages.push(this.THUMBNAIL_REQUIRED);
        }
      }
    }
    return errorMessages;
  }

  private isUrlProtocolValid(url: string): boolean {
    return url.toLocaleLowerCase().startsWith('https://');
  }

  private isMediaAllowedAtAll(mediaFeatureset: MediaFeatureset): boolean {
    const allowedTypes = this.getAllowedMediaTypes(mediaFeatureset);
    return allowedTypes && allowedTypes.length > 0;
  }

  private getAllowedMediaTypes(mediaFeatureset: MediaFeatureset): string[] {
    return mediaFeatureset ? mediaFeatureset.mediaTypes || [] : ['IMAGE', 'VIDEO', 'AUDIO'];
  }

  private isThumbnailAllowedAtAll(mediaFeatureset: MediaFeatureset): boolean {
    const allowedTypes = this.getAllowedThumbnailTypes(mediaFeatureset);
    return allowedTypes && allowedTypes.length > 0;
  }

  private getAllowedThumbnailTypes(mediaFeatureset: MediaFeatureset): string[] {
    return mediaFeatureset ? mediaFeatureset.thumbnailTypes || [] : ['IMAGE'];
  }

  private isMediaMimeTypeValid(mediaFeatureset: MediaFeatureset, mediaInfo: MediaInfo): boolean {
    const allowedTypes = this.getAllowedMediaTypes(mediaFeatureset);
    return this.isFeaturesetTypeMatching(allowedTypes, mediaInfo.mimeType);
  }

  private getAllowedMaxMediaSizeInBytes(mediaFeatureset: MediaFeatureset): number {
    return 1024 * 1024 * (mediaFeatureset ? mediaFeatureset.maxFileSizeInMB || 10 : 10);
  }

  private isMediaSizeValid(sizeInBytes: any, mediaFeatureset: MediaFeatureset): boolean {
    return sizeInBytes <= this.getAllowedMaxMediaSizeInBytes(mediaFeatureset);
  }

  private isFeaturesetTypeMatching(featuresetTypes: string[], mimeType: string): boolean {
    if (!featuresetTypes || featuresetTypes.length === 0) {
      return true;
    }

    return featuresetTypes.some((type) => {
      switch (type) {
        case 'IMAGE':
          return this.isMimeType(mimeType, 'image/');
        case 'VIDEO':
          return this.isMimeType(mimeType, 'video/');
        case 'AUDIO':
          return this.isMimeType(mimeType, 'audio/');
        default:
          return false;
      }
    });
  }

  private isMimeType(mimeType: string, type: string) {
    return mimeType && mimeType.startsWith(type);
  }

  private isThumbnailMimeTypeValid(mediaFeatureset: MediaFeatureset, mediaInfo: MediaInfo): boolean {
    const allowedTypes = this.getAllowedThumbnailTypes(mediaFeatureset);
    return this.isFeaturesetTypeMatching(allowedTypes, mediaInfo.mimeType);
  }

  private isThumbnailRequiredForSelectedMediaType(mediaFeatureset: MediaFeatureset, mediaInfo: MediaInfo): boolean {
    const mediaTypesRequiringThumbnails = mediaFeatureset.mediaTypesRequiringThumbnails || [];
    if (!mediaTypesRequiringThumbnails || mediaTypesRequiringThumbnails.length === 0) {
      return false;
    }

    return this.isFeaturesetTypeMatching(mediaTypesRequiringThumbnails, mediaInfo.mimeType);
  }
}
