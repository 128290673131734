/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./media.component";
import * as i6 from "../../../../../services/media.validation.service";
import * as i7 from "../../../../../services/http.service";
var styles_MediaComponent = [i0.styles];
var RenderType_MediaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaComponent, data: {} });
export { RenderType_MediaComponent as RenderType_MediaComponent };
function View_MediaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "image-container"], ["data-testid", "thumbnailUrl"]], [[8, "src", 4]], [[null, "error"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.updateReteNode() !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (_co.updateReteNode() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.thumbnailUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_MediaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "image-container"], ["data-testid", "mediaUrl"]], [[8, "src", 4]], [[null, "error"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.updateReteNode() !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (_co.updateReteNode() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mediaUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_MediaComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["data-testid", "videoIcon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["videocam"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MediaComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["data-testid", "audioIcon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["mic"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MediaComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["data-testid", "warningIcon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["warning"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_MediaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "image-wrapper"], ["data-testid", "mediaComponent"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaComponent_5)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showThumbnail; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showMedia; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.showVideoIcon; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.showAudioIcon; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.showWarning; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_MediaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media", [], null, null, null, View_MediaComponent_0, RenderType_MediaComponent)), i1.ɵdid(1, 49152, null, 0, i5.MediaComponent, [i6.MediaValidationService, i7.HttpService], null, null)], null, null); }
var MediaComponentNgFactory = i1.ɵccf("app-media", i5.MediaComponent, View_MediaComponent_Host_0, { mediaUrlRequired: "mediaUrlRequired", mediaUrl: "mediaUrl", thumbnailUrl: "thumbnailUrl", messageNode: "messageNode" }, { validationErrors: "validationErrors", verifyFinished: "verifyFinished" }, []);
export { MediaComponentNgFactory as MediaComponentNgFactory };
