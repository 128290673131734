import { Injectable } from '@angular/core';
import { MobileInvoiceNode, MobileInvoiceInfoSettings, PaymentProvider } from 'flow-model';
import { strict } from 'assert';
import { ZoneId } from 'js-joda';

@Injectable({
    providedIn: 'root'
})
export class MobileInvoiceValidationService {

  public static MOBILE_INVOICE_URL_PLACEHOLDER = '{{InvoiceLinkUrl}}';

  private AMOUNT_PARAMETER_IS_MISSING = 'The amount parameter is required';

  private AMOUNT_PARAMETER_INVALID_INPUT = 'The amount parameter must be decimal number with max two decimals between 0 and 999999.99 or variable';

  private OCR_PARAMETER_INVALID_INPUT = 'The OCR parameter must be variable';

  private TEXT_MESSAGE_IS_MISSING = 'Message text is required';

  private INVALID_TEXT_MESSAGE = 'Message text should contain ' + MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER;

  private TEMPLATE_INVALID_INPUT = 'Mobile Invoice Template is not valid';

  private PROVIDER_INVALID_INPUT = 'Mobile Invoice Providers are not valid';

  private CURRENCY_INVALID_INPUT = 'Mobile Invoice Currency is not valid';

  private CURRENCY_IS_MISSING = 'The Mobile Invoice Currency is required';

  private TEMPLATE_IS_MISSING = 'The Mobile Invoice Template is required';

  private SKIP_LANDING_PAGE_IS_NOT_SUPPORTED = 'Selected payment providers do not support direct payment';

  private PAYMENT_PROVIDER_IS_MISSING = 'Mobile Invoice Providers are required';

  private LANGUAGE_INVALID_INPUT = 'Mobile Invoice language is not valid';

  private INVALID_INVOICE_SETTINGS = 'Failed to load mobile invoice settings';

  private INVALID_EXPIRATION_DATE = 'Expiration time should be after the current time';

  private EXPECTED_LENGTH_OF_LINK = 90;

  constructor() {}

  public validate (mobileInvoiceNode: MobileInvoiceNode): Array<string> {
    const errorMessages = new Array<string>();
    if (this.isNullOrEmpty(mobileInvoiceNode.amountParameter)) {
      errorMessages.push(this.AMOUNT_PARAMETER_IS_MISSING);
    } else if (!this.isDecimalNumber(mobileInvoiceNode.amountParameter) && !this.isVariable(mobileInvoiceNode.amountParameter)) {
        errorMessages.push(this.AMOUNT_PARAMETER_INVALID_INPUT);
    }
    if (!this.isNullOrEmpty(mobileInvoiceNode.ocrParameter) && !this.isVariable(mobileInvoiceNode.ocrParameter)) {
      errorMessages.push(this.OCR_PARAMETER_INVALID_INPUT);
    }
    this.verifyExpirationDate(mobileInvoiceNode, errorMessages);
    return errorMessages;
  }

  getProviders(paymentProviders: PaymentProvider[]): string[] {
    const providers: string[] = [];
    if (paymentProviders === null || paymentProviders === undefined) {
      return providers;
    }
    paymentProviders.forEach(paymentProvider => {
       providers.push(paymentProvider.paymentProvider);
    });
    return providers;
  }

  public validateMobileInvoiceSettings (mobileInvoiceNode: MobileInvoiceNode, mobileInvoiceInfoSettings: MobileInvoiceInfoSettings): Array<string> {
    const errorMessages = new Array<string>();
    if (mobileInvoiceInfoSettings === undefined || mobileInvoiceInfoSettings === null) {
      errorMessages.push(this.INVALID_INVOICE_SETTINGS);
      return errorMessages;
    }
    this.validateMobileInvoiceTemplate(mobileInvoiceNode.template, mobileInvoiceInfoSettings.templates, errorMessages);
    this.validateMobileInvoiceProviders(mobileInvoiceNode.providers, this.getProviders(mobileInvoiceInfoSettings.providers), errorMessages);
    this.validateSkipLandingPage(mobileInvoiceNode.skipLandingPage, mobileInvoiceNode.providers, mobileInvoiceInfoSettings.providers, errorMessages);
    this.validateMobileInvoiceCurrency(mobileInvoiceNode.currency, mobileInvoiceInfoSettings.currencies, errorMessages);
    if (this.isInvalid(mobileInvoiceNode.language, mobileInvoiceInfoSettings.languages)) {
      errorMessages.push(this.LANGUAGE_INVALID_INPUT);
    }
    this.verifyMessageText(mobileInvoiceNode, errorMessages);
    return errorMessages;
  }
  validateMobileInvoiceTemplate(template: string, possibleTemplates: string[], errorMessages: string[]) {
    if (this.isNullOrEmpty(template)) {
      errorMessages.push(this.TEMPLATE_IS_MISSING);
    } else if (this.isInvalid(template, possibleTemplates)) {
      errorMessages.push(this.TEMPLATE_INVALID_INPUT);
    }
  }

  validateSkipLandingPage(skipLandingPage: boolean, providers: string[], possibleProviders: PaymentProvider[] , errorMessages: string[]) {
    if (!skipLandingPage) {
      return;
    } else if (!this.isSupportsLandingPage(providers, possibleProviders)) {
      errorMessages.push(this.SKIP_LANDING_PAGE_IS_NOT_SUPPORTED);
    }
  }

  providerSupportsLandingLandingPage(provider: String, possibleProviders: PaymentProvider[]): boolean {
    let supportsLandingPage = false;
    possibleProviders.forEach(paymentProvider => {
      if (paymentProvider.paymentProvider === provider && paymentProvider.supportsDirectPayment) {
          supportsLandingPage = true;
      }
    });
    return supportsLandingPage;
  }

  isSupportsLandingPage(providers: string[], possibleProviders: PaymentProvider[] ): boolean {
    if (providers === null || providers === undefined) {
      return false;
    }
    if (providers.length === 1 && this.providerSupportsLandingLandingPage(providers[0], possibleProviders)) {
      return true;
    }
    return false;
  }

  validateMobileInvoiceProviders(providers: string[], possibleProviders: string[], errorMessages: string[]) {
    if (this.isNullOrEmptyList(providers)) {
      errorMessages.push(this.PAYMENT_PROVIDER_IS_MISSING);
    } else if (this.isListInvalid(providers, possibleProviders)) {
      errorMessages.push(this.PROVIDER_INVALID_INPUT);
    }
  }
  isNullOrEmptyList(providers: string[]): boolean {
    if (!providers) {
      return true;
    }
    if (providers.length === 0) {
      return true;
    }
    return false;
  }

  validateMobileInvoiceCurrency(currency: string, currencies: string[], errorMessages: string[]) {
    if (this.isNullOrEmpty(currency)) {
      errorMessages.push(this.CURRENCY_IS_MISSING);
    } else if (this.isInvalid(currency, currencies)) {
      errorMessages.push(this.CURRENCY_INVALID_INPUT);
    }
  }

  isValidMessageLength(messageText: string, length: number): boolean {
    const regExp = new RegExp(MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER, 'g');
    const regexCount = (messageText.match(regExp) || []).length;
    const expectedLength = messageText.length - (regexCount * MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER.length)
    + (regexCount * this.EXPECTED_LENGTH_OF_LINK);
    return expectedLength < length;
  }

  verifyMessageText(mobileInvoiceNode: MobileInvoiceNode, errorMessages: string[]) {
    const regex =  new RegExp(MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER, 'g');
    const messageText = mobileInvoiceNode && mobileInvoiceNode.text ? mobileInvoiceNode.text.en : null;
    if (!messageText || messageText.length === 0) {
      errorMessages.push(this.TEXT_MESSAGE_IS_MISSING);
    } else if (messageText.search(regex) < 0) {
      errorMessages.push(this.INVALID_TEXT_MESSAGE);
    }
  }

  verifyExpirationDate(mobileInvoiceNode: MobileInvoiceNode, errorMessages: string[]) {
    const now = Math.round(Date.now() / 1000);
    const expiryDate = mobileInvoiceNode.expiryUTC;
    if (expiryDate != null && expiryDate <= now) {
      errorMessages.push(this.INVALID_EXPIRATION_DATE);
    }
  }

  public isListInvalid(values: string[], possibleValues: string[]): boolean {
    if (values === null || values === undefined) {
      return false;
    }
    let found = true;
    values.forEach(value => {
      if (this.isInvalid(value, possibleValues)) {
        found = false;
      }
    });
    return !found;
  }

  public isInvalid(value: string, possibleValues: string[]): boolean {
    if (value === undefined || value === null) {
      return false;
    }
    if (possibleValues === undefined || possibleValues === null) {
        return true;
      } else {
        const found = possibleValues.some(possibleValue => {
          return possibleValue === value;
        });
        return !found;
      }
  }

  public isNullOrEmpty(value: string): boolean {
    if (value === undefined || value === null) {
      return true;
    }
    if (value.trim() === '') {
      return true;
    }
    return false;
  }

  public isVariable(value: string): boolean {
    const regex = /^\${([a-zA-Z]\w*)}$/;
    return regex.test(value);
  }

  public isDecimalNumber(value: string): boolean {
    const regex = /^(?!^(0|0\.0|0\.00)$)(0|[1-9][0-9]{0,5})(\.\d{1,2})?$/;
    return regex.test(value);
  }
}
