import { ReteOutput } from '../flow-application/editor/rete/controls/extended-output';
import { inputSocket } from '../flow-application/editor/rete/sockets';
import { Injectable } from '@angular/core';
import { Input as ReteInput } from 'rete';
import {
  AbstractNode,
  CardHeight,
  CardOrientation,
  CarouselCardWidth,
  CarouselNode,
  EndNode,
  KeywordMatch,
  KeywordsNode,
  MediaMessageNode,
  Position,
  RichCardNode,
  StartNode,
  TextMessageNode,
  MobileInvoiceNode,
  HttpRequestMessageNode
} from 'flow-model';
import { MobileInvoiceValidationService } from './mobile-invoice.validation.service';

@Injectable({
  providedIn: 'root'
})
export class NodeHelper {

  static DEFAULT_INPUT_KEY = 'default-input';

  static createInput(): ReteInput {
    const multiConnections = true;
    return new ReteInput(NodeHelper.DEFAULT_INPUT_KEY, NodeHelper.DEFAULT_INPUT_KEY, inputSocket, multiConnections);
  }

  static createStartNode(id: number, position: Position): StartNode {
    return this.setDefaults(new StartNode(), id, position) as StartNode;
  }

  static createEndNode(id: number, position: Position): EndNode {
    return this.setDefaults(new EndNode(), id, position) as EndNode;
  }

  static newTextMessageNode(id: number, position: Position): TextMessageNode {
    const model = this.setDefaults(new TextMessageNode(), id, position);
    model.template = this.emptyTemplate();
    return model;
  }

  static newHttpRequestMessageNode(id: number, position: Position): HttpRequestMessageNode {
    const model = this.setDefaults(new HttpRequestMessageNode(), id, position);
    return model;
  }

  static newMediaMessageNode(id: number, position: Position): MediaMessageNode {
    return this.setDefaults(new MediaMessageNode(), id, position);
  }

  static newRichCardNode(id: number, position: Position): RichCardNode {
    const model = this.setDefaults(new RichCardNode(), id, position);
    model.cardOrientation = CardOrientation.VERTICAL;
    model.cardHeight = CardHeight.TALL;
    model.title = this.emptyTemplate();
    model.description = this.emptyTemplate();
    return model;
  }

  static newCarouselNode(id: number, position: Position): CarouselNode {
    const model = this.setDefaults(new CarouselNode(), id, position);
    model.carouselCardWidth = CarouselCardWidth.MEDIUM;
    return model;
  }

  static newKeywordNode(id: number, position: Position): KeywordsNode {
    const model = this.setDefaults(new KeywordsNode(), id, position);
    model.inputVariableName = null;
    model.keywords = [];
    return model;
  }

  static newMediaInvoiceNode(id: number, position: Position): MobileInvoiceNode {
    const model = this.setDefaults(new MobileInvoiceNode(), id, position);
    model.text = this.emptyTemplate();
    model.text.en = MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER;
    model.description = this.emptyTemplate();
    model.synchronous = true;
    return model;
  }

  static emptyTemplate(): { [index: string]: any} {
    return {'en': ''};
  }

  static enumSelector(definition) {
    return Object.keys(definition).map(key => ({value: definition[key], title: key}));
  }

  private static setDefaults<T extends AbstractNode>(node: T, id: number, pos: Position): T {
    node['@type'] = node.constructor.name as any;
    node.id = id;
    node.position = pos;
    return node;
  }


  static getKeyword(output: ReteOutput): KeywordMatch {
    if (output.node.data[output.key]) {
      return output.node.data[output.key] as KeywordMatch;
    }
    return null;
  }

}
