import { Component, Input } from '@angular/core';
import { HttpVariables } from 'flow-model';
import { DialogService } from 'src/app/dialogs/dialog.service';

@Component({
    selector: 'app-variables-details-list',
    templateUrl: './variables-details-list.component.html',
    styleUrls: ['./variables-details-list.component.css']
  })
export class VariablesDetailsListComponent {

    @Input()
    httpVariables: HttpVariables[];

    constructor(private dialogService: DialogService) {
    }

    showVariables() {
        this.dialogService.variables('Response Variables', 'OK',
        this.httpVariables, confirmed => {
          if (confirmed) {
          }
        });
    }

}
