import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ReteOutput } from 'src/app/flow-application/editor/rete/controls/extended-output';
import { environment } from '../../../../../../../../environments/environment';
import { Input as ReteInput } from 'rete';

@Component({
    selector: 'app-invoice-status-outputs',
    templateUrl: './mobile-invoice-status-outputs.component.html'
})
export class MobileInvoiceStatusOutputComponent {

  @Input()
  output: ReteOutput;

  @Input()
  bindSocket: (el: HTMLElement, type: string, io: ReteInput | ReteOutput) => void;

  @Output()
  delete = new EventEmitter<ReteOutput>();

  env = environment;

  deleteStatusOutput(output: ReteOutput) {
      this.delete.emit(output);
  }
}
