import { Component, Node as ReteNode } from 'rete';
import { NodeData, WorkerInputs, WorkerOutputs } from 'rete/types/core/data';

export class ReteStartNode extends Component {

  public static nodeName = 'StartNode';

  constructor() {
    super(ReteStartNode.nodeName);
  }

  async builder(node: ReteNode) {
  }

  worker(node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs, ...args: any[]): any {
  }
}
