<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <div [innerHTML]="data.question"></div>
  <mat-form-field class="text-input">
  <textarea matInput
            cdkTextareaAutosize
            placeholder="Message Text" [(ngModel)]="text"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button
          [color]="data.buttonStyles ? data.buttonStyles[0] : 'primary'"
          [mat-dialog-close]="null">
    Cancel
  </button>
  <button mat-button
          [color]="data.buttonStyles ? data.buttonStyles[1] : 'primary'"
          [mat-dialog-close]="text">
    {{data.confirmText}}
  </button>
</mat-dialog-actions>
