/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./end-node.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../socket/socket.component.ngfactory";
import * as i3 from "../../socket/socket.component";
import * as i4 from "@angular/common";
import * as i5 from "ngx-contextmenu";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/scrolling";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "../../../../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i16 from "./end-node.component";
import * as i17 from "../../../../../services/user-profile.service";
var styles_EndNodeComponent = [i0.styles];
var RenderType_EndNodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EndNodeComponent, data: {} });
export { RenderType_EndNodeComponent as RenderType_EndNodeComponent };
function View_EndNodeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-socket", [["type", "input"]], null, null, null, i2.View_SocketComponent_0, i2.RenderType_SocketComponent)), i1.ɵdid(1, 4243456, null, 0, i3.SocketComponent, [], { type: [0, "type"], inputOutput: [1, "inputOutput"], bindSocket: [2, "bindSocket"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "input"; var currVal_1 = _co.directInput; var currVal_2 = _co.bindSocket; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_EndNodeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Delete Node "]))], null, null); }
export function View_EndNodeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { basicMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "node"], ["data-testid", "EndNode"]], null, [[null, "contextmenu"]], function (_v, en, $event) { var ad = true; if (("contextmenu" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onContextMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "selected": 0, error: 1 }), i1.ɵdid(4, 16384, null, 0, i5.ɵa, [i5.ContextMenuService], { contextMenu: [0, "contextMenu"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EndNodeComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 16777216, null, null, 2, "div", [["class", "text"], ["data-testid", "EndNode-node-name"], ["matTooltip", "Your flow ends here"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 147456, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i9.Platform, i10.AriaDescriber, i10.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i11.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i12.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(-1, null, ["End Flow"])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 16777216, null, null, 4, "mat-icon", [["class", "mat-icon notranslate"], ["data-testid", "nodeStatusIcon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 13)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(13, 147456, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i9.Platform, i10.AriaDescriber, i10.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i11.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i12.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵdid(14, 9158656, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null], [2, i14.MAT_ICON_LOCATION]], null, null), i1.ɵdid(15, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, 0, [" error_outline "])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "context-menu", [], null, null, null, i15.View_ContextMenuComponent_0, i15.RenderType_ContextMenuComponent)), i1.ɵdid(18, 180224, [[1, 4]], 1, i5.ContextMenuComponent, [i5.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i5.ɵc]], null, null), i1.ɵqud(603979776, 2, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.deleteNode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_EndNodeComponent_2)), i1.ɵdid(21, 16384, [[2, 4]], 0, i5.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "node"; var currVal_1 = _ck(_v, 3, 0, _co.selected, !_co.isValid); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.basicMenu; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.directInput; _ck(_v, 7, 0, currVal_3); var currVal_4 = "Your flow ends here"; _ck(_v, 9, 0, currVal_4); var currVal_7 = _co.validityErrorMessages; _ck(_v, 13, 0, currVal_7); _ck(_v, 14, 0); var currVal_8 = (_co.isValid ? "ok" : "error"); _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var currVal_5 = i1.ɵnov(_v, 14).inline; var currVal_6 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 12, 0, currVal_5, currVal_6); }); }
export function View_EndNodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-end-node", [], null, null, null, View_EndNodeComponent_0, RenderType_EndNodeComponent)), i1.ɵdid(1, 114688, null, 0, i16.EndNodeComponent, [i17.UserProfileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EndNodeComponentNgFactory = i1.ɵccf("app-end-node", i16.EndNodeComponent, View_EndNodeComponent_Host_0, {}, {}, []);
export { EndNodeComponentNgFactory as EndNodeComponentNgFactory };
