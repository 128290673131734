import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeywordData, KeywordMatch } from '../../../../../../model/keywords';
import { IO } from 'rete';

@Component({
  selector: 'app-keywords-list',
  templateUrl: './keywords-list.component.html',
  styleUrls: ['./keywords-list.component.css']
})
export class KeywordsListComponent {

  @Input()
  keywordsData: KeywordData[];

  @Input()
  bindSocket: (el: HTMLElement, type: string, io: IO) => void;

  @Output()
  createKeyword = new EventEmitter<KeywordMatch>();

  @Output()
  deleteKeyword = new EventEmitter<KeywordData>();

  @Output()
  changed = new EventEmitter<boolean>();

  onChange() {
    this.changed.emit(true);
  }

  addKeyword($event) {
    if ($event.target.value) {
      const keywordMatch = new KeywordMatch($event.target.value);
      this.createKeyword.emit(keywordMatch);
      $event.target.value = '';
    }
  }
}
