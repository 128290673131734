import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { MediaMessageNode } from 'flow-model';
import { SuggestionData } from 'src/app/model/suggestions';
import { BasicNode } from '../../editor/renderer/node/basic-node';
import { NodeDetailsService } from './../../../services/node-details.service';
import { MediaMessageNodeComponent } from './../../editor/renderer/node/media-message-node/media-message-node.component';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { ChannelFeatureset } from 'conversation-domain';
import { resetHistory } from 'cypress/types/sinon';

@Component({
  selector: 'app-details-media-message',
  templateUrl: './details-media-message.component.html',
  styleUrls: ['./details-media-message.component.css']
})
export class DetailsMediaMessageComponent implements AfterViewInit {

  @ViewChildren('mediaUrl')
  private mediaUrlInputField: QueryList<HTMLInputElement>;
  @ViewChildren('thumbnailUrl')
  private thumbnailUrlInputField: QueryList<HTMLInputElement>;

  basicNode: BasicNode;

  model: MediaMessageNode = null;
  suggestionsModel: SuggestionData[] = null;
  thumbnailDisabled = false;
  thumbnailDisabledFeatureSet = false;
  featureSet: ChannelFeatureset;

  constructor(private nodeDetailsService: NodeDetailsService, private userProfileService: UserProfileService) {
    if (userProfileService) {
      userProfileService.updateFeatureset.subscribe((featureSet: ChannelFeatureset) => {
        this.featureSet = featureSet;
        this.thumbnailDisabled = false;
        this.thumbnailDisabledFeatureSet = false;
        if (featureSet && featureSet.mediaMessageFeatureset && featureSet.mediaMessageFeatureset.mediaFeatureset) {
          if (!featureSet.mediaMessageFeatureset.mediaFeatureset.mediaTypesSupportingThumbnails || featureSet.mediaMessageFeatureset.mediaFeatureset.mediaTypesSupportingThumbnails.length === 0) {
            this.thumbnailDisabled = true;
            this.thumbnailDisabledFeatureSet = true;
          }
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.nodeDetailsService.node$.subscribe(
      nodeComponent => {
        this.basicNode = nodeComponent;
        this.updateModel(nodeComponent);
      });

    this.registerForChanges(this.mediaUrlInputField, (url: string) => this.model.mediaUrl = url ? url.trim() : url);
    this.registerForChanges(this.thumbnailUrlInputField, (url: string) => this.model.thumbnailUrl = url ? url.trim() : url);
  }

  private updateModel(node: BasicNode) {
    this.model = null;
    this.suggestionsModel = null;
    if (node instanceof MediaMessageNodeComponent) {
      this.model = node.model;
      this.suggestionsModel = node.suggestions;
    }
    this.updateView();
  }

  private registerForChanges(container: QueryList<HTMLInputElement>, setter: (url: string) => void) {
    container.changes.subscribe(e => {
      if (e.first) {
        fromEvent(e.first.nativeElement, 'input')
          .pipe(map((event: any) => event.target.value), debounceTime(500))
          .subscribe(url => {
            setter(url);
            this.updateView();
            this.basicNode.onChange();
          });
      }
    });
  }

  private updateView() {
    if (this.model) {
      if (this.thumbnailDisabled = (!this.model.mediaUrl || this.model.mediaUrl.trim() === '')) {
        this.model.thumbnailUrl = null;
      }
      this.thumbnailDisabled = this.thumbnailDisabledFeatureSet || this.thumbnailDisabled;
    }
  }

  setMediaUrl(urlInfo: object) {
    if (urlInfo['inputFieldId'] === 'mediaUrl') {
      this.model.mediaUrl = urlInfo['url'];
    }
    if (urlInfo['inputFieldId'] === 'thumbnailUrl') {
      this.model.thumbnailUrl = urlInfo['url'];
    }
    this.basicNode.onChange();
  }
}
