import { Component, Inject } from '@angular/core';
import { MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDatepickerInputEvent, MatDialogRef } from '@angular/material';
import { DateAsNumber } from 'flow-model';
import { DateTimeFormatter, Instant, LocalDate, LocalDateTime, LocalTime, convert, ZoneId, ZonedDateTime, nativeJs } from 'js-joda';
import * as moment from 'moment';


@Component({
  selector: 'app-campaign-dialog',
  templateUrl: './campaign-dialog.component.html',
  styleUrls: ['./campaign-dialog.component.css']
})
export class CampaignDialogComponent {

  forceStart = false;

  text: string;

  textHelpForceStartCampaign = 'This option will stop/delete all active conversations of the recipients in the campaign';

  labelPosition = 'before';

  hasWAChannel: boolean;

  hideFreeText: false;

  hasForceOption: false;

  hasWEBChannel: boolean;

  startNow: boolean;

  zone: string;

  minDate;
  maxDate;
  scheduledDate: Date;
  scheduledTime;
  localDateTime: LocalDateTime;
  time: string;
  editScheduledCampaign: boolean;
  validDate = false;
  errorMessage: string;

  textHideFreeText = 'This option will disable free text input when message is received on WEB and message does not allow free text';
  textHelpScheduleCampaign = 'You can plan a campaign for a later day or start it now. When the campaign is planned for a later date Enforce campaign is required. '
  + 'This option will stop/delete all active conversations of the recipients in the campaign.';
  tooltip: string = 'WhatsApp regulates when and how you can send messages with your end users. When an end user interacts with your campaign via WhatsApp message,' +
                    ' it kicks off a 24-hour WhatsApp "session" or "window" during which you can send free-form (non-templated) messages to the user.';

  constructor(private dialogRef: MatDialogRef<CampaignDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.hasWAChannel = data.channels && data.channels.indexOf('WAM') > -1;
    this.hasForceOption = data.hasForceOption;
    this.hasWEBChannel =  data.channels && data.channels.indexOf('WEB') > -1;
    this.editScheduledCampaign = data.editSheduledCampaign;
    if (this.editScheduledCampaign) {
      this.startNow = false;
      this.setScheduledDate(data.scheduledTime);
      this.forceStart = true;
      this.text = data.finalMessage;
      this.hideFreeText = data.hideFreeText;
    } else {
       this.startNow = true;
    }
    const currentDate = Date.now();
    this.zone = moment(currentDate).format('Z');
    const date = new Date();
    const currentYear = date.getFullYear();
    this.minDate = new Date(currentDate);
    this.maxDate = new Date(currentYear + 1, date.getMonth(), date.getDate());
    this.validDate = this.isValid();

  }

  setScheduledDate(time: number): void {
    this.localDateTime =  this.transformToLocalDateTime(time);
    this.scheduledDate = this.getDate(this.localDateTime);
    this.scheduledTime = this.getTime(this.localDateTime);
  }

  getDate(target: LocalDateTime): Date {
    if (target) {
      return convert(target, ZoneId.systemDefault()).toDate();
    }
  }

  transformToLocalDateTime(date: DateAsNumber): LocalDateTime {
    if (date) {
      return ZonedDateTime.ofInstant(Instant.ofEpochSecond(date), ZoneId.SYSTEM).toLocalDateTime();
    } else {
      return null;
    }
  }

  getTime(target: LocalDateTime): string {
    if (target) {
      return target.format(DateTimeFormatter.ofPattern('HH:mm'));
    }
  }

  scheduleOptionChanged(event) {
    if (!this.startNow) {
      this.forceStart = true;
    }
  }

  isValid(): boolean {
    if (this.startNow) {
      return true;
    }
    return this.localDateTime !== null && this.localDateTime !== undefined && this.isValidScheduledTime() && this.isAfterNow() && this.isBeforeMaxDate();
  }

  isValidLocalTime() {
    return this.localDateTime !== null && this.localDateTime !== undefined && this.isValidScheduledTime();
  }

  isAfterNow(): boolean {
    return (this.localDateTime.atZone(ZoneId.SYSTEM).toEpochSecond() > Math.trunc((new Date().getTime() / 1000)));
  }

  isBeforeMaxDate(): boolean {
    return this.localDateTime.isBefore(LocalDateTime.from(nativeJs(new Date())).plusYears(1));
  }

  isValidScheduledTime(): boolean {
    return this.scheduledTime !== null && this.scheduledTime !== undefined && this.scheduledTime !== '';
  }


  onDateChanged($event: MatDatepickerInputEvent<Date>, oldValue: LocalDateTime) {
    if ($event.value) {
      const newDate = LocalDate.from(nativeJs($event.value));
      if (oldValue) {
        return LocalDateTime.of(newDate, LocalTime.from(oldValue));
      } else {
        if (this.scheduledTime && this.scheduledTime instanceof String) {
          const newTime = LocalTime.parse(this.scheduledTime);
          return LocalDateTime.of(newDate, LocalTime.from(newTime));
        } else if (this.scheduledTime && this.scheduledTime instanceof LocalTime) {
          return LocalDateTime.of(newDate, LocalTime.from(this.scheduledTime));
        } else {
          this.scheduledTime = LocalTime.of(10, 0);
          return LocalDateTime.of(newDate, LocalTime.from(this.scheduledTime));
        }
      }
    } else {
      return null;
    }
  }

  validate() {
    this.validDate = this.isValid();
    if (!this.isValidLocalTime()) {
      this.errorMessage = 'Enter valid date and time';
    }
    if (!this.validDate) {
      if (this.isValidLocalTime() && !this.isAfterNow()) {
      this.errorMessage = 'Please choose time in the future';
      }
      if (this.isValidLocalTime() && !this.isBeforeMaxDate()) {
        this.errorMessage = 'Max time for scheduling is one year ahead';
      }
    }
  }

  onTimeChange($event, oldValue: LocalDateTime) {
    if (this.scheduledDate) {
      if ($event.target.value) {
        const newTime = LocalTime.parse($event.target.value);
        return LocalDateTime.of(LocalDate.from(oldValue), newTime);
      } else {
        return oldValue;
      }
    } else {
      this.time = $event.target.value;
    }
  }

  launch() {
    if (!this.startNow && !this.isValid()) {
       this.validate();
    } else {
      this.dialogRef.close(true);
    }
  }

}
