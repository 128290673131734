<div *ngIf="model">
  <div class="details-node-name">Start Flow</div>

  <mat-form-field class="full-width">

    <textarea matInput #smsTemplate
      [(ngModel)]="model.smsTemplate"
      (ngModelChange)="onChange()"
      (blur)="smsTemplateBlur()"
      placeholder="Initial SMS text (optional)"
      cdkTextareaAutosize
      data-testid="smsTemplate">
    </textarea>

    <div *ngIf="!showSmsTooLongError">
      <span class="character-counter">{{ countOfCharactersLeft }} characters left</span>
    </div>
    <div *ngIf="showSmsTooLongError">
      <span class="character-counter error">SMS text too long!</span>
    </div>

  </mat-form-field>

  <span>Example:</span>
  <div class="sms-example">
    LINK Mobility would like to chat with you. Tap https://mydialog.me/[conversationKey] to start.
  </div>
</div>
