<div *ngIf="model">
    <div class="details-node-name">Mobile Invoice</div>
    <app-name-node [model]="basicNode.model" (changed)="basicNode.onChange()"></app-name-node>

    <div class="row">
        <div class="title">
            <h3 style="flex-grow: 10">Template: </h3>
        </div>
        <button [matMenuTriggerFor]="templateMenu" class="optionMenu" mat-stroked-button data-testid="templateButton">
            <div class="option" *ngIf="model.template; let text; else noTemplate">{{ text }}
                <mat-icon class="alignright">arrow_drop_down</mat-icon>
            </div>
            <ng-template #noTemplate>
                <div class="option"><i>No Template</i>
                    <mat-icon class="alignright">arrow_drop_down</mat-icon>
                </div>
            </ng-template>
        </button>
    </div>

    <mat-menu #templateMenu="matMenu">
        <button *ngFor="let template of mobileInvoiceInfoSettings.templates; let i=index" (click)="changeTemplate(template)"
            mat-menu-item [attr.data-testid]="'templateMenu-' + i">
            <span>{{ template }}</span>
        </button>
    </mat-menu>


    <div class="row">
        <div class="title">
            <h3 style="flex-grow: 10">Currency: </h3>
        </div>
        <button [matMenuTriggerFor]="currencyMenu" class="optionMenu" mat-stroked-button data-testid="currencyButton">
            <div class="option" *ngIf="model.currency; let text; else noCurrency">{{ text }}
                <mat-icon class="alignright">arrow_drop_down</mat-icon>
            </div>
            <ng-template #noCurrency>
                <div class="option"><i>No Currency</i>
                    <mat-icon class="alignright">arrow_drop_down</mat-icon>
                </div>
            </ng-template>
        </button>
    </div>

    <mat-menu #currencyMenu="matMenu">
        <button *ngFor="let currency of mobileInvoiceInfoSettings.currencies; let i=index"
            (click)="changeCurrency(currency)" mat-menu-item [attr.data-testid]="'currencyMenu-' + i">
            <span>{{ currency }}</span>
        </button>
    </mat-menu>


    <div class="row">
        <div class="title">
            <h3 style="flex-grow: 10">Language: </h3>
        </div>
        <button [matMenuTriggerFor]="languageMenu" class="optionMenu" mat-stroked-button data-testid="languageButton">
            <div class="option" *ngIf="model.language; let text; else noLanguage">{{ text }}
                <mat-icon class="alignright">arrow_drop_down</mat-icon>
            </div>
            <ng-template #noLanguage>
                <div class="option"><i>No Language</i>
                    <mat-icon class="alignright">arrow_drop_down</mat-icon>
                </div>
            </ng-template>
        </button>
    </div>

    <mat-menu #languageMenu="matMenu">
        <button *ngFor="let language of mobileInvoiceInfoSettings.languages; let i=index" (click)="changeLanguage(language)"
            mat-menu-item [attr.data-testid]="'languageMenu-' + i">
            <span>{{ language }}</span>
        </button>
    </mat-menu>

    <div class="row">
        <div class="flex">
            <h3 style="flex-grow: 10">Select providers: </h3>
        </div>
    </div>
    <mat-checkbox *ngFor="let provider of mobileInvoiceInfoSettings.providers; let i=index"
        [checked]="isProviderSelected(provider.paymentProvider)" (change)="providerChanged($event, i)" class="freetext-checkbox"
        matTooltip="Select provider" [matTooltipShowDelay]="env.tooltipDelay" data-testid="'providerCheckBox-' + i">
        {{ provider.paymentProvider }}
    </mat-checkbox>

    <mat-checkbox *ngFor="let provider of invalidProviders(); let i=index" [checked]="isProviderSelected(provider)"
        color="warn" (change)="invalidProviderChanged($event, provider)" class="freetext-checkbox"
        matTooltip="Unselect invalid provider" [matTooltipShowDelay]="env.tooltipDelay"
        data-testid="'invalidProviderCheckBox-' + i">
        {{ provider }}
    </mat-checkbox>
    <div class="row">
        <div class="flex">
            <h3 style="flex-grow: 10">Direct payment: </h3>
        </div>
    </div>
    <mat-checkbox *ngIf="getShowLandingPage() || !model.skipLandingPage" [disabled] = "!getShowLandingPage() && !model.skipLandingPage" [checked]="model.skipLandingPage" (change)="showLandingPageChanged($event)"
        class="freetext-checkbox"
        [matTooltip]="getProvidersSupportingLandingPage()" [matTooltipShowDelay]="env.tooltipDelay"
        data-testid="skipLandingPage">
        Skip landing page
   </mat-checkbox>

   <mat-checkbox *ngIf="!getShowLandingPage() && model.skipLandingPage" [checked]="model.skipLandingPage" (change)="showLandingPageChanged($event)"
        class="freetext-checkbox"
        color="warn" 
        matTooltip="Selected providers does not allow to skip landing page or are not configured" [matTooltipShowDelay]="env.tooltipDelay"
        data-testid="skipLandingPageError">
        Skip landing page
    </mat-checkbox>
   
    <div class="row">
        <div class="flex">
            <h3 style="flex-grow: 10">Send as text message:</h3>
        </div>
    </div>
    <mat-checkbox [checked]="!this.model.linkMessage" (change)="sendAsLinkMessageChanged($event)"
        class="freetext-checkbox"
        [matTooltip]="getProvidersSupportingLandingPage()" [matTooltipShowDelay]="env.tooltipDelay"
        data-testid="skipLandingPage">
        Text message
   </mat-checkbox>
     

    <div class="row">
        <div class="flex">
            <h3 style="flex-grow: 10">Set expiration date: </h3>
        </div>
    </div>
    <div class="row">
        <mat-form-field class="half-width-40">
            <input matInput [min]="minDate" [max]="maxDate" [(ngModel)]="expiryDate"
                (dateChange)="this.localDateTime = onDateChanged($event, this.localDateTime); onChange()"
                [matDatepicker]="picker" placeholder="Date" type="text" data-testid="expiryDate" />
            <mat-datepicker-toggle [for]="picker" matSuffix data-testid="calendar-button"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="half-width-40">
            <input matInput [(ngModel)]="expiryTime"
                (change)="this.localDateTime = onTimeChange($event, this.localDateTime); onChange()" placeholder="Time"
                type="time" maxlength="5" data-testid="expiryTime" />
        </mat-form-field>
        <mat-icon class="clearDateTime" matDatepickerToggleIcon data-testid="clear-date"
            (click)="this.localDateTime = clearDateTime($event); onChange()">clear</mat-icon>
    </div>
    
    <app-output-variable [model]="basicNode.model" (changed)="basicNode.onChange()"></app-output-variable>

    <mat-form-field class="full-width">
        <input matInput id="variable" type="text"
          [(ngModel)]="model.partnerIdOutputVariableName"
          (ngModelChange)="basicNode.onChange()"
          placeholder="Partner id output variable (optional)"
          matTooltip="Enter a variable name to enable collecting partner id from this node"
          [matTooltipShowDelay]="env.tooltipDelay"
          appDragStopPropagation
          data-testid="partnerIdVariableName"/>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput id="variable" type="text"
          [(ngModel)]="model.transactionIdOutputVariableName"
          (ngModelChange)="basicNode.onChange()"
          placeholder="Transaction id output variable (optional)"
          matTooltip="Enter a variable name to enable collecting transactionId from this node"
          [matTooltipShowDelay]="env.tooltipDelay"
          appDragStopPropagation
          data-testid="transactionIdVariableName"/>
      </mat-form-field>
    

    <div class="row">
        <button mat-raised-button data-testid="testMobileInvoiceNode" (click)="testNode()" [disabled]="requestIsInProgress || !isMobileInvoiceActive()">Preview invoice</button>
        <button (click)="manageVariables()" data-testid="manageVariablesButton" mat-icon-button matTooltip="Set test variables" [disabled]="requestIsInProgress"><i class="fa fa-cog"></i></button>
        <mat-spinner diameter="22" *ngIf="requestIsInProgress"></mat-spinner>
     </div>

</div>