import { AfterViewInit, AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges } from '@angular/core';
import { AccountSettings, CampaignInfo, CampaignState, CampaignStatistics, DateAsNumber } from 'flow-model';
import { environment } from '../../../../../environments/environment';
import { DialogService } from '../../../../dialogs/dialog.service';
import { HttpService } from '../../../../services/http.service';
import { SnackBarService } from '../../../../services/snackbar.service';
import { ActivatedRoute } from '@angular/router';
import { CampaignData } from 'src/app/model/campaign';
import { Instant, LocalDateTime, ZoneId, ZonedDateTime } from 'js-joda';

@Component({
  selector: 'app-campaign-item',
  templateUrl: './campaign-item.component.html',
  styleUrls: ['./campaign-item.component.css']
})
export class CampaignItemComponent implements AfterViewInit, AfterViewChecked, OnInit, OnChanges {

  private static elHeight: number;

  env = environment;

  @Input()
  campaignsData: Map<string, CampaignData>;

  @Input()
  campaign: CampaignInfo;

  @Input()
  flowCanRun: boolean;

  @Input()
  last: boolean;

  @Input()
  left: number;

  @Input()
  top: number;

  @Output()
  campaignChanged = new EventEmitter();

  @Output()
  campaignInitiated = new EventEmitter();

  @Output()
  campaignScheduledEdit = new EventEmitter();

  @ViewChild('details')
  detailsView: ElementRef;

  isExpanded: boolean;
  detailsMargin: string;
  campaignState = CampaignState;
  campaignStats: CampaignStatistics;
  refreshing = false;
  campaignBusy = false;
  state: CampaignState;
  scheduedDateTooltip: string;

  constructor(private http: HttpService,
              private snackBar: SnackBarService,
              private route: ActivatedRoute,
              private dialogService: DialogService
              ) {
              }

  ngOnInit() {
    if (this.campaignsData.has(this.campaign.id)) {
      const data = this.campaignsData.get(this.campaign.id);
      this.isExpanded = data.isExpanded;
      this.campaignBusy = data.isBusy;
      this.campaignStats = data.stats;
      this.refreshing = data.isRefreshing;

      this.updateDetailsMargin();
      if (this.isExpanded && this.campaignStats === null) {
        this.updateStats();
      }

      if (this.campaignBusy && data.state != null) {
        this.campaign.state = data.state;
        this.campaign.counter = 0;
      }

      data.isBusy = false;
      data.state = null;
    }
  }

  ngOnChanges() {
    if (this.campaign.state === CampaignState.SCHEDULED) {
      this.scheduedDateTooltip = this.getEditScheduledCampaignTooltip(this.campaign);
    }
  }

  ngAfterViewInit(): void {
    if (this.detailsView != null) {
      CampaignItemComponent.elHeight = this.detailsView.nativeElement.offsetHeight;
    }

    if (this.last) {
      const sidebar = document.getElementById('sidebar');
      if (sidebar != null) {
        sidebar.scrollTo(this.left, this.top);
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.last) {
      const sidebar = document.getElementById('sidebar');
      if (sidebar != null) {
        sidebar.onscroll = function(this: GlobalEventHandlers, ev: Event) {};
      }
    }
  }

  getEditScheduledCampaignTooltip(campaign: CampaignInfo): string {
    return 'Edit scheduled Campaign ' + this.transformToLocalDateTime(campaign.executionTime).toString();
  }

  transformToLocalDateTime(date: DateAsNumber): LocalDateTime {
    if (date) {
      return ZonedDateTime.ofInstant(Instant.ofEpochSecond(date), ZoneId.SYSTEM).toLocalDateTime();
    } else {
      return null;
    }
  }

  editScheduledCampaign(campaign: CampaignInfo): void {
    this.updateCampaignData();
    this.campaignScheduledEdit.emit(campaign);
  }

  canBeDelete(): boolean {
    if (this.campaign === null) {
      return false;
    }
    return (this.campaign.state === CampaignState.NEW ||
    this.campaign.state === CampaignState.STOPPED ||
    this.campaign.state === CampaignState.RUNNING ||
    this.campaign.state === CampaignState.SCHEDULED) &&
    !this.campaignBusy;
  }

  canBeScheduled(): boolean {
    if (this.campaign == null) {
      return false;
    }
    return this.campaign.state === CampaignState.SCHEDULED && !this.campaignBusy;
  }

  canBeStop(): boolean {
    if (this.campaign === null) {
      return false;
    }
    return this.campaign.state === CampaignState.RUNNING && !this.campaignBusy;
  }

  deleteCampaign(campaign: CampaignInfo) {
    this.dialogService.confirmation('Delete Campaign?', `Are you sure you want to delete <b>${campaign.name}</b>?`,
      'Delete',
      (confirmed) => {
        if (confirmed) {
          this.campaignBusy = true;
          this.state = CampaignState.DELETING;
          this.updateCampaignData();
          this.http.deleteCampaign(campaign.id).subscribe(() => {
            return this.campaignChanged.emit();
          },
            () => this.campaignBusy = false);
        }
      });
  }

  runCampaign(campaign: CampaignInfo) {
    this.updateCampaignData();
    this.campaignInitiated.emit(campaign);
  }

  downloadCampaignResults(campaign: CampaignInfo) {
    this.http.downloadCampaignResult(campaign);
  }

  stopCampaign(campaign: CampaignInfo) {
    this.dialogService.textInput('Stop campaign?',
      '<p>A stopped campaign cannot be started again. ' +
      'Are you sure you want to stop the campaign <b>' + campaign.name + '</b>?</p>' +
      '<p>Optionally, you can provide a final message that is sent out ' +
      'to all conversations of the campaign below.</p>',
      'Stop campaign',
      text => {
        if (text != null) {
          this.campaignBusy = true;
          this.state = CampaignState.STOPPING;
          this.updateCampaignData();
          this.http.stopCampaign(campaign.id, text).subscribe(() => {
              this.snackBar.showSuccess('Campaign is stopping now!');
              this.campaignChanged.emit();
            },
            (e) => {
              this.campaignBusy = false;
              this.updateCampaignData();
              console.error('HTTP Status Code = ' + e.status + ' Body = ' + e.error);
              this.snackBar.showError('Could not stop campaign! Unknown error occurred.');
            }
          );
        }
      });
  }

  renameCampaign(campaign: CampaignInfo, newName: string) {
    if (campaign.name !== newName) {
      this.http.renameCampaign(campaign.id, campaign.name, newName).subscribe(() => {
          campaign.name = newName;
        },
        (e) => {
          this.snackBar.showError(e.error.message);
        }
      );
    }
  }

  updateDetailsMargin() {
    if (!this.isExpanded) {
      this.detailsMargin = `-${CampaignItemComponent.elHeight}px`;
    } else {
      this.detailsMargin = '0';
    }
  }

  onClickExpand() {
    this.isExpanded = !this.isExpanded;
    this.updateDetailsMargin();

    if (this.isExpanded) {
      this.updateStats();
    } else {
      this.updateCampaignData();
    }
  }

  updateCampaignData() {
    let data: CampaignData;
    if (this.campaignsData.has(this.campaign.id)) {
      data = this.campaignsData.get(this.campaign.id);
    } else {
      data = new CampaignData();
    }

    data.isExpanded = this.isExpanded;
    data.isBusy = this.campaignBusy;
    data.stats = this.campaignStats;
    data.state = this.state;
    data.isRefreshing = this.refreshing;

    this.campaignsData.set(this.campaign.id, data);
  }

  private updateStats() {
    this.refreshing = true;
    this.http.getCampaignStatistics(this.campaign.id).subscribe(stats => {
      this.campaignStats = stats;
      this.refreshing = false;
      this.updateCampaignData();
    });
  }

  isCampaingInProgress(): boolean {
    const result = this.campaign.state === CampaignState.LOADING ||
      this.campaign.state === CampaignState.INITIATING ||
      this.campaign.state === CampaignState.STOPPING ||
      this.campaign.state === CampaignState.DELETING ||
      this.campaign.state === CampaignState.STOPPING_MSISNDS ||
      this.campaignBusy;
    return result;
  }

  campaignProgress(): number {
    if (this.campaign.state === CampaignState.DELETING) {
      return (this.campaign.counter * 50) / this.campaign.size;
    }
    if (this.campaign.state === CampaignState.SCHEDULED) {
      return 100;
    }
    return (this.campaign.counter * 100) / this.campaign.size;
  }

  showProgressState(): string {
    if (this.campaign.state === CampaignState.LOADING || this.state === CampaignState.LOADING) {
      return 'Loading';
    }
    if (this.campaign.state === CampaignState.INITIATING || this.state === CampaignState.INITIATING) {
      return 'Initiating';
    }
    if (this.campaign.state === CampaignState.STOPPING || this.state === CampaignState.STOPPING) {
      return 'Stopping';
    }
    if (this.campaign.state === CampaignState.DELETING || this.state === CampaignState.DELETING) {
      return 'Deleting';
    }
    if (this.campaign.state === CampaignState.SCHEDULED || this.state === CampaignState.SCHEDULED) {
      return 'Updating';
    }
    if (this.campaign.state === CampaignState.STOPPING_MSISNDS || this.state === CampaignState.STOPPING_MSISNDS) {
      return 'Stopping running msisdns';
    }
    return 'NaN';
  }
}
