<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>

  <mat-list class="variable-list">
    <mat-list-item class="variable-item" *ngFor="let v of httpVariables; let i=index">
        <mat-form-field>
            <input matInput [(ngModel)]="v.name" placeholder="name" readonly>
        </mat-form-field>
        <mat-form-field>
            <input matInput [(ngModel)]="v.value" placeholder="value">
        </mat-form-field>
    </mat-list-item>
</mat-list>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button
          [color]="data.buttonStyles ? data.buttonStyles[0] : 'primary'"
          [mat-dialog-close]="null">
    Cancel
  </button>
  <button mat-button
          [color]="data.buttonStyles ? data.buttonStyles[1] : 'primary'"
          [mat-dialog-close]="httpVariables">
    {{data.confirmText}}
  </button>
</mat-dialog-actions>