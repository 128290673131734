import { AfterViewInit, Component, ElementRef, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { FlowEditorService } from '../../services/flow-editor.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { environment } from '../../../environments/environment';
import { Connection } from 'rete';

@Component({
  selector: 'app-flow-editor',
  templateUrl: './flow-editor.component.html',
  styleUrls: ['./flow-editor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FlowEditorComponent implements AfterViewInit {

  @ViewChild('nodeEditor')
  el: ElementRef;

  @ViewChild('editorMenu')
  public basicMenu: ContextMenuComponent;

  @ViewChild('connectionMenu')
  connectionMenu: ContextMenuComponent;
  private flowDirty: boolean;

  constructor(public flowService: FlowEditorService,
              private contextMenuService: ContextMenuService
  ) {
  }

  async createNode(nodeType: string) {
    const editor = this.flowService.getEditor();
    const node = await editor.getComponent(nodeType).createNode();
    node.position = this.flowService.getMousePosition();
    editor.addNode(node);
  }

  ngAfterViewInit() {
    this.flowService.initializeEditor(this.el.nativeElement);

    this.flowService.getEditor().on('renderconnection', ({el, connection}) => {
      el.addEventListener('contextmenu', e => {
        this.contextMenuService.show.next({
          contextMenu: this.connectionMenu,
          event: e,
          item: connection
        });
        e.stopPropagation();
        e.preventDefault();
      });
    });

    this.flowService.flowState$.subscribe(state => {
      this.flowDirty = state.dirty;
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler($event) {
    if (environment.enableUnloadEvent && this.flowDirty) {
      $event.returnValue = true;
    }
  }

  removeConnection(item: Connection) {
    this.flowService.getEditor().removeConnection(item);
  }
}
