<h2 mat-dialog-title>Regular Expressions</h2>
<mat-dialog-content class="mat-typography">
  <p>In some cases you want to match parts of the text with a little more flexibility
    than an exact match. What if, for example, you want to match a word that ends in a
    digit, regardless of which digit it is? In that case you can match it against
    a regular expression like this one: <code>word[0-9]</code></p>
  <p>Brackets <code>[...]</code> indicate that you want to match against one character
    from a specific list. In this example any digit between 0 and 9. You can also add up lists,
    e.g. <code>[0-9a-z]</code>, which would match all digits and all lowercase and uppercase
    characters. It is important to stress here that regular expressions in LINK Flow are case 
    insensitive. If you want to enable case sensitiveness you should check case sensitive checkbox.
    We allow using variables in regular expressions. 
    To refer variable you can use for example<code>${{ '{' }}variable{{'}'}}</code>
    When variables are replaced their values are enclosed with \Q \E. \Q and \E are respectively the start and end of a literal string in a regex literal. 
    They instruct the regex engine to not interpret the text in between those two "markers" as regexes.
  </p>
  <h3>Character classes</h3>
  <p>Writing out each of these lists would be cumbersome to write and hard to read, which is
    why most regular expression implementations offer <strong>Character classes</strong>, which are predefined
    lists of certain groups. A couple examples are:</p>
  <table>
    <tr>
      <th>Character</th>
      <th align="start">Matches</th>
    </tr>
    <tr>
      <td><code>.</code></td>
      <td>Any character</td>
    </tr>
    <tr>
      <td><code>\d</code></td>
      <td>All digits, 0-9</td>
    </tr>
    <tr>
      <td><code>\w</code></td>
      <td>Any alphanumeric character from the basic Latin alphabet, including the underscore</td>
    </tr>
    <tr>
      <td><code>\s</code></td>
      <td>A single white space character, including space, tab, form feed, line feed</td>
    </tr>
  </table>
  <p>Thus if you want to match a pattern of one letter and one digit, you can use the pattern
    <code>\w\d</code>.</p>
  <h3>Quantifiers</h3>
  <p>In some cases you want to match optionally or more than one character with one class or group. To
    handle this, you can use <strong>Quantifiers</strong>. <code>?</code> matches "optionally", meaning the
    character matches zero or one time. <code>*</code> matches 0 or multiple times and <code>+</code>
    matches one or multiple times.</p>
  <p>To give you an explanatory example, try to imagine you want to match the word "time" or "times".
    For this you can use the pattern <code>times?</code>. The question mark tells the parser that
    the character left of the quantifier can be there either zero or one time.</p>
  <p>The asterisk quantifier <code>*</code> is amongst the most popular quantifiers in regular expressions.
    If you want to match any character 0 or many times, you can write <code>.*</code>, which happens
    to be the symbol mostly used to indicate that something is a regular expression.</p>
  <p>However, if you need a certain character at least one time, use the plus <code>+</code>. For example
    to match a street address including a house number you can use the pattern <code>The Street \d+</code>,
    which will match every string "The Street" followed by at least one digit.</p>
  <h3>Further reading</h3>
  <p>There are countless tutorials and How-Tos about regular expressions (or <strong>regex</strong> for short)
    on the internet. The implementation we are using is the EcmaScript, or JavaScript engine. A
    reference to its syntax can be found here:
    <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions" target="_blank">
      MDN: Regular Expressions</a></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button color="primary" [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
