import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injector } from '@angular/core';
import { StartNodeComponent } from '../flow-application/editor/renderer/node/start-node/start-node.component';
import { TextMessageNodeComponent } from '../flow-application/editor/renderer/node/text-message-node/text-message-node.component';
import { EndNodeComponent } from '../flow-application/editor/renderer/node/end-node/end-node.component';
import { ReteTextMessageNode } from '../flow-application/editor/rete/components/text-message-node';
import { ReteHttpRequestMessageNode } from '../flow-application/editor/rete/components/http-request-message-node';
import { HttpRequestMessageNodeComponent } from '../flow-application/editor//renderer/node/http-request-message-node/http-request-message-node.component';
import { ReteStartNode } from '../flow-application/editor/rete/components/start-node';
import { ReteEndNode } from '../flow-application/editor/rete/components/end-node';
import { ReteKeywordsNode } from '../flow-application/editor/rete/components/keywords-node';
import { KeywordsNodeComponent } from '../flow-application/editor/renderer/node/keywords-node/keywords-node.component';
import { ReteMediaMessageNode } from '../flow-application/editor/rete/components/media-message-node';
import { MediaMessageNodeComponent } from '../flow-application/editor/renderer/node/media-message-node/media-message-node.component';
import { ReteRichCardNode } from '../flow-application/editor/rete/components/rich-card-node';
import { RichCardNodeComponent } from '../flow-application/editor/renderer/node/rich-card-node/rich-card-node.component';
import { ReteCarouselNode } from '../flow-application/editor/rete/components/carousel-node';
import { CarouselNodeComponent } from '../flow-application/editor/renderer/node/carousel-node/carousel-node.component';
import { ReteMobileInvoiceNode } from '../flow-application/editor/rete/components/mobile-invoice-node';
import { MobileInvoiceNodeComponent } from '../flow-application/editor/renderer/node/mobile-invoice-node/mobile-invoice-node.component';
import * as i0 from "@angular/core";
export class FlowRendererService {
    constructor(resolver, injector, app) {
        this.resolver = resolver;
        this.injector = injector;
        this.app = app;
        this.name = 'angular-renderer';
    }
    createNodeComponent(editor, node, el, bindSocket, bindControl) {
        const nodeFactory = this.getNodeFactory(node);
        const nodeRef = nodeFactory.create(this.injector);
        nodeRef.instance.node = node;
        nodeRef.instance.nativeNodeElement = el;
        nodeRef.instance.editor = editor;
        nodeRef.instance.bindSocket = bindSocket;
        nodeRef.instance.bindControl = bindControl;
        this.app.attachView(nodeRef.hostView);
        nodeRef.changeDetectorRef.detectChanges();
        const nativeElement = nodeRef.hostView.rootNodes[0];
        el.appendChild(nativeElement);
        return nodeRef;
    }
    getNodeFactory(node) {
        switch (node.name) {
            case ReteStartNode.nodeName:
                return this.resolver.resolveComponentFactory(StartNodeComponent);
            case ReteEndNode.nodeName:
                return this.resolver.resolveComponentFactory(EndNodeComponent);
            case ReteTextMessageNode.nodeName:
                return this.resolver.resolveComponentFactory(TextMessageNodeComponent);
            case ReteMediaMessageNode.nodeName:
                return this.resolver.resolveComponentFactory(MediaMessageNodeComponent);
            case ReteKeywordsNode.nodeName:
                return this.resolver.resolveComponentFactory(KeywordsNodeComponent);
            case ReteRichCardNode.nodeName:
                return this.resolver.resolveComponentFactory(RichCardNodeComponent);
            case ReteCarouselNode.nodeName:
                return this.resolver.resolveComponentFactory(CarouselNodeComponent);
            case ReteMobileInvoiceNode.nodeName:
                return this.resolver.resolveComponentFactory(MobileInvoiceNodeComponent);
            case ReteHttpRequestMessageNode.nodeName:
                return this.resolver.resolveComponentFactory(HttpRequestMessageNodeComponent);
        }
        return null;
    }
    // noinspection JSUnusedGlobalSymbols
    install(editor) {
        editor.on('rendernode', ({ el, node, component, bindSocket, bindControl }) => {
            if (component.render && component.render !== 'flow') {
                return;
            }
            const componentRef = this.createNodeComponent(editor, node, el, bindSocket, bindControl);
            node.data['ngComponentRef'] = componentRef;
            node.data['nodeComponent'] = componentRef.instance;
        });
        editor.on('noderemoved', (node) => {
            node.data['ngComponentRef'].destroy();
        });
    }
}
FlowRendererService.ngInjectableDef = i0.defineInjectable({ factory: function FlowRendererService_Factory() { return new FlowRendererService(i0.inject(i0.ComponentFactoryResolver), i0.inject(i0.INJECTOR), i0.inject(i0.ApplicationRef)); }, token: FlowRendererService, providedIn: "root" });
