/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flow-editor.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-contextmenu";
import * as i3 from "../../../../node_modules/ngx-contextmenu/ngx-contextmenu.ngfactory";
import * as i4 from "@angular/common";
import * as i5 from "./flow-editor.component";
import * as i6 from "../../services/flow-editor.service";
var styles_FlowEditorComponent = [i0.styles];
var RenderType_FlowEditorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FlowEditorComponent, data: {} });
export { RenderType_FlowEditorComponent as RenderType_FlowEditorComponent };
function View_FlowEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" + Text Message "]))], null, null); }
function View_FlowEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" + Media Message "]))], null, null); }
function View_FlowEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" + Rich Card Message "]))], null, null); }
function View_FlowEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createNode("RichCardNode") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FlowEditorComponent_4)), i1.ɵdid(1, 16384, [[4, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], null, null); }
function View_FlowEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" + Data "]))], null, null); }
function View_FlowEditorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" + Text Match "]))], null, null); }
function View_FlowEditorComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" + Carousel "]))], null, null); }
function View_FlowEditorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createNode("CarouselNode") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FlowEditorComponent_8)), i1.ɵdid(1, 16384, [[4, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], null, null); }
function View_FlowEditorComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" + Mobile Invoice "]))], null, null); }
function View_FlowEditorComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createNode("MobileInvoiceNode") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FlowEditorComponent_10)), i1.ɵdid(1, 16384, [[4, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], null, null); }
function View_FlowEditorComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" + End Flow "]))], null, null); }
function View_FlowEditorComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Remove connection "]))], null, null); }
export function View_FlowEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { el: 0 }), i1.ɵqud(402653184, 2, { basicMenu: 0 }), i1.ɵqud(402653184, 3, { connectionMenu: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 26, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["nodeEditor", 1]], null, 25, "div", [["class", "flow-editor"], ["data-testid", "flow-editor"]], null, [[null, "contextmenu"]], function (_v, en, $event) { var ad = true; if (("contextmenu" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onContextMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.ɵa, [i2.ContextMenuService], { contextMenu: [0, "contextMenu"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 18, "context-menu", [], null, null, null, i3.View_ContextMenuComponent_0, i3.RenderType_ContextMenuComponent)), i1.ɵdid(7, 180224, [[2, 4], ["editorMenu", 4]], 1, i2.ContextMenuComponent, [i2.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i2.ɵc]], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createNode("TextMessageNode") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FlowEditorComponent_1)), i1.ɵdid(10, 16384, [[4, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createNode("MediaMessageNode") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FlowEditorComponent_2)), i1.ɵdid(12, 16384, [[4, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlowEditorComponent_3)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createNode("HttpRequestMessageNode") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FlowEditorComponent_5)), i1.ɵdid(16, 16384, [[4, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createNode("KeywordsNode") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FlowEditorComponent_6)), i1.ɵdid(18, 16384, [[4, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlowEditorComponent_7)), i1.ɵdid(20, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlowEditorComponent_9)), i1.ɵdid(22, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.createNode("EndNode") !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FlowEditorComponent_11)), i1.ɵdid(24, 16384, [[4, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" }), (_l()(), i1.ɵeld(25, 0, null, null, 4, "context-menu", [], null, null, null, i3.View_ContextMenuComponent_0, i3.RenderType_ContextMenuComponent)), i1.ɵdid(26, 180224, [[3, 4], ["connectionMenu", 4]], 1, i2.ContextMenuComponent, [i2.ContextMenuService, i1.ChangeDetectorRef, i1.ElementRef, [2, i2.ɵc]], null, null), i1.ɵqud(603979776, 5, { menuItems: 1 }), (_l()(), i1.ɵand(0, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.removeConnection($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FlowEditorComponent_12)), i1.ɵdid(29, 16384, [[5, 4]], 0, i2.ɵb, [i1.TemplateRef, i1.ElementRef], null, { execute: "execute" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.basicMenu; _ck(_v, 5, 0, currVal_0); var currVal_1 = (((_co.flowService.featureSet && _co.flowService.featureSet.richCardFeatureset) && _co.flowService.featureSet.richCardFeatureset.titleLength) && (_co.flowService.featureSet.richCardFeatureset.titleLength > 0)); _ck(_v, 14, 0, currVal_1); var currVal_2 = ((_co.flowService.featureSet && _co.flowService.featureSet.carouselCardFeatureset) && (_co.flowService.featureSet.carouselCardFeatureset.maxCardNumbers > 0)); _ck(_v, 20, 0, currVal_2); var currVal_3 = _co.flowService.getMobileInvoiceEnabled(); _ck(_v, 22, 0, currVal_3); }, null); }
export function View_FlowEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-flow-editor", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).beforeunloadHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FlowEditorComponent_0, RenderType_FlowEditorComponent)), i1.ɵdid(1, 4243456, null, 0, i5.FlowEditorComponent, [i6.FlowEditorService, i2.ContextMenuService], null, null)], null, null); }
var FlowEditorComponentNgFactory = i1.ɵccf("app-flow-editor", i5.FlowEditorComponent, View_FlowEditorComponent_Host_0, {}, {}, []);
export { FlowEditorComponentNgFactory as FlowEditorComponentNgFactory };
