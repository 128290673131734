import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MediaFeatureset } from 'conversation-domain';
import { HttpService } from '../../../services/http.service';
import { SnackBarService } from '../../../services/snackbar.service';
import { BasicNode } from '../../editor/renderer/node/basic-node';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-upload-media',
  templateUrl: './upload-media.component.html',
  styleUrls: ['./upload-media.component.css']
})
export class UploadMediaComponent implements OnInit {

  flowId: string;

  @ViewChild('fileInput')
  fileInput: ElementRef;

  uploadInProgress = false;
  private file: File;
  env = environment;
  @Input()
  node: BasicNode;
  @Input()
  inputFieldId: string;
  @Input()
  disabled = false;

  @Output()
  changed = new EventEmitter<boolean>();

  @Output()
  fileUploaded = new EventEmitter<object>();

  onChange() {
    this.changed.emit(true);
  }

  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private snackBar: SnackBarService) {
  }

  ngOnInit() {
  }

  selectFile() {
    this.fileInput.nativeElement.click();
  }

  clearFile() {
    this.fileUploaded.emit({'inputFieldId': this.inputFieldId, 'url': ''});
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    const dt = event.dataTransfer;
    if (dt.files.length === 1) {
      this.file = dt.files[0];
      this.uploadMedia();
    }
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;
    if (files.length === 1) {
      this.file = files[0];
      this.uploadMedia();
    }
  }

  private uploadMedia() {
    if (this.validateFile(this.file, this.node.featureSet.mediaMessageFeatureset.mediaFeatureset)) {
      this.uploadInProgress = true;
      this.http.uploadMedia(this.file, this.file.name, LoginService.getAccountName()).subscribe(
        result => {
          this.finalizeUpload();
          this.snackBar.showSuccess('Media uploaded');
          this.fileUploaded.emit({'inputFieldId': this.inputFieldId, 'url': result});
        },
        e => {
          this.finalizeUpload();
          this.snackBar.showError(e.error);
        }
      );
    }
  }

  private validateFile(fileForValidation: File, mediaFeatureset: MediaFeatureset) {
    if (!this.isMediaSizeValid(fileForValidation.size, mediaFeatureset)) {
      this.snackBar.showError('Media size is greater than the limit of ' + mediaFeatureset.maxFileSizeInMB + 'MB');
      return false;
    }
    if (!this.isMediaMimeTypeValid(mediaFeatureset, fileForValidation.type)) {
      this.snackBar.showError('Media type is not supported! Allowed types are: ' + this.getAllowedMediaTypes(mediaFeatureset));
      return false;
    }
    return true;
  }

  private isMediaMimeTypeValid(mediaFeatureset: MediaFeatureset, mimeType: string): boolean {
    const allowedTypes = this.getAllowedMediaTypes(mediaFeatureset);
    return this.isFeaturesetTypeMatching(allowedTypes, mimeType);
  }

  private getAllowedMediaTypes(mediaFeatureset: MediaFeatureset): string[] {
    return mediaFeatureset ? mediaFeatureset.mediaTypes || [] : ['IMAGE', 'VIDEO', 'AUDIO'];
  }

  private isFeaturesetTypeMatching(featuresetTypes: string[], mimeType: string): boolean {
    if (!featuresetTypes || featuresetTypes.length === 0) {
      return true;
    }

    return featuresetTypes.some((type) => {
      switch (type) {
        case 'IMAGE':
          return this.isMimeType(mimeType, 'image/');
        case 'VIDEO':
          return this.isMimeType(mimeType, 'video/');
        case 'AUDIO':
          return this.isMimeType(mimeType, 'audio/');
        default:
          return false;
      }
    });
  }

  private isMimeType(mimeType: string, type: string) {
    return mimeType && mimeType.startsWith(type);
  }

  private isMediaSizeValid(sizeInBytes: any, mediaFeatureset: MediaFeatureset): boolean {
    return sizeInBytes <= this.getAllowedMaxMediaSizeInBytes(mediaFeatureset);
  }

  private getAllowedMaxMediaSizeInBytes(mediaFeatureset: MediaFeatureset): number {
    return 1024 * 1024 * (mediaFeatureset ? mediaFeatureset.maxFileSizeInMB || 10 : 10);
  }


  private finalizeUpload() {
    this.uploadInProgress = false;
    this.file = null;
    this.fileInput.nativeElement.value = '';
  }
}
