<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<ng-keyboard-shortcuts-help [key]="'f1'" title="Help"></ng-keyboard-shortcuts-help>
<app-topbar [dirty]="flowDirty" [flowName]="flowEditorService.flow?.name" backTarget="/"></app-topbar>
<mat-drawer-container autosize class="drawer-container">
  <mat-drawer #drawer position="end" mode="side">
    <app-details-panel></app-details-panel>
  </mat-drawer>
  <mat-drawer-content>
    <div class="editor">
      <app-editor-sidebar (settingsOpened)="toggleSettings()" #sideBar></app-editor-sidebar>
      <app-flow-editor></app-flow-editor>
    </div>
    <div #detailsPanel class="detail-panel-toggle" (click)="toggleDrawer()" data-testid='toggle-details-button'>
      <mat-icon [ngClass]="drawer.opened ? 'open' : 'close'">keyboard_arrow_left</mat-icon>
    </div>

  </mat-drawer-content>
</mat-drawer-container>
