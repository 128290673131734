/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./flow-upload-dialog.component";
var styles_FlowUploadDialogComponent = [];
var RenderType_FlowUploadDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FlowUploadDialogComponent, data: {} });
export { RenderType_FlowUploadDialogComponent as RenderType_FlowUploadDialogComponent };
export function View_FlowUploadDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 8, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 3, "button", [["data-testid", "confirmDialogCancel"], ["mat-button", ""], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).dialogRef.close(i0.ɵnov(_v, 9).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(9, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i0.ɵdid(10, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(-1, 0, ["Cancel"])), (_l()(), i0.ɵeld(12, 0, null, null, 2, "button", [["data-testid", "fileUploadDialog"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(13, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(14, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_6 = false; _ck(_v, 9, 0, currVal_6); var currVal_7 = (_co.data.buttonStyles ? _co.data.buttonStyles[0] : "primary"); _ck(_v, 10, 0, currVal_7); var currVal_10 = (_co.data.buttonStyles ? _co.data.buttonStyles[1] : "primary"); _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.question; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 9).ariaLabel || null); var currVal_4 = (i0.ɵnov(_v, 10).disabled || null); var currVal_5 = (i0.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); var currVal_8 = (i0.ɵnov(_v, 13).disabled || null); var currVal_9 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_8, currVal_9); var currVal_11 = _co.data.confirmText; _ck(_v, 14, 0, currVal_11); }); }
export function View_FlowUploadDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-flow-upload-dialog", [], null, null, null, View_FlowUploadDialogComponent_0, RenderType_FlowUploadDialogComponent)), i0.ɵdid(1, 49152, null, 0, i7.FlowUploadDialogComponent, [i1.MAT_DIALOG_DATA, i1.MatDialogRef], null, null)], null, null); }
var FlowUploadDialogComponentNgFactory = i0.ɵccf("app-flow-upload-dialog", i7.FlowUploadDialogComponent, View_FlowUploadDialogComponent_Host_0, {}, {}, []);
export { FlowUploadDialogComponentNgFactory as FlowUploadDialogComponentNgFactory };
