<div *ngIf="suggestions && suggestions.length > 0">
  <div class="suggestions-title">Suggestions</div>
  <div *ngFor="let sugData of suggestions; let last = last">

    <div class="row">
      <button [matMenuTriggerFor]="menu" class="menu" mat-stroked-button data-testid="suggestionType">
        <mat-icon>{{ sugData.suggestionType.icon }}</mat-icon>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <div class="label" *ngIf="sugData.suggestion.template['en']; let text; else noDescription">{{ text }}</div>
      <ng-template #noDescription>
        <div class="label"><i>No Text</i></div>
      </ng-template>
    </div>

    <ng-container [ngSwitch]="sugData.suggestionType.label">
      <div *ngSwitchCase="'Open URL'" class="row">
        <mat-form-field class="full-width">
          <input matInput
                [(ngModel)]="asOpenUrl(sugData.suggestion).url"
                (keyup)="onChange()"
                placeholder="URL"
                type="url"
                data-testid="urlInput"/>
          <mat-hint>http: or https: or mailto: required</mat-hint>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="'Dial'" class="row">
        <mat-form-field class="full-width">
          <input matInput
                [(ngModel)]="asDialAction(sugData.suggestion).phoneNumber"
                (keyup)="onChange()"
                placeholder="Phone number"
                type="tel"
                data-testid="phoneNumberInput"/>
        </mat-form-field>
      </div>
      <app-view-location-action *ngSwitchCase="'View location'"
                                [location]="asViewLocation(sugData.suggestion)"
                                (change)="onChange()"></app-view-location-action>
      <app-calendar-action *ngSwitchCase="'Calendar'"
                          [calendar]="asCalendarAction(sugData.suggestion)"
                          (change)="onChange()"
                          (keyup)="onChange()" ></app-calendar-action>
    </ng-container>

    <mat-divider *ngIf="!last"></mat-divider>

    <mat-menu #menu="matMenu">
      <button *ngFor="let typeEntry of suggestionTypes"
              (click)="changeType(sugData, typeEntry[0])"
              mat-menu-item [attr.data-testid]="'typeMenu-' + typeEntry[0]">
        <mat-icon>{{ typeEntry[1].icon }}</mat-icon>
        <span>{{ typeEntry[1].label }}</span>
      </button>
    </mat-menu>

  </div>
</div>
