<h2 mat-dialog-title>Channel Configuration</h2>
<mat-dialog-content>
  <div class="sub-config">
      <div class="row">
          <div class="col-sm-6">
              <p>To disable channels, drag them into "Channels". Web channel cannot be disabled.</p>
          </div>
      </div>

      <div class="row mb-4 drag">
          <div class="col-sm-3 drag">
              <h5>Channels</h5>
              <div class="container" id="channel-available" dragula="DRAGULA_FACTS">
                <div *ngFor="let channel of availableChannel" class="channel-pill" [id]="channel.id">{{channel.name}}</div>
              </div>
          </div>
          <div class="col-sm-1 drag">
              <img src="./assets/arrows.png" alt="Left and Right Arrows" class="channel-arrows"/>
          </div>
          <div class="col-sm-4 drag">
              <h5>Enabled Channels</h5>
              <div class="container" id="channels-enabled" dragula="DRAGULA_FACTS">
                <div *ngFor="let channel of selectedChannel" [class]="channel.cssClass" [id]="channel.id">{{channel.name}}</div>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-sm-8">
              <p class="alert alert-info">
                  <b>Please note</b><br>Enabled channels will be executed in the order provided, from top to bottom.
              </p>
          </div>
      </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [color]="data.buttonStyles ? data.buttonStyles[0] : 'primary'" [mat-dialog-close]="false">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button id="saveBtn" [disabled]="false" mat-button [color]="data.buttonStyles ? data.buttonStyles[1] : 'primary'" [mat-dialog-close]="true" >Save</button>
</mat-dialog-actions>
