import { ReteOutput } from '../../../rete/controls/extended-output';
import { Component, Inject, OnInit } from '@angular/core';
import { BasicNode } from '../basic-node';
import { NodeHelper } from 'src/app/services/node-helper.service';
import { AbstractNodeUnion, KeywordsNode } from 'flow-model';
import { environment } from 'src/environments/environment';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { KeywordData, KeywordMatch } from '../../../../../model/keywords';
import { removeSummaryDuplicates } from '@angular/compiler';

@Component({
  selector: 'app-keywords-node',
  templateUrl: './keywords-node.component.html',
  styleUrls: ['./keywords-node.component.css']
})
export class KeywordsNodeComponent extends BasicNode implements OnInit {

  private keywordsCounter = 0;
  private testString: string;
  private primaryTestMatchIndex: number;
  private secondaryTestMatches: number[];
  private regexContextVariables: number[];
  private startsWithRegexContextVariables: number[];
  private equalsRegextContextVariables: number[];
  private endsWithRegexContextVariables: number[];

  keywordsData: KeywordData[] = [];
  model: KeywordsNode;

  env = environment;

  constructor(@Inject(UserProfileService) userProfileService: UserProfileService) {
    super(userProfileService);
  }

  loadFromModel(node: AbstractNodeUnion) {
    this.model = node as KeywordsNode;
    if (!this.model.inputVariableName) {
      this.model.inputVariableName = '';
    }
    this.model.keywords.forEach(keyword => {
      this.createKeyword(keyword);
    });
  }

  getModelObject(): KeywordsNode {
    this.model.position = this.getPosition();
    this.model.nextMessageId = this.getDirectlyConnectedNodeId();
    this.model.keywords = this.getKeywordsModel();
    return this.model;
  }

  getKeywordsModel() {
    return Array.from(this.keywordsData.map((kwData: KeywordData) => {
      kwData.keywordMatch.nextMessageId = kwData.output.getConnectedNodeId();
      return kwData.keywordMatch;
    }) as Array<KeywordMatch>);
  }

  verifyNodeSpecific() {
    this.verifyInputVariable();
    this.keywordsData.forEach(keywordData => {
      if (this.isBlank(keywordData.keywordMatch.keyword)) {
        this.addErrorReason('A keyword value is empty');
      }
      if (keywordData.keywordMatch.regex) {
        const outputValue = keywordData.keywordMatch.keyword;
        if (!this.isPatternValid(outputValue)) {
          this.addErrorReason('The regex pattern is invalid: ' + outputValue);
        }
      }
    });
  }

  verifyInputVariable() {
    if (!this.model.inputVariableName) {
      this.addErrorReason('An input variable name needs to be set');
    } else {
      if (!this.checkFormatVariable(this.model.inputVariableName)) {
        this.addErrorReason('An input variable must be in format ${variable}');
      }
    }
  }

  checkFormatVariable(template: string) {
    const regex = /^\${([a-zA-Z]\w*)}$/g;
    const matches = template.match(regex);
    if (matches !== null) {
      return true;
    } else {
      return false;
    }
  }

  isPatternValid(str) {
    try {
      // tslint:disable-next-line:no-unused-expression
      new RegExp(str, 'i');
      return true;
    } catch (err) {
      return false;
    }
  }

  isBlank(str: any): boolean {
    return (!str || /^\s*$/.test(str));
  }

  ngOnInit() {
    super.ngOnInit();
    this.model = NodeHelper.newKeywordNode(this.getId(), this.getPosition());
    this.addDirectOutput();
    this.primaryTestMatchIndex = null;
    this.secondaryTestMatches = null;
    this.testString = null;
  }

  onChange() {
    super.onChange();
    this.testKeywordMatch(this.testString);
  }

  getTestString() {
    return this.testString;
  }

  getPrimaryTestMatch(): number {
    return this.primaryTestMatchIndex;
  }

  getSecondaryTestMatches(): number[] {
    return this.secondaryTestMatches;
  }

  getRegexContextVariables(): number[] {
    return this.regexContextVariables;
  }

  getStartsWithRegexContextVariables(): number[] {
    return this.startsWithRegexContextVariables;
  }

  getEndsWithRegexContextVariabes(): number[] {
    return this.endsWithRegexContextVariables;
  }

  getEqualsRegexContextVariables(): number[] {
    return this.equalsRegextContextVariables;
  }

  testKeywordMatch(testString: string) {
    this.testString = testString;

    const foundMatches = testString ? this.findAllMatchingKeywords(testString) : [];

    this.fillRegexWithVariables();
    this.primaryTestMatchIndex = foundMatches.length > 0 ? foundMatches[0] : null;
    this.secondaryTestMatches = foundMatches.length > 1 ? foundMatches.slice(1, foundMatches.length) : null;
  }

  private fillRegexWithVariables() {
    this.regexContextVariables = [];
    this.startsWithRegexContextVariables = [];
    this.endsWithRegexContextVariables = [];
    this.equalsRegextContextVariables = [];
    this.keywordsData.reduce((results: number[], keyword: KeywordData, idx: number) => {
      if (keyword.keywordMatch.regex) {
        if (this.isStartsWithRegexContextVariable(keyword.keywordMatch.keyword)) {
          this.startsWithRegexContextVariables.push(idx);
        } else if (this.isEndsWithRegexContextVariable(keyword.keywordMatch.keyword)) {
          this.endsWithRegexContextVariables.push(idx);
        } else if (this.isEqualsContextVariable(keyword.keywordMatch.keyword)) {
          this.equalsRegextContextVariables.push(idx);
        } else if (this.isKeyRegexWithVariables(keyword.keywordMatch)) {
          this.regexContextVariables.push(idx);
        }
      } else if (this.isKeyRegexWithVariables(keyword.keywordMatch)) {
        this.regexContextVariables.push(idx);
      }
      return results;
    }, []);
  }

  private isEqualsContextVariable(keyword: string): boolean {
    const regex = /^\^\${([a-zA-Z]\w*)}\$$/g;
    const equalsRegex = new RegExp(regex);
    return equalsRegex.test(keyword);
  }

  private isEndsWithRegexContextVariable(keyword: string): boolean {
    const regex = /^\${([a-zA-Z]\w*)}\$$/g;
    const endsWithRegex = new RegExp(regex);
    return endsWithRegex.test(keyword);
  }

  private isStartsWithRegexContextVariable(keyword: string): boolean {
    const regex = /^\^\${([a-zA-Z]\w*)}$/g;
    const startsWithRegex = new RegExp(regex);
    return startsWithRegex.test(keyword);

  }
  private isKeyRegexWithVariables(keyword: KeywordMatch): boolean {
    const variables: Set<string> = this.calculateTestVariables(keyword.keyword);
    return variables.size > 0;
  }

  private findAllMatchingKeywords(testString: string): number[] {
    return this.keywordsData.reduce((results: number[], keyword: KeywordData, idx: number) => {
      if (this.isKeywordMatching(keyword.keywordMatch, testString)) {
        results.push(idx);
      }
      return results;
    }, []);
  }

  private isKeywordMatching(keyword: KeywordMatch, testString: string): boolean {
    if (keyword.keyword) {
      return keyword.regex ?
        this.isRegexMatching(keyword.keyword, keyword.isCaseSensitive, testString) :
        this.isExactKeywordMatching(keyword.keyword, keyword.isCaseSensitive, testString);
    }
    return false;
  }

  calculateTestVariables(str: string): Set<string> {
    const variables: Set<string> = new Set<string>();
    const regex = /\${([a-zA-Z]\w*)}/g;
    const matches = str.match(regex);
    for (const match in matches) {
      if ( typeof matches[match] === 'string') {
        const matchStr = matches[match];
        variables.add(matchStr);
      }
    }
    return variables;
  }

  private isRegexMatching(keyword: string, caseSensitive: boolean, testString: string): boolean {
    const variables: Set<string> = this.calculateTestVariables(keyword);
    if (variables.size > 0) {
      return false;
    }
    // todo: prevent that the user has to do double backslashes? e.g. \\d
    try {
      let regex: RegExp;
      if (caseSensitive) {
        regex = new RegExp(keyword);
      } else {
        regex = new RegExp(keyword, 'i');
      }
      return regex.test(testString);
    } catch (e) {
      return false;
    }
  }

  private isExactKeywordMatching(keyword: string, caseSensitive: boolean, testString: string): boolean {
    const variables: Set<string> = this.calculateTestVariables(keyword);
    if (variables.size > 0) {
      return false;
    }
    if (caseSensitive) {
      return testString.includes(keyword);
    } else {
      return testString.toLowerCase().includes(keyword.toLowerCase());
    }
  }

  createKeyword(keyword?: KeywordMatch): KeywordData {
    if (!keyword) {
      keyword = new KeywordMatch('');
    }

    const index = this.keywordsCounter++;
    const output = ReteOutput.createKeywordOutput(index);
    this.addOutput(output);
    const keywordData = new KeywordData(keyword, output);
    this.keywordsData.push(keywordData);

    this.onChange();

    return keywordData;
  }

  removeKeyword(keywordData: KeywordData) {
    this.keywordsData = this.keywordsData.filter(obj => obj !== keywordData);
    this.removeOutputWithConnections(keywordData.output);
    this.onChange();
  }
}
