import { MediaInfo } from 'flow-model';
import { HttpService } from '../../../../../services/http.service';
import { MediaFeatureset } from 'conversation-domain';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { MediaValidationService } from '../../../../../services/media.validation.service';
import { BasicNode } from '../../node/basic-node';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent {

  private INVALID_MEDIA_INFO = { linkValid: false, mimeType: null } as MediaInfo;

  @Input()
  mediaUrlRequired = true;

  @Input()
  mediaUrl: string;
  mediaUrlInfo: MediaUrlInfo = null;
  showMedia = false;

  @Input()
  thumbnailUrl: string;

  @Input()
  messageNode: BasicNode;

  thumbnailUrlMediaInfo: MediaUrlInfo = null;
  showThumbnail = false;

  showAudioIcon = false;
  showVideoIcon = false;
  showWarning = false;

  env = environment;
  private validationInProgress = false;
  private mediaFeatureset: MediaFeatureset;

  @Output()
  validationErrors = new EventEmitter<Array<string>>();

  @Output()
  verifyFinished = new EventEmitter<void>();

  constructor(
    private validationService: MediaValidationService,
    private httpService: HttpService) {
  }

  withMediaFeaturset(mediaFeatureset: MediaFeatureset) {
    this.mediaFeatureset = mediaFeatureset;
  }

  verify() {
    if (this.validationInProgress) {
      return;
    }
    this.validationInProgress = true;
    this.reloadMediaInfoIfUrlChanged()
      .finally(() => {
        this.validationInProgress = false;
        this.updateUserInterface();
        this.validateMedia();
        this.verifyFinished.emit();
      });
  }

  private async reloadMediaInfoIfUrlChanged() {
    if (!this.mediaUrlInfo || this.mediaUrlInfo.url !== this.mediaUrl) {
      const mediaInfo: MediaInfo = (this.mediaUrl && this.mediaUrl.toLocaleLowerCase().startsWith('https://')) ? await this.requestMediaInfo(this.mediaUrl) : this.INVALID_MEDIA_INFO;
      this.mediaUrlInfo = new MediaUrlInfo(mediaInfo, this.mediaUrl);
    }
    if (!this.thumbnailUrlMediaInfo || this.thumbnailUrlMediaInfo.url !== this.thumbnailUrl) {
      const thumbnailInfo: MediaInfo = (this.thumbnailUrl && this.thumbnailUrl.toLocaleLowerCase().startsWith('https://')) ? await this.requestMediaInfo(this.thumbnailUrl) : this.INVALID_MEDIA_INFO;
      this.thumbnailUrlMediaInfo = new MediaUrlInfo(thumbnailInfo, this.thumbnailUrl);
    }
  }

  private validateMedia() {
    if (this.mediaFeatureset && this.mediaUrlInfo && this.thumbnailUrlMediaInfo) {
      const errorReasons: Array<string> = this.validationService.validate(this.mediaUrlInfo, this.thumbnailUrlMediaInfo, this.mediaUrlRequired, this.mediaFeatureset);
      if (errorReasons && errorReasons.length > 0) {
        this.validationErrors.emit(errorReasons);
      }
    }
  }

  private async requestMediaInfo(url: string): Promise<MediaInfo> {
    return await this.httpService.getMediaInfo(url).toPromise();
  }

  private updateUserInterface() {
    this.showThumbnail = this.thumbnailUrlMediaInfo && this.thumbnailUrlMediaInfo.isLinkValid() && this.thumbnailUrlMediaInfo.isMediaTypeImage();
    this.showAudioIcon = !this.showThumbnail && this.mediaUrlInfo.isMediaTypeAudio();
    this.showVideoIcon = !this.showThumbnail && this.mediaUrlInfo.isMediaTypeVideo();
    this.showMedia = !this.showThumbnail && !this.showAudioIcon && !this.showVideoIcon && this.mediaUrlInfo && this.mediaUrlInfo.isLinkValid();
    this.showWarning = !this.showThumbnail && !this.showMedia && !this.showAudioIcon && !this.showVideoIcon && this.mediaUrlRequired;
    this.updateReteNode();
  }


  public getMediaTypeIcon(): string {
    if (this.mediaUrlInfo && this.mediaUrlInfo.isLinkValid()) {
      return this.mediaUrlInfo.isMediaTypeVideo() ? 'videocam' : this.mediaUrlInfo.isMediaTypeAudio() ? 'mic' : 'image';
    }
    return 'image';
  }

  updateReteNode() {
    this.messageNode.updateReteNode();
  }

}

export class MediaUrlInfo {
  constructor(
    public mediaInfo: MediaInfo,
    public url: string
  ) { }

  public hasUrl(): boolean {
    return this.url && this.url.trim().length > 0;
  }

public hasExceptionError(): boolean {
    return this.mediaInfo.exceptionError != null;
}

  public isLinkValid(): boolean {
    return this.mediaInfo && this.mediaInfo.linkValid;
  }

  public isMediaTypeImage(): boolean {
    return this.isLinkValid() ? this.isMimeType('image/') : false;
  }
  public isMediaTypeVideo(): boolean {
    return this.isLinkValid() ? this.isMimeType('video/') : false;
  }
  public isMediaTypeAudio(): boolean {
    return this.isLinkValid() ? this.isMimeType('audio/') : false;
  }
  public isMimeType(type: string) {
    return this.mediaInfo.mimeType && this.mediaInfo.mimeType.toLowerCase().startsWith(type);
  }
}
