import { ChannelFeatureset, SuggestionsFeatureset, SuggestionsNumberFeatureset } from 'conversation-domain';
import { SUGGESTION_TYPES } from './../../../../model/suggestions';
import { ReteOutput } from './../../rete/controls/extended-output';
import { BasicNode } from './basic-node';
import { OpenUrlSuggestedAction, TextSuggestion, ViewLocationSuggestedAction, CalendarEntrySuggestedAction } from 'flow-model';
import { AbstractSuggestion, AbstractSuggestionUnion} from 'flow-model';
import { SuggestionData } from '../../../../model/suggestions';
import { verifySuggestion } from '../../../../model/conversion';
import { UserProfileService } from '../../../../services/user-profile.service';
import { NodeHelper } from 'src/app/services/node-helper.service';
import { MobileInvoiceNodeComponent } from './mobile-invoice-node/mobile-invoice-node.component';

export abstract class SuggestionEnabledNode extends BasicNode {

  private NO_SUGGESTION_TEXT = 'Suggestion text is missing';
  private SUGGESTION_TITLE_LENGTH_OUT_OF_LIMIT = 'Your channel configuration does not allow a suggestion text with more than %d characters';
  private NO_SUGGESTION_SUPPORTED = 'Your channel configuration does not support any suggestion';
  private NO_URL_SUGGESTION_SUPPORTED = 'Your channel configuration does not support URL suggestion';
  private NO_VIEW_LOCATION_SUGGESTION_SUPPORTED = 'Your channel configuration does not support View Location suggestion';
  private NO_CALENDAR_SUGGESTION_SUPPORTED = 'Your channel configuration does not support Calendar suggestion';
  private NO_DIAL_SUGGESTION_SUPPORTED = 'Your channel configuration does not support Dial suggestion';
  private NO_SHARE_LOCATION_SUGGESTION_SUPPORTED = 'Your channel configuration does not support Share Location suggestion';
  private SUGGESTION_OPEN_URL_LENGTH_OUT_OF_LIMIT = 'Your channel configuration does not allow an URL in an open URL action with more than %d characters';
  private SUGGESTION_CONNECTION_MISSING = 'A suggestion is not connected';
  private TOO_MANY_TEXT_SUGGESTIONS = 'Your channel configuration does not allow more than %d text suggestions';
  private TOO_MANY_ACTIONS = 'Your channel configuration does not allow more than %d suggested actions';
  private NO_ACTIONS = 'Your channel configuration does not allow any suggested actions';
  private TOO_MANY_SUGGESTIONS = 'Your channel configuration does not allow more than %d total suggestions';
  private SUGGESTION_VIEW_LOCATION_DESCRIPTION_LENGTH_OUT_OF_LIMIT = 'Your channel configuration does not allow a label in a View Location with more thant %d characters';
  private SUGGESTION_CALENDAR_TITLE_LENGTH_OUT_OF_LIMIT = 'Your channel configuration does not allow a title in a Calendar with more thant %d characters';
  private SUGGESTION_CALENDAR_DESCRIPTION_LENGTH_OUT_OF_LIMIT = 'Your channel configuration does not allow a description in a Calendar with more thant %d characters';
  private SUGGESTION_CALENDAR_START_TIME_EMPTY = 'Your channel configuration does not allow a Calendar with empty start date';
  private SUGGESTION_CALENDAR_END_TIME_EMPTY = 'Your channel configuration does not allow a Calendar with empty end date';
  private SUGGESTION_CALENDAR_START_TIME_AFTER_END_TIME = 'Your channel configuration does not allow a Calendar with start date after end date';


  showAddSuggestionButton = true;
  suggestions = new Array<SuggestionData>();

  private suggestionKeyIndex = 0;
  private suggestionsFeatureSet: SuggestionsFeatureset;


  constructor(userProfileService: UserProfileService) {
    super(userProfileService);
  }

  protected withFeatureset(featureSet: ChannelFeatureset) {
    super.withFeatureset(featureSet);
    this.showAddSuggestionButton = this.isSuggestionAvailable() && !this.isSuggestionCountLimitReached();
    this.suggestionsFeatureSet = featureSet.suggestionsFeatureset;
  }

  loadSuggestions(suggestions: AbstractSuggestion[]) {
    if (suggestions && suggestions.length > 0) {
      suggestions.forEach(suggestion => {
        this.addSuggestion(suggestion);
      });
    }
  }

  public addNewSuggestion(text: string): SuggestionData {
    const textSuggestion = new TextSuggestion();
    textSuggestion['@type'] = 'TextSuggestion';
    textSuggestion.template = NodeHelper.emptyTemplate();
    textSuggestion.template.en = text;
    return this.addSuggestion(textSuggestion);
  }

  protected addSuggestion(suggestion: AbstractSuggestion): SuggestionData {
    const index = this.suggestionKeyIndex++;
    const suggestionType = SUGGESTION_TYPES.get(suggestion['@type']);
    const suggestionOutput = ReteOutput.createSuggestionOutput(index, suggestionType.isAction);
    const suggestionData = new SuggestionData(suggestion, suggestionType, suggestionOutput);
    this.suggestions.push(suggestionData);
    this.addOutput(suggestionOutput);
    this.showAddSuggestionButton = this.isSuggestionAvailable() && !this.isSuggestionCountLimitReached();
    this.onAddedSuggestion();
    this.onChange();
    return suggestionData;
  }

  protected onAddedSuggestion() {
    // override this method in the node component if you want to control the behaviour by your own
    // by default it removes the direct output. It makes only sense to change this behaviour if you can trigger this suggestions from everywhere in the flow, like in rich cards!
    this.removeDirectOutput();
  }

  public removeSuggestion(suggestion: SuggestionData) {
    this.suggestions = this.suggestions.filter(obj => obj !== suggestion);
    this.showAddSuggestionButton = this.isSuggestionAvailable() && !this.isSuggestionCountLimitReached();
    this.removeOutputWithConnections(suggestion.output);
    this.onRemovedSuggestion();
    this.onChange();
  }
  protected isSuggestionCountLimitReached(): boolean {
    const suggestionsNumberFeatureset = this.getSuggestionsNumberFeatureset();
    if (suggestionsNumberFeatureset) {
      return this.suggestions.length >= suggestionsNumberFeatureset.totalSuggestions;
    }
    return false;
  }

  protected isSuggestionAvailable(): boolean {
    const suggestionsNumberFeatureset = this.getSuggestionsNumberFeatureset();
    if (suggestionsNumberFeatureset) {
      return suggestionsNumberFeatureset.totalSuggestions > 0;
    }

    return false;
  }

  protected abstract getSuggestionsNumberFeatureset(): SuggestionsNumberFeatureset;

  protected onRemovedSuggestion() {
    // override this method in the node component if you want to control the behaviour by your own
    // by default it adds a direct output if no other outputs exists
    this.addDirectOutputIfNoOtherExists();
  }

  public hasSuggestions() {
    return this.suggestions != null && this.suggestions.length > 0;
  }

  protected getSuggestionModel(): AbstractSuggestionUnion[] {
    return this.suggestions.length > 0 ? Array.from(this.suggestions.map(data => {
      data.suggestion.template.en = data.suggestion.template.en ? data.suggestion.template.en.trim() : null;
      data.suggestion.nextMessageId = data.output.getConnectedNodeId();
      return data.suggestion;
    }) as Array<AbstractSuggestionUnion>) : null;
  }

  protected verifyNodeSpecific() {
    let textSuggestions = 0;
    let suggestedActions = 0;
    const map = new Map();
    for (const suggestionData of this.suggestions) {
      this.verifySuggestionTitle(suggestionData.suggestion);
      verifySuggestion(suggestionData.suggestion).forEach(e => this.addErrorReason(e));
      if (!suggestionData.output.optional && !suggestionData.output.hasConnection()) {
        this.addErrorReason(this.SUGGESTION_CONNECTION_MISSING);
      }
      suggestionData.suggestionType.isAction ? suggestedActions++ : textSuggestions++;

      this.verifyOpenUrlSuggestedAction(suggestionData);
      this.verifyViewLocationSuggestedAction(suggestionData);
      this.verifyCalendarEntrySuggestedAction(suggestionData);
      this.verifyDialSuggestedAction(suggestionData);
      this.verifyShareLocationSuggestedAction(suggestionData);
      this.verifyNotMobileInvoiceLinkNode(suggestionData);
      this.verifyOpenMobileInvoiceUrlSuggestedAction(suggestionData, map);
    }

    this.verifySuggestionNumberFeatureset(suggestedActions, textSuggestions);
    for (const value of map.values()) {
        if (value > 1) {
          this.addErrorReason('Only one suggestion of type Mobile invoice link should be connected to mobile invoice node');
        }
    }
    if (this.getErrorReasons().size > 0) {
      this.isValid = false;
    }
  }

  private isMobileInvoiceLinkSupported(): boolean {
    const channels = this.userProfileService.getConfiguredChannels();
    return channels && channels.indexOf('WEB') > -1 && (channels.split(',').length === 1);
  }

  private verifyNotMobileInvoiceLinkNode(suggestionData: SuggestionData) {
    if (suggestionData.suggestion['@type'] !== 'OpenMobileInvoiceUrlSuggestionAction') {
      if (!suggestionData.output.connections || suggestionData.output.connections.length === 0) {
        return;
      }
      const endNode =  suggestionData.output.connections[0].input;
      const node = endNode.node;
      const mobileInvoiceNode: MobileInvoiceNodeComponent  = node.data.nodeComponent as MobileInvoiceNodeComponent;
      if (mobileInvoiceNode && mobileInvoiceNode.model.linkMessage) {
        this.addErrorReason('Only suggestion of type \'Mobile invoice link\' must be connected to mobile invoice node with option \'Text message\' disabled.');
      }
    }
  }

  private verifyOpenMobileInvoiceUrlSuggestedAction(suggestionData: SuggestionData, map: Map<any, any>) {
    if (suggestionData.suggestion['@type'] === 'OpenMobileInvoiceUrlSuggestionAction') {
        if (!this.userProfileService.isMobileInvoiceActive()) {
          this.addErrorReason('Mobile Invoice is not enabled');
          return;
        }
        if (!this.isMobileInvoiceLinkSupported()) {
          this.addErrorReason('Mobile invoice link is not supported for selected channels');
          return;
        }
        if (!suggestionData.output.connections || suggestionData.output.connections.length === 0) {
          this.addErrorReason('This suggestion type Mobile Invoice link should be connected to mobile invoice node');
          return;
        }
        const endNode =  suggestionData.output.connections[0].input;
        const node = endNode.node;
        if (node.name !== 'MobileInvoiceNode') {
          this.addErrorReason('This suggestion type Mobile Invoice link should be connected to mobile invoice node');
          return;
        }
        const mobileInvoiceNode: MobileInvoiceNodeComponent  = node.data.nodeComponent as MobileInvoiceNodeComponent;
        if (mobileInvoiceNode && !mobileInvoiceNode.model.linkMessage) {
          this.addErrorReason('Unable to connect suggestion type Mobile Invoice link to text mobile invoice message');
        }
        if (mobileInvoiceNode) {
           let nodeCount = map.get(mobileInvoiceNode.getId());
           if (nodeCount) {
            map.set(mobileInvoiceNode.getId(), ++nodeCount);
           } else {
            map.set(mobileInvoiceNode.getId(), 1);
           }
        }
      }
  }

  private verifyDialSuggestedAction(suggestionData: SuggestionData) {
    if (suggestionData.suggestion['@type'] === 'DialSuggestedAction') {
      if (this.suggestionsFeatureSet) {
        if (this.suggestionsFeatureSet.suggestionPayloadLength === 0) {
          this.addErrorReason(this.NO_DIAL_SUGGESTION_SUPPORTED);
        }
      }
    }
  }

  private verifyOpenUrlSuggestedAction(suggestionData: SuggestionData) {
    if (suggestionData.suggestion['@type'] === 'OpenUrlSuggestedAction') {
      const url = (suggestionData.suggestion as OpenUrlSuggestedAction).url;
      if (this.suggestionsFeatureSet) {
        if (this.suggestionsFeatureSet.suggestionOpenUrlLength === 0) {
          this.addErrorReason(this.NO_URL_SUGGESTION_SUPPORTED);
        } else if (url && (url.length > this.suggestionsFeatureSet.suggestionOpenUrlLength)) {
          this.addErrorReason(this.SUGGESTION_OPEN_URL_LENGTH_OUT_OF_LIMIT, this.suggestionsFeatureSet.suggestionOpenUrlLength);
        }
      }
    }
  }

  private verifyShareLocationSuggestedAction(suggestionData: SuggestionData) {
    if (suggestionData.suggestion['@type'] === 'ShareLocationSuggestedAction') {
      if (this.suggestionsFeatureSet) {
        if (this.suggestionsFeatureSet.suggestionViewLocationDescriptionLength === 0) {
          this.addErrorReason(this.NO_SHARE_LOCATION_SUGGESTION_SUPPORTED);
        }
      }
    }
  }

  private verifyViewLocationSuggestedAction(suggestionData: SuggestionData) {
    if (suggestionData.suggestion['@type'] === 'ViewLocationSuggestedAction') {
      if (this.suggestionsFeatureSet) {
        const viewLocationSuggestion = (suggestionData.suggestion as ViewLocationSuggestedAction);
        if (this.suggestionsFeatureSet.suggestionViewLocationDescriptionLength === 0) {
         this.addErrorReason(this.NO_VIEW_LOCATION_SUGGESTION_SUPPORTED);
        } else if (viewLocationSuggestion.label &&  viewLocationSuggestion.label.length > this.suggestionsFeatureSet.suggestionViewLocationDescriptionLength
          && this.suggestionsFeatureSet.suggestionViewLocationDescriptionLength > 0) {
          this.addErrorReason(this.SUGGESTION_VIEW_LOCATION_DESCRIPTION_LENGTH_OUT_OF_LIMIT, this.suggestionsFeatureSet.suggestionViewLocationDescriptionLength);
        }
      }
    }
  }

  private verifyCalendarEntrySuggestedAction(suggestionData: SuggestionData) {
    if (suggestionData.suggestion['@type'] === 'CalendarEntrySuggestedAction') {
      const calendarSuggestion = suggestionData.suggestion as CalendarEntrySuggestedAction;
      if (this.suggestionsFeatureSet) {
        if (this.suggestionsFeatureSet.suggestionCalendarTitleLength === 0) {
          this.addErrorReason(this.NO_CALENDAR_SUGGESTION_SUPPORTED);
          return;
        }
        if (calendarSuggestion.title && calendarSuggestion.title.length > this.suggestionsFeatureSet.suggestionCalendarTitleLength
          && this.suggestionsFeatureSet.suggestionCalendarTitleLength > 0) {
          this.addErrorReason(this.SUGGESTION_CALENDAR_TITLE_LENGTH_OUT_OF_LIMIT, this.suggestionsFeatureSet.suggestionCalendarTitleLength);
        }
        if (calendarSuggestion.description && calendarSuggestion.description.length > this.suggestionsFeatureSet.suggestionCalendarDescriptionLength
          && this.suggestionsFeatureSet.suggestionCalendarDescriptionLength > 0) {
          this.addErrorReason(this.SUGGESTION_CALENDAR_DESCRIPTION_LENGTH_OUT_OF_LIMIT, this.suggestionsFeatureSet.suggestionCalendarDescriptionLength);
        }
      }
      if (!calendarSuggestion.startTime && !calendarSuggestion.startTimeVariable) {
        this.addErrorReason(this.SUGGESTION_CALENDAR_START_TIME_EMPTY);
      }
      if (!calendarSuggestion.endTime && !calendarSuggestion.endTimeVariable) {
        this.addErrorReason(this.SUGGESTION_CALENDAR_END_TIME_EMPTY);
      }

      if (calendarSuggestion.startTime && calendarSuggestion.endTime && calendarSuggestion.startTime > calendarSuggestion.endTime) {
        this.addErrorReason(this.SUGGESTION_CALENDAR_START_TIME_AFTER_END_TIME);
      }

    }
  }

  private verifySuggestionNumberFeatureset(suggestedActions: number, textSuggestions: number) {
    const suggestionNumberFeatureset: SuggestionsNumberFeatureset = this.getSuggestionsNumberFeatureset();
    if (suggestionNumberFeatureset) {

      if (textSuggestions > 0 && suggestionNumberFeatureset.textReplySuggestions === 0) {
        this.addErrorReason(this.NO_SUGGESTION_SUPPORTED);
        return;
      } else if (textSuggestions > suggestionNumberFeatureset.textReplySuggestions) {
        this.addErrorReason(this.TOO_MANY_TEXT_SUGGESTIONS, suggestionNumberFeatureset.textReplySuggestions);
        return;
      }

      if ((suggestedActions + textSuggestions) > 0 && suggestionNumberFeatureset.totalSuggestions === 0) {
        this.addErrorReason(this.NO_SUGGESTION_SUPPORTED);
        return;
      } else if (suggestedActions + textSuggestions > suggestionNumberFeatureset.totalSuggestions) {
        this.addErrorReason(this.TOO_MANY_SUGGESTIONS, suggestionNumberFeatureset.suggestedActions);
        return;
      }

      if (suggestedActions > 0 && suggestionNumberFeatureset.suggestedActions === 0) {
        this.addErrorReason(this.NO_ACTIONS);
      } else if (suggestedActions > suggestionNumberFeatureset.suggestedActions) {
        this.addErrorReason(this.TOO_MANY_ACTIONS, suggestionNumberFeatureset.suggestedActions);
      }
    }
  }

  private verifySuggestionTitle(suggestion: AbstractSuggestion) {
    if (!suggestion.template.en || suggestion.template.en.length === 0) {
      this.addErrorReason(this.NO_SUGGESTION_TEXT);
    }
    if (this.featureSet) {
      const suggestionMaxTitleLength = this.featureSet.suggestionsFeatureset.suggestionTitleLength;
      if (suggestion.template.en && suggestion.template.en.length > 0 && suggestionMaxTitleLength === 0) {
        this.addErrorReason(this.NO_SUGGESTION_SUPPORTED);
      } else if (suggestion.template.en && suggestion.template.en.length > suggestionMaxTitleLength) {
        this.addErrorReason(this.SUGGESTION_TITLE_LENGTH_OUT_OF_LIMIT, suggestionMaxTitleLength);
      }
    }
  }

}
