<div class="sync-mobile-field">
  <mat-checkbox
    [(ngModel)]="syncOutputEnabled"
    (ngModelChange)="onChange()"
    class="sync-mobile-field-checkbox"
    matTooltip="Wait for status reply from MI to continue flow"
    [matTooltipShowDelay]="env.tooltipDelay"
    data-testid="allowSynchronousRequest">
    Wait for status reply
  </mat-checkbox>
  <div class="fill_space"></div>
</div>

<div *ngFor="let value of mobileInvoiceNode.mobileInvoiceStatuses">
  <app-invoice-status-outputs *ngIf="mobileInvoiceNode.hasOutput(value)" [output]="mobileInvoiceNode.getOutput(value)" [bindSocket]="mobileInvoiceNode.bindSocket"
                              (delete)="syncOutputEnabled = false; onChange()" data-testid="status-output"></app-invoice-status-outputs>
</div>

<div class="fill_space"></div>
