import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-channel-dialog',
  templateUrl: './channel-dialog.component.html',
  styleUrls: ['./channel-dialog.component.css']
})
export class ChannelDialogComponent implements OnDestroy {

  public selectedChannel: Channel[];
  public availableChannel: Channel[];

  private selectedChannelString: string;

  private hasWeb: boolean;

  constructor(public dragulaService: DragulaService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.hasWeb = data.channels != null && data.channels.indexOf('WEB') > -1;
    this.selectedChannelString = this.configureSelectedChannel(data.selectedChannels, data.channels);
    this.configureAvailableChannel(data.channels, this.selectedChannelString);

    dragulaService.createGroup('DRAGULA_FACTS', {
      revertOnSpill: true,

      copy: (el, source) => {
        return false;
      },

      accepts: (el, target, source, sibling) => {
        // To avoid dragging from right to left container

        const result = target.id === 'channels-enabled' || !this.hasWeb || el.id !== 'WEB';

        if (result) {
          this.selectedChannelString = '';
          const container = target.id === 'channels-enabled' ? target : source;

          if (target.id === 'channels-enabled') {
            for (let i = 0; i < container.getElementsByTagName('div').length; i++) {
              if (container.getElementsByTagName('div')[i].id === 'WEB') {
                if (container.getElementsByTagName('div').length - 1 !== i) {
                  return false;
                }
              }
            }
          }

          for (let i = 0; i < container.getElementsByTagName('div').length; i++) {
            if (this.selectedChannelString !== '') {
              this.selectedChannelString += ',';
            }
            this.selectedChannelString += container.getElementsByTagName('div')[i].id;
          }

          if (container.getElementsByTagName('div').length === 0) {
            if (!document.getElementById('saveBtn').hasAttribute('disabled')) {
              document.getElementById('saveBtn').setAttribute('disabled', 'false');
            }
          } else {
            if (document.getElementById('saveBtn').hasAttribute('disabled')) {
              document.getElementById('saveBtn').removeAttribute('disabled');
            }
          }
        }
        return result;
      }
    });
  }

  selectedChannalsAsString(): string {
    return this.selectedChannelString;
  }

  ngOnDestroy() {
    this.dragulaService.destroy('DRAGULA_FACTS');
  }

  private configureSelectedChannel(selectedChannels: string, channels: String[]): string {
    this.selectedChannel = new Array();
    const selChannels = this.getSelectedChannelsAsArray(selectedChannels, channels);
    if (selChannels != null && selChannels.length > 0) {
      for (const channel of selChannels) {
        this.selectedChannel.push(Channel.createChannel(channel, this.hasWeb));
      }
    }
    return selChannels.join();
  }

  private getSelectedChannelsAsArray(selectedChannels: String, channels: String[]): String[] {
    const result = new Array();
    if (selectedChannels === '') {
      if (channels != null) {
        for (const channel of channels) {
          result.push(channel);
        }
      }
      return result;
    }

    return selectedChannels.split(',');
  }

  private configureAvailableChannel(channels: String[], selectedChannelString: string) {
    this.availableChannel = new Array();

    if (channels != null) {
      for (const channel of channels) {
        if (selectedChannelString.indexOf(channel.toString()) < 0) {
          this.availableChannel.push(Channel.createChannel(channel, this.hasWeb));
        }
      }
    }

    if (!this.hasWeb && selectedChannelString.indexOf('WEB') < 0) {
      this.availableChannel.push(Channel.createChannel('WEB', this.hasWeb));
    }
  }
}

class Channel {
  constructor(public name: string, public id: string, public cssClass: string) {
  }

  static createChannel(channel: String, hasWeb: boolean): Channel {
    switch (channel) {
      case 'FBM': {
        return new Channel('Facebook Messenger', 'FBM', 'channel-pill');
      }
      case 'RBM': {
        return new Channel('Google RBM', 'RBM', 'channel-pill');
      }
      case 'WAM': {
        return new Channel('WhatsApp', 'WAM', 'channel-pill');
      }
      case 'WEB': {
        if (hasWeb) {
          return new Channel('Web channel', 'WEB', 'channel-pill channel-disabled');
        }
        return new Channel('Web channel', 'WEB', 'channel-pill');
      }
    }
    return new Channel('N/A', 'N/A', 'N/A');
  }
}
