import { HttpAction, HttpBodyType, HttpVariables, HttpRequestMessageNode } from 'flow-model';

import { createTestVariablesFromString, pushUniqueVariables } from 'src/app/model/test-variables';

export class HttpRequestTypeData {

  constructor(public httpAction: HttpAction, public text: string) {
  }
}

export class HttpRequestBodyType {

  constructor(public httpBodyType: HttpBodyType, public text: string) {
  }
}

export function getAllAction(): HttpRequestTypeData[] {
  const actions: HttpRequestTypeData[] = [];

  for (const key in HttpAction) {
    if (HttpAction.hasOwnProperty(key)) {
      const text: string = HttpAction[key];
      actions.push(new HttpRequestTypeData(HttpAction[key as keyof typeof HttpAction], text));
    }
  }
  return actions;
}

export function getAllHttpRequestBodyType(): HttpRequestBodyType[] {
  const httpRequestBodyTypes: HttpRequestBodyType[] = [];

  for (const key in HttpBodyType) {
    if (HttpBodyType.hasOwnProperty(key)) {
    const text: string = HttpBodyType[key];
    httpRequestBodyTypes.push(new HttpRequestBodyType(HttpBodyType[key as keyof HttpBodyType], text));
    }
  }

  return httpRequestBodyTypes;
}

export function getAction(value: string): string {
  const keys = Object.keys(HttpAction).filter(x => HttpAction[x] === value);
  return keys.length > 0 ? keys[0] : null;
}


export function getHttpVariables(model: HttpRequestMessageNode): HttpVariables[] {
  const httpVariables: HttpVariables[] = calculateTestVariables(model);
  const variables: HttpVariables[] = [];
  httpVariables.forEach(element => {
    const httpVariable: HttpVariables = new HttpVariables();
    httpVariable.name = element.name;
    httpVariable.value = element.value;
    variables.push(httpVariable);
  });

  return variables;
}

function calculateTestVariables(model: HttpRequestMessageNode): HttpVariables[] {
  const modelHttpVariables: HttpVariables[] = getVariablesFromNode(model);
  let  testVariables: HttpVariables[] = model.testVariables;
  if (testVariables == null) {
    testVariables = [];
  }
  const finalVariables: HttpVariables[] = [];
  for (const modelHttpVariable of modelHttpVariables) {
    const variableIndex = testVariables.findIndex(testVariable =>
      testVariable.name === modelHttpVariable.name
    );
    if (variableIndex >= 0) {
      finalVariables.push(testVariables[variableIndex]);
    } else {
      finalVariables.push(modelHttpVariable);
    }
  }
  return finalVariables;
}

function getVariablesFromNode(model: HttpRequestMessageNode): HttpVariables[] {
  const httpVariables: HttpVariables[] = [];
  pushUniqueVariables(httpVariables, createTestVariablesFromString(model.requestUrl));
  if (model.httpHeaders !== undefined && model.httpHeaders !== null) {
    for (const header of model.httpHeaders) {
      pushUniqueVariables(httpVariables, createTestVariablesFromString(header.key));
      pushUniqueVariables(httpVariables, createTestVariablesFromString(header.value));
    }
  }
  if (model.action === HttpAction.GET) {
    return httpVariables;
  }
  if (model.httpBodyType === HttpBodyType.FORM_DATA) {
    if (model.formDataEntries !== undefined && model.formDataEntries !== null) {
      for (const formDataEntry of model.formDataEntries) {
        pushUniqueVariables(httpVariables, createTestVariablesFromString(formDataEntry.key));
        pushUniqueVariables(httpVariables, createTestVariablesFromString(formDataEntry.value));
      }
    }
  }
  if (model.httpBodyType === HttpBodyType.RAW) {
    pushUniqueVariables(httpVariables, createTestVariablesFromString(model.messageBody));
  }
  return httpVariables;
}

