import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { HttpVariables } from 'flow-model';

@Component({
  selector: 'app-variables-dialog',
  templateUrl: './variables-dialog.component.html',
  styleUrls: ['./variables-dialog.component.css']
})
export class VariablesDialogComponent {

  httpVariables: HttpVariables[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
      this.httpVariables = data.httpVariables;
  }

}
