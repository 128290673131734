import { Component, Node as ReteNode } from 'rete';
import { NodeHelper } from '../../../../services/node-helper.service';
import { NodeData, WorkerInputs, WorkerOutputs } from 'rete/types/core/data';

export class ReteTextMessageNode extends Component {

  public static nodeName = 'TextMessageNode';

  constructor() {
    super(ReteTextMessageNode.nodeName);
  }

  async builder(node: ReteNode) {
    node.addInput(NodeHelper.createInput());
  }

  worker(node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs, ...args: any[]): any {
  }
}
