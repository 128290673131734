import { ChannelFeatureset, SuggestionsNumberFeatureset } from 'conversation-domain';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractNode, TextMessageNode } from 'flow-model';
import { UserProfileService } from '../../../../../services/user-profile.service';

import { SuggestionEnabledNode } from '../suggestion-enabled-node';
import { NodeHelper } from 'src/app/services/node-helper.service';
import { FreeTextComponent } from '../../node-shared-components/free-text/free-text.component';

@Component({
  selector: 'app-text-message-node',
  templateUrl: './text-message-node.component.html',
  styleUrls: ['./text-message-node.component.css']
})
export class TextMessageNodeComponent extends SuggestionEnabledNode implements OnInit {
  private NO_MESSAGE_TEXT = 'Message text is empty';
  private MESSAGE_TOO_LONG = 'Message text too long, please do not use more than %d characters';

  model: TextMessageNode = null;

  @ViewChild(FreeTextComponent)
  freeTextComponent: FreeTextComponent;

  constructor(@Inject(UserProfileService) userProfileService: UserProfileService) {
    super(userProfileService);
  }

  protected getSuggestionsNumberFeatureset(): SuggestionsNumberFeatureset {
    if (this.featureSet && this.featureSet.textMessageFeatureset) {
      return this.featureSet.textMessageFeatureset.suggestionsNumberFeatureset;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addDirectOutput();
    this.model = NodeHelper.newTextMessageNode(this.getId(), this.getPosition());
  }

  protected withFeatureset(featureSet: ChannelFeatureset) {
    super.withFeatureset(featureSet);
  }

  loadFromModel(abstractNode: AbstractNode) {
    this.model = abstractNode as TextMessageNode;
    this.loadSuggestions(this.model.suggestions);
    this.freeTextComponent.loadFromModel(this.model.onFreeText);
    if (!this.model.template) {
      this.model.template = NodeHelper.emptyTemplate();
    }
    if (this.model.outputVariableName === undefined) {
      this.model.outputVariableName = null;
    }
  }

  getModelObject(): AbstractNode {
    this.model.position = this.getPosition();
    this.model.nextMessageId = this.getDirectlyConnectedNodeId();
    this.model.onFreeText = this.getOnFreeText();
    this.model.suggestions = this.getSuggestionModel();
    return this.model;
  }

  protected verifyNodeSpecific() {
    super.verifyNodeSpecific();
    this.verifyMessageText();
  }

  private verifyMessageText() {
    const messageText = this.model && this.model.template ? this.model.template.en : null;
    if (!messageText || messageText.length === 0) {
      this.addErrorReason(this.NO_MESSAGE_TEXT);
    } else if (this.featureSet && messageText.length > this.featureSet.textMessageFeatureset.length) {
      this.addErrorReason(this.MESSAGE_TOO_LONG, this.featureSet.textMessageFeatureset.length);
    }
  }
}
