import { HttpVariables } from 'flow-model';

export function createTestVariablesFromString(str: string): HttpVariables[] {
    const httpVariables: HttpVariables[] = [];
    if (str === undefined || str === null) {
      return httpVariables;
    }
    const regex = /\${([a-zA-Z]\w*)}/g;
    const matches = str.match(regex);
    for (const match in matches) {
      if ( typeof matches[match] === 'string') {
        const matchStr = matches[match];
        const variableName = matchStr.substring(2, matchStr.length - 1);
        const httpVariable: HttpVariables = new HttpVariables();
        httpVariable.name = variableName;
        httpVariable.value = '';
        httpVariables.push(httpVariable);
      }
    }
    return httpVariables;
  }

  export function pushUniqueVariables(httpVariables: HttpVariables[], httpVariablesToAdd: HttpVariables[]) {
    for (const variable of httpVariablesToAdd) {
      const found = httpVariables.some(httpVariable => {
      return httpVariable.name === variable.name;
      });
      if (!found) {
         httpVariables.push(variable);
      }
    }
  }
