<app-topbar titleSuffix="Reports"></app-topbar>
<mat-drawer-container autosize class="drawer-container">
  <mat-drawer-content class="overview-content">
    <div class="report-layout">
      <form #reportForm class="report-form">
        <div class="filter-row">
          <div class="filter-el">
            <div class="filter-text">
             <label>Period</label>
            </div>
            <div class="filter-type">
              <mat-select [(ngModel)]="reportType" data-testid="OrientationDropDownMenu"  [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let key of reportTypeKeys" value="{{key}}">
                  {{key}}
                </mat-option>
              </mat-select>
            </div>
            <div class="filter-text" *ngIf="showMonthly() || showWeekly()">
              <label>last</label>
            </div>
            <div class="filter-digit" *ngIf="showMonthly()">
              <mat-select [(ngModel)]="months" data-testid="OrientationDropDownMenu"  [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let key of monthsValues" value="{{key}}">
                  {{key}}
                </mat-option>
              </mat-select>
            </div>
            <div class="filter-digit" *ngIf="showWeekly()">
              <mat-select [(ngModel)]="weeks" data-testid="OrientationDropDownMenu"  [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let key of weeksValues" value="{{key}}">
                  {{key}}
                </mat-option>
              </mat-select>
            </div>
            <div class="filter-text" *ngIf="showMonthly()">
              <label>{{(months === '1') ? 'month' : 'months'}}</label>
            </div>
            <div class="filter-text" *ngIf="showWeekly()">
              <label>{{(weeks === '1') ? 'week' : 'weeks'}}</label>
            </div>
            <div class="filter-calendar" *ngIf="showDaily()">
              <mat-form-field class="width-60">
                <input [(ngModel)]="startDate"
                     (dateChange)="onStartDateChange()"
                     [matDatepicker]="pickerStart"
                     matInput placeholder="Start date"
                     type="text"
                     data-testid="calendarActionStartDate"
                     [min]="minDate"
                     [max]="maxDate"
                     [ngModelOptions]="{standalone: true}"/>
                <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="filter-calendar" *ngIf="showDaily()">
              <mat-form-field class="width-60">
                <input [(ngModel)]="endDate"
                     (dateChange)="onEndDateChange()"
                     [matDatepicker]="pickerEnd"
                     matInput placeholder="End date"
                     type="text"
                     data-testid="calendarActionEndDate"
                     [min]="minDate"
                     [max]="maxDate"
                     [ngModelOptions]="{standalone: true}"/>
                <mat-datepicker-toggle [for]="pickerEnd" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="filter-btn">
            <button mat-raised-button data-testid="reportsButton" (click)="reportsClick()" class="report-btn">Generate Report</button>
          </div>
          <div class="filter-btn">
            <button mat-raised-button data-testid="csvButton" (click)="csvClick()" class="report-btn" [disabled]="!reports || !reports.length || reports.length == 0 || showProgress">Generate csv file</button>
          </div>
        </div>
        <div class="filter-row">
          <div class="filter-el">
            <div class="filter-text-sl">
              <label>Customer ID</label>
            </div>
            <div class="filter-text-sl">
              <input [(ngModel)]="customerId" [ngModelOptions]="{standalone: true}"/>
            </div>
            <div class="filter-text-sl" *ngIf="userRole === 'SUPER_ADMIN' || userRole === 'GLOBAL_BUSINESS'">
              Country
            </div>
            <div class="filter-country-sl" *ngIf="userRole === 'SUPER_ADMIN' || userRole === 'GLOBAL_BUSINESS'">
              <mat-select [(ngModel)]="country" data-testid="OrientationDropDownMenu" [ngModelOptions]="{standalone: true}">
                <mat-option value="{{getAllCountries()}}">All Countries</mat-option>
                <mat-option *ngFor="let country of countries" value="{{country.code}}">
                  {{country.name}}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="filter-progress" *ngIf="showProgress">
            <mat-progress-bar [color]="'primary'" *ngIf="showProgress" [mode]="'indeterminate'">
            </mat-progress-bar>
          </div>
        </div>
      </form>
      <mat-divider></mat-divider>
      <br>
      <div *ngFor="let report of reports">
        <div class="report-list">
          <div class="row" *ngIf="isDailyReport()">
            <div class="time-header">Date</div>
            <div class="time">{{getCurrentDayDate(report.startTime) | date:'dd MMMM yyyy'}}</div>
          </div>
          <div class="row" *ngIf="isWeeklyReport()">
            <div class="time-header">Week</div>
            <div class="time">{{getCurrentDayDate(report.startTime) | date:'dd.MM'}}-{{getCurrentDayDate(report.endTime) | date:'dd.MM yyyy'}}</div>
          </div>
          <div class="row" *ngIf="isMonthlyReport()">
            <div class="time-header">Month</div>
            <div class="time">{{getCurrentDayDate(report.startTime) | date:'MMMM yyyy'}}</div>
          </div>
          <div class="header-row" *ngIf="report.rows.length > 0">
            <div class="header-row odd">
              <div class="header-item-text">Customer Name</div>
              <div class="header-item-text">Customer Id</div>
              <div class="header-item-text">Country</div>
              <div class="header-item-digit">All Conversations</div>
              <div class="header-item-digit">All Sessions</div>
              <div class="header-item-digit">SMS sent</div>
            </div>
            <div *ngFor="let channel of report.channels" class="header-row odd">
              <div class="header-item-digit">{{channel}} Conversations</div>
              <div class="header-item-digit">{{channel}} Sessions</div>
            </div>
          </div>
          <div class="header-no-row" *ngIf="report.rows.length == 0">
            No records are found for that period.
          </div>
          <div class="row" *ngFor="let row of report.rows; let i = index">
            <div class="{{ i % 2 == 0 ? 'row' : 'row odd'}}">
              <div class="row-text">{{row.customerName}}</div>
              <div class="row-text">{{row.customerId}}</div>
              <div class="row-text">{{getCountry(row.countryCode)}}</div>
              <div class="row-digit">{{row.conversationsCount}}</div>
              <div class="row-digit">{{row.sessionsCount}}</div>
              <div class="row-digit">{{row.smsCount}}</div>
            </div>
            <div *ngFor="let channel of report.channels" class="{{ i % 2 == 0 ? 'row' : 'row odd'}}">
              <div class="row-digit">{{getConversationStarted(row, channel)}}</div>
              <div class="row-digit">{{getSessionStarted(row, channel)}}</div>
            </div>
          </div>
        </div>
      </div>
      <mat-divider *ngIf="reports && reports.length > 0 && stats"></mat-divider>
      <div class="report-list" *ngIf="reports && reports.length > 0 && stats">
        <div class="header-row">
          <div class="header-row odd">
            <div class="header-item-text">Total</div>
            <div class="header-item-text"></div>
            <div class="header-item-text"></div>
            <div class="header-item-digit">All Conversations</div>
            <div class="header-item-digit">All Sessions</div>
            <div class="header-item-digit">SMS sent</div>
          </div>
          <div *ngFor="let channel of stats.channels" class="header-row odd">
            <div class="header-item-digit">{{channel}} Conversations</div>
            <div class="header-item-digit">{{channel}} Sessions</div>
          </div>
        </div>
        <div class="row">
          <div class="row">
            <div class="header-item-text"></div>
            <div class="header-item-text"></div>
            <div class="header-item-text"></div>
            <div class="header-item-digit">{{stats.conversationsCount}}</div>
            <div class="header-item-digit">{{stats.sessionsCount}}</div>
            <div class="header-item-digit">{{stats.smsCount}}</div>
          </div>
          <div *ngFor="let channel of stats.channels" class="row">
            <div class="header-item-digit">{{stats.getConversationCount(channel)}}</div>
            <div class="header-item-digit">{{stats.getSessionCount(channel)}}</div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>
