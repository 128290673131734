import { NodeDetailsService } from './../../../services/node-details.service';
import { Component, OnInit } from '@angular/core';
import { StartNode } from 'flow-model';
import { getSmsLength } from '../../../util/sms-helper';
import { BasicNode } from '../../editor/renderer/node/basic-node';
import { StartNodeComponent } from '../../editor/renderer/node/start-node/start-node.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-start',
  templateUrl: './details-start.component.html',
  styleUrls: ['./details-start.component.css']
})
export class DetailsStartComponent implements OnInit {

  basicNode: BasicNode;
  model: StartNode = null;
  env = environment;
  showSmsTooLongError = false;
  countOfCharactersLeft = 459;

  constructor(private nodeDetailsService: NodeDetailsService) { }

  ngOnInit(): void {
    this.nodeDetailsService.node$.subscribe(
      nodeComponent => {
        this.basicNode = nodeComponent;
        if (this.model && !(nodeComponent instanceof StartNodeComponent)) {
          this.smsTemplateBlur();
        }
        this.model = (nodeComponent instanceof StartNodeComponent) ? nodeComponent.model : null;
        this.updateView();
      });
  }

  onChange() {
    this.updateView();
    this.basicNode.onChange();
  }

  smsTemplateBlur() {
    if (this.model && this.model.smsTemplate) {
      const smsTemplate = this.model.smsTemplate.trim();
      if (smsTemplate !== this.model.smsTemplate) {
        this.model.smsTemplate = smsTemplate;
        this.onChange();
      }

    }
  }

  private updateView() {
    if (this.model && this.model.smsTemplate) {
      this.countOfCharactersLeft = this.env.maximumSmsLength - getSmsLength(this.model.smsTemplate);
      this.showSmsTooLongError = this.countOfCharactersLeft < 0;
    } else if (this.model && !this.model.smsTemplate) {
      this.countOfCharactersLeft = 459;
      this.showSmsTooLongError = false;
    }
  }
}
