import { inputSocket } from '../flow-application/editor/rete/sockets';
import { Input as ReteInput } from 'rete';
import { CardHeight, CardOrientation, CarouselCardWidth, CarouselNode, EndNode, KeywordsNode, MediaMessageNode, RichCardNode, StartNode, TextMessageNode, MobileInvoiceNode, HttpRequestMessageNode } from 'flow-model';
import { MobileInvoiceValidationService } from './mobile-invoice.validation.service';
import * as i0 from "@angular/core";
export class NodeHelper {
    static createInput() {
        const multiConnections = true;
        return new ReteInput(NodeHelper.DEFAULT_INPUT_KEY, NodeHelper.DEFAULT_INPUT_KEY, inputSocket, multiConnections);
    }
    static createStartNode(id, position) {
        return this.setDefaults(new StartNode(), id, position);
    }
    static createEndNode(id, position) {
        return this.setDefaults(new EndNode(), id, position);
    }
    static newTextMessageNode(id, position) {
        const model = this.setDefaults(new TextMessageNode(), id, position);
        model.template = this.emptyTemplate();
        return model;
    }
    static newHttpRequestMessageNode(id, position) {
        const model = this.setDefaults(new HttpRequestMessageNode(), id, position);
        return model;
    }
    static newMediaMessageNode(id, position) {
        return this.setDefaults(new MediaMessageNode(), id, position);
    }
    static newRichCardNode(id, position) {
        const model = this.setDefaults(new RichCardNode(), id, position);
        model.cardOrientation = CardOrientation.VERTICAL;
        model.cardHeight = CardHeight.TALL;
        model.title = this.emptyTemplate();
        model.description = this.emptyTemplate();
        return model;
    }
    static newCarouselNode(id, position) {
        const model = this.setDefaults(new CarouselNode(), id, position);
        model.carouselCardWidth = CarouselCardWidth.MEDIUM;
        return model;
    }
    static newKeywordNode(id, position) {
        const model = this.setDefaults(new KeywordsNode(), id, position);
        model.inputVariableName = null;
        model.keywords = [];
        return model;
    }
    static newMediaInvoiceNode(id, position) {
        const model = this.setDefaults(new MobileInvoiceNode(), id, position);
        model.text = this.emptyTemplate();
        model.text.en = MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER;
        model.description = this.emptyTemplate();
        model.synchronous = true;
        return model;
    }
    static emptyTemplate() {
        return { 'en': '' };
    }
    static enumSelector(definition) {
        return Object.keys(definition).map(key => ({ value: definition[key], title: key }));
    }
    static setDefaults(node, id, pos) {
        node['@type'] = node.constructor.name;
        node.id = id;
        node.position = pos;
        return node;
    }
    static getKeyword(output) {
        if (output.node.data[output.key]) {
            return output.node.data[output.key];
        }
        return null;
    }
}
NodeHelper.DEFAULT_INPUT_KEY = 'default-input';
NodeHelper.ngInjectableDef = i0.defineInjectable({ factory: function NodeHelper_Factory() { return new NodeHelper(); }, token: NodeHelper, providedIn: "root" });
