<span *ngIf="!isEditing; else editInput"
      (click)="editName()">
  {{ label }}
</span>

<ng-template #editInput>
  <input #textInput
         (blur)="onChange($event)"
         (keyup.enter)="onChange($event)"
         (keyup.escape)="isEditing=false; textInput.value = label;"
         [value]="label"
         class="is-editing">
</ng-template>
