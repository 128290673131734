<div cdkDropList (cdkDropListDropped)="drop($event)">
  <div cdkDrag *ngFor="let suggestionData of messageNode.suggestions">
    <app-suggestion [suggestionData]="suggestionData" [bindSocket]="messageNode.bindSocket"
      (changedSuggestion)="onChange($event)" (delete)="messageNode.removeSuggestion($event)" data-testid="suggestion">
    </app-suggestion>
  </div>
</div>

<div class="suggestion" *ngIf="messageNode.showAddSuggestionButton === true" data-testid="addSuggestion">
  <mat-icon class="add suggestion-inline-button" (click)="addNewSuggestion('')"
    data-testid="addSuggestionButton">add</mat-icon>
  <input type="text" (keyup.enter)="addSuggestion($event)" (blur)="addSuggestion($event)" placeholder="New Suggestion"
    data-testid="suggestionInput" />
  <div class="suggestion-spacer-add"></div>
</div>
