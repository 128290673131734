<div *ngIf="model">
  <div class="details-node-name">Media Message</div>
  <app-name-node [model]="basicNode.model" (changed)="basicNode.onChange()"></app-name-node>
  <mat-form-field class="full-width">
    <input matInput #mediaUrl id="mediaUrl" [(ngModel)]="model.mediaUrl" placeholder="Media URL" type="url" data-testid="MediaUrlInput" />
    <mat-hint>https: required</mat-hint>
  </mat-form-field>
  <app-upload-media [node]="basicNode" [inputFieldId]="'mediaUrl'" (fileUploaded)="setMediaUrl($event)" data-testid="mediaUploadComponent"></app-upload-media>
  <mat-form-field class="full-width">
    <input matInput #thumbnailUrl id="thumbnailUrl" [(ngModel)]="model.thumbnailUrl" [disabled]="thumbnailDisabled" placeholder="Thumbnail URL (optional)" type="url"
      data-testid="ThumbnailUrlInput">
    <span matSuffix *ngIf="thumbnailDisabledFeatureSet"><mat-icon matTooltip="Thumbnails are not supported by WhatsApp" [ngClass]="'error'" data-testid="nodeStatusIcon">error_outline</mat-icon></span>
    <mat-hint>https: required</mat-hint>
  </mat-form-field>
  <app-upload-media [node]="basicNode" [inputFieldId]="'thumbnailUrl'" (fileUploaded)="setMediaUrl($event)" data-testid="thumbnailUploadComponent" [disabled]="thumbnailDisabled"></app-upload-media>
  <app-output-variable [model]="basicNode.model" (changed)="basicNode.onChange()"></app-output-variable>
  <app-suggestion-details-list [suggestions]="suggestionsModel" (changed)="basicNode.onChange()">
  </app-suggestion-details-list>
</div>
