<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <div [innerHTML]="data.question"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [color]="data.buttonStyles ? data.buttonStyles[0] : 'primary'" [mat-dialog-close]="false" data-testid="confirmDialogCancel">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button (click)="selectFile()" mat-button [color]="data.buttonStyles ? data.buttonStyles[1] : 'primary'" data-testid="fileUploadDialog">{{data.confirmText}}</button>
  
</mat-dialog-actions>
