import { ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from '../services/report.service';
import { SnackBarService } from '../services/snackbar.service';
import { UserProfileService } from '../services/user-profile.service';
import { ReportPeriodType, ReportFilter } from 'flow-model';
import * as download from 'downloadjs';
import { DatePipe } from '@angular/common';
export class ReportComponent {
    constructor(route, reportService, userProfileService, snackBar) {
        this.route = route;
        this.reportService = reportService;
        this.userProfileService = userProfileService;
        this.snackBar = snackBar;
        this.showProgress = false;
        this.reportType = ReportPeriodType.MONTHLY;
        this.reportTypeKeys = [ReportPeriodType.MONTHLY, ReportPeriodType.WEEKLY, ReportPeriodType.DAILY];
        this.months = '1';
        this.monthsValues = ['1', '2', '3', '4'];
        this.weeks = '1';
        this.weeksValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
        reportService.reports$.subscribe(reportResult => {
            if (reportResult) {
                this.reportResult = reportResult;
                this.reports = reportResult.periodicAuditReports;
                this.stats = this.createAggregationData();
                this.showProgress = false;
            }
        });
        reportService.reportsError$.subscribe(reportErrorResult => {
            if (reportErrorResult) {
                this.snackBar.showError('Failed to load reports');
                this.showProgress = false;
            }
        });
        reportService.countries$.subscribe(countriesResult => {
            if (countriesResult) {
                this.countries = countriesResult;
            }
        });
        reportService.fetchCountries();
        this.country = ReportComponent.ALL_COUNTRIES;
        userProfileService.userRole$.subscribe(userRole => { this.userRole = userRole; });
        this.initMinMaxDate();
    }
    static getSeparator() {
        return ',';
    }
    initMinMaxDate() {
        const currentDate = Date.now();
        this.minDatePeriod = new Date(currentDate - 100 * ReportComponent.MS_PER_DAY);
        this.maxDatePeriod = new Date(currentDate);
        this.minDate = this.minDatePeriod;
        this.maxDate = this.maxDatePeriod;
    }
    ngOnInit() {
    }
    getConversationStarted(row, channel) {
        const counters = row.chanelCounters;
        if (counters != null && counters.length > 0) {
            for (const counter of counters) {
                if (counter.channel === channel) {
                    return counter.conversationsCount.toString();
                }
            }
        }
        return '';
    }
    getSessionStarted(row, channel) {
        const counters = row.chanelCounters;
        if (counters != null && counters.length > 0) {
            for (const counter of counters) {
                if (counter.channel === channel) {
                    return counter.sessionsCount.toString();
                }
            }
        }
        return '';
    }
    getConversationStartedN(row, channel) {
        const counters = row.chanelCounters;
        if (counters != null && counters.length > 0) {
            for (const counter of counters) {
                if (counter.channel === channel) {
                    return counter.conversationsCount;
                }
            }
        }
        return 0;
    }
    getSessionStartedN(row, channel) {
        const counters = row.chanelCounters;
        if (counters != null && counters.length > 0) {
            for (const counter of counters) {
                if (counter.channel === channel) {
                    return counter.sessionsCount;
                }
            }
        }
        return 0;
    }
    reportsClick() {
        this.reports = new Array();
        const reportFilter = new ReportFilter();
        reportFilter.periodType = this.reportType;
        if (this.reportType === ReportPeriodType.MONTHLY) {
            reportFilter.months = parseInt(this.months, null);
        }
        if (this.reportType === ReportPeriodType.WEEKLY) {
            reportFilter.weeks = parseInt(this.weeks, null);
        }
        if (this.reportType === ReportPeriodType.DAILY) {
            if (!this.startDate || this.startDate === null || !this.endDate || this.endDate === null) {
                this.snackBar.showError('Please set values of start and end date for Daily reports');
                return;
            }
            if (this.startDate > this.endDate) {
                this.snackBar.showError('Please set start less or equal to end date for Daily reports');
                return;
            }
            const copyStartDate = new Date(this.startDate);
            const copyEndDate = new Date(this.endDate);
            if (Math.floor((copyEndDate.getTime() - copyStartDate.getTime()) / ReportComponent.MS_PER_DAY) > 10) {
                this.snackBar.showError('Please set maximum 10 days period between start and end date');
                return;
            }
            reportFilter.startDate = (copyStartDate.getTime()) / 1000 - copyStartDate.getTimezoneOffset() * 60;
            reportFilter.endDate = (copyEndDate.getTime()) / 1000 - copyEndDate.getTimezoneOffset() * 60;
        }
        reportFilter.customerId = this.customerId;
        reportFilter.countryCode = this.country;
        this.showProgress = true;
        this.reportService.fetchReports(reportFilter);
    }
    showMonthly() {
        return this.reportType === ReportPeriodType.MONTHLY;
    }
    showWeekly() {
        return this.reportType === ReportPeriodType.WEEKLY;
    }
    showDaily() {
        return this.reportType === ReportPeriodType.DAILY;
    }
    isMonthlyReport() {
        return this.matchReportType(ReportPeriodType.MONTHLY);
    }
    isWeeklyReport() {
        return this.matchReportType(ReportPeriodType.WEEKLY);
    }
    isDailyReport() {
        return this.matchReportType(ReportPeriodType.DAILY);
    }
    getCurrentDayDate(date) {
        const dateObj = new Date(date * 1000);
        return new Date(date * 1000 + dateObj.getTimezoneOffset() * 60000).toString();
    }
    getAllCountries() {
        return ReportComponent.ALL_COUNTRIES;
    }
    csvClick() {
        this.generateCSVReport();
    }
    onStartDateChange() {
        if (!this.startDate && !this.endDate) {
            this.minDate = this.minDatePeriod;
            this.maxDate = this.maxDatePeriod;
            return;
        }
        if (this.startDate) {
            this.minDate = this.startDate;
            const newMaxDate = this.getDateAfterDays(10, this.startDate);
            this.maxDate = newMaxDate.getTime() < this.maxDatePeriod.getTime() ? newMaxDate : this.maxDatePeriod;
            return;
        }
        if (this.endDate) {
            const newMinDate = this.getDateBeforeDays(10, this.endDate);
            this.minDate = newMinDate.getTime() > this.minDatePeriod.getTime() ? newMinDate : this.minDatePeriod;
        }
    }
    onEndDateChange() {
        if (!this.startDate && !this.endDate) {
            this.minDate = this.minDatePeriod;
            this.maxDate = this.maxDatePeriod;
            return;
        }
        if (this.endDate) {
            this.maxDate = this.endDate;
            const newMinDate = this.getDateBeforeDays(10, this.endDate);
            this.minDate = newMinDate.getTime() > this.minDatePeriod.getTime() ? newMinDate : this.minDatePeriod;
            return;
        }
        if (this.startDate) {
            const newMaxDate = this.getDateAfterDays(10, this.startDate);
            this.maxDate = newMaxDate.getTime() < this.maxDatePeriod.getTime() ? newMaxDate : this.maxDatePeriod;
        }
    }
    getDateBeforeDays(days, date) {
        const copy = new Date(date);
        const result = new Date(copy.getTime() - ReportComponent.MS_PER_DAY * (days - 1));
        return result;
    }
    getDateAfterDays(days, date) {
        const copy = new Date(date);
        const result = new Date(copy.getTime() + ReportComponent.MS_PER_DAY * days - 1);
        return result;
    }
    matchReportType(type) {
        return this.reportResult && this.reportResult.reportFilter &&
            this.reportResult.reportFilter.periodType && this.reportResult.reportFilter.periodType === type;
    }
    generateCSVReport() {
        if (this.reports && this.reports.length > 0) {
            const channels = this.getAllChannels();
            let body = this.createHeaders(channels);
            for (const report of this.reports) {
                if (report.rows && report.rows.length > 0) {
                    let showPeriod = true;
                    for (const row of report.rows) {
                        body += this.createRow(report, row, channels, showPeriod);
                        showPeriod = false;
                    }
                }
                else {
                    body += this.createNoDataRow(report, channels);
                }
            }
            body += this.createTotalHeaderRow(channels);
            body += this.createTotalDataRow(channels);
            const dataBlob = new Blob([body]);
            download(dataBlob, 'reports.csv', 'text/csv; charset=utf-8');
        }
    }
    getAllChannels() {
        const channels = new Set();
        for (const report of this.reports) {
            report.channels.forEach(value => channels.add(value));
        }
        return channels;
    }
    createHeaders(channels) {
        let channelsHeader = '';
        for (const channel of channels) {
            channelsHeader += ReportComponent.dlm + channel + ' Conversations';
            channelsHeader += ReportComponent.dlm + channel + ' Sessions';
        }
        return 'Period' + ReportComponent.dlm + 'Customer Name' + ReportComponent.dlm + 'Customer Id' + ReportComponent.dlm +
            'Country' + ReportComponent.dlm + 'All Conversations' + ReportComponent.dlm + 'All Sessions'
            + ReportComponent.dlm + 'SMS sent'
            + channelsHeader + '\n';
    }
    createRow(report, row, channels, showPeriod) {
        let rowTxt = showPeriod ? this.getDateFormatted(report) + ReportComponent.dlm : ReportComponent.dlm;
        rowTxt += row.customerName;
        rowTxt += ReportComponent.dlm + row.customerId;
        rowTxt += ReportComponent.dlm + this.getCountry(row.countryCode);
        rowTxt += ReportComponent.dlm + row.conversationsCount;
        rowTxt += ReportComponent.dlm + row.sessionsCount;
        rowTxt += ReportComponent.dlm + row.smsCount;
        for (const channel of channels) {
            rowTxt += ReportComponent.dlm + this.getConversationStarted(row, channel);
            rowTxt += ReportComponent.dlm + this.getSessionStarted(row, channel);
        }
        rowTxt += '\n';
        return rowTxt;
    }
    createNoDataRow(report, channels) {
        let rowTxt = this.getDateFormatted(report) + ReportComponent.dlm;
        const dlmCount = channels.size * 2 + 5;
        for (let i = 0; i < dlmCount; i++) {
            rowTxt += ReportComponent.dlm;
        }
        rowTxt += '\n';
        return rowTxt;
    }
    createTotalHeaderRow(channels) {
        let rowTxt = 'Total' + ReportComponent.dlm + ReportComponent.dlm + ReportComponent.dlm + ReportComponent.dlm + 'All Conversations'
            + ReportComponent.dlm + 'All Sessions' + ReportComponent.dlm + 'SMS sent';
        for (const channel of channels) {
            rowTxt += ReportComponent.dlm + channel + ' Conversations';
            rowTxt += ReportComponent.dlm + channel + ' Sessions';
        }
        rowTxt += '\n';
        return rowTxt;
    }
    createTotalDataRow(channels) {
        let rowTxt = ReportComponent.dlm + ReportComponent.dlm + ReportComponent.dlm + ReportComponent.dlm + this.stats.conversationsCount
            + ReportComponent.dlm + this.stats.sessionsCount + ReportComponent.dlm + this.stats.smsCount;
        for (const channel of channels) {
            rowTxt += ReportComponent.dlm + this.stats.getConversationCount(channel);
            rowTxt += ReportComponent.dlm + this.stats.getSessionCount(channel);
        }
        rowTxt += '\n';
        return rowTxt;
    }
    getDateFormatted(report) {
        const datepipe = new DatePipe('en-US');
        if (this.isDailyReport()) {
            return datepipe.transform(this.getCurrentDayDate(report.startTime), 'dd MMMM yyyy');
        }
        if (this.isWeeklyReport()) {
            return datepipe.transform(this.getCurrentDayDate(report.startTime), 'dd.MM') + '-' + datepipe.transform(this.getCurrentDayDate(report.endTime), 'dd.MM yyyy');
        }
        if (this.isMonthlyReport()) {
            return datepipe.transform(this.getCurrentDayDate(report.startTime), 'MMMM yyyy');
        }
        return '';
    }
    getCountry(countryCode) {
        if (countryCode && countryCode.length > 0 && this.countries) {
            for (const country of this.countries) {
                if (country.code === countryCode) {
                    return country.name;
                }
            }
        }
        return '';
    }
    createAggregationData() {
        const stats = new AggregateStats();
        if (this.reports && this.reports.length > 0) {
            const channels = this.getAllChannels();
            stats.channels = channels;
            for (const report of this.reports) {
                if (report.rows && report.rows.length > 0) {
                    for (const row of report.rows) {
                        stats.conversationsCount += row.conversationsCount;
                        stats.sessionsCount += row.sessionsCount;
                        stats.smsCount += row.smsCount;
                        for (const channel of channels) {
                            stats.increaseConversationCount(channel, this.getConversationStartedN(row, channel));
                            stats.increaseSessionCount(channel, this.getSessionStartedN(row, channel));
                        }
                    }
                }
            }
        }
        return stats;
    }
}
ReportComponent.MS_PER_DAY = 1000 * 60 * 60 * 24;
ReportComponent.ALL_COUNTRIES = '[ALL]';
ReportComponent.dlm = ReportComponent.getSeparator();
class AggregateStats {
    constructor() {
        this.conversations = new Map();
        this.sessions = new Map();
        this.conversationsCount = 0;
        this.sessionsCount = 0;
        this.smsCount = 0;
        this.channels = new Set();
    }
    increaseConversationCount(channel, count) {
        this.increaseMapCount(this.conversations, channel, count);
    }
    increaseSessionCount(channel, count) {
        this.increaseMapCount(this.sessions, channel, count);
    }
    increaseMapCount(map, channel, count) {
        let value = count;
        if (map.has(channel)) {
            value = map.get(channel) + count;
        }
        map.set(channel, value);
    }
    getConversationCount(channel) {
        return this.getMapCount(this.conversations, channel);
    }
    getSessionCount(channel) {
        return this.getMapCount(this.sessions, channel);
    }
    getMapCount(map, channel) {
        if (map.has(channel)) {
            return map.get(channel);
        }
        return 0;
    }
}
