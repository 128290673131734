import { StartNodeComponent } from './../flow-application/editor/renderer/node/start-node/start-node.component';
import { AbstractNode, AbstractNodeUnion, CarouselNode, Flow, FlowDefinition, FlowMetaData, KeywordsNode, MediaMessageNode, Position, RichCardNode, TextMessageNode,
         MobileInvoiceNode } from 'flow-model';
import { HttpService } from './http.service';
import { ApplicationRef, Injectable } from '@angular/core';
import { Connection, Node as ReteNode, NodeEditor } from 'rete';
import { ReteTextMessageNode } from '../flow-application/editor/rete/components/text-message-node';
import { ReteHttpRequestMessageNode } from '../flow-application/editor/rete/components/http-request-message-node';
import { ReteStartNode } from '../flow-application/editor/rete/components/start-node';
import ConnectionPlugin from 'rete-connection-plugin';
import AreaPlugin from 'rete-area-plugin';
import { FlowRendererService } from './flow-renderer.service';
import { NodeHelper } from './node-helper.service';
import { ReteEndNode } from '../flow-application/editor/rete/components/end-node';
import { serialize } from 'class-transformer';
import { BasicNode } from '../flow-application/editor/renderer/node/basic-node';
import { NodeDetailsService } from './node-details.service';
import { BehaviorSubject } from 'rxjs';
import { SnackBarService } from './snackbar.service';
import { ReteKeywordsNode } from '../flow-application/editor/rete/components/keywords-node';
import { nodeToReteNodeJson } from '../model/conversion';
import '../model/events';
import { ReteMediaMessageNode } from '../flow-application/editor/rete/components/media-message-node';
import { ReteRichCardNode } from '../flow-application/editor/rete/components/rich-card-node';
import { ReteCarouselNode } from '../flow-application/editor/rete/components/carousel-node';
import { ReteOutput } from '../flow-application/editor/rete/controls/extended-output';
import { ReteMobileInvoiceNode } from '../flow-application/editor/rete/components/mobile-invoice-node';
import { HttpErrorResponse } from '@angular/common/http';
import { UserProfileService } from './user-profile.service';
import { ChannelFeatureset } from 'conversation-domain';

const FLOW_VERSION = 'flow@0.1.0';

export interface FlowState {
  dirty: boolean;
  valid: boolean;
  canRun: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FlowEditorService {
  public flow: Flow;
  private selectedChannels: string;
  private mobileInvoiceEnabled = false;

  private nodeEditor: NodeEditor;
  private mousePosition: [number, number];
  private dirty = false;
  private stateSubject = new BehaviorSubject<FlowState>({ dirty: false, valid: false, canRun: false });
  public flowState$ = this.stateSubject.asObservable();
  private selectedNode: ReteNode;
  private isLoading = false;
  public canTranslate = true;
  public featureSet: ChannelFeatureset;

  private MOBILE_INVOICE_ENABLED = 'MOBILE_INVOICE_ENABLED';
  private SELECTED_CHANNELS = 'SELECTED_CHANNELS';



  constructor(
    private flowRendererService: FlowRendererService,
    private applicationRef: ApplicationRef,
    private httpService: HttpService,
    private snackBar: SnackBarService,
    private nodeDetailsService: NodeDetailsService,
    private userProfileService: UserProfileService
  ) {
    this.loadSelectedChannels();
    this.loadMobileInvoiceLocalStorage();

    if (userProfileService) {
      userProfileService.updateFeatureset.subscribe((featureSet: ChannelFeatureset) => {
        this.featureSet = featureSet;
      });
    }

    userProfileService.channels$.subscribe(channels => {
      if (channels && !this.selectedChannels) {
        for (const channel of channels) {
          if (this.selectedChannels) {
            this.selectedChannels += ',' + channel.toString();
          } else {
            this.selectedChannels = channel.toString();
          }
        }
      }
    });
  }

  private static connectionUpdate(connection: Connection) {
    const inputNode = connection.input.node.data['nodeComponent'] as BasicNode;
    if (inputNode) {
      inputNode.verifyNode();
      inputNode.inputConnectionUpdated();
    }
    const outputNode = (connection.output as ReteOutput).getNodeComponent() as BasicNode;
    if (outputNode) {
      outputNode.verifyNode();
      outputNode.outputConnectionUpdated();
    }
  }

  private static verifyNode(node: any) {
    if (node.data) {
      node.data['nodeComponent'].verifyNode();
    }
  }

  initializeEditor(nativeElement: HTMLElement) {
    if (this.nodeEditor) {
      this.nodeEditor.clear();
      this.nodeEditor.destroy();
    }

    this.nodeEditor = new NodeEditor(FLOW_VERSION, nativeElement);
    this.setupPlugins();
    this.registerEvents();
    this.registerComponents();

    this.nodeEditor.view.resize();
  }

  private async createStartNode() {
    const position = new Position();
    position.x = 400;
    position.y = 200;
    const startNode = NodeHelper.createStartNode(1, position);
    const reteStartNode: ReteNode = ReteNode.fromJSON(nodeToReteNodeJson(startNode));
    return this.nodeEditor.getComponent(ReteStartNode.nodeName).build(reteStartNode).then(startNodeInstance => {
      this.nodeEditor.addNode(startNodeInstance);
    });
  }

  clear(flowId?: string) {
    this.flow = new Flow();
    this.flow.flowDefinition = new FlowDefinition();
    this.flow.id = flowId;
    this.flow.name = 'Unnamed Flow';

    ReteNode.resetId();
    if (this.nodeEditor) {
      this.nodeEditor.clear();
      this.createStartNode().then(() => this.setClean());
    }
  }

  private registerEvents() {
    this.nodeEditor.bind('iochanged');
    this.nodeEditor.bind('flowchanged');
    this.nodeEditor.bind('validate');
    this.nodeEditor.on('mousemove', (e) => {
      this.mousePosition = [e.x, e.y];
    });
    this.nodeEditor.on('nodetranslate', e => this.onNodeTranslate(e.node, e.x, e.y));

    const changeEvents = [
      'nodecreated',
      'noderemoved',
      'connectioncreated',
      'connectionremoved',
      'flowchanged'
    ];
    this.nodeEditor.on('validate' as any, () => {
      this.emitStateChange();
    });
    this.nodeEditor.on(changeEvents.join(' ') as any,
      () => this.onAnyChange());

    this.nodeEditor.on('iochanged', node => FlowEditorService.verifyNode(node));
    this.nodeEditor.on(['connectioncreated', 'connectionremoved'],
      FlowEditorService.connectionUpdate);
    this.nodeEditor.on('nodeselected', this.onSelectReteNodeEvent.bind(this));
    this.nodeEditor.on('noderemoved', this.onReteNodeRemoved.bind(this));
    this.nodeEditor.on('error', (err: Error) => {
      console.error('Editor error! ' + err.message);
    });
  }

  onNodeTranslate(node: ReteNode, x: number, y: number): boolean {
    return this.canTranslate;
   }

  private setupPlugins() {
    this.nodeEditor.use(ConnectionPlugin);
    this.nodeEditor.use(AreaPlugin, {
      background: true,
      scaleExtent: true,
      translateExtent: true,
      snap: true
    });
    this.nodeEditor.use(this.flowRendererService);
  }

  private getNodes(): ReteNode[] {
    if (this.nodeEditor) {
      return this.nodeEditor.nodes;
    } else {
      return null;
    }
  }

  public getFlowJSON(flowId: string): string {

    const result = this.flow;
    result.flowDefinition = new FlowDefinition();
    result.id = flowId;

    const nodes = this.getNodes();
    if (nodes && nodes.length > 0) {
      result.flowDefinition.messages = [];
      nodes.forEach((node) => {
        const basicNode: BasicNode = node.data.nodeComponent as BasicNode;
        result.flowDefinition.messages.push(basicNode.getModelObject() as AbstractNodeUnion);
      });
    }

    return serialize(result);
  }


  public getNodeById(id: number): AbstractNode {
    const nodes = this.getNodes();
    let nodeById: AbstractNode = null;
    if (nodes && nodes.length > 0) {
      nodes.forEach((node) => {
        const basicNode: BasicNode = node.data.nodeComponent as BasicNode;
        if (basicNode.getId() === id) {
          nodeById = basicNode.getModelObject();
        }
      });
    }
    return nodeById;
  }

  updateNodes() {
    const nodes = this.getNodes();
    if (nodes && nodes.length > 0) {
      nodes.forEach((node) => {
        const basicNode: BasicNode = node.data.nodeComponent as BasicNode;
        basicNode.loadFeatureSet();
      });
    }
  }

  getEditor(): NodeEditor {
    return this.nodeEditor;
  }

  getMousePosition(): [number, number] {
    return this.mousePosition;
  }

  public setClean() {
    this.dirty = false;
    this.emitStateChange();
  }

  loadFlow(flowId: string) {
    this.isLoading = true;

    this.httpService.loadFlow(flowId).subscribe(
      (flow: Flow) => this.loadFlowData(flow).then(() => {
        this.isLoading = false;
        this.setClean();
      }),
      (error) => {
        this.isLoading = false;
        if (error.status === 404) {
          this.clear(flowId);
          this.snackBar.showSuccess('New flow created.');
        } else {
          console.error(error);
          this.snackBar.showError('Error while loading flow!');
        }
      }
    );
  }

  loadFlowData(flow: Flow) {
    this.flow = flow;
    if (!this.flow.name) {
      this.flow.name = 'Unnamed Flow';
    }
    this.nodeEditor.clear();

    return this.loadNodes(flow).then(nodeRegistry => {
      this.applicationRef.tick();
      this.connectNodes(flow, nodeRegistry);
    });
  }

  private async loadNodes(flow: Flow): Promise<Map<number, ReteNode>> {
    const nodeRegistry = new Map<number, ReteNode>();
    const nodePromises = flow.flowDefinition.messages.map((flowNode: AbstractNodeUnion) => {
      const component = this.nodeEditor.getComponent(flowNode['@type']);
      const reteNode = ReteNode.fromJSON(nodeToReteNodeJson(flowNode));

      return component.build(reteNode).then(node => {
        this.nodeEditor.addNode(node);
        nodeRegistry.set(flowNode.id, node);
        (node.data.nodeComponent as BasicNode).loadModel(flowNode);
      });
    });
    return Promise.all(nodePromises).then(() => Promise.resolve(nodeRegistry));
  }

  private connectNodes(flow: Flow, nodeRegistry: Map<number, ReteNode>) {
    flow.flowDefinition.messages.forEach((msg: AbstractNode) => {
      this.connectDirectOutputIfPresent(msg, nodeRegistry);
      if (msg['@type'] === ReteTextMessageNode.nodeName || msg['@type'] === ReteMediaMessageNode.nodeName || msg['@type'] === ReteRichCardNode.nodeName) {
        const msgNode = msg as TextMessageNode | MediaMessageNode | RichCardNode;
        this.connectOnFreeTextIfPresent(msgNode, nodeRegistry);
        this.connectSuggestionsIfPresent(msgNode, nodeRegistry);
      } else if (msg['@type'] === ReteCarouselNode.nodeName) {
        const carouselNode = msg as CarouselNode;
        this.connectOnFreeTextIfPresent(carouselNode, nodeRegistry);
        this.connectCarouselToRichCards(carouselNode, nodeRegistry);
      } else if (msg['@type'] === ReteKeywordsNode.nodeName) {
        const msgNode = msg as KeywordsNode;
        this.connectKeywords(msgNode, nodeRegistry);
      } else if (msg['@type'] === ReteMobileInvoiceNode.nodeName) {
        const mobileInvoiceNode = msg as MobileInvoiceNode;
        this.connectMobileInvoiceStatus(mobileInvoiceNode, nodeRegistry);
      }
    });
  }

  private connectDirectOutputIfPresent(msg: AbstractNode, nodeRegistry: Map<number, ReteNode>) {
    if (msg.nextMessageId) {
      this.connectOutputInput(msg.id, ReteOutput.DEFAULT_NEXT_KEY, msg.nextMessageId, nodeRegistry);
    }
  }
  private connectOnFreeTextIfPresent(msg: TextMessageNode | MediaMessageNode | RichCardNode | CarouselNode, nodeRegistry: Map<number, ReteNode>) {
    if (msg.onFreeText && msg.onFreeText.nextMessageId) {
      this.connectOutputInput(msg.id, ReteOutput.FREE_TEXT_KEY, msg.onFreeText.nextMessageId, nodeRegistry);
    }
  }
  private connectSuggestionsIfPresent(msg: TextMessageNode | MediaMessageNode | RichCardNode, nodeRegistry: Map<number, ReteNode>) {
    if (msg.suggestions) {
      msg.suggestions.forEach((suggestion, idx) => {
        if (suggestion.nextMessageId) {
          const key = ReteOutput.createIndexedKey(ReteOutput.SUGGESTION_KEY, idx);
          this.connectOutputInput(msg.id, key, suggestion.nextMessageId, nodeRegistry);
        }
      });
    }
  }
  private connectMobileInvoiceStatus(mobileInvoiceNode: MobileInvoiceNode, nodeRegistry: Map<number, ReteNode>) {
    if (mobileInvoiceNode.statusOutputs) {
      for (const key in mobileInvoiceNode.statusOutputs) {
        if (mobileInvoiceNode.statusOutputs.hasOwnProperty(key)) {
           const reteKey = ReteOutput.MOBILE_INVOICE_STATUS_KEY +  key;
           if (mobileInvoiceNode.statusOutputs[key]) {
            this.connectOutputInput(mobileInvoiceNode.id, reteKey, mobileInvoiceNode.statusOutputs[key], nodeRegistry);
           }
        }
      }
    }
  }
  private connectKeywords(keywordsNode: KeywordsNode, nodeRegistry: Map<number, ReteNode>) {
    if (keywordsNode.keywords) {
      keywordsNode.keywords.forEach((keyword, idx) => {
        if (keyword.nextMessageId) {
          const key = ReteOutput.createIndexedKey(ReteOutput.KEYWORD_KEY, idx);
          this.connectOutputInput(keywordsNode.id, key, keyword.nextMessageId, nodeRegistry);
        }
      });
    }
  }
  private connectCarouselToRichCards(msg: CarouselNode, nodeRegistry: Map<number, ReteNode>) {
    if (msg.cardIDs) {
      msg.cardIDs
        .forEach((cardId, idx) => {
        const key = ReteOutput.createIndexedKey(ReteOutput.CAROUSEL_RICH_CARD_KEY, idx);
        if (cardId !== null) {
          this.connectOutputInput(msg.id, key, cardId, nodeRegistry);
        }
      });
    }
  }
  private connectOutputInput(srcMsgId: number, outputKey: string, dstMsgId: number, nodeRegistry: Map<number, ReteNode>) {
    const srcOutput = nodeRegistry.get(srcMsgId).outputs.get(outputKey);
    const dstInput = nodeRegistry.get(dstMsgId).inputs.get(NodeHelper.DEFAULT_INPUT_KEY);
    if (srcOutput && dstInput) {
      this.nodeEditor.connect(srcOutput, dstInput, {});
    }
  }

  save(flowId: string) {
    const flowJson = this.getFlowJSON(flowId);
    this.saveJsonFlow(flowJson);
  }

  saveJsonFlow(flowJson: string) {
    this.httpService.saveFlow(flowJson)
      .subscribe(() => {
        this.setClean();
        this.snackBar.showSuccess('Flow was saved successfully');
      }, error => {
        console.log(error);
        this.snackBar.showError('Error while saving flow! ' + error.error);
      });
  }

  renameCurrentFlow(newName: string) {
    this.flow.name = newName;
    this.onAnyChange();
  }

  renameFlow(newName: string, flow: FlowMetaData) {
    this.httpService.saveNewFlowName(flow.name, newName, flow.id).subscribe(
      () => {
      },
      error => {
        console.log(error);
        this.snackBar.showError('Error while updating flow name!');
      });
  }

  selectStartNode(): ReteNode {
    for (const node of this.getNodes()) {
      if (node.name === 'StartNode') {
        const startNodeComponent = node.data['nodeComponent'] as StartNodeComponent;
        startNodeComponent.setSelected(true);
        this.selectedNode = node;
        this.nodeDetailsService.changeNode(startNodeComponent);
        break;
      }
    }
    return this.selectedNode;
  }

  private registerComponents() {
    const components = [
      new ReteStartNode(),
      new ReteTextMessageNode(),
      new ReteMediaMessageNode(),
      new ReteKeywordsNode(),
      new ReteRichCardNode(),
      new ReteEndNode(),
      new ReteCarouselNode(),
      new ReteMobileInvoiceNode(),
      new ReteHttpRequestMessageNode()
    ];

    components.forEach(c => {
      this.nodeEditor.register(c);
    });
  }

  private onSelectReteNodeEvent(newSelectedNode: ReteNode) {
    if (this.selectedNode) {
      if (newSelectedNode.id === this.selectedNode.id) {
        return;
      }
      (this.selectedNode.data['nodeComponent'] as BasicNode).setSelected(false);
    }

    this.selectedNode = newSelectedNode;
    const selectedComponent = newSelectedNode.data['nodeComponent'] as BasicNode;
    selectedComponent.setSelected(true);
    this.nodeDetailsService.changeNode(selectedComponent);
  }

  private onReteNodeRemoved(removedNode: ReteNode) {
    if (removedNode && this.selectedNode && removedNode.id === this.selectedNode.id) {
      this.selectedNode = null;
      this.nodeDetailsService.changeNode(null);
    }
  }

  private isValid(): boolean {
    const nodes = this.getNodes();
    if (nodes) {
      for (const node of nodes) {
        if (!(node.data['nodeComponent'] as BasicNode).isValid) {
          return false;
        }
      }
    }
    return true;
  }

  private onAnyChange() {
    if (this.isLoading) {
      return;
    }
    // TODO try to find another way to detect changes in the details panel on suggestion deletion.
    if (this.selectedNode) {
      this.nodeDetailsService.changeNode(this.selectedNode.data['nodeComponent'] as BasicNode);
    }
    this.dirty = true;
    this.emitStateChange();
  }

  private emitStateChange() {
    const valid = this.isValid();
    this.stateSubject.next({ dirty: this.dirty, valid: valid, canRun: !this.dirty && valid });
  }

  public fetchMobileInvoice(): void {
    this.httpService.loadMobileInvoice().subscribe(
      mobileInvoice => {
        if (mobileInvoice) {
          this.mobileInvoiceEnabled = mobileInvoice.enabled;
          localStorage.setItem(this.MOBILE_INVOICE_ENABLED, this.mobileInvoiceEnabled ? 'true' : 'false');

          if (this.mobileInvoiceEnabled) {
            this.userProfileService.fetchMobileInvoiceSettings();
          }
        }
      },
      (response: HttpErrorResponse) => {
        if (response.status !== 401) {
          throw response;
        }
      });
  }

  public clearLocalStorage(): void {
    localStorage.removeItem(this.MOBILE_INVOICE_ENABLED);
    localStorage.removeItem(this.SELECTED_CHANNELS);
  }

  private loadMobileInvoiceLocalStorage(): void {
    this.mobileInvoiceEnabled = false;
    const mobileInvoice = localStorage.getItem(this.MOBILE_INVOICE_ENABLED);
    if (mobileInvoice) {
      if (mobileInvoice === 'true') {
        this.mobileInvoiceEnabled = true;
      }
    }
  }

  private loadSelectedChannels(): void {
    this.selectedChannels = '';
    const selectedChannels = localStorage.getItem(this.SELECTED_CHANNELS);
    if (selectedChannels) {
      this.selectedChannels = selectedChannels;
    }
  }

  public getMobileInvoiceEnabled(): boolean {
    return this.mobileInvoiceEnabled;
  }

  public getSelectedChannels(): string {
    return this.selectedChannels;
  }

  public setSelectedChannels(channels: string) {
    this.selectedChannels = channels;
    localStorage.setItem(this.SELECTED_CHANNELS, channels);
  }
}
