import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { MatSnackBar } from '@angular/material';
import { UserProfileService } from '../services/user-profile.service';
import { FlowEditorService } from '../services/flow-editor.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  loginInProgress: boolean;
  @ViewChild('loginForm') loginForm: ElementRef;

  returnUrl: string;

  constructor(private route: ActivatedRoute,
              private loginService: LoginService,
              private router: Router,
              private snackBar: MatSnackBar,
              private userProfileService: UserProfileService,
              private flowService: FlowEditorService) {
  }

  ngOnInit() {
    // reset login status
    this.loginService.logout(false);
    this.flowService.clearLocalStorage();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  tryLogin() {
    if (!this.loginForm.nativeElement.reportValidity()) {
      return;
    }
    this.snackBar.dismiss();
    this.loginInProgress = true;
    this.loginService.doLogin(
      this.username,
      this.password
    )
      .subscribe(
        response => {
          if (response) {
            LoginService.authenticated(response, this.username);
            this.flowService.setSelectedChannels('');
            this.userProfileService.fetchFeatureset();
            this.userProfileService.fetchChannels();
            this.userProfileService.fetchAccountSettings();
            this.userProfileService.fetchUserRole();
            this.flowService.fetchMobileInvoice();
            this.router.navigateByUrl(this.returnUrl);
          }
          this.loginInProgress = false;
        },
        () => {
          this.snackBar.open('Login failed', 'Dismiss', {
            verticalPosition: 'top',
            panelClass: 'snackbar-error'
          });
          this.loginInProgress = false;
        });
  }

}
