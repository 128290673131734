/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./keywords-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./keyword/keyword.component.ngfactory";
import * as i3 from "./keyword/keyword.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "./keywords-list.component";
var styles_KeywordsListComponent = [i0.styles];
var RenderType_KeywordsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KeywordsListComponent, data: {} });
export { RenderType_KeywordsListComponent as RenderType_KeywordsListComponent };
function View_KeywordsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-keyword", [["data-testid", "keyword"]], null, [[null, "changedKeyword"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changedKeyword" === en)) {
        var pd_0 = (_co.onChange() !== false);
        ad = (pd_0 && ad);
    } if (("delete" === en)) {
        var pd_1 = (_co.deleteKeyword.emit(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_KeywordComponent_0, i2.RenderType_KeywordComponent)), i1.ɵdid(2, 49152, null, 0, i3.KeywordComponent, [], { output: [0, "output"], bindSocket: [1, "bindSocket"], keyword: [2, "keyword"] }, { changedKeyword: "changedKeyword", delete: "delete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.output; var currVal_1 = _co.bindSocket; var currVal_2 = _v.context.$implicit.keywordMatch; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_KeywordsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeywordsListComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "keyword"], ["data-testid", "addKeyword"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "keyword-inline-button mat-icon notranslate"], ["data-testid", "addKeywordButton"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createKeyword.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["data-testid", "keywordInput"], ["placeholder", "New Keyword"], ["type", "text"]], null, [[null, "keyup.enter"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup.enter" === en)) {
        var pd_0 = (_co.addKeyword($event) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.addKeyword($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "keyword-spacer-add"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.keywordsData; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4).inline; var currVal_2 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_KeywordsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-keywords-list", [], null, null, null, View_KeywordsListComponent_0, RenderType_KeywordsListComponent)), i1.ɵdid(1, 49152, null, 0, i7.KeywordsListComponent, [], null, null)], null, null); }
var KeywordsListComponentNgFactory = i1.ɵccf("app-keywords-list", i7.KeywordsListComponent, View_KeywordsListComponent_Host_0, { keywordsData: "keywordsData", bindSocket: "bindSocket" }, { createKeyword: "createKeyword", deleteKeyword: "deleteKeyword", changed: "changed" }, []);
export { KeywordsListComponentNgFactory as KeywordsListComponentNgFactory };
