import { ReteOutput } from './editor/rete/controls/extended-output';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FlowEditorService } from '../services/flow-editor.service';
import { MatDrawer } from '@angular/material';
import { Input as ReteInput, Node as ReteNode, Output } from 'rete';
import { SnackBarService } from '../services/snackbar.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { ActivatedRoute } from '@angular/router';
import { ReteRichCardNode } from './editor/rete/components/rich-card-node';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-flow-application',
  templateUrl: './flow-application.component.html',
  styleUrls: ['./flow-application.component.css']
})
export class FlowApplicationComponent implements OnInit, AfterViewInit {

  constructor(public flowEditorService: FlowEditorService,
    public snackbarService: SnackBarService,
    private route: ActivatedRoute,
    private loginService: LoginService) {

    this.flowEditorService.flowState$.subscribe(state => {
      this.flowDirty = state.dirty;
    });
  }

  @ViewChild('drawer')
  drawer: MatDrawer;

  @ViewChild('sideBar')
  sideBar: SidebarComponent;

  @ViewChild('detailsPanel')
  detailsPanel;

  shortcuts: ShortcutInput[] = [];

  private oldSelectedNode = null;

  private currentSelectedNode: ReteNode = null;
  private flowId: string;
  flowDirty: boolean;

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.flowId = id;
    }
  }

  ngAfterViewInit(): void {
    this.flowEditorService.getEditor().on('nodeselected', this.onReteNodeSelected.bind(this));
    this.flowEditorService.getEditor().on('connectioncreate', connection => this.handleConnectionCreation(connection));

    this.shortcuts.push(
      {
        key: 'ctrl + s',
        preventDefault: true,
        label: 'Flow',
        description: 'Save the current flow',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: () => this.flowEditorService.save(this.flowId)
      });
  }

  toggleDrawer() {
    this.drawer.toggle().then(() => {
      if (this.drawer.opened) {
        this.detailsPanel.nativeElement.classList.remove('new');
      }
    });
  }

  toggleSettings() {
    if (!this.drawer.opened) {
      this.currentSelectedNode = this.flowEditorService.selectStartNode();
      this.drawer.open();
    } else if (this.drawer.opened && this.currentSelectedNode.name === 'StartNode') {
      this.drawer.close();
      this.detailsPanel.nativeElement.classList.remove('new');
    } else if (this.drawer.opened && this.currentSelectedNode.name !== 'StartNode') {
      this.currentSelectedNode = this.flowEditorService.selectStartNode();
    }
  }

  private onReteNodeSelected(newSelectedNode: ReteNode) {
    // retrigger protection
    this.currentSelectedNode = newSelectedNode;
    if (this.oldSelectedNode && newSelectedNode.id === this.oldSelectedNode.id) {
      return;
    }

    if (this.oldSelectedNode == null) {
      this.drawer.open();
    }

    this.oldSelectedNode = newSelectedNode;

    if (!this.drawer.opened) {
      this.detailsPanel.nativeElement.classList.add('flash');
      this.detailsPanel.nativeElement.classList.add('new');
      setTimeout(() => {
        this.detailsPanel.nativeElement.classList.remove('flash');
      }, 500);
    }
  }

  private handleConnectionCreation(connection: { input: ReteInput, output: Output }) {
    if ((connection.output as ReteOutput).isCarouselOutput() && connection.input.node.name !== ReteRichCardNode.nodeName) {
      return false;
    }
    return !this.isNonBlockingLoop(connection);
  }

  private isNonBlockingLoop(connection: { input: ReteInput, output: Output }): boolean {
    if (!(connection.output as ReteOutput).isUserInteractionRequired()) {
      return this.hasNonBlockingLoopInBranches(connection.output.node, [], connection.input.node);
    }
    return false;
  }

  private hasNonBlockingLoopInBranches(node: ReteNode, visitedNodeIds: number[], nodeToConnect?: ReteNode): boolean {
    if (visitedNodeIds.indexOf(node.id) > -1) {
      this.snackbarService.showError('This connection is not possible, it would lead to a endless loop in your flow');
      return true;
    }
    visitedNodeIds.push(node.id);
    let isLoop = false;
    this.getNonBlockingBranchNodes(node, nodeToConnect).forEach(nextNode => {
      isLoop = isLoop || this.hasNonBlockingLoopInBranches(nextNode, visitedNodeIds.slice());
    });
    return isLoop;
  }

  private getNonBlockingBranchNodes(node: ReteNode, nodeToConnect?: ReteNode): ReteNode[] {
    const nextNodes: ReteNode[] = [];
    node.outputs.forEach((output: ReteOutput) => {
      if (nodeToConnect) {
        nextNodes.push(nodeToConnect);
      }
      if (output.hasConnection() && !output.isUserInteractionRequired()) {
        nextNodes.push(output.connections[0].input.node);
      }
    });
    return nextNodes;
  }
}
