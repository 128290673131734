<div *ngIf="model">
  <div class="details-node-name">Rich Card Message</div>
  <app-name-node [model]="basicNode.model" (changed)="basicNode.onChange()"></app-name-node>
  <mat-form-field>
    <mat-label>Card Orientation</mat-label>
    <mat-select [(ngModel)]="model.cardOrientation" (ngModelChange)="basicNode.onChange()"
      data-testid="OrientationDropDownMenu">
      <mat-option *ngFor="let c of cardOrientations" value="{{c.value}}">
        {{c.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="model.cardOrientation === 'VERTICAL'">
    <mat-label>Card Height</mat-label>
    <mat-select [(ngModel)]="model.cardHeight" (ngModelChange)="basicNode.onChange()"
      data-testid="CardHeightDropDownMenu">
      <mat-option *ngFor="let c of cardHeights" value="{{c.value}}">
        {{c.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="full-width">
    <input matInput #mediaUrl id="mediaUrl"
        [(ngModel)]="model.mediaUrl"
        placeholder="Media URL (optional)" type="url"
        data-testid="RichCardMediaUrlInput"/>
    <mat-hint>https: required</mat-hint>
  </mat-form-field>
  <app-upload-media [node]="basicNode" [inputFieldId]="'mediaUrl'" (fileUploaded)="setMediaUrl($event)" data-testid="mediaUploadComponent"></app-upload-media>
  <mat-form-field class="full-width">
    <input matInput #thumbnailUrl id="thumbnailUrl"
        [(ngModel)]="model.thumbnailUrl"  [disabled]="thumbnailDisabled"
        placeholder="Thumbnail URL (optional)" type="url"
        data-testid="RichCardThumbnailUrlInput"/>
    <mat-hint>https: required</mat-hint>
  </mat-form-field>
  <app-upload-media [node]="basicNode" [inputFieldId]="'thumbnailUrl'" (fileUploaded)="setMediaUrl($event)" data-testid="thumbnailUploadComponent"></app-upload-media>
  <mat-form-field *ngIf="model.cardOrientation === 'HORIZONTAL'">
    <mat-label>Image alignment</mat-label>
    <mat-select [(ngModel)]="model.thumbnailImageAlignment" (ngModelChange)="basicNode.onChange()"
      data-testid="ThumbnailAlignmentDropDownMenu">
      <mat-option *ngFor="let c of thumbnailImageAlignments" value="{{c.value}}">
        {{c.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-output-variable [model]="basicNode.model" (changed)="basicNode.onChange()"></app-output-variable>
  <app-suggestion-details-list [suggestions]="suggestionsModel" [suggestionTypes]="getEnabledSuggestions()" (changed)="basicNode.onChange()">
  </app-suggestion-details-list>
</div>
