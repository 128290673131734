<div class="mobile-status-output">
    <mat-icon (click)="deleteStatusOutput(output)"
              data-testid="deleteStatusOutput"
              class="mobile-status-output-inline-button delete-button">
      cancel
    </mat-icon>
    <div class="fill_space"></div>
    <div class="default-output-text" data-testid="defaultOutputText">
      {{ output.name }}
    </div>
    <app-socket
      [bindSocket]="bindSocket"
      [inputOutput]="output"
      data-testid="StatusOutputSocket"
      type="output">
    </app-socket>
</div>