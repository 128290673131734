<!--suppress HtmlFormInputWithoutLabel -->
<input [hidden]="!isEditing"
       #nameInput
       [value]="name"
       data-testid="flowName"
       class="edit-flow-name"
       placeholder="New Flow Name"
       (blur)="success()"
       (keyup.enter)="success()"
       (keyup.escape)="cancel()">
<div class="name-link" *ngIf="!isEditing">
  <a [routerLink]="link">
    {{ name }}
  </a>
  <i *ngIf="showEditButton"
      (click)="edit()"
      class="rename pointer fa fa-pen-square"
      data-testid="editNameButton"></i>
</div>
