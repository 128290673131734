import { ReteOutput } from './../../../../../rete/controls/extended-output';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Input as ReteInput } from 'rete';
import { environment } from '../../../../../../../../environments/environment';
import { KeywordMatch } from 'flow-model';

@Component({
  selector: 'app-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['./keyword.component.css']
})
export class KeywordComponent {

  @Input()
  output: ReteOutput;

  @Input()
  bindSocket: (el: HTMLElement, type: string, io: ReteInput | ReteOutput) => void;

  @Input()
  keyword: KeywordMatch;

  @Output()
  changedKeyword = new EventEmitter<void>();

  @Output()
  delete = new EventEmitter<ReteOutput>();

  env = environment;

  keywordChanged() {
    this.changedKeyword.emit();
  }

  deleteKeyword(output: ReteOutput) {
    this.delete.emit(output);
  }
}
