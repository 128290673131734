import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { SnackBarService } from '../../../services/snackbar.service';
import { ActivatedRoute } from '@angular/router';
import { deserializeArray } from 'class-transformer';
import { AccountSettings, CampaignInfo, CampaignState, DateAsNumber } from 'flow-model';
import { FlowEditorService, FlowState } from '../../../services/flow-editor.service';
import { environment } from '../../../../environments/environment';
import { Subscription, Observable, timer } from 'rxjs';
import { CampaignData } from 'src/app/model/campaign';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { scan } from 'rxjs/operators';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { CampaignDialogComponent } from 'src/app/dialogs/campaign-dialog/campaign-dialog.component';
import { DateTimeFormatter, Instant, LocalDateTime, ZoneId, ZonedDateTime } from 'js-joda';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit, OnDestroy {
  campaignsData = new Map<string, CampaignData>();
  campaigns: CampaignInfo[] = [];
  flowId: string;

  private accountSettings: AccountSettings;

  lastCampaignId: string = null;

  scanIndexForward = false;

  hasNextItems = false;

  hasPreviousItems = false;

  @ViewChild('fileInput')
  fileInput: ElementRef;

  uploadInProgress = false;
  pageUpdateInProgress = false;
  private file: File;
  private lastRefreshTime = new Date();
  flowCanRun = true;

  env = environment;

  scrollTop: number;
  scrollLeft: number;

  private updateTimer: Observable<number> = timer(500, 500);
  private subscription: Subscription;

  public static transformToEpochNumber(date: LocalDateTime): DateAsNumber {
    if (date) {
      return date.atZone(ZoneId.SYSTEM).toEpochSecond();
    } else {
      return null;
    }
  }

  public static getScheduledTime(localDateTime: LocalDateTime): string {
    if (localDateTime) {
      const scheduledTime = this.transformToEpochNumber(localDateTime);
      const utcDate = ZonedDateTime.ofInstant(Instant.ofEpochSecond(scheduledTime), ZoneId.UTC);
      const startTime = utcDate.format(DateTimeFormatter.ofPattern('yyyy-MM-dd\'T\'HH:mm'));
      return startTime;
    }
    return null;
  }

  constructor(private http: HttpService,
              private snackBar: SnackBarService,
              private route: ActivatedRoute,
              private flowEditorService: FlowEditorService,
              private dialogService: DialogService,
              private userProfileService: UserProfileService) {
                this.loadAccountSettings();
  }

  ngOnInit() {
    this.flowId = this.route.snapshot.paramMap.get('id');
    if (this.flowId) {
      this.updateCampaigns();
    }
    this.flowEditorService.flowState$.subscribe((state: FlowState) => {
      this.flowCanRun = state.canRun;
    });

    this.subscription = this.updateTimer.subscribe((seconds) => {
      if (this.flowId) {
        this.timerUpdateCampaigns();
      }
    });
  }

  private loadAccountSettings() {
    this.userProfileService.accountSettings$.subscribe(accountSettings => {
      if (accountSettings) {
        this.accountSettings = accountSettings;
      } else {
        this.accountSettings = new AccountSettings();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private timerUpdateCampaigns() {
    const datetime = new Date();
    const miliseconds = this.hasProgressCampaign() ? 1000 : 5000;

    if ((datetime.getTime() - this.lastRefreshTime.getTime()) > miliseconds) {
      this.lastRefreshTime = datetime;
      if (!this.pageUpdateInProgress) {
          this.updateCampaigns();
      }
    }
  }

  private hasProgressCampaign(): boolean {
    for (const data of this.campaignsData.values()) {
      if (this.isProgressState(data.state)) {
        return true;
      }
    }
    for (const campaign of this.campaigns) {
      if (this.isProgressState(campaign.state)) {
        return true;
      }
    }
    return false;
  }

  private isProgressState(state: CampaignState): boolean {
    return state === CampaignState.LOADING ||
    state === CampaignState.INITIATING ||
    state === CampaignState.STOPPING ||
    state === CampaignState.STOPPING_MSISNDS ||
    state === CampaignState.DELETING;
  }

  loadNextItems() {
    this.pageUpdateInProgress = true;
    if (this.campaigns.length > 0 ) {
    const campaignId = this.campaigns[this.campaigns.length - 1].id;
    this.updateCampaigns(campaignId, false, false);
    } else {
      this.loadFirstItems();
    }
  }

  loadFirstItems() {
    this.pageUpdateInProgress = true;
    this.updateCampaigns(null, false, false);
  }

  loadPreviousItems() {
    this.pageUpdateInProgress = true;
    if (this.campaigns.length > 0) {
    const campaignId = this.campaigns[0].id;
    this.updateCampaigns(campaignId, true, false);
    } else {
      this.loadFirstItems();
    }
  }

  loadLastItems() {
    this.pageUpdateInProgress = true;
    this.updateCampaigns(null, true, false);
  }


  startCampaign(campaign: CampaignInfo) {
    if (this.accountSettings && this.accountSettings.disableForceStartCampaign) {
      this.proceedWithCampaignDialog(campaign, false);
    } else {
      this.proceedWithCampaignDialog(campaign, true);
    }
  }

  editScheduledCampaign(campaign: CampaignInfo) {
    const campaignDialogService: CampaignDialogComponent  = this.dialogService.campaign('Edit campaign',
      `<p><b>${campaign.name}</b> is configured for channels ` +  campaign.channels + `.<br/> The campaign\'s flow is in the state in which the campaign is scheduled for the first time.`
       + `If you choose to start the campaign now the flow will be in it's the current state.</p>`,
      'Edit campaign',
      campaign.channels, !this.accountSettings.disableForceStartCampaign, true, campaign.executionTime, campaign.finalMessage, campaign.hideFreeText,
      confirmed => {
        if (confirmed) {
          this.campaignsData.get(campaign.id).isBusy = true;
          if (campaignDialogService.startNow) {
            this.campaignsData.get(campaign.id).state = CampaignState.INITIATING;
            this.executeStartCampaign(campaign, !this.accountSettings.disableForceStartCampaign && campaignDialogService.forceStart, campaignDialogService.text, campaignDialogService.hideFreeText);
          } else {
            const startTime = CampaignsComponent.getScheduledTime(campaignDialogService.localDateTime);
            this.executeEditScheduleCampaign(campaign.id, campaignDialogService.text, campaignDialogService.hideFreeText, startTime);
          }
        }
      });
  }

  private  executeEditScheduleCampaign(id: string, finalMessage: string, hideFreeText: boolean, startTime: string) {
    this.http.editScheduledCampaign(id, hideFreeText, finalMessage, startTime).subscribe(() => {
      this.snackBar.showSuccess('Campaign is saved!');
  },
  (e) => {
      console.error('HTTP Status Code = ' + e.status + ' Body = ' + e.error);
      if (e.error && e.error.message) {
        this.snackBar.showErrorPermanently(e.error.message);
      } else {
        this.snackBar.showError('Could not save scheduled campaign! Unknown error occurred.');
      }
    }
  );
  }

  private proceedWithCampaignDialog(campaign: CampaignInfo, hasForceOption: boolean) {
    const campaignDialogService: CampaignDialogComponent  = this.dialogService.campaign('Ready to run?',
      `<p>Once a campaign has been started, it cannot be launched again. Are you sure you want to start the campaign <b>${campaign.name}</b>?</p>`,
      'Launch campaign',
      this.flowEditorService.getSelectedChannels(), hasForceOption, false, 0, null, false,
      confirmed => {
        if (confirmed) {
          this.campaignsData.get(campaign.id).isBusy = true;
        if (campaignDialogService.startNow) {
          this.campaignsData.get(campaign.id).state = CampaignState.INITIATING;
          this.executeStartCampaign(campaign, hasForceOption && campaignDialogService.forceStart, campaignDialogService.text, campaignDialogService.hideFreeText);
        } else {
            const startTime = CampaignsComponent.getScheduledTime(campaignDialogService.localDateTime);
            this.campaignsData.get(campaign.id).state = CampaignState.SCHEDULED;
            this.scheduleCampaign(campaign.id, startTime, this.flowEditorService.getSelectedChannels(), campaignDialogService.text, campaignDialogService.hideFreeText);
        }}
      });
  }

  executeStartCampaign(campaign: CampaignInfo, forceStart: boolean, finalMessage: string, hideFreeText: boolean) {
      if (forceStart) {
        this.forceTriggerCampaign(campaign, finalMessage, hideFreeText);
      } else {
        this.triggerCampaign(campaign, hideFreeText);
      }
  }

  scheduleCampaign(id: string, startTime: string, channels: string, finalMessage: string, hideFreeText: boolean) {
    this.http.scheduleCampaign(id, startTime, channels, finalMessage, hideFreeText).subscribe(() => {
      this.snackBar.showSuccess('Campaign is saved!');
      this.loadFirstItems();
    },
     (e) => {
      console.error('HTTP Status Code = ' + e.status + ' Body = ' + e.error);
      if (e.error && e.error.message) {
        this.snackBar.showErrorPermanently(e.error.message);
      } else {
      this.snackBar.showError('Could not schedule campaign! Unknown error occurred.');
      }
     });
  }

  private forceTriggerCampaign(campaign: CampaignInfo, finalMessage: string, hideFreeText: boolean) {
    this.http.forceTriggerCampaign(campaign.id, this.flowEditorService.getSelectedChannels(), finalMessage, hideFreeText).subscribe(() => {
      this.showSuccessOnTriggerCampaign();
    },
      (e) => {
        this.proccessFailureOnTriggerCampaign(campaign, e);
      }
    );

  }


  private proceedWithConfirmationDialog(campaign: CampaignInfo) {
   this.dialogService.confirmation('Ready to run?',
    `Once a campaign has been started, it cannot be launched again. Are you sure you want to start the campaign <b>${campaign.name}</b>?`,
    'Launch campaign',
    confirmed => {
      if (confirmed) {
        this.campaignsData.get(campaign.id).isBusy = true;
        this.campaignsData.get(campaign.id).state = CampaignState.INITIATING;
        this.triggerCampaign(campaign, false);
      }
    });
  }

  private triggerCampaign(campaign: CampaignInfo, hideFreeText: boolean) {
    this.http.triggerCampaign(campaign.id, this.flowEditorService.getSelectedChannels(), hideFreeText).subscribe(() => {
      this.showSuccessOnTriggerCampaign();
    },
    (e) => {
      this.proccessFailureOnTriggerCampaign(campaign, e);
      }
    );
  }

  private proccessFailureOnTriggerCampaign(campaign: CampaignInfo, e: any) {
    this.campaignsData.get(campaign.id).isBusy = false;
    if (e.status === 422) {
      this.snackBar.showErrorPermanently(e.error.message);
    } else if (e.status === 406) {
      this.snackBar.showErrorPermanently('Could not start campaign: ' + e.error.message);
    } else {
      console.error('HTTP Status Code = ' + e.status + ' Body = ' + e.error);
      this.snackBar.showError('Could not start campaign! Unknown error occurred.');
    }
}

  private showSuccessOnTriggerCampaign() {
    this.snackBar.showSuccess('Campaign was launched!');
    this.loadFirstItems();
  }

  updateCampaigns(lastCampaignId: string = null, scanIndexForward: boolean = null, useClassVariables: boolean = true) {

    const sidebar = document.getElementById('sidebar');
    if (sidebar != null) {
      this.scrollTop = sidebar.scrollTop;
      this.scrollLeft = sidebar.scrollLeft;

      const top = sidebar.scrollTop;
      const left = sidebar.scrollLeft;

      sidebar.onscroll = function() {
        sidebar.scrollTo(left, top);
      };
    }
    if (useClassVariables) {
      lastCampaignId = this.lastCampaignId;
      scanIndexForward = this.scanIndexForward;
    }

    this.http.loadCampaignPage(lastCampaignId, scanIndexForward, UserProfileService.ITEMS_LIMIT + 1).subscribe((response: string) => {
      if (useClassVariables && this.pageUpdateInProgress) {
        return;
      }
      const campaigns = deserializeArray(CampaignInfo, response);
      this.lastCampaignId = lastCampaignId;
      this.scanIndexForward = scanIndexForward;
      this.campaigns = this.calculatePaginationItems(campaigns, lastCampaignId, scanIndexForward);
      const set = new Set<string>();
      for (const campaign of this.campaigns) {
        set.add(campaign.id);
      }
      for (const key of this.campaignsData.keys()) {
        if (!set.has(key)) {
         this.campaignsData.delete(key);
        }
      }
      if (this.pageUpdateInProgress = true) {
        this.pageUpdateInProgress = false;
      }
      });
  }

  private calculatePaginationItems(campaigns: CampaignInfo[], lastCampaignId: string, scanIndexForward: boolean): CampaignInfo[] {
    let pageItems: CampaignInfo[] = [];
    pageItems = campaigns;
    if (pageItems.length > 0) {
      this.calculateNavigationItems(pageItems, lastCampaignId, scanIndexForward);
    } else {
      if (this.lastCampaignId || this.scanIndexForward) {
        this.loadFirstItems();
      } else {
        this.hasNextItems = false;
        this.hasPreviousItems = false;
      }
    }
    if (pageItems.length > UserProfileService.ITEMS_LIMIT) {
      pageItems.pop();
    }
    if (this.scanIndexForward) {
      pageItems = campaigns.reverse();
    } else {
      pageItems = campaigns;
    }
    return pageItems;
  }

  calculateNavigationItems(pageItems: CampaignInfo[], lastCampaignId: string, scanIndexForward: boolean) {
      if (scanIndexForward) {
        this.calculateHasNextItemsForScanForward(pageItems, lastCampaignId);
      } else {
        this.calculateHasNextItemsForNotScanForward(pageItems, lastCampaignId);
      }
  }

  calculateHasNextItemsForNotScanForward(pageItems: CampaignInfo[] , lastCampaignId: string) {
    if (pageItems.length > UserProfileService.ITEMS_LIMIT) {
      this.hasNextItems = true;
    } else {
      this.hasNextItems = false;
    }
    if (lastCampaignId) {
      this.hasPreviousItems = true;
    } else {
      this.hasPreviousItems = false;
    }
  }

  calculateHasNextItemsForScanForward(pageItems: CampaignInfo[] , lastCampaignId: string) {
    if (pageItems.length > UserProfileService.ITEMS_LIMIT) {
      this.hasPreviousItems = true;
    } else {
      this.hasPreviousItems = false;
    }
    if (lastCampaignId) {
      this.hasNextItems = true;
    } else {
      this.hasNextItems = false;
    }
  }

  selectFile() {
    this.fileInput.nativeElement.click();
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    const dt = event.dataTransfer;
    if (dt.files.length === 1) {
      this.file = dt.files[0];
      this.uploadCampaign();
    }
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;
    if (files.length === 1) {
      this.file = files[0];
      this.uploadCampaign();
    }
  }

  private uploadCampaign() {
    this.uploadInProgress = true;

    this.http.uploadCampaign(this.file, this.file.name, this.flowId).subscribe(
      () => {
        this.finalizeUpload();
        this.snackBar.showSuccess('Campaign is uploading');
        this.loadFirstItems();
      },
      e => {
        this.finalizeUpload();
        this.snackBar.showError('Campaign upload failed: ' + e.error);
      }
    );
  }

  private finalizeUpload() {
    this.uploadInProgress = false;
    this.file = null;
    this.fileInput.nativeElement.value = '';
    this.updateCampaigns();
  }
}
