/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-media.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/forms";
import * as i8 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/tooltip";
import * as i10 from "@angular/cdk/overlay";
import * as i11 from "@angular/cdk/scrolling";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "@angular/platform-browser";
import * as i15 from "@angular/material/button";
import * as i16 from "./upload-media.component";
import * as i17 from "../../../services/http.service";
import * as i18 from "@angular/router";
import * as i19 from "../../../services/snackbar.service";
var styles_UploadMediaComponent = [i0.styles];
var RenderType_UploadMediaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadMediaComponent, data: {} });
export { RenderType_UploadMediaComponent as RenderType_UploadMediaComponent };
function View_UploadMediaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "22"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = "22"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_UploadMediaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { fileInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i7.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(3, 4210688, null, 0, i7.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i7.ControlContainer, null, [i7.NgForm]), i1.ɵdid(5, 16384, null, 0, i7.NgControlStatusGroup, [[4, i7.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 3, "button", [["color", "primary"], ["data-testid", "uploadMediaButton"], ["mat-raised-button", ""], ["matTooltip", "Upload media"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "drop"], [null, "dragover"], [null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("drop" === en)) {
        var pd_3 = (_co.onDrop($event) !== false);
        ad = (pd_3 && ad);
    } if (("dragover" === en)) {
        var pd_4 = (_co.onDragOver($event) !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = (_co.selectFile() !== false);
        ad = (pd_5 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(7, 147456, null, 0, i9.MatTooltip, [i10.Overlay, i1.ElementRef, i11.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i12.AriaDescriber, i12.FocusMonitor, i9.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i13.Directionality], [2, i9.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i14.HAMMER_LOADER]], { showDelay: [0, "showDelay"], message: [1, "message"] }, null), i1.ɵdid(8, 180224, null, 0, i15.MatButton, [i1.ElementRef, i4.Platform, i12.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Upload Media "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadMediaComponent_1)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["class", "clear"], ["mat-button", ""], ["target", "_blank"]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.clearFile() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_MatAnchor_0, i8.RenderType_MatAnchor)), i1.ɵdid(13, 180224, null, 0, i15.MatAnchor, [i4.Platform, i12.FocusMonitor, i1.ElementRef, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Clear"])), (_l()(), i1.ɵeld(15, 0, [[1, 0], ["fileInput", 1]], null, 0, "input", [["accept", "*/*"], ["data-testid", "mediaFileInput"], ["style", "visibility: hidden"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.env.tooltipDelay; var currVal_10 = "Upload media"; _ck(_v, 7, 0, currVal_9, currVal_10); var currVal_11 = (_co.uploadInProgress || _co.disabled); var currVal_12 = "primary"; _ck(_v, 8, 0, currVal_11, currVal_12); var currVal_13 = _co.uploadInProgress; _ck(_v, 11, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = (i1.ɵnov(_v, 8).disabled || null); var currVal_8 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_7, currVal_8); var currVal_14 = (i1.ɵnov(_v, 13).disabled ? (0 - 1) : (i1.ɵnov(_v, 13).tabIndex || 0)); var currVal_15 = (i1.ɵnov(_v, 13).disabled || null); var currVal_16 = i1.ɵnov(_v, 13).disabled.toString(); var currVal_17 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_14, currVal_15, currVal_16, currVal_17); }); }
export function View_UploadMediaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-media", [], null, null, null, View_UploadMediaComponent_0, RenderType_UploadMediaComponent)), i1.ɵdid(1, 114688, null, 0, i16.UploadMediaComponent, [i17.HttpService, i18.ActivatedRoute, i19.SnackBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadMediaComponentNgFactory = i1.ɵccf("app-upload-media", i16.UploadMediaComponent, View_UploadMediaComponent_Host_0, { node: "node", inputFieldId: "inputFieldId", disabled: "disabled" }, { changed: "changed", fileUploaded: "fileUploaded" }, []);
export { UploadMediaComponentNgFactory as UploadMediaComponentNgFactory };
