import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef } from '@angular/core';
import { FlowEditorService } from '../../services/flow-editor.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { environment } from '../../../environments/environment';
export class FlowEditorComponent {
    constructor(flowService, contextMenuService) {
        this.flowService = flowService;
        this.contextMenuService = contextMenuService;
    }
    createNode(nodeType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const editor = this.flowService.getEditor();
            const node = yield editor.getComponent(nodeType).createNode();
            node.position = this.flowService.getMousePosition();
            editor.addNode(node);
        });
    }
    ngAfterViewInit() {
        this.flowService.initializeEditor(this.el.nativeElement);
        this.flowService.getEditor().on('renderconnection', ({ el, connection }) => {
            el.addEventListener('contextmenu', e => {
                this.contextMenuService.show.next({
                    contextMenu: this.connectionMenu,
                    event: e,
                    item: connection
                });
                e.stopPropagation();
                e.preventDefault();
            });
        });
        this.flowService.flowState$.subscribe(state => {
            this.flowDirty = state.dirty;
        });
    }
    beforeunloadHandler($event) {
        if (environment.enableUnloadEvent && this.flowDirty) {
            $event.returnValue = true;
        }
    }
    removeConnection(item) {
        this.flowService.getEditor().removeConnection(item);
    }
}
