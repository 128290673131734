import { ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TextinputDialogComponent } from './textinput-dialog/textinput-dialog.component';
import { ChannelDialogComponent } from './channel-dialog/channel-dialog.component';
import { VariablesDialogComponent } from './variables-dialog/variables-dialog.component';
import { ResponseVariablesDialogComponent } from './response-variables-dialog/response-variables-dialog.component';
import { MobileInvoiceDialogComponent } from 'src/app/dialogs/mobile-invoice-dialog/mobile-invoice-dialog.component';
import { PlatformLocation } from '@angular/common';
import { FlowUploadDialogComponent } from './flow-upload-dialog/flow-upload-dialog.component';
import { CampaignDialogComponent } from './campaign-dialog/campaign-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/common";
export class DialogService {
    constructor(dialog, location) {
        this.dialog = dialog;
        this.location = location;
        location.onPopState(() => this.dialog.closeAll());
    }
    static safe_tags_replace(str) {
        return str.replace(/[&<>]/g, DialogService.replaceTag);
    }
    static replaceTag(tag) {
        return DialogService.tagsToReplace[tag] || tag;
    }
    confirmation(title, question, confirmText, afterClosed, buttonStyles) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: { title, question, confirmText, buttonStyles }
        });
        dialogRef.afterClosed().subscribe(afterClosed);
    }
    showFileUploadDialog(title, question, confirmText, fileInput, afterClosed, buttonStyles) {
        const dialogRef = this.dialog.open(FlowUploadDialogComponent, {
            data: { title, question, confirmText, buttonStyles, fileInput }
        });
        dialogRef.afterClosed().subscribe(afterClosed);
    }
    showMobileInvoiceDialog(httpRequestInfo, node, confirmText, buttonStyles) {
        this.dialog.open(MobileInvoiceDialogComponent, {
            data: { httpRequestInfo, node, confirmText, buttonStyles },
            height: '400px',
            width: '460px'
        });
    }
    textInput(title, question, confirmText, afterClosed, buttonStyles) {
        const dialogRef = this.dialog.open(TextinputDialogComponent, {
            data: { title, question, confirmText, buttonStyles }
        });
        dialogRef.afterClosed().subscribe(afterClosed);
    }
    channel(channels, selectedChannels, afterClosed, buttonStyles) {
        const dialogRef = this.dialog.open(ChannelDialogComponent, {
            data: { channels, selectedChannels, buttonStyles }
        });
        dialogRef.afterClosed().subscribe(afterClosed);
        return dialogRef.componentInstance;
    }
    campaign(title, question, confirmText, channels, hasForceOption, editSheduledCampaign, scheduledTime, finalMessage, hideFreeText, afterClosed, buttonStyles) {
        const dialogRef = this.dialog.open(CampaignDialogComponent, {
            data: { title, question, confirmText, buttonStyles, channels, hasForceOption, editSheduledCampaign, scheduledTime, finalMessage, hideFreeText },
            width: '700px'
        });
        dialogRef.afterClosed().subscribe(afterClosed);
        return dialogRef.componentInstance;
    }
    variables(title, confirmText, httpVariables, afterClosed, buttonStyles) {
        const dialogRef = this.dialog.open(VariablesDialogComponent, {
            data: { title, confirmText, httpVariables, buttonStyles }
        });
        dialogRef.afterClosed().subscribe(afterClosed);
        return dialogRef.componentInstance;
    }
    responseVariables(title, confirmText, responseVariables, afterClosed, buttonStyles) {
        const dialogRef = this.dialog.open(ResponseVariablesDialogComponent, {
            width: '500px',
            data: { title, confirmText, responseVariables, buttonStyles }
        });
        dialogRef.afterClosed().subscribe(afterClosed);
    }
}
DialogService.tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
};
DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i1.MatDialog), i0.inject(i2.PlatformLocation)); }, token: DialogService, providedIn: "root" });
