<div class="keyword">
  <mat-icon (click)="deleteKeyword(output)"
            data-testid="deleteKeyword"
            class="keyword-inline-button delete-button">
    cancel
  </mat-icon>
  <ng-container *ngIf="keyword.regex">
    <mat-icon matTooltip="Regular expression"
              [matTooltipShowDelay]="env.tooltipDelay"
              class="type-icon"
              data-testid="keywordRegexIcon">
      code
    </mat-icon>
  </ng-container>
  <div class="fill_space"></div>
  <!--suppress HtmlFormInputWithoutLabel -->
  <input
    (keyup)="keywordChanged()"
    [(ngModel)]="keyword.keyword"
    appDragStopPropagation
    data-testid="keywordInput"
    placeholder="Keyword"
    required type="text" />
  <app-socket
    [bindSocket]="bindSocket"
    [inputOutput]="output"
    data-testid="keywordSocket"
    type="output">
  </app-socket>
</div>
