<mat-toolbar class="tool-bar" color="primary" role="heading" >

  <button *ngIf="!flowName && (userRole === 'SUPER_ADMIN' || userRole === 'GLOBAL_BUSINESS' || userRole === 'BUSINESS')" mat-icon-button [matMenuTriggerFor]="mainmenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #mainmenu="matMenu">
    <button mat-menu-item  (click)="flowDesigner()">
      <mat-icon>picture_in_picture</mat-icon>
      <span>Flow Designer</span>
    </button>
    <button mat-menu-item (click)="report()">
      <mat-icon>timeline</mat-icon>
      <span>Reports</span>
    </button>
  </mat-menu>

  <a (click)="backLink()" *ngIf="backTarget" data-testid="backButton" mat-icon-button>
    <mat-icon>arrow_back</mat-icon>
  </a>

  <span class="link-flow">LINK Flow</span>

  <ng-container *ngIf="flowName">
    &nbsp;-&nbsp;
    <app-editable-label [ngClass]="dirty ? 'flow-name-dirty' : 'flow-name'"
                        [label]="flowName"
                        (valueChanged)="rename($event)"
                        data-testid="flowName"
    ></app-editable-label>
    <sub><span *ngIf="dirty" class="flow-name-dirty flow-name-sub" data-testid="editorIsDirty">(modified)</span></sub>
  </ng-container>

  <ng-container *ngIf="flowName">
    <button mat-raised-button data-testid="channelButton" (click)="channelClick()">Channel</button>
  </ng-container>

  <span *ngIf="titleSuffix">&nbsp;{{ titleSuffix }}</span>

  <span class="fill_space"></span>

  <ng-container *ngIf="account">
    <a [href]="env.faqUrl" class="help-icon" matTooltip="Help" [matTooltipShowDelay]="env.tooltipDelay" target="_blank">
      <i class="fa fa-question-circle"></i>
    </a>
    <div class="user-icon" matTooltip="{{ account }} ({{ user }})">
      <i class="fa fa-user"></i>
    </div>
    <button mat-raised-button data-testid="logoutButton" (click)="logout()">Logout</button>
  </ng-container>

</mat-toolbar>
