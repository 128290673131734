import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AbstractNode, AccountSettings } from 'flow-model';
import { UserProfileService } from 'src/app/services/user-profile.service';

@Component({
  selector: 'app-name-node',
  templateUrl: './name-node.component.html',
})
export class NameNodeComponent {

  @Input()
  model: AbstractNode;
  env = environment;
  accountSettings: AccountSettings;

  @Output()
  changed = new EventEmitter<boolean>();

  constructor(private userProfileService: UserProfileService) {
    this.loadAccountSettings();
  }

  onChange() {
    this.changed.emit(true);
  }

  private loadAccountSettings() {
    this.userProfileService.accountSettings$.subscribe(accountSettings => {
      if (accountSettings) {
        this.accountSettings = accountSettings;
      } else {
        this.accountSettings = new AccountSettings();
      }
    });
  }
}
