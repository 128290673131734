import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { KeywordsNodeComponent } from '../../editor/renderer/node/keywords-node/keywords-node.component';
import { RegexHelpComponent } from '../../regex-help/regex-help.component';
import { RegexExamplesComponent } from '../../regex-examples/regex-examples.component';
import { MatDialog } from '@angular/material';
import { KeywordMatch } from 'flow-model';

@Component({
  selector: 'app-keyword-details-list',
  templateUrl: './keyword-details-list.component.html',
  styleUrls: ['./keyword-details-list.component.css']
})
export class KeywordDetailsListComponent {

  @Input()
  keywordNode: KeywordsNodeComponent;

  env = environment;

  REGEX_ICON = 'code';
  TEXT_ICON = 'text_fields';

  constructor(public dialog: MatDialog) {
  }

  getTestString() {
    if (this.keywordNode instanceof KeywordsNodeComponent) {
      return this.keywordNode.getTestString();
    }
    return null;
  }

  onKeywordChange() {
    this.keywordNode.onChange();
  }

  showRegexHelp() {
    this.dialog.open(RegexHelpComponent, {maxWidth: '800px'});
  }

  showRegexExamples() {
    this.dialog.open(RegexExamplesComponent, {maxWidth: '800px'});
  }

  getKeywordTypeIcon(keyword: KeywordMatch) {
    return keyword.regex ? this.getRegexIcon() : this.getTextIcon();
  }

  getRegexIcon() {
    return this.REGEX_ICON;
  }

  getTextIcon() {
    return this.TEXT_ICON;
  }

  changeKeywordType(keyword: KeywordMatch, newValue: boolean) {
    keyword.regex = newValue;
    this.keywordNode.onChange();
  }

  testKeywordMatch($event: any) {
      this.keywordNode.testKeywordMatch($event.target.value);
  }
}
