import { NodeDetailsService } from './../../../services/node-details.service';
import { Component, OnInit } from '@angular/core';
import { TextMessageNode } from 'flow-model';
import { BasicNode } from '../../editor/renderer/node/basic-node';
import { TextMessageNodeComponent } from '../../editor/renderer/node/text-message-node/text-message-node.component';
import { SuggestionData } from 'src/app/model/suggestions';

@Component({
  selector: 'app-details-text-message',
  templateUrl: './details-text-message.component.html',
  styleUrls: ['./details-text-message.component.css']
})
export class DetailsTextMessageComponent implements OnInit {

  basicNode: BasicNode;

  model: TextMessageNode = null;
  suggestionsModel: SuggestionData[] = null;

  constructor(private nodeDetailsService: NodeDetailsService) { }

  ngOnInit(): void {
    this.nodeDetailsService.node$.subscribe(
      nodeComponent => {
        this.basicNode = nodeComponent;
        this.updateModel(nodeComponent);
      });
  }

  private updateModel(node: BasicNode) {
    this.model = null;
    this.suggestionsModel = null;
    if (node instanceof TextMessageNodeComponent) {
      this.model = node.model;
      this.suggestionsModel = node.suggestions;
    }
  }
}
