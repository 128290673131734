import { Output } from 'rete';
import { carouselRichCardSocket, freeTextSocket, keywordSocket, nextMessageSocket, suggestionSocket, mobileInvoiceStatusSocket } from '../sockets';
export class ReteOutput extends Output {
    constructor(key, title, socket, optional, interruptsFlow) {
        super(key, title, socket, false);
        this.interruptsFlow = false;
        this.optional = false;
        this.optional = optional;
        this.interruptsFlow = interruptsFlow;
    }
    static createDirectOutput() {
        return new ReteOutput(ReteOutput.DEFAULT_NEXT_KEY, ReteOutput.DEFAULT_NEXT_KEY, nextMessageSocket, false, false);
    }
    static createOptionalDirectOutput() {
        return new ReteOutput(ReteOutput.DEFAULT_NEXT_KEY, ReteOutput.DEFAULT_NEXT_KEY, nextMessageSocket, true, false);
    }
    static createSuggestionOutput(idx, optional) {
        return new ReteOutput(ReteOutput.createIndexedKey(ReteOutput.SUGGESTION_KEY, idx), ReteOutput.SUGGESTION_KEY, suggestionSocket, optional, true);
    }
    static createKeywordOutput(idx) {
        return new ReteOutput(ReteOutput.createIndexedKey(ReteOutput.KEYWORD_KEY, idx), ReteOutput.KEYWORD_KEY, keywordSocket, false, false);
    }
    static createCarouselRichCardOutput(idx) {
        return new ReteOutput(ReteOutput.createIndexedKey(ReteOutput.CAROUSEL_RICH_CARD_KEY, idx), ReteOutput.CAROUSEL_RICH_CARD_KEY, carouselRichCardSocket, false, false);
    }
    static createMobileInvoiceStatusOutput(status) {
        return new ReteOutput(ReteOutput.MOBILE_INVOICE_STATUS_KEY + status, status, mobileInvoiceStatusSocket, false, true);
    }
    static createFreeTextOutput() {
        return new ReteOutput(ReteOutput.FREE_TEXT_KEY, ReteOutput.FREE_TEXT_KEY, freeTextSocket, false, true);
    }
    static createIndexedKey(outputKeyPrefix, idx) {
        return outputKeyPrefix + `${idx}`.padStart(7, '0');
    }
    getConnectedNodeId() {
        if (this.hasConnection()) {
            return this.connections[0]['input'].node.id;
        }
        return null;
    }
    isUserInteractionRequired() {
        return this.interruptsFlow;
    }
    isCarouselOutput() {
        return this.name === ReteOutput.CAROUSEL_RICH_CARD_KEY;
    }
    getNodeComponent() {
        return this.node.data['nodeComponent'];
    }
}
ReteOutput.DEFAULT_NEXT_KEY = 'next';
ReteOutput.FREE_TEXT_KEY = 'onFreeText';
ReteOutput.SUGGESTION_KEY = 'Suggestion';
ReteOutput.KEYWORD_KEY = 'Keyword';
ReteOutput.CAROUSEL_RICH_CARD_KEY = 'CarouselRichCard';
ReteOutput.MOBILE_INVOICE_STATUS_KEY = 'MobileInvoiceStatus';
