/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-output.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@angular/cdk/drag-drop";
import * as i12 from "../../../../socket/socket.component.ngfactory";
import * as i13 from "../../../../socket/socket.component";
import * as i14 from "./card-output.component";
import * as i15 from "../../../../../../../services/flow-editor.service";
var styles_CardOutputComponent = [i0.styles];
var RenderType_CardOutputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardOutputComponent, data: {} });
export { RenderType_CardOutputComponent as RenderType_CardOutputComponent };
export function View_CardOutputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "card-output"]], null, [[null, "mousedown"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.offNodeTranslate() !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (_co.onNodeTranslate() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "card-output-inline-button delete-button mat-icon notranslate"], ["data-testid", "deleteCardOutput"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"], [null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteCardOutput(_co.output) !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = ($event.stopPropagation() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, [" cancel "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "fill_space"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "default-output-text"], ["data-testid", "defaultOutputText"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Rich Card"])), (_l()(), i1.ɵeld(7, 16777216, null, null, 4, "mat-icon", [["cdkDragHandle", ""], ["class", "reorder-button mat-icon notranslate cdk-drag-handle"], ["matTooltip", "Move suggestion"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(8, 147456, null, 0, i4.MatTooltip, [i5.Overlay, i1.ElementRef, i6.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { showDelay: [0, "showDelay"], message: [1, "message"] }, null), i1.ɵdid(9, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), i1.ɵdid(10, 147456, null, 0, i11.CdkDragHandle, [i1.ElementRef, [2, i11.ɵb]], null, null), (_l()(), i1.ɵted(-1, 0, [" drag_indicator "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-socket", [["data-testid", "cardOutputSocket"], ["type", "output"]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_SocketComponent_0, i12.RenderType_SocketComponent)), i1.ɵdid(13, 4243456, null, 0, i13.SocketComponent, [], { type: [0, "type"], inputOutput: [1, "inputOutput"], bindSocket: [2, "bindSocket"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_4 = _co.env.tooltipDelay; var currVal_5 = "Move suggestion"; _ck(_v, 8, 0, currVal_4, currVal_5); _ck(_v, 9, 0); var currVal_6 = "output"; var currVal_7 = _co.output; var currVal_8 = _co.bindSocket; _ck(_v, 13, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 9).inline; var currVal_3 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_CardOutputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-output", [], null, null, null, View_CardOutputComponent_0, RenderType_CardOutputComponent)), i1.ɵdid(1, 49152, null, 0, i14.CardOutputComponent, [i15.FlowEditorService], null, null)], null, null); }
var CardOutputComponentNgFactory = i1.ɵccf("app-card-output", i14.CardOutputComponent, View_CardOutputComponent_Host_0, { output: "output", bindSocket: "bindSocket" }, { delete: "delete" }, []);
export { CardOutputComponentNgFactory as CardOutputComponentNgFactory };
