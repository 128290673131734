<div class="campaign-item flex">

  <ng-container *ngIf="true"><!--This is a hack about rendering the container related to the animation (css transition)-->
    <button mat-icon-button class="expand-button" (click)="onClickExpand()" [disabled]="campaign.state === campaignState.ERROR || isCampaingInProgress()">
      <mat-icon>{{ isExpanded ? "expand_more" : "chevron_right" }}</mat-icon>
    </button>
  </ng-container>

  <app-editable-label [label]="campaign.name" (valueChanged)="renameCampaign(campaign, $event)"
    matTooltip="{{campaign.name}}" [matTooltipShowDelay]="env.tooltipDelay" class="name" data-testid="campaignName">
  </app-editable-label>

  <ng-container *ngIf="true"><!--This is a hack about rendering the container related to the animation (css transition)-->
    <button (click)="downloadCampaignResults(campaign)"
            [disabled]="!canBeDelete()"
            attr.data-testid="downloadCampaignResult-{{campaign.name}}"
            matTooltip="Download Results"
            [matTooltipShowDelay]="env.tooltipDelay"
            mat-icon-button>
      <i class="fa fa-file-download"></i>
    </button>
  </ng-container>

  <ng-container *ngIf="campaign.state == campaignState.NEW">
    <div [matTooltipDisabled]="flowCanRun"
         [matTooltipShowDelay]="env.tooltipDelay"
         class="button-wrapper"
         data-testid="runCampaignButtonWrapper"
         matTooltip="Flow is not saved or is invalid!">
      <button (click)="runCampaign(campaign)"
              attr.data-testid="runCampaign-{{campaign.name}}"
              matTooltip="Launch Campaign" [matTooltipDisabled]="!flowCanRun" [matTooltipShowDelay]="env.tooltipDelay" [disabled]="!flowCanRun || campaignBusy"
              mat-icon-button>
        <i class="fa fa-play"></i>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="campaign.state != campaignState.NEW  && campaign.state != campaignState.SCHEDULED">
    <div class="button-wrapper"
         data-testid="stopCampaignButtonWrapper"
         matTooltip="Campaign has been stopped"
         [matTooltipShowDelay]="env.tooltipDelay"
         [matTooltipDisabled]="campaign.state != campaignState.STOPPED">
      <button (click)="stopCampaign(campaign)" attr.data-testid="stopCampaign-{{campaign.name}}"
        matTooltip="Stop Campaign" [matTooltipDisabled]="campaign.state !== campaignState.RUNNING || campaignBusy" [matTooltipShowDelay]="env.tooltipDelay"
        [disabled]="campaign.state !== campaignState.RUNNING || campaignBusy" mat-icon-button>
        <i class="fa fa-stop"></i>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="campaign.state == campaignState.SCHEDULED">
    <div class="button-wrapper">
      <button (click)="editScheduledCampaign(campaign)" attr.data-testid="scheduleCampaign-{{campaign.name}}"
        matTooltip="{{scheduedDateTooltip}}" [matTooltipShowDelay]="env.tooltipDelay" [disabled]="!canBeScheduled()" mat-icon-button>
        <i class="fas fa-calendar-alt"></i>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="true"><!--This is a hack about rendering the container related to the animation (css transition)-->
    <button (click)="deleteCampaign(campaign)"
            [disabled]="!canBeDelete() && campaign.state != campaignState.ERROR"
            attr.data-testid="deleteCampaign-{{campaign.name}}"
            mat-icon-button
            matTooltip="Delete Campaign" [matTooltipShowDelay]="env.tooltipDelay">
      <i class="fa fa-times-circle {{!canBeDelete() && campaign.state != campaignState.ERROR ? 'delete-disabled' : 'delete-enabled'}}"></i>
    </button>
  </ng-container>
</div>

<ng-container *ngIf="(campaignBusy || !canBeDelete()) && campaign.state != campaignState.ERROR">
  <div class="progressbar flex">
    <label class="progressbar">
      {{showProgressState()}}
    </label>
    <mat-progress-bar [color]="campaign.state === campaignState.DELETING ? 'warn' : 'primary'" [value]="campaignProgress()"
                      *ngIf="campaignBusy || !canBeDelete()" [mode]="campaign.state === campaignState.LOADING ? 'indeterminate' : 'determinate' ">
    </mat-progress-bar>
  </div>
</ng-container>

<ng-container *ngIf="campaign.state === campaignState.ERROR">
  <div class="campaign-error">
    {{campaign.error}}
  </div>
</ng-container>

<ng-container *ngIf="true"><!--This is a hack about rendering the container related the animation-->
  <div class="campaign-details-container">
    <div #details class="campaign-details" [class.expanded]="isExpanded" [ngStyle]="{marginTop: detailsMargin}">
      <div class="campaign-details-item">
        <mat-icon matTooltip="Count of opened conversations" [matTooltipShowDelay]="env.tooltipDelay">visibility
        </mat-icon>
        <span>
          &nbsp;&nbsp;{{ campaignStats ? campaignStats.conversationsOpened : '-' }} /
          {{ campaignStats ? campaignStats.numberOfConversations : '-' }}
        </span>
      </div>
      <div class="campaign-details-item">
        <mat-icon matTooltip="Count of finished conversations" [matTooltipShowDelay]="env.tooltipDelay">flag</mat-icon>
        <span>
          &nbsp;&nbsp;{{ campaignStats ? campaignStats.conversationsEnded : '-' }} /
          {{ campaignStats ? campaignStats.numberOfConversations : '-' }}
        </span>
      </div>
      <mat-spinner diameter="20" *ngIf="refreshing"></mat-spinner>
    </div>
  </div>
</ng-container>
