import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-editable-label',
  templateUrl: './editable-label.component.html',
  styleUrls: ['./editable-label.component.css']
})
export class EditableLabelComponent implements OnInit {

  isEditing: boolean;
  @ViewChild('textInput')
  textInput: ElementRef;
  @Input()
  label: string;
  @Output()
  valueChanged = new EventEmitter<string>();
  env = environment;

  private previousValue: string;

  constructor() { }

  ngOnInit() {
  }

  editName() {
    this.previousValue = this.label;
    this.isEditing = true;
    setTimeout(() => {
      this.textInput.nativeElement.focus();
    }, 0);
  }

  onChange($event: any) {
    this.isEditing = false;
    if ($event.target.value !== this.previousValue) {
      this.valueChanged.emit($event.target.value);
    }
  }
}
