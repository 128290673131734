<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
    <div class="flex">
        <p style="flex-grow: 10">Click to add response variables</p>
        <button (click)="addResponseVariable()" [matTooltipShowDelay]="env.tooltipDelay" mat-icon-button matTooltip="Add Response variable" data-testid="addResponseVariable">
            <mat-icon>add_circle</mat-icon>
        </button>
    </div>
  <mat-list class="variable-list">
    <mat-list-item class="variable-item" *ngFor="let v of responseVariables; let i=index">
        <mat-form-field>
            <input matInput [(ngModel)]="v.name" placeholder="name">
        </mat-form-field>
        <mat-form-field>
            <input matInput [(ngModel)]="v.expression" placeholder="expression">
        </mat-form-field>
        <button mat-icon-button (click)="removeResponseVariable(i)" title="Remove">
            <mat-icon class="delete-button">cancel</mat-icon>
        </button>
    </mat-list-item>
</mat-list>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button
          [color]="data.buttonStyles ? data.buttonStyles[0] : 'primary'"
          [mat-dialog-close]="null">
    Cancel
  </button>
  <button mat-button
          [color]="data.buttonStyles ? data.buttonStyles[1] : 'primary'"
          [mat-dialog-close]="responseVariables">
    {{data.confirmText}}
  </button>
</mat-dialog-actions>