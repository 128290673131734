import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';


import { AppComponent } from './app.component';
import { FlowEditorComponent } from './flow-application/editor/flow-editor.component';
import { QRCodeModule } from 'angularx-qrcode';
import { SocketComponent } from './flow-application/editor/renderer/socket/socket.component';
import { SidebarComponent } from './flow-application/sidebar/sidebar.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ConversationTriggerComponent } from './flow-application/sidebar/conversation-trigger/conversation-trigger.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { ChannelDialogComponent } from './dialogs/channel-dialog/channel-dialog.component';
import { defaultDialogSettings } from './dialogs/defaults';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './services/auth.guard';
import { FormsModule } from '@angular/forms';
import { FlowApplicationComponent } from './flow-application/flow-application.component';
import { StartNodeComponent } from './flow-application/editor/renderer/node/start-node/start-node.component';
import { TextMessageNodeComponent } from './flow-application/editor/renderer/node/text-message-node/text-message-node.component';
import { EndNodeComponent } from './flow-application/editor/renderer/node/end-node/end-node.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { LoginService } from './services/login.service';
import { CampaignsComponent } from './flow-application/sidebar/campaigns/campaigns.component';
import { MaterialModule } from './material-module/material.module';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { TopbarComponent } from './flow-application/topbar/topbar.component';
import { DetailsPanelComponent } from './flow-application/details-panel/details-panel.component';
import { NodeDetailsService } from './services/node-details.service';
import { OutputVariableComponent } from './flow-application/details-panel/output-variable/output-variable.component';
import { SuggestionsListComponent } from './flow-application/editor/renderer/node-shared-components/suggestions-list/suggestions-list.component';
import { SuggestionComponent } from './flow-application/editor/renderer/node-shared-components/suggestions-list/suggestion/suggestion.component';
import { FlowEditorService } from './services/flow-editor.service';
import { SuggestionDetailsListComponent } from './flow-application/details-panel/suggestion-details-list/suggestion-details-list.component';
import { CalendarActionComponent } from './flow-application/details-panel/suggestion-details-list/calendar-action/calendar-action.component';
import { ViewLocationActionComponent } from './flow-application/details-panel/suggestion-details-list/view-location-action/view-location-action.component';
import { TextinputDialogComponent } from './dialogs/textinput-dialog/textinput-dialog.component';
import { AppRoutingModule } from './app-routing.module';
import { FlowOverviewComponent } from './flow-overview/flow-overview.component';
import { NameEditableComponent } from './flow-overview/name-editable/name-editable.component';
import { FooterComponent } from './footer/footer.component';
import { EditableLabelComponent } from './flow-application/editable-label/editable-label.component';
import { KeywordsListComponent } from './flow-application/editor/renderer/node/keywords-node/keywords-list/keywords-list.component';
import { KeywordComponent } from './flow-application/editor/renderer/node/keywords-node/keywords-list/keyword/keyword.component';
import { KeywordsNodeComponent } from './flow-application/editor/renderer/node/keywords-node/keywords-node.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { KeywordDetailsListComponent } from './flow-application/details-panel/keyword-details-list/keyword-details-list.component';
import { RegexHelpComponent } from './flow-application/regex-help/regex-help.component';
import { RegexExamplesComponent } from './flow-application/regex-examples/regex-examples.component';
import { MediaMessageNodeComponent } from './flow-application/editor/renderer/node/media-message-node/media-message-node.component';
import { CampaignItemComponent } from './flow-application/sidebar/campaigns/campaign-item/campaign-item.component';
import { RichCardNodeComponent } from './flow-application/editor/renderer/node/rich-card-node/rich-card-node.component';
import { MediaComponent } from './flow-application/editor/renderer/node-shared-components/media-component/media.component';
import { MobileInvoiceNodeComponent } from './flow-application/editor/renderer/node/mobile-invoice-node/mobile-invoice-node.component';
import { CarouselNodeComponent } from './flow-application/editor/renderer/node/carousel-node/carousel-node.component';
import { FreeTextComponent } from './flow-application/editor/renderer/node-shared-components/free-text/free-text.component';
import { DetailsStartComponent } from './flow-application/details-panel/details-start/details-start.component';
import { DetailsTextMessageComponent } from './flow-application/details-panel/details-text-message/details-text-message.component';
import { DetailsMediaMessageComponent } from './flow-application/details-panel/details-media-message/details-media-message.component';
import { DetailsRichCardMessageComponent } from './flow-application/details-panel/details-rich-card-message/details-rich-card-message.component';
import { DetailsKeywordsComponent } from './flow-application/details-panel/details-keywords/details-keywords.component';
import { DetailsEndComponent } from './flow-application/details-panel/details-end/details-end.component';
import { CardOutputListComponent } from './flow-application/editor/renderer/node/carousel-node/card-output-list/card-output-list.component';
import { CardOutputComponent } from './flow-application/editor/renderer/node/carousel-node/card-output-list/card-output/card-output.component';
import { DetailsCarouselComponent } from './flow-application/details-panel/details-carousel/details-carousel.component';
import { DragulaModule } from 'ng2-dragula';
import { UploadMediaComponent } from './flow-application/details-panel/upload-media/upload-media.component';
import { HttpRequestMessageNodeComponent } from './flow-application/editor/renderer/node/http-request-message-node/http-request-message-node.component';
import { DetailsHttpRequestMessageComponent } from './flow-application/details-panel/details-http-request-message-node/details-http-request-message.component';
import { VariablesDialogComponent } from './dialogs/variables-dialog/variables-dialog.component';
import { DetailsMobileInvoiceComponent } from './flow-application/details-panel/details-mobile-invoice/details-mobile-invoice.component';
import { ResponseVariablesDialogComponent } from './dialogs/response-variables-dialog/response-variables-dialog.component';
import { MobileInvoiceDialogComponent } from './dialogs/mobile-invoice-dialog/mobile-invoice-dialog.component';
import { VariablesDetailsListComponent } from './flow-application/details-panel/variables-details-list/variables-details-list.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MobileInvoiceStatusListComponent } from './flow-application/editor/renderer/node/mobile-invoice-node/mobile-invoice-status-list/mobile-invoice-status-list.component';
import { MobileInvoiceStatusComponent } from './flow-application/editor/renderer/node/mobile-invoice-node/mobile-invoice-status-list/mobile-invoice-status/mobile-invoice-status-output.component';
import { MobileInvoiceStatusOutputsListComponent } from './flow-application/editor/renderer/node/mobile-invoice-node/mobile-invoice-status-outputs-list/mobile-invoice-status-outputs-list.component';
// tslint:disable-next-line:max-line-length
import { MobileInvoiceStatusOutputComponent } from './flow-application/editor/renderer/node/mobile-invoice-node/mobile-invoice-status-outputs-list/mobile-invoice-status-outputs/mobile-invoice-status-outputs.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { FlowUploadDialogComponent } from './dialogs/flow-upload-dialog/flow-upload-dialog.component';
import { CampaignDialogComponent } from './dialogs/campaign-dialog/campaign-dialog.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReportComponent } from './report/report.component';
import { NameNodeComponent } from './flow-application/details-panel/name-node/name-node.component';



@NgModule({
  declarations: [
    AppComponent,
    FlowEditorComponent,
    SocketComponent,
    TopbarComponent,
    SidebarComponent,
    DetailsPanelComponent,
    ConversationTriggerComponent,
    ConfirmDialogComponent,
    FlowUploadDialogComponent,
    TextinputDialogComponent,
    ChannelDialogComponent,
    CampaignDialogComponent,
    VariablesDialogComponent,
    ResponseVariablesDialogComponent,
    MobileInvoiceDialogComponent,
    LoginComponent,
    ReportComponent,
    FlowApplicationComponent,
    StartNodeComponent,
    MediaMessageNodeComponent,
    MediaComponent,
    TextMessageNodeComponent,
    EndNodeComponent,
    RichCardNodeComponent,
    CampaignsComponent,
    ClickStopPropagationDirective,
    OutputVariableComponent,
    NameNodeComponent,
    SuggestionsListComponent,
    SuggestionComponent,
    SuggestionDetailsListComponent,
    CalendarActionComponent,
    ViewLocationActionComponent,
    FlowOverviewComponent,
    NameEditableComponent,
    FooterComponent,
    EditableLabelComponent,
    KeywordsNodeComponent,
    KeywordsListComponent,
    KeywordComponent,
    KeywordDetailsListComponent,
    RegexHelpComponent,
    RegexExamplesComponent,
    CampaignItemComponent,
    CarouselNodeComponent,
    FreeTextComponent,
    DetailsStartComponent,
    DetailsHttpRequestMessageComponent,
    VariablesDetailsListComponent,
    DetailsTextMessageComponent,
    DetailsMediaMessageComponent,
    DetailsRichCardMessageComponent,
    DetailsKeywordsComponent,
    DetailsMobileInvoiceComponent,
    DetailsEndComponent,
    FreeTextComponent,
    CardOutputListComponent,
    CardOutputComponent,
    DetailsCarouselComponent,
    UploadMediaComponent,
    MobileInvoiceNodeComponent,
    HttpRequestMessageNodeComponent,
    MobileInvoiceStatusListComponent,
    MobileInvoiceStatusOutputsListComponent,
    MobileInvoiceStatusOutputComponent,
    MobileInvoiceStatusComponent
  ],
  imports: [
    MaterialModule,
    MatProgressBarModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxTrimDirectiveModule,
    QRCodeModule,
    ClipboardModule,
    MatSlideToggleModule,
    DragDropModule,
    ContextMenuModule.forRoot({
      autoFocus: true
    }),
    AppRoutingModule,
    KeyboardShortcutsModule.forRoot(),
    DragulaModule.forRoot()
  ],
  entryComponents: [
    StartNodeComponent,
    EndNodeComponent,
    TextMessageNodeComponent,
    MediaMessageNodeComponent,
    KeywordsNodeComponent,
    RichCardNodeComponent,
    HttpRequestMessageNodeComponent,
    MediaComponent,
    ConfirmDialogComponent,
    FlowUploadDialogComponent,
    TextinputDialogComponent,
    ChannelDialogComponent,
    CampaignDialogComponent,
    VariablesDialogComponent,
    ResponseVariablesDialogComponent,
    MobileInvoiceDialogComponent,
    CarouselNodeComponent,
    MobileInvoiceNodeComponent,
    RegexHelpComponent,
    RegexExamplesComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: defaultDialogSettings
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (loginService: LoginService) {
        return new AuthInterceptorService(loginService);
      },
      multi: true,
      deps: [LoginService]
    },
    AuthGuard,
    NodeDetailsService,
    FlowEditorService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
