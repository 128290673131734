<h2 mat-dialog-title>Regular Expression Examples</h2>
<mat-dialog-content class="mat-typography">
  <div class="row">
    <label>A number in the range from 0.01 to 999999.99:&nbsp;</label>
    <input data-testid="invoiceLink" class="regex-number" #inputTarget readonly value="&#94;(&#63;&#33;&#94;(0&#124;0\.0&#124;0\.00)&#36;)(0&#124;[1-9][0-9]&#123;0,5&#125;)(\.\d&#123;1,2&#125;)&#63;&#36;" />
    <button data-testid="copyToClipboard" class="smallButton" matTooltip="Copy to clipboard" [ngxClipboard]="inputTarget" (cbOnSuccess)="onCopySuccess()" (cbOnError)="onCopyError()"><i class="fa fa-copy"></i></button>
  </div>
  <div class="row">
    <label>
    Example of regex variable placeholer, with this configuration it will match &nbsp; &nbsp;<br/>
    the exact value:&nbsp;
    </label>
    <div class="alignBottom">
    <input data-testid="variableLink" class="variableWidth" #inputRegex readonly value="&#94;$&#123;variable&#125;&#36;" />
    <button data-testid="copyToClipboardVariableLink" class="smallButton" matTooltip="Copy to clipboard" [ngxClipboard]="inputRegex" (cbOnSuccess)="onCopySuccess()" (cbOnError)="onCopyError()"><i class="fa fa-copy"></i></button>
    </div>
  </div>
  <div class="row">
    <label>For more information please check
      <a (click)="showRegexHelp()" href="javascript:void(0);" class="regex-help-link">
        What are "Regular Expressions"?
      </a>
    </label>
  </div> 
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button color="primary" [mat-dialog-close]="true" data-testid="exampleRegPanelClose">Close</button>
</mat-dialog-actions>
