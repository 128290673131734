import { AbstractNode, AbstractSuggestion, CalendarEntrySuggestedAction, DialSuggestedAction, OpenUrlSuggestedAction, ViewLocationSuggestedAction } from 'flow-model';


export function nodeToReteNodeJson(node: AbstractNode): any {
  return {
    name: node['@type'],
    id: node.id,
    data: {
      outputVariableName: node.outputVariableName
    },
    position: [node.position.x, node.position.y]
  };
}

export function verifySuggestion(suggestion: AbstractSuggestion): string[] {
  const NO_URL = 'URL is missing';
  const URL_PREFIX = 'URL should start with http:// or https:// or mailto:';
  const NO_PHONENUMBER = 'Phone number is missing';
  const NO_SEARCHQUERY_OR_COORDS = 'Search query or coordinates with label are required.';
  const OUT_OF_RANGE = 'Latitude or Longitude value are out of range.';
  const NO_TITLE_DESCRIPTION = 'Calendar title & description is required';
  const NO_START_ENDTIME = 'Calendar start time and end time is required';

  const errors: string[] = [];

  if (suggestion['@type'] === 'OpenUrlSuggestedAction') {
    const openUrlSuggestion = suggestion as OpenUrlSuggestedAction;
    if (!openUrlSuggestion.url) {
      errors.push(NO_URL);
    } else if (!(openUrlSuggestion.url.startsWith('http://') ||
        openUrlSuggestion.url.startsWith('https://') ||
        openUrlSuggestion.url.startsWith('mailto:'))) {
      errors.push(URL_PREFIX);
    }
  } else if (suggestion['@type'] === 'DialSuggestedAction') {
    const dialSuggestion = suggestion as DialSuggestedAction;
    if (!dialSuggestion.phoneNumber) {
      errors.push(NO_PHONENUMBER);
    }
  } else if (suggestion['@type'] === 'ViewLocationSuggestedAction') {
    const viewLocationSuggestion = suggestion as ViewLocationSuggestedAction;
    if (!(viewLocationSuggestion.searchQuery || viewLocationSuggestion.label && (viewLocationSuggestion.latitude && viewLocationSuggestion.longitude)
      || (viewLocationSuggestion.latitudeVariable && viewLocationSuggestion.longitudeVariable))) {
      errors.push(NO_SEARCHQUERY_OR_COORDS);
    }
    if (viewLocationSuggestion.latitude && viewLocationSuggestion.longitude && (viewLocationSuggestion.latitude < -90 || viewLocationSuggestion.latitude > 90
      || viewLocationSuggestion.longitude < -180 || viewLocationSuggestion.longitude > 180)) {
      errors.push(OUT_OF_RANGE);
    }
  } else if (suggestion['@type'] === 'CalendarEntrySuggestedAction') {
    const calendarSuggestion = suggestion as CalendarEntrySuggestedAction;
    if (!(calendarSuggestion.title && calendarSuggestion.description)) {
      errors.push(NO_TITLE_DESCRIPTION);
    }
    if (!(calendarSuggestion.startTime && calendarSuggestion.endTime) && !(calendarSuggestion.startTimeVariable && calendarSuggestion.endTimeVariable)) {
      errors.push(NO_START_ENDTIME);
    }
  }
  return errors;
}
