import { KeywordMatch as KeywordMatchModel } from 'flow-model';
import { ReteOutput } from '../flow-application/editor/rete/controls/extended-output';

export class KeywordData {
  constructor(public keywordMatch: KeywordMatch,
              public output: ReteOutput) {
  }
}

export class KeywordMatch extends KeywordMatchModel {

  constructor(keyword?: string) {
    super();
    this.keyword = keyword;
  }
}
