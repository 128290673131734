<div class="node" data-testid="StartNode" [ngClass]="{'selected': selected, error: !isValid}">
  <div class="title">
    <div class="text" data-testid="StartNode-node-name" matTooltip="Your flow starts here">Start Flow</div>
    <div class="status">
      <mat-icon [ngClass]="isValid ? 'ok' : 'error'" [matTooltip]="validityErrorMessages" data-testid="nodeStatusIcon">
        error_outline</mat-icon>
    </div>

    <app-socket *ngIf="directOutput" [inputOutput]="directOutput" [bindSocket]="bindSocket" type="output"></app-socket>
  </div>
</div>
