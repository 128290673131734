<div [contextMenu]="basicMenu"
     data-testid="RichCardNode"
     class="node"
     [ngClass]="{'selected': selected, error: !isValid}">

  <div class="title">
    <app-socket *ngIf="directInput" [bindSocket]="bindSocket" [inputOutput]="directInput" type="input"></app-socket>
    <mat-icon class="node-type-icon">picture_in_picture</mat-icon>
    <div data-testid="RichCardNode-node-name" class="text">Rich Card</div>

    <div class="status">
      <mat-icon [matTooltip]="validityErrorMessages" [ngClass]="isValid ? 'ok' : 'error'" data-testid="nodeStatusIcon">
        error_outline
      </mat-icon>
    </div>

    <div class="fill_space"></div>
    <div *ngIf="!isPartOfCarousel">
      <app-socket *ngIf="directOutput" [bindSocket]="bindSocket" [inputOutput]="directOutput" type="output"></app-socket>
    </div>
  </div>

  <app-media (validationErrors)="addValidationErrors($event)"
             [mediaUrlRequired]="false"
             [mediaUrl]="model.mediaUrl"
             [thumbnailUrl]="model.thumbnailUrl" [messageNode]=this>
  </app-media>

  <div class="control">

    <mat-form-field>
      <textarea matInput placeholder="Title"
                  [(ngModel)]="model.title['en']"
                  (ngModelChange)="onChange()"
                  cdkTextareaAutosize
                  appDragStopPropagation
                  data-testid="titleInput">
        </textarea>
    </mat-form-field>

    <mat-form-field>
        <textarea matInput placeholder="Description"
                  [(ngModel)]="model.description['en']"
                  (ngModelChange)="onChange()"
                  cdkTextareaAutosize
                  appDragStopPropagation
                  data-testid="descriptionInput">
        </textarea>
    </mat-form-field>

  </div>

  <app-free-text *ngIf="!isPartOfCarousel" [messageNode]="this"></app-free-text>
  <app-suggestions-list [messageNode]="this"></app-suggestions-list>

  <div class="spacer"></div>

  <context-menu>
    <ng-template (execute)="deleteNode()" contextMenuItem>
      Delete Node
    </ng-template>
  </context-menu>
</div>
