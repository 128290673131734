<div class="details-panel" data-testid="details-panel">
  <div *ngIf="showNothingSelected" class="no-node-selected">Please select a node to edit message details</div>
  <app-details-start></app-details-start>
  <app-details-end></app-details-end>
  <app-details-text-message></app-details-text-message>
  <app-details-media-message></app-details-media-message>
  <app-details-rich-card-message></app-details-rich-card-message>
  <app-details-carousel></app-details-carousel>
  <app-details-keywords></app-details-keywords>
  <app-details-http-request-message></app-details-http-request-message>
  <app-details-mobile-invoice></app-details-mobile-invoice>
</div>
