<div class="freetext-field">
  <mat-checkbox
    [(ngModel)]="freeTextEnabled"
    (ngModelChange)="onChange()"
    class="freetext-checkbox"
    matTooltip="Activate to react on free text input of the user"
    [matTooltipShowDelay]="env.tooltipDelay"
    data-testid="allowFreeTextCheckBox">
    Allow free text input
  </mat-checkbox>
  <div class="fill_space"></div>
  <app-socket *ngIf="freeTextOutput != null" [bindSocket]="messageNode.bindSocket"
    [inputOutput]="freeTextOutput" data-testid="freeTextSocket" type="output"></app-socket>
</div>
