import { Component, Input } from '@angular/core';
import { MobileInvoiceNodeComponent } from '../mobile-invoice-node.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-invoice-status-outputs-list',
  templateUrl: './mobile-invoice-status-outputs-list.component.html',
  styleUrls: ['./mobile-invoice-status-outputs-list.component.css']
})
export class MobileInvoiceStatusOutputsListComponent {
  @Input()
  mobileInvoiceNode: MobileInvoiceNodeComponent;

  @Input()
  syncOutputEnabled = false;

  env = environment;

  constructor() {
  }

  onChange() {
    if (this.syncOutputEnabled) {
      this.mobileInvoiceNode.model.synchronous = true;
      this.mobileInvoiceNode.removeDirectOutput();
      this.mobileInvoiceNode.addStatusOutput();
    } else {
      this.mobileInvoiceNode.model.synchronous = false;
      this.mobileInvoiceNode.removeStatusOutputs();
      this.mobileInvoiceNode.addDirectOutput();
    }
  }
}


