import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { convert, DateTimeFormatter, Instant, LocalDate, LocalDateTime, LocalTime, nativeJs, ZonedDateTime, ZoneId } from 'js-joda';
import { MatCheckboxChange, MatDatepickerInputEvent } from '@angular/material';
import { CalendarEntrySuggestedAction, DateAsNumber } from 'flow-model';


@Component({
  selector: 'app-calendar-action',
  templateUrl: './calendar-action.component.html',
})
export class CalendarActionComponent implements OnInit {

  @Input()
  public calendar: CalendarEntrySuggestedAction;

  @Output()
  change = new EventEmitter<boolean>();

  localStartTime: LocalDateTime;
  localEndTime: LocalDateTime;

  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;

  datesFromVariables = false;

  static transformToEpochNumber(date: LocalDateTime): DateAsNumber {
    if (date) {
      return date.atZone(ZoneId.SYSTEM).toEpochSecond();
    } else {
      return null;
    }
  }

  static transformToLocalDateTime(date: DateAsNumber): LocalDateTime {
    if (date) {
      return ZonedDateTime.ofInstant(Instant.ofEpochSecond(date), ZoneId.SYSTEM).toLocalDateTime();
    } else {
      return null;
    }
  }

  ngOnInit() {
    if (this.calendar.startTimeVariable || this.calendar.endTimeVariable) {
      this.datesFromVariables = true;
    } else {
      if (this.calendar.startTime) {
        this.localStartTime = CalendarActionComponent.transformToLocalDateTime(this.calendar.startTime);
        this.startDate = this.getDate(this.localStartTime);
        this.startTime = this.getTime(this.localStartTime);
      }
      if (this.calendar.endTime) {
        this.localEndTime = CalendarActionComponent.transformToLocalDateTime(this.calendar.endTime);
        this.endDate = this.getDate(this.localEndTime);
        this.endTime = this.getTime(this.localEndTime);
      }
    }
  }

  // noinspection JSMethodCanBeStatic
  getTime(target: LocalDateTime): string {
    if (target) {
      return target.format(DateTimeFormatter.ofPattern('HH:mm'));
    }
  }

  // noinspection JSMethodCanBeStatic
  getDate(target: LocalDateTime): Date {
    if (target) {
      return convert(target, ZoneId.systemDefault()).toDate();
    }
  }

  onDateChanged($event: MatDatepickerInputEvent<Date>, oldValue: LocalDateTime) {
    if ($event.value) {
      const newDate = LocalDate.from(nativeJs($event.value));
      if (oldValue) {
        return LocalDateTime.of(newDate, LocalTime.from(oldValue));
      } else {
        return LocalDateTime.of(newDate, LocalTime.ofSecondOfDay(0));
      }
    } else {
      return null;
    }
  }

  onStartDateChangedKey($event: KeyboardEvent, oldValue: LocalDateTime) {
    if (this.startDate) {
      const newDate = LocalDate.from(nativeJs(this.startDate));
      if (oldValue) {
        return LocalDateTime.of(newDate, LocalTime.from(oldValue));
      } else {
        this.startTime = null;
        return LocalDateTime.of(newDate, LocalTime.ofSecondOfDay(0));
      }
    } else {
      this.startTime = null;
      return null;
    }
  }

  onEndDateChangedKey($event: KeyboardEvent, oldValue: LocalDateTime) {
    if (this.endDate) {
      const newDate = LocalDate.from(nativeJs(this.endDate));
      if (oldValue) {
        return LocalDateTime.of(newDate, LocalTime.from(oldValue));
      } else {
        this.endTime = null;
        return LocalDateTime.of(newDate, LocalTime.ofSecondOfDay(0));
      }
    } else {
      this.endTime = null;
      return null;
    }
  }

  onTimeChange($event, oldValue: LocalDateTime) {
    if (oldValue && $event.target.value) {
      const newTime = LocalTime.parse($event.target.value);
      return LocalDateTime.of(LocalDate.from(oldValue), newTime);
    } else {
      if (oldValue) {
        return LocalDateTime.of(LocalDate.from(oldValue), LocalTime.ofSecondOfDay(0));
      }
      $event.target.value = null;
      return null;
    }
  }

  toggleDateVariables($event: MatCheckboxChange) {
    this.datesFromVariables = $event.checked;

    if (this.datesFromVariables) {
      this.calendar.startTime = null;
      this.calendar.endTime = null;
      this.startDate = null;
      this.startTime = null;
      this.endDate = null;
      this.endTime = null;
    } else {
      this.calendar.startTimeVariable = null;
      this.calendar.endTimeVariable = null;
      this.localStartTime = null;
    }
    this.onChange();
  }

  onChange() {
    this.calendar.startTime = CalendarActionComponent.transformToEpochNumber(this.localStartTime);
    this.calendar.endTime = CalendarActionComponent.transformToEpochNumber(this.localEndTime);

    this.change.emit(true);
  }
}
