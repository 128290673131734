<div class="card-output" (mousedown)="offNodeTranslate()" (mouseup)="onNodeTranslate()">
  <mat-icon (click)="deleteCardOutput(output)"
            data-testid="deleteCardOutput"
            (mousedown)="$event.stopPropagation()"
            class="card-output-inline-button delete-button" >
    cancel
  </mat-icon>
  <div class="fill_space"></div>
  <div class="default-output-text" data-testid="defaultOutputText">Rich Card</div>
  <mat-icon cdkDragHandle
            matTooltip="Move suggestion"
            [matTooltipShowDelay]="env.tooltipDelay"
            class="reorder-button">
    drag_indicator
  </mat-icon>
  <app-socket
    [bindSocket]="bindSocket"
    [inputOutput]="output"
    data-testid="cardOutputSocket"
    (mousedown)="$event.stopPropagation()"
    type="output">
  </app-socket>
</div>
