import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "@angular/router";
export class ReportService {
    constructor(httpService, router) {
        this.httpService = httpService;
        this.router = router;
        this.reportsSubject = new BehaviorSubject(null);
        this.reports$ = this.reportsSubject.asObservable();
        this.reportsErrorSubject = new BehaviorSubject(null);
        this.reportsError$ = this.reportsErrorSubject.asObservable();
        this.countriesSubject = new BehaviorSubject(null);
        this.countries$ = this.countriesSubject.asObservable();
        this.fetchCountries();
    }
    showReport() {
        this.router.navigate(['/report']);
    }
    fetchReports(reportFilter) {
        this.httpService.loadReports(reportFilter).subscribe(reports => {
            this.reportsSubject.next(reports);
        }, (response) => {
            if (response.status !== 401) {
                this.reportsErrorSubject.next(response);
            }
        });
    }
    clearReports() {
        this.reportsSubject.next(null);
    }
    fetchCountries() {
        this.httpService.loadCountries().subscribe(countries => {
            this.countriesSubject.next(countries);
        }, (response) => {
            if (response.status !== 401) {
                throw response;
            }
        });
    }
}
ReportService.ngInjectableDef = i0.defineInjectable({ factory: function ReportService_Factory() { return new ReportService(i0.inject(i1.HttpService), i0.inject(i2.Router)); }, token: ReportService, providedIn: "root" });
