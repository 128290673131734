import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TextinputDialogComponent } from './textinput-dialog/textinput-dialog.component';
import { ChannelDialogComponent } from './channel-dialog/channel-dialog.component';
import { VariablesDialogComponent } from './variables-dialog/variables-dialog.component';
import { HttpVariables, ResponseVariable, HttpRequestInfo, MobileInvoiceNode } from 'flow-model';
import { ResponseVariablesDialogComponent } from './response-variables-dialog/response-variables-dialog.component';
import { MobileInvoiceDialogComponent } from 'src/app/dialogs/mobile-invoice-dialog/mobile-invoice-dialog.component';
import { PlatformLocation } from '@angular/common';
import { FlowUploadDialogComponent } from './flow-upload-dialog/flow-upload-dialog.component';
import { CampaignDialogComponent } from './campaign-dialog/campaign-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private static tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
  };

  constructor(private dialog: MatDialog,  private location: PlatformLocation) {
    location.onPopState(() => this.dialog.closeAll());
  }

  public static safe_tags_replace(str) {
    return str.replace(/[&<>]/g, DialogService.replaceTag);
  }

  private static replaceTag(tag) {
    return DialogService.tagsToReplace[tag] || tag;
  }

  confirmation(title: string, question: string, confirmText: string, afterClosed: (confirmed) => void, buttonStyles?: string[]) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {title, question, confirmText, buttonStyles}
    });
    dialogRef.afterClosed().subscribe(afterClosed);
  }

  showFileUploadDialog(title: string, question: string, confirmText: string, fileInput: ElementRef, afterClosed: (confirmed) => void, buttonStyles?: string[]) {
    const dialogRef = this.dialog.open(FlowUploadDialogComponent, {
      data: {title, question, confirmText, buttonStyles, fileInput}
    });
    dialogRef.afterClosed().subscribe(afterClosed);
  }

  showMobileInvoiceDialog(httpRequestInfo: HttpRequestInfo, node: MobileInvoiceNode, confirmText: string, buttonStyles?: string[]) {
    this.dialog.open(MobileInvoiceDialogComponent, {
      data: {httpRequestInfo, node, confirmText, buttonStyles},
      height: '400px',
      width: '460px'
    });
  }

  textInput(title: string, question: string, confirmText: string, afterClosed: (text) => void, buttonStyles?: string[]) {
    const dialogRef = this.dialog.open(TextinputDialogComponent, {
      data: {title, question, confirmText, buttonStyles}
    });
    dialogRef.afterClosed().subscribe(afterClosed);
  }

  channel(channels: String[], selectedChannels: String, afterClosed: (text) => void, buttonStyles?: string[]): ChannelDialogComponent {
    const dialogRef = this.dialog.open(ChannelDialogComponent, {
      data: {channels, selectedChannels, buttonStyles}
    });
    dialogRef.afterClosed().subscribe(afterClosed);
    return dialogRef.componentInstance;
  }

  campaign(title: string, question: string, confirmText: string, channels: string, hasForceOption: boolean, editSheduledCampaign: boolean,
    scheduledTime: number, finalMessage: string, hideFreeText: boolean,
    afterClosed: (text) => void, buttonStyles?: string[]): CampaignDialogComponent {
    const dialogRef = this.dialog.open(CampaignDialogComponent, {
      data: {title, question, confirmText, buttonStyles, channels, hasForceOption, editSheduledCampaign, scheduledTime, finalMessage, hideFreeText},
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(afterClosed);
    return dialogRef.componentInstance;
  }

  variables(title: string, confirmText: string, httpVariables: HttpVariables[], afterClosed: (text) => void, buttonStyles?: string[]): VariablesDialogComponent {
    const dialogRef = this.dialog.open(VariablesDialogComponent, {
      data: {title, confirmText, httpVariables, buttonStyles}
    });
    dialogRef.afterClosed().subscribe(afterClosed);
    return dialogRef.componentInstance;
  }

  responseVariables(title: string, confirmText: string, responseVariables: ResponseVariable[], afterClosed: (confirmed) => void, buttonStyles?: string[]) {
    const dialogRef = this.dialog.open(ResponseVariablesDialogComponent, {
      width: '500px',
      data: {title, confirmText, responseVariables, buttonStyles}
    });
    dialogRef.afterClosed().subscribe(afterClosed);
  }

}
