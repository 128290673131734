<div class="wrapper">
  <div #nodeEditor class="flow-editor" [contextMenu]="basicMenu" data-testid="flow-editor">
    <!-- translate .... zoom-->
    <context-menu #editorMenu>
      <!--suppress JSIgnoredPromiseFromCall -->
      <ng-template contextMenuItem (execute)="createNode('TextMessageNode')">
        + Text Message
      </ng-template>
      <!--suppress JSIgnoredPromiseFromCall -->
      <ng-template contextMenuItem (execute)="createNode('MediaMessageNode')" >
        + Media Message
      </ng-template>
      <!--suppress JSIgnoredPromiseFromCall -->
      <ng-template *ngIf="flowService.featureSet && flowService.featureSet.richCardFeatureset && flowService.featureSet.richCardFeatureset.titleLength && flowService.featureSet.richCardFeatureset.titleLength > 0" contextMenuItem (execute)="createNode('RichCardNode')" >
        + Rich Card Message
      </ng-template>
       <!--suppress JSIgnoredPromiseFromCall -->
      <ng-template contextMenuItem (execute)="createNode('HttpRequestMessageNode')">
        + Data
      </ng-template>

      <!--suppress JSIgnoredPromiseFromCall -->
      <ng-template contextMenuItem (execute)="createNode('KeywordsNode')">
        + Text Match
      </ng-template>
      <!--suppress JSIgnoredPromiseFromCall -->
      <ng-template *ngIf="flowService.featureSet && flowService.featureSet.carouselCardFeatureset && flowService.featureSet.carouselCardFeatureset.maxCardNumbers > 0" contextMenuItem (execute)="createNode('CarouselNode')">
        + Carousel
      </ng-template>
      <!--suppress JSIgnoredPromiseFromCall -->
      <ng-template *ngIf="flowService.getMobileInvoiceEnabled()" contextMenuItem (execute)="createNode('MobileInvoiceNode')">
        + Mobile Invoice
      </ng-template>
      <!--suppress JSIgnoredPromiseFromCall -->
      <ng-template contextMenuItem (execute)="createNode('EndNode')">
        + End Flow
      </ng-template>
    </context-menu>

    <context-menu #connectionMenu>
      <ng-template contextMenuItem (execute)="removeConnection($event.item)">
        Remove connection
      </ng-template>
    </context-menu>
  </div>
</div>
