<div>
  <h2>Campaigns</h2>
  <form>
    <button mat-raised-button (drop)="onDrop($event)" (dragover)="onDragOver($event)" (click)="selectFile()"
      color="primary" [disabled]="uploadInProgress" data-testid="uploadCsvButton"
      matTooltip="Upload campaign data in the CSV format" [matTooltipShowDelay]="env.tooltipDelay">Upload CSV
    </button>
    <input #fileInput (change)="onFileChange($event)" type="file" accept=".csv" style="visibility: hidden"
      data-testid="csvFileInput">
  </form>
  <div style="margin-left: -10px;">
    <div *ngIf="hasNextItems || hasPreviousItems" class="pagecomponents">
      <hr class="divider"/>
      <button (click)="loadFirstItems()" [disabled]="pageUpdateInProgress" mat-icon-button matTooltip="Load first items" data-testid="firstPageButton">
        <mat-icon>first_page</mat-icon>
      </button>
      <button (click)="loadPreviousItems()" [disabled]="!hasPreviousItems || pageUpdateInProgress" mat-icon-button matTooltip="Load previous items" data-testid="previousPageButton">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button (click)="loadNextItems()" [disabled]="!hasNextItems || pageUpdateInProgress" mat-icon-button matTooltip="Load next items" data-testid="nextPageButton">
        <mat-icon>navigate_next</mat-icon>
      </button>
      <button (click)="loadLastItems()" [disabled]="pageUpdateInProgress" mat-icon-button matTooltip="Load last items" data-testid="lastPageButton">
        <mat-icon>last_page</mat-icon>
      </button>
      </div>
    <ul [ngClass]="hasNextItems || hasPreviousItems ? 'smallmargin' : 'normalmargin'" data-testid="campaignList">
      <li *ngFor="let campaign of campaigns; let last = last">
        <app-campaign-item [campaign]="campaign" [flowCanRun]="flowCanRun" [campaignsData]="campaignsData" (campaignChanged)="this.updateCampaigns()" (campaignScheduledEdit)="this.editScheduledCampaign(campaign)" (campaignInitiated)="this.startCampaign(campaign)" [last]="last" [top]="scrollTop" [left]="scrollLeft">
        </app-campaign-item>
      </li>
    </ul>
  </div>

</div>
