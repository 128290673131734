import { Output, Socket } from 'rete';
import { carouselRichCardSocket, freeTextSocket, keywordSocket, nextMessageSocket, suggestionSocket, mobileInvoiceStatusSocket } from '../sockets';
import { BasicNode } from '../../renderer/node/basic-node';

export class ReteOutput extends Output {

  static DEFAULT_NEXT_KEY = 'next';
  static FREE_TEXT_KEY = 'onFreeText';
  static SUGGESTION_KEY = 'Suggestion';
  static KEYWORD_KEY = 'Keyword';
  static CAROUSEL_RICH_CARD_KEY = 'CarouselRichCard';
  static MOBILE_INVOICE_STATUS_KEY = 'MobileInvoiceStatus';


  private interruptsFlow = false;
  public optional = false;

  constructor(key: string, title: string, socket: Socket, optional: boolean, interruptsFlow: boolean) {
    super(key, title, socket, false);
    this.optional = optional;
    this.interruptsFlow = interruptsFlow;
  }

  static createDirectOutput(): ReteOutput {
    return new ReteOutput(ReteOutput.DEFAULT_NEXT_KEY, ReteOutput.DEFAULT_NEXT_KEY, nextMessageSocket, false, false);
  }

  static createOptionalDirectOutput(): ReteOutput {
    return new ReteOutput(ReteOutput.DEFAULT_NEXT_KEY, ReteOutput.DEFAULT_NEXT_KEY, nextMessageSocket, true, false);
  }

  static createSuggestionOutput(idx: number, optional: boolean): ReteOutput {
    return new ReteOutput(ReteOutput.createIndexedKey(ReteOutput.SUGGESTION_KEY, idx), ReteOutput.SUGGESTION_KEY, suggestionSocket, optional, true);
  }

  static createKeywordOutput(idx: number): ReteOutput {
    return new ReteOutput(ReteOutput.createIndexedKey(ReteOutput.KEYWORD_KEY, idx), ReteOutput.KEYWORD_KEY, keywordSocket, false, false);
  }

  static createCarouselRichCardOutput(idx: number): ReteOutput {
    return new ReteOutput(ReteOutput.createIndexedKey(ReteOutput.CAROUSEL_RICH_CARD_KEY, idx), ReteOutput.CAROUSEL_RICH_CARD_KEY, carouselRichCardSocket, false, false);
  }

  static createMobileInvoiceStatusOutput(status: string): ReteOutput {
    return new ReteOutput(ReteOutput.MOBILE_INVOICE_STATUS_KEY + status, status, mobileInvoiceStatusSocket, false, true);
  }

  static createFreeTextOutput(): ReteOutput {
    return new ReteOutput(ReteOutput.FREE_TEXT_KEY, ReteOutput.FREE_TEXT_KEY, freeTextSocket, false, true);
  }

  static createIndexedKey(outputKeyPrefix: string, idx: number): string {
    return outputKeyPrefix + `${idx}`.padStart(7, '0');
  }

  public getConnectedNodeId(): number {
    if (this.hasConnection()) {
      return this.connections[0]['input'].node.id;
    }
    return null;
  }

  public isUserInteractionRequired(): boolean {
    return this.interruptsFlow;
  }

  public isCarouselOutput(): boolean {
    return this.name === ReteOutput.CAROUSEL_RICH_CARD_KEY;
  }

  public getNodeComponent(): BasicNode {
    return this.node.data['nodeComponent'] as BasicNode;
  }
}
