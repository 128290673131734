/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./regex-examples.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/platform-browser";
import * as i10 from "ngx-clipboard";
import * as i11 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i12 from "@angular/material/button";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "./regex-examples.component";
import * as i15 from "../../services/snackbar.service";
var styles_RegexExamplesComponent = [i0.styles];
var RenderType_RegexExamplesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegexExamplesComponent, data: {} });
export { RenderType_RegexExamplesComponent as RenderType_RegexExamplesComponent };
export function View_RegexExamplesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Regular Expression Examples"])), (_l()(), i1.ɵeld(3, 0, null, null, 25, "mat-dialog-content", [["class", "mat-typography mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["A number in the range from 0.01 to 999999.99:\u00A0"])), (_l()(), i1.ɵeld(8, 0, [["inputTarget", 1]], null, 0, "input", [["class", "regex-number"], ["data-testid", "invoiceLink"], ["readonly", ""], ["value", "^(?!^(0|0\\.0|0\\.00)$)(0|[1-9][0-9]{0,5})(\\.\\d{1,2})?$"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 3, "button", [["class", "smallButton"], ["data-testid", "copyToClipboard"], ["matTooltip", "Copy to clipboard"]], null, [[null, "cbOnSuccess"], [null, "cbOnError"], [null, "longpress"], [null, "keydown"], [null, "touchend"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11).onClick($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("cbOnSuccess" === en)) {
        var pd_4 = (_co.onCopySuccess() !== false);
        ad = (pd_4 && ad);
    } if (("cbOnError" === en)) {
        var pd_5 = (_co.onCopyError() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 147456, null, 0, i3.MatTooltip, [i4.Overlay, i1.ElementRef, i5.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵdid(11, 212992, null, 0, i10.ClipboardDirective, [i10.ClipboardService], { targetElm: [0, "targetElm"] }, { cbOnSuccess: "cbOnSuccess", cbOnError: "cbOnError" }), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fa fa-copy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Example of regex variable placeholer, with this configuration it will match \u00A0 \u00A0"])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" the exact value:\u00A0 "])), (_l()(), i1.ɵeld(18, 0, null, null, 5, "div", [["class", "alignBottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, [["inputRegex", 1]], null, 0, "input", [["class", "variableWidth"], ["data-testid", "variableLink"], ["readonly", ""], ["value", "^${variable}$"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 16777216, null, null, 3, "button", [["class", "smallButton"], ["data-testid", "copyToClipboardVariableLink"], ["matTooltip", "Copy to clipboard"]], null, [[null, "cbOnSuccess"], [null, "cbOnError"], [null, "longpress"], [null, "keydown"], [null, "touchend"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 21)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 21)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 22).onClick($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("cbOnSuccess" === en)) {
        var pd_4 = (_co.onCopySuccess() !== false);
        ad = (pd_4 && ad);
    } if (("cbOnError" === en)) {
        var pd_5 = (_co.onCopyError() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 147456, null, 0, i3.MatTooltip, [i4.Overlay, i1.ElementRef, i5.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵdid(22, 212992, null, 0, i10.ClipboardDirective, [i10.ClipboardService], { targetElm: [0, "targetElm"] }, { cbOnSuccess: "cbOnSuccess", cbOnError: "cbOnError" }), (_l()(), i1.ɵeld(23, 0, null, null, 0, "i", [["class", "fa fa-copy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 3, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["For more information please check "])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "a", [["class", "regex-help-link"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showRegexHelp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" What are \"Regular Expressions\"? "])), (_l()(), i1.ɵeld(29, 0, null, null, 5, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(30, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(31, 0, null, null, 3, "button", [["color", "primary"], ["data-testid", "exampleRegPanelClose"], ["mat-button", ""], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32).dialogRef.close(i1.ɵnov(_v, 32).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(32, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(33, 180224, null, 0, i12.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_1 = "Copy to clipboard"; _ck(_v, 10, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 8); _ck(_v, 11, 0, currVal_2); var currVal_3 = "Copy to clipboard"; _ck(_v, 21, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 19); _ck(_v, 22, 0, currVal_4); var currVal_8 = true; _ck(_v, 32, 0, currVal_8); var currVal_9 = "primary"; _ck(_v, 33, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_5 = (i1.ɵnov(_v, 32).ariaLabel || null); var currVal_6 = (i1.ɵnov(_v, 33).disabled || null); var currVal_7 = (i1.ɵnov(_v, 33)._animationMode === "NoopAnimations"); _ck(_v, 31, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_RegexExamplesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-regex-examples", [], null, null, null, View_RegexExamplesComponent_0, RenderType_RegexExamplesComponent)), i1.ɵdid(1, 49152, null, 0, i14.RegexExamplesComponent, [i15.SnackBarService, i2.MatDialog], null, null)], null, null); }
var RegexExamplesComponentNgFactory = i1.ɵccf("app-regex-examples", i14.RegexExamplesComponent, View_RegexExamplesComponent_Host_0, {}, {}, []);
export { RegexExamplesComponentNgFactory as RegexExamplesComponentNgFactory };
