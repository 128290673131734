<div [contextMenu]="basicMenu"
     class="node"
     [ngClass]="{'selected': selected, error: !isValid}"
     data-testid="MobileInvoiceNode">

  <div class="title">
    <div *ngIf="directInput" class="input">
      <app-socket
        [bindSocket]="bindSocket"
        [inputOutput]="directInput"
        type="input">
      </app-socket>
    </div>
    <mat-icon class="node-type-icon">credit_card</mat-icon>
    <div class="text" data-testid="MobileInvoiceNode-node-name">MobileInvoice</div>
    <div class="status">
      <mat-icon data-testid="nodeStatusIcon" [ngClass]="isValid ? 'ok' : 'error'" [matTooltip]="validityErrorMessages">
        error_outline
      </mat-icon>
    </div>
    <div class="fill_space"></div>
    <app-socket *ngIf="directOutput" [bindSocket]="bindSocket" [inputOutput]="directOutput" type="output"></app-socket>
  </div>


  <div class="control" *ngIf="isTextMessage()">
    <mat-form-field *ngIf="model" [matTooltip]="textHelpMessages" matTooltipPosition="above">
      <mat-label>Message Text
        <mat-icon id="inputHelp" data-testid="messageHelpIcon" matSuffix>
          help_outline
        </mat-icon>
      </mat-label>
      <textarea matInput id="textInput"
                [(ngModel)]="model.text['en']"
                (ngModelChange)="onChange()"
                cdkTextareaAutosize
                appDragStopPropagation
                data-testid="messageInput">
      </textarea>
    </mat-form-field>
  </div>

  <div class="control">
    <mat-form-field *ngIf="model">
      <textarea matInput placeholder="Description"
                [(ngModel)]="model.description['en']"
                (ngModelChange)="onChange()"
                maxlength="500"
                cdkTextareaAutosize
                appDragStopPropagation
                data-testid="descriptionInput">
      </textarea>
    </mat-form-field>
  </div>

  <div class="control">
    <mat-form-field *ngIf="model">
      <textarea matInput placeholder="Amount Parameter"
                [(ngModel)]="model.amountParameter"
                (ngModelChange)="onChange()"
                trim
                cdkTextareaAutosize
                appDragStopPropagation
                data-testid="amountInput">
      </textarea>
    </mat-form-field>


    <mat-form-field *ngIf="model">
      <textarea matInput placeholder="OCR parameter"
                [(ngModel)]="model.ocrParameter"
                (ngModelChange)="onChange()"
                trim
                cdkTextareaAutosize
                appDragStopPropagation
                data-testid="ocrParameterInput">
      </textarea>
    </mat-form-field>


    <div class="spacer"></div>

    <context-menu>
      <ng-template (execute)="deleteNode()" contextMenuItem>
        Delete Node
      </ng-template>
    </context-menu>
  </div>

  <app-mobile-invoice-status-outputs-list #mobileInvoiceStatusesList [mobileInvoiceNode]="this" [syncOutputEnabled]="model.synchronous"></app-mobile-invoice-status-outputs-list>

  <div class="spacer"></div>
</div>
