<div [contextMenu]="basicMenu"
     class="node"
     [ngClass]="{'selected': selected, error: !isValid}"
     data-testid="KeywordsNode">

  <div class="title">
    <div *ngIf="directInput" class="input">
      <app-socket
        [bindSocket]="bindSocket"
        [inputOutput]="directInput"
        type="input">
      </app-socket>
    </div>
    <mat-icon class="node-type-icon">memory</mat-icon>
    <div class="text" data-testid="KeywordsNode-node-name">Text Match</div>
    <div class="status">
      <mat-icon data-testid="nodeStatusIcon" [ngClass]="isValid ? 'ok' : 'error'" [matTooltip]="validityErrorMessages">
        error_outline
      </mat-icon>
    </div>
  </div>

  <div class="control">
    <mat-form-field>
      <input type="text"
             matInput
             [(ngModel)]="model.inputVariableName"
             placeholder="Input Variable"
             matTooltip="The keywords will be matched against the content of this variable"
             [matTooltipShowDelay]="env.tooltipDelay"
             (keyup)="onChange()"
             data-testid="inputVariableName"/>
    </mat-form-field>
  </div>

  <app-keywords-list [keywordsData]="keywordsData"
                     [bindSocket]="bindSocket"
                     (changed)="onChange()"
                     (createKeyword)="createKeyword($event)" (deleteKeyword)="removeKeyword($event)"></app-keywords-list>

  <div class="default-output">
    <div class="fill_space"></div>
    <div class="default-output-text" data-testid="defaultOutputText" matTooltip="Continue here if no keyword matches">No Match</div>
    <!--suppress AngularInvalidExpressionResultType -->
    <app-socket [bindSocket]="bindSocket"
                [inputOutput]="directOutput"
                data-testid="defaultKeywordSocket"
                type="output">
    </app-socket>
  </div>

  <div class="spacer"></div>

  <context-menu>
    <ng-template (execute)="deleteNode()" contextMenuItem>
      Delete Node
    </ng-template>
  </context-menu>
</div>
