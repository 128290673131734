import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BasicNode } from '../flow-application/editor/renderer/node/basic-node';

@Injectable()
export class NodeDetailsService {

  // Observable string sources
  private nodeSource = new Subject<BasicNode>();

  // Observable string streams
  node$ = this.nodeSource.asObservable();

  changeNode(node: BasicNode) {
    this.nodeSource.next(node);
  }

}
