import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { CarouselCardWidth, CarouselNode } from 'flow-model';
import { NodeDetailsService } from '../../../services/node-details.service';
import { CarouselNodeComponent } from '../../editor/renderer/node/carousel-node/carousel-node.component';

@Component({
  selector: 'app-details-carousel',
  templateUrl: './details-carousel.component.html',
  styleUrls: ['./details-carousel.component.css']
})
export class DetailsCarouselComponent implements AfterViewInit {

  @Input()
  model: CarouselNode;

  carouselCardWidth = CarouselCardWidth;
  carouselNode: CarouselNodeComponent;

  constructor(private nodeDetailsService: NodeDetailsService) { }

  ngAfterViewInit(): void {
    this.nodeDetailsService.node$.subscribe(
      nodeComponent => {
        if (nodeComponent && nodeComponent instanceof CarouselNodeComponent) {
          this.carouselNode = nodeComponent;
          this.model = nodeComponent.model;
        } else {
          this.model = null;
        }
      });
  }

  onChange() {
    this.carouselNode.onChange();
  }
}
