import { environment } from './../../../environments/environment';
import { FlowEditorService } from '../../services/flow-editor.service';
import { LoginService } from '../../services/login.service';
import { ReportService } from '../../services/report.service';
import { DialogService } from '../../dialogs/dialog.service';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/services/user-profile.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent {

  user = LoginService.getUserName();
  account = LoginService.getAccountName();

  private channels: String[];

  userRole: String;

  @Input()
  flowName: string;
  @Input()
  dirty: boolean;
  @Input()
  titleSuffix: string;
  @Input()
  backTarget: string;
  @ViewChild('inputFlowName')
  inputFlowName: ElementRef<HTMLInputElement>;

  env = environment;

  constructor(
    private dialogService: DialogService,
    private loginService: LoginService,
    private reportService: ReportService,
    private flowService: FlowEditorService,
    private router: Router,
    private userProfileService: UserProfileService) {
    userProfileService.channels$.subscribe(channels => {
      if (channels) {
        this.channels = channels;
      }
    });

    userProfileService.userRole$.subscribe(userRole => {this.userRole = userRole; });
  }

  rename(newName: string) {
    if (newName && this.flowName !== newName) {
      this.flowName = newName;
      this.flowService.renameCurrentFlow(this.flowName);
    }
  }

  logout() {
    this.dialogService.confirmation(
      'Log out?',
      'Would you like to log out?',
      'Log out',
      confirmed => {
        if (confirmed) {
          this.loginService.logout();
          this.reportService.clearReports();
          this.userRole = '';
        }
      });
  }

  report() {
    this.saveNavigate(() => this.navigateReport());
  }

  flowDesigner() {
    this.saveNavigate(() => this.navigateFlows());
  }

  backLink() {
    this.saveNavigate(() => this.navigateBack());
  }

  saveNavigate(fn: () => void) {
    if (this.flowName && this.dirty) {
      this.dialogService.confirmation(
        'Discard changes?',
        'There are unsaved changes in your flow. Leaving now will discard these changes. <br><br> Going back?',
        'Discard',
        confirmed => {
          if (confirmed) {
            fn();
          }
        },
        ['primary', 'warn']);
    } else {
      fn();
    }
  }

  private navigateBack() {
    this.flowService.clear();
    this.router.navigate([this.backTarget]);
  }

  private navigateReport() {
    this.flowService.clear();
    this.reportService.showReport();
  }

  private navigateFlows() {
    this.flowService.clear();
    this.router.navigate(['/flows']);
  }

  public channelClick() {
    const dirty = this.dirty;
    const dialogRef = this.dialogService.channel(
      this.channels,
      this.flowService.getSelectedChannels(),
      confirmed => {
        if (confirmed) {
          this.flowService.setSelectedChannels(dialogRef.selectedChannalsAsString());
          this.userProfileService.fetchSelectedFeatureset(this.flowService.getSelectedChannels());
          this.flowService.updateNodes();
          this.dirty = dirty;
        }
      });
  }
}
