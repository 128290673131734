<app-topbar titleSuffix="Overview"></app-topbar>
<mat-drawer-container autosize class="drawer-container">
  <mat-drawer-content class="overview-content">

    <div class="loading" *ngIf="!flowList">Loading ...</div>

    <div class="flow-list">

      <button [routerLink]="['/editor', newFlowId]"
              mat-raised-button
              color="primary"
              class="new-flow-button"
              data-testid="createNewFlow">Create New Flow
      </button>

      <div class="header-row">
        <div class="header-item grow">Flow Name</div>
        <div class="header-item modify-width">Modify Date</div>
        <div class="header-item tools"></div>
      </div>
      <mat-divider></mat-divider>

      <div (mouseenter)="mouseEnter(nameEdit)"
           (mouseleave)="mouseLeave(nameEdit)"
           class="row"
           *ngFor="let flow of flowList?.flowList"
           attr.data-testid="flow-{{flow.id}}">
        <app-name-editable #nameEdit
                           class="grow"
                           (nameChanged)="flowService.renameFlow($event, flow)"
                           [name]="flow.name ? flow.name : 'Unnamed Flow'"
                           [link]="['/editor/', flow.id]"></app-name-editable>
        <div class="modify-width">{{ flow?.modifyTime | date : 'medium' }} </div>
        <div class="tools">
          <a class="launch-icon" [routerLink]="['/editor', flow.id]"><mat-icon data-testid="flowEdit" matTooltip="Open Flow">launch</mat-icon></a>
          <mat-icon (click)="deleteFlow(flow)" class="delete-button pointer" data-testid="flowDelete" matTooltip="Delete Flow">cancel</mat-icon>
        </div>
      </div>

      <div class="no-flows" *ngIf="flowList?.flowList.length === 0">No flows created, yet.</div>

    </div>

    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>

