import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NodeHelper } from './../../../services/node-helper.service';
import { Component, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { RichCardNode, CardHeight, CardOrientation, ThumbnailImageAlignment } from 'flow-model';
import { SuggestionData, ALL_SUGGESTIONS, isShareLocationSuggestedAction } from 'src/app/model/suggestions';
import { BasicNode } from '../../editor/renderer/node/basic-node';
import { RichCardNodeComponent } from '../../editor/renderer/node/rich-card-node/rich-card-node.component';
import { NodeDetailsService } from './../../../services/node-details.service';

@Component({
  selector: 'app-details-rich-card-message',
  templateUrl: './details-rich-card-message.component.html',
  styleUrls: ['./details-rich-card-message.component.css']
})
export class DetailsRichCardMessageComponent implements AfterViewInit {

  @ViewChildren('mediaUrl')
  private mediaUrlInputField: QueryList<HTMLInputElement>;
  @ViewChildren('thumbnailUrl')
  private thumbnailUrlInputField: QueryList<HTMLInputElement>;

  basicNode: BasicNode;
  model: RichCardNode = null;
  suggestionsModel: SuggestionData[] = null;
  thumbnailDisabled = false;

  cardHeights = NodeHelper.enumSelector(CardHeight);
  cardOrientations = NodeHelper.enumSelector(CardOrientation);
  thumbnailImageAlignments = NodeHelper.enumSelector(ThumbnailImageAlignment);

  constructor(private nodeDetailsService: NodeDetailsService) {
  }

  ngAfterViewInit(): void {
    this.nodeDetailsService.node$.subscribe(
      nodeComponent => {
        this.basicNode = nodeComponent;
        this.updateModel(nodeComponent);
      });
    this.registerForChanges(this.mediaUrlInputField, (url: string) => this.model.mediaUrl = url ? url.trim() : url);
    this.registerForChanges(this.thumbnailUrlInputField, (url: string) => this.model.thumbnailUrl = url ? url.trim() : url);
  }

  private updateModel(node: BasicNode) {
    this.model = null;
    this.suggestionsModel = null;
    if (node instanceof RichCardNodeComponent) {
      this.model = node.model;
      this.suggestionsModel = node.suggestions;
    }
  }

  private registerForChanges(container: QueryList<HTMLInputElement>, setter: (url: string) => void) {
    container.changes.subscribe(e => {
      if (e.first) {
        fromEvent(e.first.nativeElement, 'keyup')
          .pipe(map((event: any) => event.target.value), debounceTime(500))
          .subscribe(url => {
            setter(url);
            if (this.thumbnailDisabled = (!this.model.mediaUrl || this.model.mediaUrl.trim() === '')) {
              this.model.thumbnailUrl = null;
            }
            this.basicNode.onChange();
          });
      }
    });
  }


  getEnabledSuggestions() {
    return ALL_SUGGESTIONS.filter(suggestion => !isShareLocationSuggestedAction(suggestion));
  }

  setMediaUrl(urlInfo: object) {
    if (urlInfo['inputFieldId'] === 'mediaUrl') {
      this.model.mediaUrl = urlInfo['url'];
    }
    if (urlInfo['inputFieldId'] === 'thumbnailUrl') {
      this.model.thumbnailUrl = urlInfo['url'];
    }
    this.basicNode.onChange();
  }
}
