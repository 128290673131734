<div *ngIf="model">
    <div class="details-node-name">Data Message</div>
    <app-name-node [model]="basicNode.model" (changed)="basicNode.onChange()"></app-name-node>

    <mat-form-field class="full-width">
        <input matInput id="requestUrl" [(ngModel)]="model.requestUrl" (ngModelChange)="basicNode.onChange()" placeholder="Request URL" type="text" matTooltip="Enter url to send data" [matTooltipShowDelay]="env.tooltipDelay" data-testid="paneRequestUrl" />
    </mat-form-field>

    <mat-checkbox [(ngModel)]="model.asynchronous" (ngModelChange)="onChange()" class="freetext-checkbox" matTooltip="Send asynchronous request" [matTooltipShowDelay]="env.tooltipDelay" data-testid="asynchronousCheckBox">
        Asynchronous request
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="model.transientOutput" (ngModelChange)="onChange()" class="freetext-checkbox" matTooltip="Variables from response are not kept in database. They can be used at the start of next node execution. They might not be set in case of async request." [matTooltipShowDelay]="env.tooltipDelay" data-testid="transientCheckBox">
        Transient data from response
    </mat-checkbox>
    <mat-checkbox *ngIf="isMobileInvoiceActive() || model.hmacMI" [(ngModel)]="model.hmacMI" (ngModelChange)="onChange()" class="freetext-checkbox" matTooltip="Use hmac Payment API authorization" [matTooltipShowDelay]="env.tooltipDelay" data-testid="hmacAuthorizationCheckBox">
        Payment API authorization
    </mat-checkbox>

    <div class="row">
        <div class="flex">
            <h3 style="flex-grow: 10">Action: </h3>
        </div>
        <button [matMenuTriggerFor]="menu" class="menu" mat-stroked-button data-testid="actionType">
           <div class="label" *ngIf="model.action; let text; else noDescription">{{ getHttpAction(text) }}
               <mat-icon>arrow_drop_down</mat-icon>
           </div>
           <ng-template #noDescription>
            <div class="label"><i>No Action</i>
                <mat-icon>arrow_drop_down</mat-icon>
            </div>
           </ng-template>
           
        </button>
    </div>

    <mat-menu #menu="matMenu">
        <button *ngFor="let actionData of actions; let i=index" (click)="changeAction(actionData)" mat-menu-item [attr.data-testid]="'actionDataMenu-' + i">
            <span>{{ getHttpAction(actionData.text) }}</span>
        </button>
    </mat-menu>

    <mat-form-field class="full-width">
        <input matInput id="variable" type="text"
          [(ngModel)]="model.outputVariableName"
          (ngModelChange)="basicNode.onChange()"
          placeholder="Status code output variable (optional)"
          matTooltip="Enter a variable name to enable data collecting for this node"
          [matTooltipShowDelay]="env.tooltipDelay"
          appDragStopPropagation
          data-testid="outputVariableName"/>
      </mat-form-field>

    <div *ngIf="model.action == HttpAction.GET" class="row">
        <p style = "flex-grow: 10">Select data from response</p>
        <button (click)="manageResponseVariables()" data-testid="manageResponseVariablesButton" mat-icon-button matTooltip="Response Variables">
            <mat-icon>view_list</mat-icon>
        </button>
    </div>
    <div *ngIf="model.action">

        <mat-tab-group #tabGroup dynamicHeight>
            <mat-tab label="Headers">
                <div class="flex">
                    <p style="flex-grow: 10">Add headers</p>
                    <button (click)="addHeader()" data-testid="addHeaderButton" [matTooltipShowDelay]="env.tooltipDelay" mat-icon-button matTooltip="Add Header">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </div>

                <mat-list class="variable-list">
                    <mat-list-item class="variable-item" *ngFor="let h of model.httpHeaders; let i=index">
                        <mat-form-field>
                            <input matInput [(ngModel)]="h.key" (ngModelChange)="onChange()" placeholder="Key">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput [(ngModel)]="h.value" (ngModelChange)="onChange()" placeholder="Value">
                        </mat-form-field>
                        <button mat-icon-button (click)="removeHeader(i)" title="Remove Header">
                            <mat-icon class="delete-button">cancel</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
           
            <mat-tab  label="Body" *ngIf="model.action == HttpAction.POST">
                 <label *ngFor="let tab of httpBodyTypes; let index = index">
                    <input type="radio" name="httpBodyType" [(ngModel)]="model.httpBodyType" (ngModelChange)="onChange()" [value]="tab.text"  [attr.data-testid]="'httpBody' + index">
                    <small>{{tab.text}}</small>
                </label>
                <div [ngSwitch]="model.httpBodyType">
                    <div *ngSwitchCase="HttpBodyType.FORM_DATA">

                        <div class="flex">
                            <p style="flex-grow: 10">Form Data Entries</p>
                            <button (click)="addFormData()" [matTooltipShowDelay]="env.tooltipDelay" mat-icon-button matTooltip="Add Form Data" data-testid="addFormData">
                                <mat-icon>add_circle</mat-icon>
                            </button>
                        </div>

                        <mat-list class="variable-list">
                            <mat-list-item class="variable-item" *ngFor="let formData of model.formDataEntries; let i=index">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="formData.key" placeholder="Key">
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput [(ngModel)]="formData.value" placeholder="Value">
                                </mat-form-field>
                                <button mat-icon-button (click)="removeFormDataEntry(i)" title="Remove">
                                    <mat-icon class="delete-button">cancel</mat-icon>
                                </button>
                            </mat-list-item>
                        </mat-list>

                    </div>
                    <div *ngSwitchCase="HttpBodyType.RAW" class="container">

                        <textarea cdkTextareaAutosize class="messageBody" [(ngModel)]= "model.messageBody" (ngModelChange)="onChange()">
                        </textarea>

                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Tests">
                <div class="row">
                   <button mat-raised-button data-testid="testHttpNode" (click)="testNode()" [disabled]="requestIsInProgress">Send request</button>
                   <button (click)="manageVariables()" data-testid="manageVariablesButton" mat-icon-button matTooltip="Manage Variables" [disabled]="requestIsInProgress"><i class="fa fa-cog"></i></button>
                   <mat-spinner diameter="22" *ngIf="requestIsInProgress"></mat-spinner>
                </div>
                <div>
                  <p>Response</p>
                  
                  <div class="container">
                    <div class="row">
                        <label>Status: 
                            <input type="text" name="status" id="status" size="3" data-testid="responseStatusCode" readonly  #status [(ngModel)] = "httpRequestInfo.statusCode">
                        </label>
                        
                        <app-variables-details-list [httpVariables] = "httpRequestInfo.httpVariables"> </app-variables-details-list>
                       
                    </div>
                    <p class="emptyRow"></p>
                    <textarea data-testid="responseBodyTextArea" cdkTextareaAutosize class="responseBody" readonly #responseBody [(ngModel)] = "httpRequestInfo.responseBody">
                        
                    </textarea> 
                  </div>
               </div>
            </mat-tab>
        </mat-tab-group>
    </div>

</div>