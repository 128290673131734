import { NodeDetailsService } from '../../services/node-details.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { BasicNode } from '../editor/renderer/node/basic-node';

@Component({
  selector: 'app-details-panel',
  templateUrl: './details-panel.component.html',
  styleUrls: ['./details-panel.component.css'],
})
export class DetailsPanelComponent {

  selectedNode: BasicNode;
  showNothingSelected = true;

  constructor(nodeDetailsService: NodeDetailsService) {
    nodeDetailsService.node$.subscribe(
      nodeComponent => {
        this.selectedNode = nodeComponent;
        this.showNothingSelected = (this.selectedNode === null);
      });
  }
}
