<div *ngIf="model">
  <div class="details-node-name">Text Match</div>
  <app-name-node [model]="keywordsNodeComponent.model" (changed)="keywordsNodeComponent.onChange()"></app-name-node>

  <mat-form-field class="full-width">
    <input matInput id="variable" [(ngModel)]="model.inputVariableName"
      (ngModelChange)="keywordsNodeComponent.onChange()" placeholder="Input Variable" type="text"
      matTooltip="The keywords will be matched against the content of this variable"
      [matTooltipShowDelay]="env.tooltipDelay" data-testid="paneInputVariableName" />
  </mat-form-field>

  <app-output-variable [model]="keywordsNodeComponent.model" (changed)="keywordsNodeComponent.onChange()"></app-output-variable>
  <app-keyword-details-list [keywordNode]="keywordsNodeComponent"></app-keyword-details-list>
</div>
