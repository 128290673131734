<div class="login-site">
  <div class="login-layout">
    <mat-card class="login-card">
      <mat-card-header>
        <mat-card-title>
          <img src="/assets/lm-logo.png" alt="LINK Mobility logo"></mat-card-title>
      </mat-card-header>
      <form #loginForm class="login-form">
        <mat-card-content>
          <mat-form-field class="login-form-fullwidth">
            <input matInput
                   placeholder="Username"
                   [(ngModel)]="username"
                   name="username"
                   required
                   autofocus
                   data-testid="login-username-input">
          </mat-form-field>
          <mat-form-field class="login-form-fullwidth">
            <input matInput
                   placeholder="Password"
                   [(ngModel)]="password"
                   name="password"
                   type="password"
                   required
                   data-testid="login-password-input">
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions align="end">
          <mat-spinner [style.display]="loginInProgress ? 'block' : 'none'" diameter="36"></mat-spinner>
          <button (click)="tryLogin()" [disabled]="loginInProgress" color="primary" data-testid="login-button"
                  mat-raised-button type="submit">Login
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
  <app-footer></app-footer>
</div>

