<div class="sidebar" data-testid="sidebar" id="sidebar">
  <div class="button-list">
    <button (click)="toggleSettings()" data-testid="flowSettingsButton" mat-icon-button matTooltip="Flow Settings"><i class="fa fa-cog"></i></button>
    <button (click)="save(flowId)" data-testid="flowSaveButton" mat-icon-button matTooltip="Save Flow"><i class="fa fa-save"></i></button>
    <button (click)="load()" [disabled]="!flowDirty" data-testid="flowLoadButton" mat-icon-button matTooltip="Discard unsaved changes"><i class="fa fa-history"></i></button>
    <button (click)="delete()" data-testid="flowDeleteButton" mat-icon-button matTooltip="Delete Flow"><i class="fa fa-trash-alt"></i></button>
    <button (click)="downloadFlow()" data-testid="flowDownloadButton" mat-icon-button matTooltip="Download Flow"><i class="fa fa-download"></i></button>
    <button (click)="selectFile()" [disabled]="uploadInProgress" data-testid="uploadJsonButton"
    matTooltip="Upload flow from file" mat-icon-button><i class="fa fa-upload"></i></button>
    <mat-spinner diameter="22" *ngIf="uploadInProgress"></mat-spinner>
  </div>
  <div>
    <input #fileInput (change)="onFileChange($event)" type="file" accept=".json" style="visibility: hidden"
      data-testid="jsonFileInput">
  </div>
  <mat-divider></mat-divider>

  <app-conversation-trigger [flowId]="flowId" (campaignTriggered)="campaignTriggered()"></app-conversation-trigger>
  <mat-divider></mat-divider>

  <app-campaigns #campaigns></app-campaigns>
  <mat-divider></mat-divider>
</div>
