<div *ngIf="model">
  <div class="details-node-name">Carousel Card</div>
  <app-name-node [model]="model" (changed)="onChange()"></app-name-node>
  <mat-form-field>
    <mat-label>Card Width</mat-label>
    <mat-select [(ngModel)]="model.carouselCardWidth"
                (ngModelChange)="onChange()"
                data-testid="widthDropDownMenu">
      <mat-option value="{{carouselCardWidth.SMALL}}">{{ carouselCardWidth.SMALL }}</mat-option>
      <mat-option value="{{carouselCardWidth.MEDIUM}}">{{ carouselCardWidth.MEDIUM }}</mat-option>
    </mat-select>
  </mat-form-field>
  <app-output-variable [model]="model" (changed)="onChange()" ></app-output-variable>
</div>
