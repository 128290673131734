<div class="row">
  <mat-form-field class="full-width">
    <input [(ngModel)]="calendar.title" data-testid="calendarActionTitle" matInput placeholder="Title" type="text"/>
  </mat-form-field>
</div>
<div class="row">
  <mat-form-field class="full-width">
    <input [(ngModel)]="calendar.description" matInput placeholder="Description" type="text" data-testid="calendarActionDescription"/>
  </mat-form-field>
</div>
<div class="row">
  <mat-checkbox (change)="toggleDateVariables($event)"
                [checked]="datesFromVariables"
                data-testid="toggleDateVariables">
    Retrieve Dates from Variables
  </mat-checkbox>
</div>

<ng-container *ngIf="datesFromVariables; else datePickers">
  <div class="row">
    <mat-form-field>
      <span class="variable-input-addon" matPrefix>${{ '{' }}</span>
      <input [(ngModel)]="calendar.startTimeVariable"
             matInput
             data-testid="starttimeVariable"
             class="variable-input"
             placeholder="Start Date Variable Name">
      <span class="variable-input-addon" matSuffix>}</span>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <span matPrefix>${{ '{' }}</span>
      <input [(ngModel)]="calendar.endTimeVariable"
             matInput
             data-testid="endtimeVariable"
             class="variable-input"
             placeholder="End Date Variable Name">
      <span matSuffix>}</span>
    </mat-form-field>
  </div>
</ng-container>

<ng-template #datePickers>
  <div class="row">
    <mat-form-field class="width-60">
      <input [(ngModel)]="startDate"
             (dateChange)="this.localStartTime = onDateChanged($event, this.localStartTime); onChange()"
             (keyup)="this.localStartTime = onStartDateChangedKey($event, this.localStartTime); onChange()"
             [matDatepicker]="pickerStart"
             matInput placeholder="Start date"
             type="text"
             data-testid="calendarActionStartDate"/>
      <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #pickerStart></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="width-40">
      <input [(ngModel)]="startTime"
             (change)="this.localStartTime = onTimeChange($event, this.localStartTime); onChange()"
             (keyup)="this.localStartTime = onTimeChange($event, this.localStartTime); onChange()"
             matInput placeholder="Start time"
             type="time"
             maxlength="5"
             data-testid="calendarActionStartTime"/>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="width-60">
      <input [(ngModel)]="endDate"
             (dateChange)="this.localEndTime = onDateChanged($event, this.localEndTime); onChange()"
             (keyup)="this.localEndTime = onEndDateChangedKey($event, this.localEndTime); onChange()"
             [matDatepicker]="pickerEnd"
             matInput placeholder="End date"
             type="text"
             data-testid="calendarActionEndDate"/>
      <mat-datepicker-toggle [for]="pickerEnd" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #pickerEnd></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="width-40">
      <input [(ngModel)]="endTime"
             (change)="this.localEndTime = onTimeChange($event, this.localEndTime); onChange()"
             (keyup)="this.localEndTime = onTimeChange($event, this.localEndTime); onChange()"
             matInput placeholder="End time"
             type="time"
             maxlength="5"
             data-testid="calendarActionEndTime"/>
    </mat-form-field>
  </div>
</ng-template>

<div class="row" *ngIf="this.localEndTime ? this.localStartTime?.isAfter(this.localEndTime) : false">
  <mat-error>End-time must not be before start-time</mat-error>
</div>
