import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { MobileInvoiceNode, HttpRequestInfo } from 'flow-model';
import { HttpService } from 'src/app/services/http.service';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { Subscription } from 'rxjs';
import { deserialize } from 'class-transformer';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-mobile-invoice-dialog',
  templateUrl: './mobile-invoice-dialog.component.html',
  styleUrls: ['./mobile-invoice-dialog.component.css']
})
export class MobileInvoiceDialogComponent {

  env = environment;

  model: MobileInvoiceNode = null;

  httpRequestInfo: HttpRequestInfo;

  requestIsInProgress = false;

  sub: Subscription;

  isVisible = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private httpService: HttpService, private snackBarService: SnackBarService) {
    this.httpRequestInfo = data.httpRequestInfo;
    this.model = data.node;
  }

  sendTestRequest() {
    this.requestIsInProgress = true;
    this.isVisible = false;
    this.httpRequestInfo.statusCode = '';
    this.httpRequestInfo.responseBody = '';
    this.httpRequestInfo.httpVariables = null;
    this.sub = this.httpService.sendTestMobileInvoiceNode(this.model)
      .subscribe(
        (data: string) => {
          this.requestIsInProgress = false;
          this.isVisible = false;
          const httpRequestInfo: HttpRequestInfo = deserialize(HttpRequestInfo, data);
          this.httpRequestInfo.statusCode = httpRequestInfo.statusCode;
          this.httpRequestInfo.responseBody = httpRequestInfo.responseBody;
        },
        (response: HttpErrorResponse) => {
          this.requestIsInProgress = false;
          this.isVisible = false;
          if (response.status === 422) {
            const httpRequestInfo: HttpRequestInfo = deserialize(HttpRequestInfo, response.error);
            this.snackBarService.showError(httpRequestInfo.message);
          } else {
            this.snackBarService.showError('Encountered an error or misconfiguration and was unable to complete your request');
          }
        }
      );
  }

}
