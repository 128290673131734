import { MatSnackBar } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class SnackBarService {
    constructor(snackBar) {
        this.snackBar = snackBar;
    }
    showError(msg, duration = 5000) {
        this.snackBar.open(msg, 'Dismiss', {
            verticalPosition: 'top',
            panelClass: 'snackbar-error',
            duration: duration,
            data: 'data-testid=snackbar'
        });
    }
    showWarning(msg, duration = 5000) {
        this.snackBar.open(msg, 'Dismiss', {
            verticalPosition: 'top',
            panelClass: 'snackbar-warning',
            duration: duration,
            data: 'data-testid=snackbar'
        });
    }
    showSuccessOnDetailsPanel(msg) {
        this.snackBar.open(msg, 'Dismiss', {
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            panelClass: 'snackbar-success',
            duration: 3000,
            data: 'data-testid=snackbar'
        });
    }
    showErrorOnDetailsPanel(msg) {
        this.snackBar.open(msg, 'Dismiss', {
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            panelClass: 'snackbar-error',
            duration: 3000,
            data: 'data-testid=snackbar'
        });
    }
    showSuccess(msg) {
        this.snackBar.open(msg, 'Dismiss', {
            verticalPosition: 'top',
            panelClass: 'snackbar-success',
            duration: 3000
        });
    }
    showErrorPermanently(msg) {
        this.showError(msg, 0);
    }
}
SnackBarService.ngInjectableDef = i0.defineInjectable({ factory: function SnackBarService_Factory() { return new SnackBarService(i0.inject(i1.MatSnackBar)); }, token: SnackBarService, providedIn: "root" });
