import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewLocationSuggestedAction } from 'flow-model';
import { MatRadioChange } from '@angular/material';

@Component({
  selector: 'app-view-location-action',
  templateUrl: './view-location-action.component.html',
  styleUrls: ['./view-location-action.component.css']
})
export class ViewLocationActionComponent implements OnInit {

  @Input()
  location: ViewLocationSuggestedAction;

  @Output()
  change = new EventEmitter<void>();

  selectedOption: string;

  constructor() {
  }

  ngOnInit() {
    if (this.location.latitude && this.location.longitude) {
      this.selectedOption = 'latlng';
    } else if (this.location.latitudeVariable && this.location.longitudeVariable) {
      this.selectedOption = 'latlngvariables';
    } else {
      this.selectedOption = 'query';
    }
  }

  onRadioChange($event: MatRadioChange) {
    if ($event.value === 'query') {
      this.location.latitude = null;
      this.location.longitude = null;
      this.location.latitudeVariable = null;
      this.location.longitudeVariable = null;
      this.location.label = null;
    } else if ($event.value === 'latlng') {
      this.location.searchQuery = null;
      this.location.latitudeVariable = null;
      this.location.longitudeVariable = null;
    } else if ($event.value === 'latlngvariables') {
      this.location.searchQuery = null;
      this.location.latitude = null;
      this.location.longitude = null;
    }
    this.change.emit();
  }
}
