<div class="suggestion" (mousedown)="offNodeTranslate()" (mouseup)="onNodeTranslate()">
    <mat-icon (mousedown)="$event.stopPropagation()" (click)="deleteSuggestion()"
              data-testid="deleteSuggestion"
              class="suggestion-inline-button delete-button">
      cancel
    </mat-icon>
  <ng-container *ngIf="suggestionData.suggestionType && suggestionData.suggestionType.label !== 'Text'">
    <mat-icon [matTooltip]="suggestionData.suggestionType.label"
              [matTooltipShowDelay]="env.tooltipDelay"
              class="type-icon"
              data-testid="actionIcon">
      {{ suggestionData.suggestionType.icon }}
    </mat-icon>
  </ng-container>
  <div class="fill_space"></div>
  <input
    [(ngModel)]="suggestionData.suggestion.template['en']"
    (ngModelChange)="onChange()"
    appDragStopPropagation
    data-testid="suggestionInput"
    placeholder="Suggestion"
    required type="text"/>
  <mat-icon cdkDragHandle
            matTooltip="Move suggestion"
            [matTooltipShowDelay]="env.tooltipDelay"
            class="reorder-button">
    drag_indicator
  </mat-icon>
  <app-socket
    [bindSocket]="bindSocket"
    [inputOutput]="suggestionData.output"
    data-testid="suggestionSocket"
    (mousedown)="$event.stopPropagation()"
    type="output">
  </app-socket>
</div>
