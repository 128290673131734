import { OnFreeText } from 'flow-model';
import { Component, Input } from '@angular/core';
import { Input as ReteInput } from 'rete';
import { environment } from '../../../../../../environments/environment';
import { BasicNode } from '../../node/basic-node';
import { ReteOutput } from '../../../rete/controls/extended-output';

@Component({
  selector: 'app-free-text',
  templateUrl: './free-text.component.html',
  styleUrls: ['./free-text.component.css']
})
export class FreeTextComponent {

  @Input()
  messageNode: BasicNode;

  freeTextOutput: ReteOutput;

  @Input()
  bindSocket: (el: HTMLElement, type: string, io: ReteInput | ReteOutput) => void;

  freeTextEnabled = false;
  env = environment;

  onChange() {
    if (this.freeTextEnabled) {
      this.freeTextOutput = this.messageNode.addFreeTextOutput();
    } else {
      this.messageNode.removeFreeTextOutput();
      this.freeTextOutput = null;
    }
  }

  loadFromModel(onFreeText: OnFreeText) {
    if (onFreeText) {
      this.freeTextOutput = this.messageNode.addFreeTextOutput();
    }
    this.freeTextEnabled = this.freeTextOutput != null;
  }
}
