import { Socket } from 'rete';

export const inputSocket = new Socket('InputSocket');
export const nextMessageSocket = new Socket('NextMessage');
export const freeTextSocket = new Socket('OnFreeText');
export const suggestionSocket = new Socket('Suggestion');
export const keywordSocket = new Socket('Keyword');
export const carouselRichCardSocket = new Socket('CarouselRichCard');
export const mobileInvoiceStatusSocket = new Socket('MobileInvoiceStatus');

nextMessageSocket.combineWith(inputSocket);
freeTextSocket.combineWith(inputSocket);
suggestionSocket.combineWith(inputSocket);
keywordSocket.combineWith(inputSocket);
carouselRichCardSocket.combineWith(inputSocket);
mobileInvoiceStatusSocket.combineWith(inputSocket);
