/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-output-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/drag-drop";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "./card-output/card-output.component.ngfactory";
import * as i7 from "./card-output/card-output.component";
import * as i8 from "../../../../../../services/flow-editor.service";
import * as i9 from "../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "./card-output-list.component";
var styles_CardOutputListComponent = [i0.styles];
var RenderType_CardOutputListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardOutputListComponent, data: {} });
export { RenderType_CardOutputListComponent as RenderType_CardOutputListComponent };
function View_CardOutputListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "div", [["cdkDrag", ""], ["class", "drop-rich-card cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i2.ɵb, null, [i2.CdkDrag]), i1.ɵdid(2, 4866048, [[1, 4]], 3, i2.CdkDrag, [i1.ElementRef, [3, i2.CDK_DROP_LIST], i3.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i4.ViewportRuler, i2.DragDropRegistry, i2.CDK_DRAG_CONFIG, [2, i5.Directionality], i2.DragDrop, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 2, { _handles: 1 }), i1.ɵqud(335544320, 3, { _previewTemplate: 0 }), i1.ɵqud(335544320, 4, { _placeholderTemplate: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-card-output", [["data-testid", "card-output"]], null, [[null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("delete" === en)) {
        var pd_0 = (_co.carouselNode.removeCardOutput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_CardOutputComponent_0, i6.RenderType_CardOutputComponent)), i1.ɵdid(7, 49152, null, 0, i7.CardOutputComponent, [i8.FlowEditorService], { output: [0, "output"], bindSocket: [1, "bindSocket"] }, { delete: "delete" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit; var currVal_3 = _co.carouselNode.bindSocket; _ck(_v, 7, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CardOutputListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "card-output"], ["data-testid", "addCardOutput"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.carouselNode.addCardOutput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "card-output-inline-button mat-icon notranslate"], ["data-testid", "addCardOutputButton"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, [" add"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["disabled", ""], ["placeholder", "New Card Output"], ["type", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "card-output-spacer-add"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_CardOutputListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "rich-card-collection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["cdkDropList", ""], ["class", "cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.drop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.CDK_DROP_LIST, null, [i2.CdkDropList]), i1.ɵdid(3, 1196032, null, 1, i2.CdkDropList, [i1.ElementRef, i2.DragDropRegistry, i1.ChangeDetectorRef, [2, i5.Directionality], [3, i2.CdkDropListGroup], [2, i3.DOCUMENT], i2.DragDrop], null, { dropped: "cdkDropListDropped" }), i1.ɵqud(603979776, 1, { _draggables: 1 }), i1.ɵprd(256, null, i2.CdkDropListGroup, undefined, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardOutputListComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardOutputListComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.carouselNode.cardOutputs; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.carouselNode.showAddCardConnectionButton; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = i1.ɵnov(_v, 3).disabled; var currVal_2 = i1.ɵnov(_v, 3)._dropListRef.isDragging(); var currVal_3 = i1.ɵnov(_v, 3)._dropListRef.isReceiving(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_CardOutputListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-output-list", [], null, null, null, View_CardOutputListComponent_0, RenderType_CardOutputListComponent)), i1.ɵdid(1, 49152, null, 0, i11.CardOutputListComponent, [i8.FlowEditorService], null, null)], null, null); }
var CardOutputListComponentNgFactory = i1.ɵccf("app-card-output-list", i11.CardOutputListComponent, View_CardOutputListComponent_Host_0, { carouselNode: "carouselNode" }, {}, []);
export { CardOutputListComponentNgFactory as CardOutputListComponentNgFactory };
