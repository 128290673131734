import { FlowEditorService } from '../services/flow-editor.service';
import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { FlowList, FlowMetaData } from 'flow-model';
import { deserialize } from 'class-transformer';
import { DialogService } from '../dialogs/dialog.service';
import { v4 as uuid } from 'uuid';
import { SnackBarService } from '../services/snackbar.service';
import { NameEditableComponent } from './name-editable/name-editable.component';

@Component({
  selector: 'app-flow-overview',
  templateUrl: './flow-overview.component.html',
  styleUrls: ['./flow-overview.component.css']
})
export class FlowOverviewComponent implements OnInit {

  flowList: FlowList = null;
  newFlowId = uuid();

  public flowService: FlowEditorService;

  constructor(
    private httpService: HttpService,
    private dialogService: DialogService,
    private flowEditorService: FlowEditorService,
    private snackBar: SnackBarService) {
      this.flowService = flowEditorService;
  }

  ngOnInit() {
    this.loadFlows();
  }

  deleteFlow(flow: FlowMetaData) {

    let flowDesc = (flow.name) ? flow.name : flow.id;
    flowDesc = DialogService.safe_tags_replace(flowDesc);

    this.dialogService.confirmation('Delete Flow?', `Are you sure you want to delete <b>${flowDesc}</b>?`,
      'Delete',
      (confirmed) => {
        if (confirmed) {
          this.httpService.deleteFlow(flow.id).subscribe(() => {
            this.flowList.flowList = this.flowList.flowList.filter(obj => obj !== flow);
          }, () => {
            this.snackBar.showError('Flow could not be deleted! ');
          });
        }
      }, ['primary', 'warn']);
  }

  private loadFlows() {
    this.httpService.loadFlowList().subscribe((flow: any) => {
      const flowList: FlowList = deserialize(FlowList, flow);

      flowList.flowList.sort((n1, n2) => {
        if (n1.modifyTime < n2.modifyTime) {
          return 1;
        }
        if (n1.modifyTime > n2.modifyTime) {
          return -1;
        }
        return 0;
      });

      this.flowList = flowList;
    });
  }

  mouseEnter(nameEdit: NameEditableComponent) {
    nameEdit.showEditButton = true;
  }

  mouseLeave(nameEdit: NameEditableComponent) {
    nameEdit.showEditButton = false;
  }
}
