import { ReteOutput } from './../../../../rete/controls/extended-output';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Input as ReteInput } from 'rete';
import { SuggestionData } from '../../../../../../model/suggestions';
import { environment } from '../../../../../../../environments/environment';
import { FlowEditorService } from 'src/app/services/flow-editor.service';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.css']
})
export class SuggestionComponent {

  @Input()
  suggestionData: SuggestionData;
  @Input()
  bindSocket: (el: HTMLElement, type: string, io: ReteInput | ReteOutput) => void;

  @Output()
  changedSuggestion = new EventEmitter<SuggestionData>();
  @Output()
  delete = new EventEmitter<SuggestionData>();

  env = environment;

  constructor(
    private flowService: FlowEditorService) {
  }

  onChange() {
    this.changedSuggestion.emit(this.suggestionData);
  }

  deleteSuggestion() {
    this.delete.emit(this.suggestionData);
  }

  offNodeTranslate() {
    this.flowService.canTranslate = false;
  }

  onNodeTranslate() {
    this.flowService.canTranslate = true;
  }
}
