import { Component } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { RegexHelpComponent } from '../regex-help/regex-help.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-regex-examples',
  templateUrl: './regex-examples.component.html',
  styleUrls: ['./regex-examples.component.css']
})
export class RegexExamplesComponent {

  constructor(private snackBarService: SnackBarService, public dialog: MatDialog) { }

  onCopySuccess() {
    this.snackBarService.showSuccessOnDetailsPanel('Regex is copied');
  }

  showRegexHelp() {
    this.dialog.open(RegexHelpComponent, {maxWidth: '800px'});
  }

  onCopyError() {
    this.snackBarService.showErrorOnDetailsPanel('Error copy regex');
  }
}
