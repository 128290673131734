import { ReteOutput } from './../../rete/controls/extended-output';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Input as ReteInput } from 'rete';
import { environment } from '../../../../../environments/environment';
import { BasicNode } from '../node/basic-node';

@Component({
  selector: 'app-socket',
  templateUrl: './socket.component.html',
  styleUrls: ['./socket.component.css']
})
export class SocketComponent implements AfterViewInit {

  @ViewChild('socket')
  socketRef;

  @Input()
  type: string;

  @Input()
  inputOutput: ReteInput | ReteOutput;

  @Input()
  bindSocket: (el: HTMLElement, type: string, io: ReteInput | ReteOutput) => void;

  env = environment;

  ngAfterViewInit() {
    if (this.socketRef) {
      this.bindSocket(this.socketRef.nativeElement, this.type, this.inputOutput);
    }
  }

  getVerifiedClass(): string {
    if (this.inputOutput instanceof ReteOutput && this.inputOutput.optional) {
      return 'optional';
    }
    if (this.inputOutput.connections.length > 0) {
      return 'mandatory-connected';
    } else {
      return 'mandatory-not-connected';
    }
  }
}
