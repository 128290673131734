<div *ngFor="let keyword of keywordsData">
  <app-keyword [output]="keyword.output"
               [keyword]="keyword.keywordMatch"
               [bindSocket]="bindSocket"
               (changedKeyword)="onChange()"
               (delete)="deleteKeyword.emit(keyword)"
               data-testid="keyword"></app-keyword>
</div>

<div class="keyword" data-testid="addKeyword">
  <mat-icon (click)="createKeyword.emit()" class="keyword-inline-button" data-testid="addKeywordButton">add</mat-icon>
  <!--suppress HtmlFormInputWithoutLabel -->
  <input type="text" (keyup.enter)="addKeyword($event)" (blur)="addKeyword($event)" placeholder="New Keyword" data-testid="keywordInput"/>
  <div class="keyword-spacer-add"></div>
</div>

