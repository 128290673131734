import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { ResponseVariable } from 'flow-model';

@Component({
  selector: 'app-response-variables-dialog',
  templateUrl: './response-variables-dialog.component.html',
  styleUrls: ['./response-variables-dialog.component.css']
})
export class ResponseVariablesDialogComponent {

  env = environment;

  responseVariables: ResponseVariable[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.responseVariables = data.responseVariables;
  }

  addResponseVariable() {
    if (this.responseVariables == null) {
      this.responseVariables = [];
    }
    const  responseVariable = new ResponseVariable();
    responseVariable.name = '';
    responseVariable.expression = '';
    this.responseVariables.push(responseVariable);
  }

  removeResponseVariable(idx: number) {
    this.responseVariables.splice(idx, 1);
  }

}
