import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragStopPropagation]'
})
export class ClickStopPropagationDirective {

  constructor() {
  }
  @HostListener('mousedown', ['$event'])
  onMouseDown(event) {
    event.stopPropagation();
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event) {
    event.stopPropagation();
  }

  @HostListener('pointermove', ['$event'])
  onMouseMove(event) {
    event.stopPropagation();
  }
}
