import { AfterViewInit, Component } from '@angular/core';
import { NodeDetailsService } from 'src/app/services/node-details.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { BasicNode } from '../../editor/renderer/node/basic-node';
import { MobileInvoiceNodeComponent } from '../../editor/renderer/node/mobile-invoice-node/mobile-invoice-node.component';
import { MobileInvoiceNode, MobileInvoiceInfoSettings, PaymentProvider, DateAsNumber, HttpRequestInfo, HttpVariables } from 'flow-model';
import { MatCheckboxChange, MatDatepickerInputEvent } from '@angular/material';
import { environment } from 'src/environments/environment';
import { LocalDateTime, ZoneId, ZonedDateTime, Instant, DateTimeFormatter, convert, LocalDate, nativeJs, LocalTime } from 'js-joda';
import { getHttpVariables } from 'src/app/model/mobileInvoice';
import { HttpService } from 'src/app/services/http.service';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-details-mobile-invoice',
  templateUrl: './details-mobile-invoice.component.html',
  styleUrls: ['./details-mobile-invoice.component.css']
})
export class DetailsMobileInvoiceComponent implements AfterViewInit {

  basicNode: BasicNode;

  model: MobileInvoiceNode = null;

  mobileInvoiceInfoSettings: MobileInvoiceInfoSettings = new MobileInvoiceInfoSettings();

  env = environment;

  localDateTime: LocalDateTime;

  expiryDate: Date;
  expiryTime: string;

  httpRequestInfo: HttpRequestInfo;

  requestIsInProgress = false;

  sub: Subscription;

  minDate: Date;
  maxDate: Date;

  static transformToEpochNumber(date: LocalDateTime): DateAsNumber {
    if (date) {
      return date.atZone(ZoneId.SYSTEM).toEpochSecond();
    } else {
      return null;
    }
  }

  static transformToLocalDateTime(date: DateAsNumber): LocalDateTime {
    if (date) {
      return ZonedDateTime.ofInstant(Instant.ofEpochSecond(date), ZoneId.SYSTEM).toLocalDateTime();
    } else {
      return null;
    }
  }

  constructor(private nodeDetailsService: NodeDetailsService, private userProfileService: UserProfileService,
    private dialogService: DialogService, private httpService: HttpService, private snackBarService: SnackBarService,
    private dialog: MatDialog) {
    this.loadMobileInvoiceSettings();
    const currentDate = Date.now();
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentDate);
    this.maxDate = new Date(currentYear + 10, 11, 31);
  }

  // noinspection JSMethodCanBeStatic
  getTime(target: LocalDateTime): string {
    if (target) {
      return target.format(DateTimeFormatter.ofPattern('HH:mm'));
    }
  }

  // noinspection JSMethodCanBeStatic
  getDate(target: LocalDateTime): Date {
    if (target) {
      return convert(target, ZoneId.systemDefault()).toDate();
    }
  }

  ngAfterViewInit(): void {
    this.nodeDetailsService.node$.subscribe(
      nodeComponent => {
        this.basicNode = nodeComponent;
        this.updateModel(nodeComponent);
      });
  }

  sendAsLinkMessageChanged(event: MatCheckboxChange) {
     this.model.linkMessage = !event.checked;
     this.basicNode.onChange();
  }

  manageVariables() {
    const httpVariables: HttpVariables[] = getHttpVariables(this.model);
    if (httpVariables.length === 0) {
      this.snackBarService.showSuccessOnDetailsPanel('There are no variables in request');
      return;
    }
    this.dialogService.variables('Enter Variables', 'OK',
    httpVariables, confirmed => {
      if (confirmed) {
        this.model.testVariables = httpVariables;
      }
    });
  }

  onCopySuccess() {
    this.snackBarService.showSuccessOnDetailsPanel('Url is copied');
  }

  onCopyError() {
    this.snackBarService.showErrorOnDetailsPanel('Error copy url');
  }

  testNode() {
    this.httpRequestInfo.statusCode = '';
    this.httpRequestInfo.responseBody = '';
    this.httpRequestInfo.httpVariables = null;
    const httpVariables: HttpVariables[] = getHttpVariables(this.model);
    const emptyValueFound = httpVariables.some(httpVariable => httpVariable.value.trim() === '');
    if (emptyValueFound) {
      this.manageVariables();
    } else {
      this.dialogService.showMobileInvoiceDialog(
       this.httpRequestInfo,
       this.model,
      'Preview Mobile Invoice',
      ['primary', 'warn']);
   }
  }

  public isMobileInvoiceActive(): boolean {
    return this.userProfileService.isMobileInvoiceActive();
  }

  changeTemplate(template: string) {
    this.model.template = template;
    this.basicNode.onChange();
  }

  changeCurrency(currency: string) {
    this.model.currency = currency;
    this.basicNode.onChange();
  }

  changeLanguage(language: string) {
    this.model.language = language;
    this.basicNode.onChange();
  }

  private loadMobileInvoiceSettings() {
    this.userProfileService.mobileInvoiceInfoSettings$.subscribe(mobileInvoiceInfoSettings => {
      if (mobileInvoiceInfoSettings) {
        this.mobileInvoiceInfoSettings = mobileInvoiceInfoSettings;
      }
    });
  }

  private updateModel(node: BasicNode) {
    let prevNodeId = -1;
    let newNodeId = -2;
    if (this.model) {
      prevNodeId = this.model.id;
    }
    this.model = null;
    if (node instanceof MobileInvoiceNodeComponent) {
      this.model = node.model;
      newNodeId = this.model.id;
      if (this.model.expiryUTC !== null) {
        this.localDateTime = DetailsMobileInvoiceComponent.transformToLocalDateTime(this.model.expiryUTC);
        this.expiryDate = this.getDate(this.localDateTime);
        this.expiryTime = this.getTime(this.localDateTime);
      } else {
        this.localDateTime = null;
        this.expiryDate = null;
        this.expiryTime = null;
      }
      if (prevNodeId !== newNodeId) {
        this.httpRequestInfo = new HttpRequestInfo();
        this.httpRequestInfo.responseBody = '';
        this.httpRequestInfo.statusCode = '';
        this.httpRequestInfo.message = '';
      }
    }
    this.updateView(prevNodeId !== newNodeId);
  }

  private updateView(nodeChanged: boolean) {
    if (nodeChanged) {
      if (this.requestIsInProgress) {
        this.sub.unsubscribe();
        this.requestIsInProgress = false;
      }
    }
  }

  isProviderSelected(provider: string): boolean {
    if (this.model.providers === null || this.model.providers === undefined) {
      return false;
    }
    const found = this.model.providers.some(selectedProvider => {
      return selectedProvider === provider;
    });
    return found;
  }

  private containsProvider(provider: string, providers: string[]) {
    if (providers === null || providers === undefined) {
      return !provider;
    }
    const found = providers.some(selectedProvider => {
      return selectedProvider === provider;
    });
    return found;
  }

  getProviders(paymentProviders: PaymentProvider[]): string[] {
    const providers: string[] = [];
    if (paymentProviders === null || paymentProviders === undefined) {
      return providers;
    }
    paymentProviders.forEach(paymentProvider => {
       providers.push(paymentProvider.paymentProvider);
    });
    return providers;
  }

  invalidProviders(): string[] {
    if (this.model.providers === null || this.model.providers === undefined) {
      return [];
    }
    const invalidProviders: string[] = [];
    this.model.providers.forEach(provider => {
      if (!this.containsProvider(provider, this.getProviders(this.mobileInvoiceInfoSettings.providers))) {
        invalidProviders.push(provider);
      }
    });
    return invalidProviders;
  }

  reorderProviders() {
    if (this.model.providers === null || this.model.providers === undefined) {
      return;
    }
    const providers = this.model.providers;
    this.model.providers = [];
    this.mobileInvoiceInfoSettings.providers.forEach(paymentProvider => {
      if (this.containsProvider(paymentProvider.paymentProvider, providers)) {
        this.model.providers.push(paymentProvider.paymentProvider);
      }
    });
  }

  supportsLandingPage(provider: String): boolean {
    let supportsLandingPage = false;
    this.mobileInvoiceInfoSettings.providers.forEach(paymentProvider => {
      if (paymentProvider.paymentProvider === provider && paymentProvider.supportsDirectPayment) {
          supportsLandingPage = true;
      }
    });
    return supportsLandingPage;
  }

  checkShowLandingPage() {
    if (this.model.providers === null || this.model.providers === undefined) {
      this.model.skipLandingPage = false;
    }
    if (this.model.providers.length !== 1 || !this.supportsLandingPage(this.model.providers[0])) {
      this.model.skipLandingPage = false;
    }
  }

  getProvidersSupportingLandingPage(): string {
    const paymentProviders = [];
    this.mobileInvoiceInfoSettings.providers.forEach (paymentProvider => {
      if (paymentProvider.supportsDirectPayment) {
        paymentProviders.push(paymentProvider.paymentProvider);
      }
    });
    if (paymentProviders.length > 0) {
      return 'Providers supporting direct payment: ' + paymentProviders.join(',') + '. Direct payment require only one provider to be selected.';
    } else {
      return 'There is no providers supporting direct payment configured';
    }
  }

  getShowLandingPage(): boolean {
    if (this.model.providers === null || this.model.providers === undefined) {
      return false;
    }
    if (this.model.providers.length === 1 && this.supportsLandingPage(this.model.providers[0])) {
      return true;
    } else {
      return false;
    }
  }

  showLandingPageChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.model.skipLandingPage = true;
    } else {
      this.model.skipLandingPage = false;
    }
    this.basicNode.onChange();
  }

  providerChanged(event: MatCheckboxChange, ind: number) {
    if (this.model.providers === null || this.model.providers === undefined) {
      this.model.providers = [];
    }
    if (event.checked) {
      this.model.providers.push(this.mobileInvoiceInfoSettings.providers[ind].paymentProvider);
      this.reorderProviders();
    } else {
      const idx = this.model.providers.findIndex(provider => provider === this.mobileInvoiceInfoSettings.providers[ind].paymentProvider);
      this.model.providers.splice(idx, 1);
    }
    this.checkShowLandingPage();
    this.basicNode.onChange();
  }

  invalidProviderChanged(event: MatCheckboxChange, provider: string) {
    if (event.checked) {
      this.model.providers.push(provider);
    } else {
      const idx = this.model.providers.findIndex(modelProvider => modelProvider === provider);
      this.model.providers.splice(idx, 1);
    }
    this.basicNode.onChange();
  }

  onDateChanged($event: MatDatepickerInputEvent<Date>, oldValue: LocalDateTime) {
    if ($event.value) {
      const newDate = LocalDate.from(nativeJs($event.value));
      if (oldValue) {
        return LocalDateTime.of(newDate, LocalTime.from(oldValue));
      } else {
        return LocalDateTime.of(newDate, LocalTime.MAX);
      }
    } else {
      return oldValue;
    }
  }

  onTimeChange($event, oldValue: LocalDateTime) {
    if (this.expiryDate) {
      if ($event.target.value) {
        const newTime = LocalTime.parse($event.target.value);
        return LocalDateTime.of(LocalDate.from(oldValue), newTime);
      } else {
        return oldValue;
      }
    }
  }

  onChange() {
    this.model.expiryUTC = DetailsMobileInvoiceComponent.transformToEpochNumber(this.localDateTime);
    this.basicNode.onChange();
  }

  clearDateTime(event) {
    event.stopPropagation();
    this.expiryDate = null;
    this.expiryTime = null;
  }

}
