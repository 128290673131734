<div class="keyword-title">Keywords / Regular Expressions</div>
<div class="regex-help">
  <div class="spacer">
    <a (click)="showRegexHelp()" href="javascript:void(0);" class="regex-help-link">
      What are "Regular Expressions"?
    </a>
  </div>
  <div>
    <a (click)="showRegexExamples()" href="javascript:void(0);" class="regex-examples-link">
      Regular Expression Examples
    </a>
  </div>
</div>
<div *ngFor="let kwData of keywordNode.keywordsData; let i = index">
  <div>
    <mat-divider></mat-divider>
    <div class="row">
      <button [matMenuTriggerFor]="menu" class="menu" mat-stroked-button data-testid="keywordType">
        <mat-icon>{{ getKeywordTypeIcon(kwData.keywordMatch) }}</mat-icon>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-checkbox class="checkbox-bottom" (change)="onKeywordChange()" [(ngModel)]="kwData.keywordMatch.isCaseSensitive" matTooltip="Keyword regular expression will be matched case sensitive">
      Case sensitive
      </mat-checkbox>
    </div>
    
    <div class="text-area">
      <mat-form-field class="keyword-width">
        <!--suppress HtmlFormInputWithoutLabel -->
        <input
          (keyup)="onKeywordChange()"
          [(ngModel)]="kwData.keywordMatch.keyword"
          matInput
          data-testid="keywordInput"
          placeholder="Keyword"
          required type="text" />
      <mat-hint class="keyword-matched-context" *ngIf="keywordNode.getStartsWithRegexContextVariables()?.includes(i)" align="end">
            <mat-icon class="keyword-matched-icon" data-testid="startsWithMatchIcon">functions</mat-icon>
             Starts with
             Context variable
      </mat-hint>
      <mat-hint class="keyword-matched-context" *ngIf="keywordNode.getEndsWithRegexContextVariabes()?.includes(i)" align="end">
          <mat-icon class="keyword-matched-icon" data-testid="endsWithMatchIcon">functions</mat-icon>
           Ends with
           Context variable
      </mat-hint>
      <mat-hint class="keyword-matched-context" *ngIf="keywordNode.getEqualsRegexContextVariables()?.includes(i)" align="end">
        <mat-icon class="keyword-matched-icon" data-testid="equalsMatchIcon">functions</mat-icon>
         Equals to
         Context variable
    </mat-hint>
        <mat-hint class="keyword-matched-context" *ngIf="keywordNode.getRegexContextVariables()?.includes(i)" align="end">
          <mat-icon class="keyword-matched-icon" data-testid="variableMatchIcon">functions</mat-icon>
           Context variable
        </mat-hint>
        <mat-hint class="keyword-matched-primary" *ngIf="keywordNode.getPrimaryTestMatch() == i && !keywordNode.getRegexContextVariables()?.includes(i)" align="end">
          <mat-icon class="keyword-matched-icon" data-testid="keywordMatchIcon">check</mat-icon>
          Matched
        </mat-hint>
        <mat-hint class="keyword-matched-secondary" *ngIf="keywordNode.getSecondaryTestMatches()?.includes(i) &&  !keywordNode.getRegexContextVariables()?.includes(i)" align="end">
          <mat-icon class="keyword-matched-icon" data-testid="keywordSecondaryMatchIcon">check</mat-icon>
          Matched (not used)
        </mat-hint>
      </mat-form-field>
    </div>

    <mat-menu #menu="matMenu">
      <button (click)="changeKeywordType(kwData.keywordMatch, false)"
              mat-menu-item [attr.data-testid]="'typeMenu-text'">
        <mat-icon>{{ getTextIcon() }}</mat-icon>
        <span>Text</span>
      </button>
      <button (click)="changeKeywordType(kwData.keywordMatch, true)"
              mat-menu-item [attr.data-testid]="'typeMenu-regex'">
        <mat-icon>{{ getRegexIcon() }}</mat-icon>
        <span>Regular Expression</span>
      </button>
    </mat-menu>
  </div>
</div>

<mat-divider></mat-divider>

<div class="test-area">
    <div class="keyword-title">Testing</div>
    <mat-form-field class="full-width">
      <!--suppress HtmlFormInputWithoutLabel -->
      <input matInput
             placeholder="Enter phrase to test"
             [value]="getTestString()"
             type="text"
             (keyup)="testKeywordMatch($event)"
             data-testid="keywordTestInput"/>
    </mat-form-field>
    <div class="match-note">
      <b>Note:</b>
      Regular expression testing in LINK Flow Designer and the actual matching of user input
      in the conversation might differ when using the following features \a, \e, \ca, \cz,
      \b, \B, \A, \Z, \z, \Q…\E, \s and modifiers (?s), (?m), (?x), (?-ismxn).
    </div>
</div>
