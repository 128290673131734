import { FreeTextComponent } from '../../node-shared-components/free-text/free-text.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NodeHelper } from '../../../../../services/node-helper.service';
import { AbstractNode, RichCardNode } from 'flow-model';
import { UserProfileService } from '../../../../../services/user-profile.service';
import { SuggestionEnabledNode } from '../suggestion-enabled-node';
import { MediaComponent } from '../../node-shared-components/media-component/media.component';
import { ChannelFeatureset, MediaFeatureset, SuggestionsNumberFeatureset } from 'conversation-domain';
import { ReteOutput } from '../../../rete/controls/extended-output';
import { SnackBarService } from '../../../../../services/snackbar.service';
import { CarouselNodeComponent } from '../carousel-node/carousel-node.component';

@Component({
  selector: 'app-rich-card-node',
  templateUrl: './rich-card-node.component.html',
  styleUrls: ['./rich-card-node.component.css']
})

export class RichCardNodeComponent extends SuggestionEnabledNode implements OnInit {
  private TITLE_REQUIRED = 'Title is required!';
  private DESCRIPTION_REQUIRED = 'Description is required';
  private MEDIA_REQUIRED = 'Media is required';
  private ADDITIONAL_ELEMENT_REQUIRED = 'Missing at least one of the elements: ';
  private TITLE_LONGER_THAN_ALLOWED = 'Title cannot contain more characters than: ';
  private NO_TITLE_ALLOWED = 'No title is allowed';
  private DESCRIPTION_LONGER_THAN_ALLOWED = 'Description cannot contain more characters than: ';
  private NO_DESCRIPTION_ALLOWED = 'No description is allowed';
  private NO_CARDS = 'Your channel configuration does not allow rich cards';

  model: RichCardNode = null;
  mediaFeatureset: MediaFeatureset = null;
  isPartOfCarousel = false;

  @ViewChild(MediaComponent)
  mediaComponent: MediaComponent;
  @ViewChild(FreeTextComponent)
  freeTextComponent: FreeTextComponent;

  constructor(@Inject(UserProfileService) userProfileService: UserProfileService,
              @Inject(SnackBarService) private snackBarService: SnackBarService) {
    super(userProfileService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.addDirectOutput();
    this.model = NodeHelper.newRichCardNode(this.getId(), this.getPosition());
  }

  protected withFeatureset(featureSet: ChannelFeatureset) {
    super.withFeatureset(featureSet);
    this.mediaFeatureset = featureSet.mediaMessageFeatureset.mediaFeatureset;
  }

  protected getSuggestionsNumberFeatureset(): SuggestionsNumberFeatureset {
    if (this.isRichCardAvailable()) {
      return this.featureSet.richCardFeatureset.suggestionsNumberFeaturesetWithinCard;
    }
  }

  // decides which outputs should exists and defines the optional flags
  // this methods overrides the predefined defaults from BasicNode/SuggestionEnabledNode
  public onDisabledFreeText() {
    if (!this.directOutput && !this.isPartOfCarousel) {
      const isOptionalDirectOutput = this.hasSuggestions();
      this.addDirectOutput(isOptionalDirectOutput);
    }
  }

  public onAddedSuggestion() {
    if (this.directOutput) {
      this.directOutput.optional = true;
    }
    this.notifyCarouselOfSuggestions();
  }

  public onRemovedSuggestion() {
    if (this.directOutput && !this.freeTextOutput && !this.hasSuggestions()) {
      this.directOutput.optional = false;
    }
    this.notifyCarouselOfSuggestions();
  }

  private notifyCarouselOfSuggestions() {
    if (this.isPartOfCarousel) {
      this.directInput.connections.forEach(connection => {
        const output = connection.output as ReteOutput;
        if (output.isCarouselOutput()) {
          (output.getNodeComponent() as CarouselNodeComponent).suggestionsChanged();
        }
      });
    }
  }

  public inputConnectionUpdated() {
    this.isPartOfCarousel = false;
    if (this.directInput.hasConnection()) {
      let anyCarouselConnected = false;
      this.directInput.connections.forEach(
        connection => anyCarouselConnected = anyCarouselConnected || (connection.output as ReteOutput).isCarouselOutput()
      );
      if (anyCarouselConnected) {
        let anyConnectionRemoved = false;
        this.directInput.connections.forEach(connection => {
          if (!(connection.output as ReteOutput).isCarouselOutput()) {
            anyConnectionRemoved = true;
            this.editor.removeConnection(connection);
          }
        });

        if (anyConnectionRemoved) {
          this.snackBarService.showWarning('Rich Card can only be connected to either Carousels or other messages!');
        }

        this.isPartOfCarousel = true;
        this.removeFreeTextOutput();
        this.removeDirectOutput();
      }
    } else {
      if (!this.freeTextComponent || !this.freeTextComponent.freeTextEnabled) {
        this.onDisabledFreeText();
      }
    }

  }

  loadFromModel(node: AbstractNode) {
    this.model = node as RichCardNode;
    if (!this.model.title) {
      this.model.title = NodeHelper.emptyTemplate();
    }
    if (!this.model.description) {
      this.model.description = NodeHelper.emptyTemplate();
    }
    this.loadSuggestions(this.model.suggestions);
    this.freeTextComponent.loadFromModel(this.model.onFreeText);
  }

  getModelObject(): AbstractNode {
    this.model.position = this.getPosition();
    this.model.nextMessageId = this.getDirectlyConnectedNodeId();
    this.model.onFreeText = this.getOnFreeText();
    this.model.suggestions = this.getSuggestionModel();
    return this.model;
  }

  addValidationErrors(errorReasons: Array<string>) {
    errorReasons.forEach((msg) => this.addErrorReason(msg));
    if (errorReasons && errorReasons.length > 0) {
      this.editor.trigger('flowchanged');
      this.updateReteNode();
    }
  }

  private isRichCardAvailable(): boolean {
    if (this.featureSet && this.featureSet.richCardFeatureset && this.featureSet.richCardFeatureset.titleLength && this.featureSet.richCardFeatureset.titleLength > 0) {
      return true;
    }
    return false;
  }

  verifyNodeSpecific() {
    if (!this.isRichCardAvailable()) {
      this.addErrorReason(this.NO_CARDS);
    } else {
      super.verifyNodeSpecific();
      this.verifyFields();
    }
    if (this.mediaComponent) {
      this.mediaComponent.withMediaFeaturset(this.getMediaFeatureset());
      this.mediaComponent.mediaUrl = this.model.mediaUrl;
      this.mediaComponent.thumbnailUrl = this.model.thumbnailUrl;
      this.mediaComponent.verify();
    }
  }

  protected verifyOutgoingEdge() {
    if (!this.isPartOfCarousel) {
      super.verifyOutgoingEdge();
    }
  }

  private getMediaFeatureset(): MediaFeatureset {
    if (this.featureSet && this.featureSet.richCardFeatureset) {
      return this.featureSet.richCardFeatureset.mediaFeatureset;
    }
    return null;
  }

  verifyFields() {
    if (this.featureSet && this.featureSet.richCardFeatureset) {
      if (this.featureSet.richCardFeatureset.mandatoryFieldsFeatureset.requiredFields.length > 0) {
        const mandatoryFields = this.featureSet.richCardFeatureset.mandatoryFieldsFeatureset.requiredFields;
        const minOneOfOptionalFields: string[] = this.featureSet.richCardFeatureset.mandatoryFieldsFeatureset.minOneOfOptionalFields;

        // checking in the mandatory list of fields
        if (!this.model.title['en'] && mandatoryFields.includes('title')) {
          this.addErrorReason(this.TITLE_REQUIRED);
        }
        if (!this.model.description['en'] && mandatoryFields.includes('description')) {
          this.addErrorReason(this.DESCRIPTION_REQUIRED);
        }
        if (!this.model.mediaUrl && mandatoryFields.includes('cardMedia')) {
          this.addErrorReason(this.MEDIA_REQUIRED);
        }

        if (minOneOfOptionalFields.length > 0) {
          let countOfOfOptionalfields = 0;
          if (this.model.title['en'] && minOneOfOptionalFields.includes('title')) {
            countOfOfOptionalfields++;
          }
          if (this.model.description['en'] && minOneOfOptionalFields.includes('description')) {
            countOfOfOptionalfields++;
          }
          if (this.model.mediaUrl && minOneOfOptionalFields.includes('cardMedia')) {
            countOfOfOptionalfields++;
          }
          if (this.suggestions && this.suggestions.length > 0 && minOneOfOptionalFields.includes('suggestions')) {
            countOfOfOptionalfields++;
          }
          if (countOfOfOptionalfields === 0 && minOneOfOptionalFields.length > 0) {
            this.addErrorReason(this.ADDITIONAL_ELEMENT_REQUIRED + minOneOfOptionalFields.join(', '));
          }
        }
      }

      if (this.model.title['en'] && this.model.title['en'].length > 0 && this.featureSet.richCardFeatureset.titleLength === 0) {
        this.addErrorReason(this.NO_TITLE_ALLOWED);
      } else if (this.model.title['en'] && this.model.title['en'].length > this.featureSet.richCardFeatureset.titleLength) {
        this.addErrorReason(this.TITLE_LONGER_THAN_ALLOWED + this.featureSet.richCardFeatureset.titleLength);
      }

      if (this.model.description['en'] && this.model.description['en'].length > 0 && this.featureSet.richCardFeatureset.descriptionLength === 0) {
        this.addErrorReason(this.NO_DESCRIPTION_ALLOWED);
      } else if (this.model.description['en'] && this.model.description['en'].length > this.featureSet.richCardFeatureset.descriptionLength) {
        this.addErrorReason(this.DESCRIPTION_LONGER_THAN_ALLOWED + this.featureSet.richCardFeatureset.descriptionLength);
      }
    }
  }
}
