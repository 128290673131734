/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i2 from "@angular/material/icon";
import * as i3 from "../../../../socket/socket.component.ngfactory";
import * as i4 from "../../../../socket/socket.component";
import * as i5 from "./mobile-invoice-status-outputs.component";
var styles_MobileInvoiceStatusOutputComponent = [];
var RenderType_MobileInvoiceStatusOutputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MobileInvoiceStatusOutputComponent, data: {} });
export { RenderType_MobileInvoiceStatusOutputComponent as RenderType_MobileInvoiceStatusOutputComponent };
export function View_MobileInvoiceStatusOutputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "mobile-status-output"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mobile-status-output-inline-button delete-button mat-icon notranslate"], ["data-testid", "deleteStatusOutput"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteStatusOutput(_co.output) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(2, 9158656, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null], [2, i2.MAT_ICON_LOCATION]], null, null), (_l()(), i0.ɵted(-1, 0, [" cancel "])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "fill_space"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "default-output-text"], ["data-testid", "defaultOutputText"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-socket", [["data-testid", "StatusOutputSocket"], ["type", "output"]], null, null, null, i3.View_SocketComponent_0, i3.RenderType_SocketComponent)), i0.ɵdid(8, 4243456, null, 0, i4.SocketComponent, [], { type: [0, "type"], inputOutput: [1, "inputOutput"], bindSocket: [2, "bindSocket"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_3 = "output"; var currVal_4 = _co.output; var currVal_5 = _co.bindSocket; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).inline; var currVal_1 = (((i0.ɵnov(_v, 2).color !== "primary") && (i0.ɵnov(_v, 2).color !== "accent")) && (i0.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.output.name; _ck(_v, 6, 0, currVal_2); }); }
export function View_MobileInvoiceStatusOutputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-invoice-status-outputs", [], null, null, null, View_MobileInvoiceStatusOutputComponent_0, RenderType_MobileInvoiceStatusOutputComponent)), i0.ɵdid(1, 49152, null, 0, i5.MobileInvoiceStatusOutputComponent, [], null, null)], null, null); }
var MobileInvoiceStatusOutputComponentNgFactory = i0.ɵccf("app-invoice-status-outputs", i5.MobileInvoiceStatusOutputComponent, View_MobileInvoiceStatusOutputComponent_Host_0, { output: "output", bindSocket: "bindSocket" }, { delete: "delete" }, []);
export { MobileInvoiceStatusOutputComponentNgFactory as MobileInvoiceStatusOutputComponentNgFactory };
