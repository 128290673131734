import { NodeDetailsService } from './../../../services/node-details.service';
import { Component, OnInit } from '@angular/core';
import { KeywordsNodeComponent } from '../../editor/renderer/node/keywords-node/keywords-node.component';
import { environment } from 'src/environments/environment';
import { KeywordsNode } from 'flow-model';

@Component({
  selector: 'app-details-keywords',
  templateUrl: './details-keywords.component.html',
  styleUrls: ['./details-keywords.component.css']
})
export class DetailsKeywordsComponent implements OnInit {

  keywordsNodeComponent: KeywordsNodeComponent;
  model: KeywordsNode = null;
  env = environment;

  constructor(private nodeDetailsService: NodeDetailsService) { }

  ngOnInit(): void {
    this.nodeDetailsService.node$.subscribe(
      nodeComponent => {
        this.keywordsNodeComponent = nodeComponent as KeywordsNodeComponent;
        this.model = (nodeComponent instanceof KeywordsNodeComponent) ? nodeComponent.model : null;
      });
  }
}
