import { sha256 } from 'js-sha256';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "@angular/router";
const TOKEN = 'TOKEN';
const ACCOUNT_NAME = 'ACCOUNT_NAME';
const USER_NAME = 'USER_NAME';
export class LoginService {
    constructor(httpService, router) {
        this.httpService = httpService;
        this.router = router;
    }
    static authenticated(auth, username) {
        localStorage.setItem(TOKEN, auth.token);
        localStorage.setItem(ACCOUNT_NAME, auth.accountName);
        localStorage.setItem(USER_NAME, username);
    }
    static getToken() {
        return localStorage.getItem(TOKEN);
    }
    static getUserName() {
        return localStorage.getItem(USER_NAME);
    }
    static getAccountName() {
        return localStorage.getItem(ACCOUNT_NAME);
    }
    static isLoggedIn() {
        return localStorage.getItem(TOKEN) != null;
    }
    doLogin(userName, password) {
        return this.httpService.sendAuthRequest(userName, sha256(password));
    }
    logout(goToLogin = true) {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(USER_NAME);
        localStorage.removeItem(ACCOUNT_NAME);
        if (goToLogin) {
            this.router.navigate(['/login']);
        }
    }
}
LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpService), i0.inject(i2.Router)); }, token: LoginService, providedIn: "root" });
