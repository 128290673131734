<div [contextMenu]="basicMenu"
     data-testid="EndNode"
     class="node"
     [ngClass]="{'selected': selected, error: !isValid}">

  <div class="title">
    <app-socket *ngIf="directInput" [inputOutput]="directInput" [bindSocket]="bindSocket" type="input"></app-socket>
    <div class="text" data-testid="EndNode-node-name" matTooltip="Your flow ends here">End Flow</div>
    <div class="status">
      <mat-icon data-testid="nodeStatusIcon" [ngClass]="isValid ? 'ok' : 'error'" [matTooltip]="validityErrorMessages">
        error_outline
      </mat-icon>
    </div>
  </div>

  <context-menu>
    <ng-template contextMenuItem (execute)="deleteNode()">
      Delete Node
    </ng-template>
  </context-menu>
</div>
