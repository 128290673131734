import * as i0 from "@angular/core";
export class MediaValidationService {
    constructor() {
        this.MEDIA_NOT_ALLOWED = 'Your channel configuration does not allow usage of media';
        this.MEDIA_URL_IS_MISSING = 'The media URL is required';
        this.MEDIA_URL_NO_PROTOCOL = 'The media url must start with https://';
        this.MEDIA_URL_DEAD_LINK = 'No valid media could be found using the given URL';
        this.MEDIA_URL_INVALID_MIME_TYPE = 'The media must be of type: ';
        this.MEDIA_SIZE_EXCEEDS_MAX_ALLOWED = 'The media size exceeds the maximum allowed size (in bytes) of : ';
        this.THUMBNAIL_NOT_ALLOWED = 'Thumbnails are not supported by WhatsApp';
        this.THUMBNAIL_URL_NO_PROTOCOL = 'The thumbnail url must start with https://';
        this.THUMBNAIL_URL_DEAD_LINK = 'No valid thumbnail image could be found using the given URL';
        this.THUMBNAIL_URL_INVALID_MIME_TYPE = 'The thumbnail must be of type: ';
        this.THUMBNAIL_SIZE_EXCEEDS_MAX_ALLOWED = 'The thumbnail size exceeds the maximum allowed size (in bytes) of : ';
        this.THUMBNAIL_REQUIRED = 'A thumbnail is required for your selected type of media';
    }
    validate(mediaUrlInfo, thumbnailMediaUrlInfo, mediaUrlRequired = true, mediaFeatureset) {
        const errorMessages = new Array();
        if (mediaFeatureset === null) {
            return errorMessages;
        }
        if (!this.isMediaAllowedAtAll(mediaFeatureset)) {
            errorMessages.push(this.MEDIA_NOT_ALLOWED);
        }
        else if (mediaUrlRequired && !mediaUrlInfo.hasUrl()) {
            errorMessages.push(this.MEDIA_URL_IS_MISSING);
        }
        else if (mediaUrlInfo.hasExceptionError()) {
            errorMessages.push(mediaUrlInfo.mediaInfo.exceptionError);
        }
        else if (mediaUrlInfo.hasUrl() && !mediaUrlInfo.isLinkValid()) {
            errorMessages.push(this.MEDIA_URL_DEAD_LINK);
        }
        else if (mediaUrlInfo.hasUrl() && !this.isUrlProtocolValid(mediaUrlInfo.url)) {
            errorMessages.push(this.MEDIA_URL_NO_PROTOCOL);
        }
        else if (mediaUrlInfo.hasUrl() && !this.isMediaMimeTypeValid(mediaFeatureset, mediaUrlInfo.mediaInfo)) {
            errorMessages.push(this.MEDIA_URL_INVALID_MIME_TYPE + this.getAllowedMediaTypes(mediaFeatureset).join(', ').toLowerCase());
        }
        else if (mediaUrlInfo.hasUrl() && !this.isMediaSizeValid(mediaUrlInfo.mediaInfo.sizeInBytes, mediaFeatureset)) {
            errorMessages.push(this.MEDIA_SIZE_EXCEEDS_MAX_ALLOWED + this.getAllowedMaxMediaSizeInBytes(mediaFeatureset));
        }
        else {
            if (thumbnailMediaUrlInfo && thumbnailMediaUrlInfo.url) {
                // thumbnail is set
                if (!this.isThumbnailAllowedAtAll(mediaFeatureset)) {
                    errorMessages.push(this.THUMBNAIL_NOT_ALLOWED);
                }
                else if (!this.isUrlProtocolValid(thumbnailMediaUrlInfo.url)) {
                    errorMessages.push(this.THUMBNAIL_URL_NO_PROTOCOL);
                }
                else if (!thumbnailMediaUrlInfo.mediaInfo.linkValid) {
                    errorMessages.push(this.THUMBNAIL_URL_DEAD_LINK);
                }
                else if (!this.isThumbnailMimeTypeValid(mediaFeatureset, thumbnailMediaUrlInfo.mediaInfo)) {
                    errorMessages.push(this.THUMBNAIL_URL_INVALID_MIME_TYPE + this.getAllowedThumbnailTypes(mediaFeatureset).join(', ').toLowerCase());
                }
                else if (!this.isMediaSizeValid(thumbnailMediaUrlInfo.mediaInfo.sizeInBytes, mediaFeatureset)) {
                    errorMessages.push(this.THUMBNAIL_SIZE_EXCEEDS_MAX_ALLOWED + this.getAllowedMaxMediaSizeInBytes(mediaFeatureset));
                }
            }
            else {
                // thumbnail is not set
                if (this.isThumbnailRequiredForSelectedMediaType(mediaFeatureset, mediaUrlInfo.mediaInfo)) {
                    errorMessages.push(this.THUMBNAIL_REQUIRED);
                }
            }
        }
        return errorMessages;
    }
    isUrlProtocolValid(url) {
        return url.toLocaleLowerCase().startsWith('https://');
    }
    isMediaAllowedAtAll(mediaFeatureset) {
        const allowedTypes = this.getAllowedMediaTypes(mediaFeatureset);
        return allowedTypes && allowedTypes.length > 0;
    }
    getAllowedMediaTypes(mediaFeatureset) {
        return mediaFeatureset ? mediaFeatureset.mediaTypes || [] : ['IMAGE', 'VIDEO', 'AUDIO'];
    }
    isThumbnailAllowedAtAll(mediaFeatureset) {
        const allowedTypes = this.getAllowedThumbnailTypes(mediaFeatureset);
        return allowedTypes && allowedTypes.length > 0;
    }
    getAllowedThumbnailTypes(mediaFeatureset) {
        return mediaFeatureset ? mediaFeatureset.thumbnailTypes || [] : ['IMAGE'];
    }
    isMediaMimeTypeValid(mediaFeatureset, mediaInfo) {
        const allowedTypes = this.getAllowedMediaTypes(mediaFeatureset);
        return this.isFeaturesetTypeMatching(allowedTypes, mediaInfo.mimeType);
    }
    getAllowedMaxMediaSizeInBytes(mediaFeatureset) {
        return 1024 * 1024 * (mediaFeatureset ? mediaFeatureset.maxFileSizeInMB || 10 : 10);
    }
    isMediaSizeValid(sizeInBytes, mediaFeatureset) {
        return sizeInBytes <= this.getAllowedMaxMediaSizeInBytes(mediaFeatureset);
    }
    isFeaturesetTypeMatching(featuresetTypes, mimeType) {
        if (!featuresetTypes || featuresetTypes.length === 0) {
            return true;
        }
        return featuresetTypes.some((type) => {
            switch (type) {
                case 'IMAGE':
                    return this.isMimeType(mimeType, 'image/');
                case 'VIDEO':
                    return this.isMimeType(mimeType, 'video/');
                case 'AUDIO':
                    return this.isMimeType(mimeType, 'audio/');
                default:
                    return false;
            }
        });
    }
    isMimeType(mimeType, type) {
        return mimeType && mimeType.startsWith(type);
    }
    isThumbnailMimeTypeValid(mediaFeatureset, mediaInfo) {
        const allowedTypes = this.getAllowedThumbnailTypes(mediaFeatureset);
        return this.isFeaturesetTypeMatching(allowedTypes, mediaInfo.mimeType);
    }
    isThumbnailRequiredForSelectedMediaType(mediaFeatureset, mediaInfo) {
        const mediaTypesRequiringThumbnails = mediaFeatureset.mediaTypesRequiringThumbnails || [];
        if (!mediaTypesRequiringThumbnails || mediaTypesRequiringThumbnails.length === 0) {
            return false;
        }
        return this.isFeaturesetTypeMatching(mediaTypesRequiringThumbnails, mediaInfo.mimeType);
    }
}
MediaValidationService.ngInjectableDef = i0.defineInjectable({ factory: function MediaValidationService_Factory() { return new MediaValidationService(); }, token: MediaValidationService, providedIn: "root" });
