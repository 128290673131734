import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { delay, flatMap, map , concatMap, retryWhen } from 'rxjs/operators';
import * as download from 'downloadjs';
import { Observable, of, Subscriber,  throwError } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { AbstractNode, CampaignInfo, CampaignStatistics, Flow, FlowDefinition, LoginResult, MediaInfo } from 'flow-model';
import { HttpRequestMessageNode, MobileInvoiceInfoSettings, MobileInvoiceNode, ReportFilter, ReportResult, Country, AccountSettings } from 'flow-model';
import { ChannelFeatureset, MobileInvoice } from 'conversation-domain';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private retryCount = 4;

  private retryWaitMilliSeconds = 1000;

  private TARGET_MAPS = [
    {target: Flow, properties: {flowDefinition: FlowDefinition}},
    {target: FlowDefinition, properties: {messages: AbstractNode}}
    ];

  constructor(private http: HttpClient) {
  }

  private static createHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  sendAuthRequest(userName: string, passwordHash: string) {
    return this.http
      .post<LoginResult>(`${environment.backendUrl}/login`,
        {
          username: userName,
          passwordHash: passwordHash
        });
  }

  loadFeatureset(): Observable<ChannelFeatureset> {
    const headers = HttpService.createHeaders();
    return this.http
      .get(`${environment.backendUrl}/featureset`, {headers})
      .pipe(map(response => plainToClass(ChannelFeatureset, response)));
  }

  sendTestHttpRequestMessage(httpRequestMessageNode: HttpRequestMessageNode) {
    const headers = HttpService.createHeaders();
    return this.http.post(`${environment.backendUrl}/node`, httpRequestMessageNode, {headers, responseType: 'text'});
  }

  sendTestMobileInvoiceNode(mobileInvoiceNode: MobileInvoiceNode) {
    const headers = HttpService.createHeaders();
    return this.http.post(`${environment.backendUrl}/mobile-invoice-node`, mobileInvoiceNode, {headers, responseType: 'text'});
  }

  loadSelectedFeatureset(channels: string): Observable<ChannelFeatureset> {
    const headers = HttpService.createHeaders();
    let params = new HttpParams();
    params = params.append('channels', channels);
    return this.http
      .get(`${environment.backendUrl}/channelFeatureset`, {headers: headers, params: params})
      .pipe(map(response => plainToClass(ChannelFeatureset, response)));
  }

  loadChannels(): Observable<String[]> {
    const headers = HttpService.createHeaders();
    return this.http
      .get(`${environment.backendUrl}/channels`, {headers})
      .pipe(map(response => {
          if ((<String[]> response).length) {
            return <String[]> response;
          }
          return new Array();
        }));
  }

  loadUserRole(): Observable<String> {
    const headers = HttpService.createHeaders();
    return this.http
      .get(`${environment.backendUrl}/userrole`, {headers, responseType: 'text'}).pipe(map(response => plainToClass(String, response)));
  }

  loadMobileInvoice(): Observable<MobileInvoice> {
    const headers = HttpService.createHeaders();
    return this.http
      .get(`${environment.backendUrl}/mobileInvoice`, {headers})
      .pipe(map(response => plainToClass(MobileInvoice, response)));
  }

  loadMobileInvoiceInfoSettings(): Observable<MobileInvoiceInfoSettings> {
    const headers = HttpService.createHeaders();

    return this.http
      .get(`${environment.backendUrl}/mobileinvoiceinfosettings`, {headers})
      .pipe(map(response => plainToClass(MobileInvoiceInfoSettings, response)));
  }

  loadAccountSettings(): Observable<AccountSettings> {
    const headers = HttpService.createHeaders();
    return this.http.get(`${environment.backendUrl}/accountsettings`, {headers})
    .pipe(map(response => plainToClass(AccountSettings, response)));
  }

  saveFlow(flowModel: string) {
    const headers = HttpService.createHeaders();
    return this.http.post(`${environment.backendUrl}/flow`, flowModel, {headers, responseType: 'text'});
  }

  loadFlow(flowId: string): Observable<Flow> {
    const headers = HttpService.createHeaders();
    return this.http
      .get(`${environment.backendUrl}/flow/${flowId}`, {headers})
      .pipe(map(response => plainToClass(Flow, response, { targetMaps: this.TARGET_MAPS })));
  }

  loadFlowList() {
    const headers = HttpService.createHeaders();
    return this.http.get(`${environment.backendUrl}/flows`, {headers, responseType: 'text'});
  }

  deleteFlow(flowId: string) {
    const headers = HttpService.createHeaders();
    return this.http.delete(`${environment.backendUrl}/flow/${flowId}`, {headers, responseType: 'text'});
  }

  forceTriggerFlow(msisdn: string, campaignData: Map<any, any>, flowId: string, channels: string, message: string, hideFreeText: boolean) {
    const csvFile = this.createCsvFile(campaignData, msisdn);
    return this.uploadShortCampaign(csvFile, `${msisdn}.csv`, flowId)
      .pipe(delay(1000), flatMap((newCampaignId: string) =>
        this.forceTriggerCampaign(newCampaignId, channels, message, hideFreeText)
      ));
  }

  scheduleTriggerFlow(msisdn: string, campaignData: Map<any, any>, flowId: string, startTime: string, channels: string, finalMessage: string, hideFreeText: boolean) {
    const csvFile = this.createCsvFile(campaignData, msisdn);
    return this.uploadShortCampaign(csvFile, `${msisdn}.csv`, flowId)
      .pipe(delay(1000), flatMap((newCampaignId: string) =>
        this.scheduleTriggerCampaign(newCampaignId, startTime, channels, finalMessage, hideFreeText)
      ));
  }

  scheduleTriggerCampaign(id: string, startTime: string, channels: string, finalMessage: string, hideFreeText: boolean): any {
    let url = `${environment.backendUrl}/campaigns/${id}/schedule?startTime=` + startTime;
    if (channels) {
      url = url + '&channels=' + channels;
    }
    if (hideFreeText) {
      url = url + '&hideFreeText=' + hideFreeText;
    }
    return this.http.post(url, finalMessage).pipe(
      retryWhen(errorObject =>
        errorObject.pipe(
          concatMap((error, count) => {
            if (count <= this.retryCount && error.status === 409) {
              return of(error);
            }
            return throwError(error);
          }),
          delay(this.retryWaitMilliSeconds)
        )
      )
    );
  }

  forceTriggerCampaign(id: string, channels: string, finalMessage: string, hideFreeText: boolean) {
    let url = `${environment.backendUrl}/campaigns/${id}/start?enforceStart=true`;
    if (channels) {
      url = url + '&channels=' + channels;
    }
    if (hideFreeText) {
      url = url + '&hideFreeText=' + hideFreeText;
    }
    return this.http.post(url, finalMessage).pipe(
      retryWhen(errorObject =>
        errorObject.pipe(
          concatMap((error, count) => {
            if (count <= this.retryCount && error.status === 409) {
              return of(error);
            }
            return throwError(error);
          }),
          delay(this.retryWaitMilliSeconds)
        )
      )
    );
  }

  triggerFlow(msisdn: string, campaignData: Map<any, any>, flowId: string, channels: string, hideFreeText: boolean) {
    const csvFile = this.createCsvFile(campaignData, msisdn);
    return this.uploadShortCampaign(csvFile, `${msisdn}.csv`, flowId)
      .pipe(delay(1000), flatMap((newCampaignId: string) =>
        this.triggerCampaign(newCampaignId, channels, hideFreeText)
      ));
  }

  private createCsvFile(campaignData: Map<any, any>, msisdn: string) {
    let variableNames;
    let variableValues;

    if (campaignData.size > 0) {
      variableNames = ';' + Array.from(campaignData.keys()).join(';');
      variableValues = ';' + Array.from(campaignData.values()).join(';');
    } else {
      variableNames = '';
      variableValues = '';
    }
    const csvFile = new Blob(
      [`msisdn${variableNames}\n${msisdn}${variableValues}`],
      { type: 'text/csv' });
    return csvFile;
  }

  uploadCampaign(file: Blob, name: string, flowId: string) {
    const formData = new FormData();
    formData.append('flowId', flowId);
    formData.append('file', file, name);
    return this.http.post(`${environment.backendUrl}/campaigns`, formData, {responseType: 'text'});
  }

  uploadShortCampaign(file: Blob, name: string, flowId: string) {
    const formData = new FormData();
    formData.append('flowId', flowId);
    formData.append('file', file, name);
    return this.http.post(`${environment.backendUrl}/shortcampaigns`, formData, {responseType: 'text'});
  }

  deleteCampaign(id: string) {
    return this.http.delete(`${environment.backendUrl}/campaigns/${id}`);
  }

  triggerCampaign(id: string, channels: string, hideFreeText: boolean) {
    const formData = new FormData();
    formData.append('channels', channels);
    if (hideFreeText) {
      formData.append('hideFreeText', 'true');
    }
    return this.http.post(`${environment.backendUrl}/campaigns/${id}/start`, formData, {}).pipe(
      retryWhen(errorObject =>
        errorObject.pipe(
          concatMap((error, count) => {
            if (count <= this.retryCount && error.status === 409) {
              return of(error);
            }
            return throwError(error);
          }),
          delay(this.retryWaitMilliSeconds)
        )
      )
    );
  }

  loadCampaignPage(lastCampaignId: string, scanIndexForward: boolean, limit: number) {
    let url = `${environment.backendUrl}/campaignitems?`;
    let paramsSet = false;
    if (lastCampaignId) {
      url = url + 'lastCampaignId=' + lastCampaignId;
      paramsSet =  true;
    }
    if (scanIndexForward !== null && scanIndexForward !== undefined) {
      if (paramsSet) {
        url = url + '&';
      }
      url = url + 'scanIndexForward=' + scanIndexForward;
      paramsSet = true;
    }
    if (limit) {
      if (paramsSet) {
        url = url + '&';
      }
      url = url + 'limit=' + limit;
    }
    return this.http.get(url, {responseType: 'text'});
  }

  loadCampaigns() {
    return this.http.get(`${environment.backendUrl}/campaigns`, {responseType: 'text'});
  }

  downloadCampaignResult(campaign: CampaignInfo) {
    const getResult = this.http.get(
      `${environment.backendUrl}/campaigns/${campaign.id}/data`,
      {responseType: 'blob', observe: 'response'});
    getResult.subscribe(response => {
      download(response.body, campaign.name + '-result.csv', 'text/csv');
    });
    return getResult;
  }

  getCampaignStatistics(campaignId: string): Observable<CampaignStatistics> {
    return this.http.get<CampaignStatistics>(
      `${environment.backendUrl}/campaigns/${campaignId}/statistics`);
  }

  stopCampaign(id: string, finalMessage: string) {
    return this.http.post(`${environment.backendUrl}/campaigns/${id}/stop`, finalMessage);
  }

  editScheduledCampaign(id: string, hideFreeText: boolean, finalMessage: string, startTime: string) {
    let url = `${environment.backendUrl}/campaigns/schedules/${id}/edit?startTime=` + startTime;
    if (hideFreeText) {
      url = url + '&hideFreeText=' + hideFreeText;
    }
    return this.http.post(url, finalMessage);
  }

  scheduleCampaign(id: string, startTime: string, channels: string, finalMessage: string, hideFreeText: boolean) {
    let url = `${environment.backendUrl}/campaigns/${id}/schedule?startTime=` + startTime;
    if (channels) {
      url = url + '&channels=' + channels;
    }
    if (hideFreeText) {
      url = url + '&hideFreeText=' + hideFreeText;
    }
    return this.http.post(url, finalMessage);
  }

  saveNewFlowName(oldName: string, newName: string, flowId: string) {
    return this.http.patch(`${environment.backendUrl}/flow/${flowId}`, {
      oldName, newName
    });
  }

  renameCampaign(campaignId: string, oldName: string, newName: string) {
    return this.http.patch(`${environment.backendUrl}/campaigns/${campaignId}`, {
      oldName, newName
    });
  }

  getMediaInfo(url: string): Observable<MediaInfo> {
    return this.http.post<MediaInfo>(`${environment.backendUrl}/media/info`, url);
  }

  uploadMedia(file: Blob, name: string, accountId: string) {
    const formData = new FormData();
    formData.append('accountId', accountId);
    formData.append('file', file, name);
    return this.http.post(`${environment.backendUrl}/media/upload`, formData, {responseType: 'text'});
  }

  loadReports(reportFilter: ReportFilter): Observable<ReportResult> {
    return this.http.post(`${environment.backendUrl}/reports`, reportFilter).pipe(map(response => plainToClass(ReportResult, response)));
  }

  loadCountries(): Observable<Country[]> {
    const headers = HttpService.createHeaders();
    return this.http
      .get(`${environment.backendUrl}/countries`, {headers}).pipe(map(response => {
        if ((<Country[]> response).length) {
          return <Country[]> response;
        }
        return new Array();
      }));
  }
}
