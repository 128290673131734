/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./name-editable.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./name-editable.component";
var styles_NameEditableComponent = [i0.styles];
var RenderType_NameEditableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NameEditableComponent, data: {} });
export { RenderType_NameEditableComponent as RenderType_NameEditableComponent };
function View_NameEditableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "rename pointer fa fa-pen-square"], ["data-testid", "editNameButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_NameEditableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "name-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NameEditableComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 2, 0, currVal_2); var currVal_4 = _co.showEditButton; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.name; _ck(_v, 3, 0, currVal_3); }); }
export function View_NameEditableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { nameInput: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["nameInput", 1]], null, 0, "input", [["class", "edit-flow-name"], ["data-testid", "flowName"], ["placeholder", "New Flow Name"]], [[8, "hidden", 0], [8, "value", 0]], [[null, "blur"], [null, "keyup.enter"], [null, "keyup.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.success() !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.success() !== false);
        ad = (pd_1 && ad);
    } if (("keyup.escape" === en)) {
        var pd_2 = (_co.cancel() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NameEditableComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isEditing; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isEditing; var currVal_1 = _co.name; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_NameEditableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-name-editable", [], null, null, null, View_NameEditableComponent_0, RenderType_NameEditableComponent)), i1.ɵdid(1, 49152, null, 0, i4.NameEditableComponent, [], null, null)], null, null); }
var NameEditableComponentNgFactory = i1.ɵccf("app-name-editable", i4.NameEditableComponent, View_NameEditableComponent_Host_0, { name: "name", link: "link", showEditButton: "showEditButton" }, { nameChanged: "nameChanged" }, []);
export { NameEditableComponentNgFactory as NameEditableComponentNgFactory };
