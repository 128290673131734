<form>
  <button mat-raised-button (drop)="onDrop($event)" (dragover)="onDragOver($event)" (click)="selectFile()"
          color="primary" [disabled]="uploadInProgress || disabled" data-testid="uploadMediaButton"
          matTooltip="Upload media" [matTooltipShowDelay]="env.tooltipDelay">Upload Media
  </button>
  <mat-spinner diameter="22" *ngIf="uploadInProgress"></mat-spinner>
  <a mat-button (click)="clearFile()" target="_blank" class="clear">Clear</a>
  <input #fileInput (change)="onFileChange($event)" type="file" accept="*/*" style="visibility: hidden"
         data-testid="mediaFileInput">
</form>

