import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input} from '@angular/core';
import { CarouselNodeComponent } from '../carousel-node.component';
import { FlowEditorService } from 'src/app/services/flow-editor.service';
import { ReteOutput } from 'src/app/flow-application/editor/rete/controls/extended-output';

@Component({
  selector: 'app-card-output-list',
  templateUrl: './card-output-list.component.html',
  styleUrls: ['./card-output-list.component.css']
})
export class CardOutputListComponent {

  @Input()
  carouselNode: CarouselNodeComponent;

  constructor(private flowService: FlowEditorService) { }

  onChange($event) {
    this.carouselNode.onChange();
  }

  drop(event: CdkDragDrop<ReteOutput[]>) {
    moveItemInArray(this.carouselNode.cardOutputs, event.previousIndex, event.currentIndex);
    this.onChange(null);
    this.flowService.canTranslate = true;
  }
}
