import * as tslib_1 from "tslib";
import { HttpService } from '../../../../../services/http.service';
import { EventEmitter } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { MediaValidationService } from '../../../../../services/media.validation.service';
import { BasicNode } from '../../node/basic-node';
export class MediaComponent {
    constructor(validationService, httpService) {
        this.validationService = validationService;
        this.httpService = httpService;
        this.INVALID_MEDIA_INFO = { linkValid: false, mimeType: null };
        this.mediaUrlRequired = true;
        this.mediaUrlInfo = null;
        this.showMedia = false;
        this.thumbnailUrlMediaInfo = null;
        this.showThumbnail = false;
        this.showAudioIcon = false;
        this.showVideoIcon = false;
        this.showWarning = false;
        this.env = environment;
        this.validationInProgress = false;
        this.validationErrors = new EventEmitter();
        this.verifyFinished = new EventEmitter();
    }
    withMediaFeaturset(mediaFeatureset) {
        this.mediaFeatureset = mediaFeatureset;
    }
    verify() {
        if (this.validationInProgress) {
            return;
        }
        this.validationInProgress = true;
        this.reloadMediaInfoIfUrlChanged()
            .finally(() => {
            this.validationInProgress = false;
            this.updateUserInterface();
            this.validateMedia();
            this.verifyFinished.emit();
        });
    }
    reloadMediaInfoIfUrlChanged() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.mediaUrlInfo || this.mediaUrlInfo.url !== this.mediaUrl) {
                const mediaInfo = (this.mediaUrl && this.mediaUrl.toLocaleLowerCase().startsWith('https://')) ? yield this.requestMediaInfo(this.mediaUrl) : this.INVALID_MEDIA_INFO;
                this.mediaUrlInfo = new MediaUrlInfo(mediaInfo, this.mediaUrl);
            }
            if (!this.thumbnailUrlMediaInfo || this.thumbnailUrlMediaInfo.url !== this.thumbnailUrl) {
                const thumbnailInfo = (this.thumbnailUrl && this.thumbnailUrl.toLocaleLowerCase().startsWith('https://')) ? yield this.requestMediaInfo(this.thumbnailUrl) : this.INVALID_MEDIA_INFO;
                this.thumbnailUrlMediaInfo = new MediaUrlInfo(thumbnailInfo, this.thumbnailUrl);
            }
        });
    }
    validateMedia() {
        if (this.mediaFeatureset && this.mediaUrlInfo && this.thumbnailUrlMediaInfo) {
            const errorReasons = this.validationService.validate(this.mediaUrlInfo, this.thumbnailUrlMediaInfo, this.mediaUrlRequired, this.mediaFeatureset);
            if (errorReasons && errorReasons.length > 0) {
                this.validationErrors.emit(errorReasons);
            }
        }
    }
    requestMediaInfo(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.httpService.getMediaInfo(url).toPromise();
        });
    }
    updateUserInterface() {
        this.showThumbnail = this.thumbnailUrlMediaInfo && this.thumbnailUrlMediaInfo.isLinkValid() && this.thumbnailUrlMediaInfo.isMediaTypeImage();
        this.showAudioIcon = !this.showThumbnail && this.mediaUrlInfo.isMediaTypeAudio();
        this.showVideoIcon = !this.showThumbnail && this.mediaUrlInfo.isMediaTypeVideo();
        this.showMedia = !this.showThumbnail && !this.showAudioIcon && !this.showVideoIcon && this.mediaUrlInfo && this.mediaUrlInfo.isLinkValid();
        this.showWarning = !this.showThumbnail && !this.showMedia && !this.showAudioIcon && !this.showVideoIcon && this.mediaUrlRequired;
        this.updateReteNode();
    }
    getMediaTypeIcon() {
        if (this.mediaUrlInfo && this.mediaUrlInfo.isLinkValid()) {
            return this.mediaUrlInfo.isMediaTypeVideo() ? 'videocam' : this.mediaUrlInfo.isMediaTypeAudio() ? 'mic' : 'image';
        }
        return 'image';
    }
    updateReteNode() {
        this.messageNode.updateReteNode();
    }
}
export class MediaUrlInfo {
    constructor(mediaInfo, url) {
        this.mediaInfo = mediaInfo;
        this.url = url;
    }
    hasUrl() {
        return this.url && this.url.trim().length > 0;
    }
    hasExceptionError() {
        return this.mediaInfo.exceptionError != null;
    }
    isLinkValid() {
        return this.mediaInfo && this.mediaInfo.linkValid;
    }
    isMediaTypeImage() {
        return this.isLinkValid() ? this.isMimeType('image/') : false;
    }
    isMediaTypeVideo() {
        return this.isLinkValid() ? this.isMimeType('video/') : false;
    }
    isMediaTypeAudio() {
        return this.isLinkValid() ? this.isMimeType('audio/') : false;
    }
    isMimeType(type) {
        return this.mediaInfo.mimeType && this.mediaInfo.mimeType.toLowerCase().startsWith(type);
    }
}
