import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-name-editable',
  templateUrl: './name-editable.component.html',
  styleUrls: ['./name-editable.component.css']
})
export class NameEditableComponent {

  isEditing: boolean;

  @ViewChild('nameInput')
  nameInput: ElementRef<HTMLInputElement>;

  @Input()
  name: string;
  private oldName: string;
  @Input()
  link: string[];
  @Input()
  showEditButton = false;
  @Output()
  nameChanged = new EventEmitter<string>();

  constructor() { }

  edit() {
    this.oldName = this.name;
    this.isEditing = true;
    setTimeout(() => this.nameInput.nativeElement.focus(), 0);
  }

  cancel() {
    this.nameInput.nativeElement.value = this.oldName;
    this.isEditing = false;
  }

  success() {
    if (this.nameInput.nativeElement.value !== this.name) {
      this.name = this.nameInput.nativeElement.value;
      this.nameChanged.emit(this.name);
    }
    this.isEditing = false;
  }
}
