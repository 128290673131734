import { of } from 'rxjs';
import { LoginService } from './login.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./login.service";
export class AuthInterceptorService {
    constructor(loginService) {
        this.loginService = loginService;
    }
    intercept(req, next) {
        if (req.url.startsWith(environment.backendUrl) && !req.url.endsWith('/login') && LoginService.isLoggedIn()) {
            // Clone the request to add the new header.
            const authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + LoginService.getToken()) });
            // here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
            return next.handle(authReq).pipe(catchError((error) => {
                this.handleAuthError(error);
                return of(error);
            }));
        }
        else {
            return next.handle(req);
        }
    }
    handleAuthError(err) {
        // handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            // navigate /delete cookies or whatever
            this.loginService.logout();
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message);
        }
        throw (err);
    }
}
AuthInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function AuthInterceptorService_Factory() { return new AuthInterceptorService(i0.inject(i1.LoginService)); }, token: AuthInterceptorService, providedIn: "root" });
