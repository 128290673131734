import { ChannelFeatureset } from 'conversation-domain';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractNode, HttpRequestMessageNode, HttpAction , HttpBodyType} from 'flow-model';
import { UserProfileService } from '../../../../../services/user-profile.service';
import { BasicNode } from '../basic-node';
import { NodeHelper } from 'src/app/services/node-helper.service';
import { FreeTextComponent } from '../../node-shared-components/free-text/free-text.component';
import { getAction } from 'src/app/model/requests';

@Component({
  selector: 'app-http-request-message-node',
  templateUrl: './http-request-message-node.component.html',
  styleUrls: ['./http-request-message-node.component.css']
})
export class HttpRequestMessageNodeComponent extends BasicNode implements OnInit {
  private NO_MESSAGE_TEXT = 'Message text is empty';
  private MESSAGE_TOO_LONG = 'Message text too long, please do not use more than %d characters';
  private MOBILE_INVOICE_INACTIVE = 'Current user has no active mobile invoce settings';

  model: HttpRequestMessageNode = null;

  constructor(@Inject(UserProfileService) userProfileService: UserProfileService) {
    super(userProfileService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addDirectOutput();
    this.model = NodeHelper.newHttpRequestMessageNode(this.getId(), this.getPosition());
  }

  protected withFeatureset(featureSet: ChannelFeatureset) {
    super.withFeatureset(featureSet);
  }

  loadFromModel(abstractNode: AbstractNode) {
    this.model = abstractNode as HttpRequestMessageNode;
  }

  getModelObject(): AbstractNode {
    this.model.position = this.getPosition();
    this.model.nextMessageId = this.getDirectlyConnectedNodeId();
    this.removeUnnecessarilyData();
    return this.model;
  }

  private removeUnnecessarilyData() {
      if (this.model.action === HttpAction.GET) {
        this.model.httpBodyType = HttpBodyType.NONE;
        this.model.messageBody = null;
        this.model.formDataEntries = null;
      } else if (this.model.action = HttpAction.POST) {
        this.model.responseVariables = null;
      }
  }

  getHttpAction(value: string): string {
    return getAction(value);
 }

  protected verifyNodeSpecific() {
    if (!this.userProfileService.isMobileInvoiceActive() && this.model.hmacMI) {
      this.addErrorReason(this.MOBILE_INVOICE_INACTIVE);
    }
    this.verifyMessageText();
  }

  private verifyMessageText() {
  }
}
