import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FlowEditorService } from '../../services/flow-editor.service';
import { DialogService } from '../../dialogs/dialog.service';
import { HttpService } from '../../services/http.service';
import { SnackBarService } from '../../services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignsComponent } from './campaigns/campaigns.component';
import * as download from 'downloadjs';
import { Flow, FlowDefinition, AbstractNode } from 'flow-model';
import { plainToClass, deserialize, serialize } from 'class-transformer';

@Component({
  selector: 'app-editor-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  private TARGET_MAPS = [
    {target: FlowDefinition, properties: {messages: AbstractNode}}
    ];

  flowId: string;
  @Output()
  settingsOpened = new EventEmitter<void>();

  @ViewChild('campaigns')
  campaigns: CampaignsComponent;

  flowDirty: boolean;

  @ViewChild('fileInput')
  fileInput: ElementRef;

  uploadInProgress = false;
  private file: File;

  constructor(
    private flowService: FlowEditorService,
    private snackBar: SnackBarService,
    private httpService: HttpService,
    private dialogManager: DialogService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.flowId = id;
      this.load();
    }
    this.flowService.flowState$.subscribe(state => {
      this.flowDirty = state.dirty;
    });
  }

  downloadFlow() {
     if (this.flowDirty) {
       this.dialogManager.confirmation('Download without changes?',
       'There are unsaved changes in your flow. Downloading will be performed without these changes. <br><br> Download the flow from database?',
       'OK',
       confirmed => {
         if (confirmed) {
            this.downloadFlowById(this.flowId);
         }
       },
       ['primary', 'primary']);
     } else {
        this.downloadFlowById(this.flowId);
     }
  }

  downloadFlowById(flowId: string) {
    this.httpService.loadFlow(flowId).subscribe(
      (flow: Flow) =>  {
        const data = JSON.stringify(flow.flowDefinition);
        const dataBlob = new Blob([data]);
        download(dataBlob, flow.name + '-flow.json', 'application/json');
      },
        (error) => {
          if (error.status === 404) {
            this.snackBar.showError('Flow is not found in database. Please save the flow to continue');
          } else {
            this.snackBar.showError('Error while loading flow!');
            }
          }
    );
  }

  selectFile() {
    this.dialogManager.showFileUploadDialog('Import flow from file?',
    'Current flow will be replaced with imported flow <br><br> Replace flow with file content?',
    'OK', this.fileInput,
    confirmed => {

    },
    ['primary', 'primary']);
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;
    if (files.length === 1) {
      this.file = files[0];
      this.uploadFlow();
    }
  }

private uploadFlow() {
    this.uploadInProgress = true;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.result instanceof ArrayBuffer) {
        this.snackBar.showError('Unexpected resut');
        this.finalizeUpload();
      } else {
        if (this.isJSON(fileReader.result)) {
          this.loadJsonResult(fileReader.result);
        } else {
            this.snackBar.showError('File content is not a valid json');
        }
        this.finalizeUpload();
      }
    };
    fileReader.onerror = (error) => {
      this.snackBar.showError('Error reading file!' + error);
      this.finalizeUpload();
    };
    fileReader.readAsText(this.file, 'UTF-8');
  }

  private loadJsonResult(jsonFlowDefinition: string) {
    const flowDefinition = deserialize(FlowDefinition, jsonFlowDefinition, { targetMaps: this.TARGET_MAPS });
    const currentFlow = this.flowService.flow;
    const flow = new Flow();
    flow.id = currentFlow.id;
    flow.name = currentFlow.name;
    flow.flowDefinition = flowDefinition;
    this.flowService.loadFlowData(flow).then(result => {

    }).catch(error => {
      this.flowService.loadFlowData(currentFlow);
      this.snackBar.showError(error);
    });
  }

  finalizeUpload() {
    this.uploadInProgress = false;
    this.file = null;
    this.fileInput.nativeElement.value = '';
  }

  isJSON(str: string) {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }


  load() {
    if (this.flowDirty) {
      this.dialogManager.confirmation(
        'Discard changes?',
        'There are unsaved changes in your flow. Loading now will discard these changes. <br><br> Load the flow anyways?',
        'Discard',
        confirmed => {
          if (confirmed) {
            this.flowService.loadFlow(this.flowId);
          }
        },
        ['primary', 'warn']);
    } else {
      this.flowService.loadFlow(this.flowId);
    }
  }

  delete() {
    this.dialogManager.confirmation(
      'Delete Flow?',
      'Do you really want to delete the flow?',
      'Delete',
      confirmed => {
        if (confirmed) {
          this.doDelete();
        }
      },
      ['primary', 'warn']);
  }

  save(flowId: string) {
    this.flowService.save(flowId);
  }

  private doDelete() {
    this.httpService.deleteFlow(this.flowId)
      .subscribe(
        () => {
          this.snackBar.showSuccess('Flow Deleted.');
          this.router.navigate(['/']);
        },
        (error) => {
          if (error.status === 500) {
            this.router.navigate(['/']);
          } else {
            this.snackBar.showError('Flow could not be deleted! ' + error.error);
          }
        }
      );
  }

  campaignTriggered() {
    this.campaigns.loadFirstItems();
  }

  toggleSettings() {
    this.settingsOpened.emit();
  }
}
