/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./details-text-message.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../name-node/name-node.component.ngfactory";
import * as i3 from "../name-node/name-node.component";
import * as i4 from "../../../services/user-profile.service";
import * as i5 from "../output-variable/output-variable.component.ngfactory";
import * as i6 from "../output-variable/output-variable.component";
import * as i7 from "../suggestion-details-list/suggestion-details-list.component.ngfactory";
import * as i8 from "../suggestion-details-list/suggestion-details-list.component";
import * as i9 from "../../../services/flow-editor.service";
import * as i10 from "@angular/common";
import * as i11 from "./details-text-message.component";
import * as i12 from "../../../services/node-details.service";
var styles_DetailsTextMessageComponent = [i0.styles];
var RenderType_DetailsTextMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DetailsTextMessageComponent, data: {} });
export { RenderType_DetailsTextMessageComponent as RenderType_DetailsTextMessageComponent };
function View_DetailsTextMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "details-node-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Text Message"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-name-node", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.basicNode.onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NameNodeComponent_0, i2.RenderType_NameNodeComponent)), i1.ɵdid(4, 49152, null, 0, i3.NameNodeComponent, [i4.UserProfileService], { model: [0, "model"] }, { changed: "changed" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-output-variable", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.basicNode.onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_OutputVariableComponent_0, i5.RenderType_OutputVariableComponent)), i1.ɵdid(6, 49152, null, 0, i6.OutputVariableComponent, [], { model: [0, "model"] }, { changed: "changed" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-suggestion-details-list", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.basicNode.onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_SuggestionDetailsListComponent_0, i7.RenderType_SuggestionDetailsListComponent)), i1.ɵdid(8, 114688, null, 0, i8.SuggestionDetailsListComponent, [i9.FlowEditorService, i4.UserProfileService], { suggestions: [0, "suggestions"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.basicNode.model; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.basicNode.model; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.suggestionsModel; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_DetailsTextMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DetailsTextMessageComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DetailsTextMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-details-text-message", [], null, null, null, View_DetailsTextMessageComponent_0, RenderType_DetailsTextMessageComponent)), i1.ɵdid(1, 114688, null, 0, i11.DetailsTextMessageComponent, [i12.NodeDetailsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DetailsTextMessageComponentNgFactory = i1.ɵccf("app-details-text-message", i11.DetailsTextMessageComponent, View_DetailsTextMessageComponent_Host_0, {}, {}, []);
export { DetailsTextMessageComponentNgFactory as DetailsTextMessageComponentNgFactory };
