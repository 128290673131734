import { NodeHelper } from 'src/app/services/node-helper.service';
import { UserProfileService } from './../../../../../services/user-profile.service';
import { Component, Inject, OnInit } from '@angular/core';
import { BasicNode } from '../basic-node';
import { AbstractNode, StartNode } from 'flow-model';
import { environment } from '../../../../../../environments/environment';
import { getSmsLength, KEY_PLACEHOLDER } from '../../../../../util/sms-helper';

@Component({
  selector: 'app-start-node',
  templateUrl: './start-node.component.html',
  styleUrls: ['./start-node.component.css']
})
export class StartNodeComponent extends BasicNode implements OnInit {
  private TEXT_TOO_LONG = 'The SMS template text exceeded the maximum length limit';
  private NO_URL = 'No conversation URL including the conversationKey placeholder could be found in the SMS template';

  model: StartNode = null;
  env = environment;


  constructor(@Inject(UserProfileService) userProfileService: UserProfileService) {
    super(userProfileService);
  }

  static containsConversationUrl(smsTemplate: string) {
    return smsTemplate.match(`[hH][tT][tT][pP][sS]?:\\/\\/[^\\s]*?\\${KEY_PLACEHOLDER}`);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addDirectOutput();
    this.model = NodeHelper.createStartNode(this.getId(), this.getPosition());
  }

  loadFromModel(node: AbstractNode) {
    this.model = node as StartNode;
  }

  verifyNodeSpecific() {
    if (this.model && this.model.smsTemplate) {
      if (getSmsLength(this.model.smsTemplate) > this.env.maximumSmsLength) {
        this.addErrorReason(this.TEXT_TOO_LONG, this.env.maximumSmsLength);
      }

      if (!StartNodeComponent.containsConversationUrl(this.model.smsTemplate)) {
        this.addErrorReason(this.NO_URL);
      }
    }
  }

  getModelObject(): AbstractNode {
    this.model.nextMessageId = this.getDirectlyConnectedNodeId();
    this.model.position = this.getPosition();
    return this.model;
  }

}
