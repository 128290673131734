import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Input as ReteInput } from 'rete';
import { environment } from '../../../../../../../../environments/environment';
import { ReteOutput } from '../../../../../rete/controls/extended-output';
import { FlowEditorService } from 'src/app/services/flow-editor.service';

@Component({
  selector: 'app-card-output',
  templateUrl: './card-output.component.html',
  styleUrls: ['./card-output.component.css']
})
export class CardOutputComponent {

  @Input()
  output: ReteOutput;

  @Input()
  bindSocket: (el: HTMLElement, type: string, io: ReteInput | ReteOutput) => void;

  @Output()
  delete = new EventEmitter<ReteOutput>();

  env = environment;

  constructor(private flowService: FlowEditorService) {}

  deleteCardOutput(output: ReteOutput) {
    this.delete.emit(output);
  }

  offNodeTranslate() {
    this.flowService.canTranslate = false;
  }

  onNodeTranslate() {
    this.flowService.canTranslate = true;
  }
}
