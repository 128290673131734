import { MobileInvoiceNode, HttpVariables } from 'flow-model';
import { createTestVariablesFromString, pushUniqueVariables } from 'src/app/model/test-variables';

export function getHttpVariables(model: MobileInvoiceNode): HttpVariables[] {
    const httpVariables: HttpVariables[] = calculateTestVariables(model);
    const variables: HttpVariables[] = [];
    httpVariables.forEach(element => {
      const httpVariable: HttpVariables = new HttpVariables();
      httpVariable.name = element.name;
      httpVariable.value = element.value;
      variables.push(httpVariable);
    });
    return variables;
  }

  function calculateTestVariables(model: MobileInvoiceNode): HttpVariables[] {
    const modelHttpVariables: HttpVariables[] = getVariablesFromNode(model);
    let  testVariables: HttpVariables[] = model.testVariables;
    if (testVariables == null) {
      testVariables = [];
    }
    const finalVariables: HttpVariables[] = [];
    for (const modelHttpVariable of modelHttpVariables) {
      const variableIndex = testVariables.findIndex(testVariable =>
        testVariable.name === modelHttpVariable.name
      );
      if (variableIndex >= 0) {
        finalVariables.push(testVariables[variableIndex]);
      } else {
        finalVariables.push(modelHttpVariable);
      }
    }
    return finalVariables;
  }

  function getVariablesFromNode(model: MobileInvoiceNode): HttpVariables[] {
    const httpVariables: HttpVariables[] = [];
    pushUniqueVariables(httpVariables, createTestVariablesFromString(model.amountParameter));
    pushUniqueVariables(httpVariables, createTestVariablesFromString(model.ocrParameter));
    if (model.description && model.description['en']) {
       pushUniqueVariables(httpVariables, createTestVariablesFromString(model.description['en']));
    }
    return httpVariables;
}
