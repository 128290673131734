/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./start-node.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../socket/socket.component.ngfactory";
import * as i3 from "../../socket/socket.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/material/tooltip";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "@angular/cdk/scrolling";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i13 from "@angular/material/icon";
import * as i14 from "./start-node.component";
import * as i15 from "../../../../../services/user-profile.service";
var styles_StartNodeComponent = [i0.styles];
var RenderType_StartNodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartNodeComponent, data: {} });
export { RenderType_StartNodeComponent as RenderType_StartNodeComponent };
function View_StartNodeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-socket", [["type", "output"]], null, null, null, i2.View_SocketComponent_0, i2.RenderType_SocketComponent)), i1.ɵdid(1, 4243456, null, 0, i3.SocketComponent, [], { type: [0, "type"], inputOutput: [1, "inputOutput"], bindSocket: [2, "bindSocket"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "output"; var currVal_1 = _co.directOutput; var currVal_2 = _co.bindSocket; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_StartNodeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { basicMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "node"], ["data-testid", "StartNode"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "selected": 0, error: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 2, "div", [["class", "text"], ["data-testid", "StartNode-node-name"], ["matTooltip", "Your flow starts here"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 147456, null, 0, i5.MatTooltip, [i6.Overlay, i1.ElementRef, i7.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i8.Platform, i9.AriaDescriber, i9.FocusMonitor, i5.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i5.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(-1, null, ["Start Flow"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 4, "mat-icon", [["class", "mat-icon notranslate"], ["data-testid", "nodeStatusIcon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(10, 147456, null, 0, i5.MatTooltip, [i6.Overlay, i1.ElementRef, i7.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i8.Platform, i9.AriaDescriber, i9.FocusMonitor, i5.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i5.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵdid(11, 9158656, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null], [2, i13.MAT_ICON_LOCATION]], null, null), i1.ɵdid(12, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, 0, [" error_outline"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartNodeComponent_1)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "node"; var currVal_1 = _ck(_v, 3, 0, _co.selected, !_co.isValid); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "Your flow starts here"; _ck(_v, 6, 0, currVal_2); var currVal_5 = _co.validityErrorMessages; _ck(_v, 10, 0, currVal_5); _ck(_v, 11, 0); var currVal_6 = (_co.isValid ? "ok" : "error"); _ck(_v, 12, 0, currVal_6); var currVal_7 = _co.directOutput; _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 11).inline; var currVal_4 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 9, 0, currVal_3, currVal_4); }); }
export function View_StartNodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-start-node", [], null, null, null, View_StartNodeComponent_0, RenderType_StartNodeComponent)), i1.ɵdid(1, 114688, null, 0, i14.StartNodeComponent, [i15.UserProfileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartNodeComponentNgFactory = i1.ɵccf("app-start-node", i14.StartNodeComponent, View_StartNodeComponent_Host_0, {}, {}, []);
export { StartNodeComponentNgFactory as StartNodeComponentNgFactory };
