import * as i0 from "@angular/core";
export class MobileInvoiceValidationService {
    constructor() {
        this.AMOUNT_PARAMETER_IS_MISSING = 'The amount parameter is required';
        this.AMOUNT_PARAMETER_INVALID_INPUT = 'The amount parameter must be decimal number with max two decimals between 0 and 999999.99 or variable';
        this.OCR_PARAMETER_INVALID_INPUT = 'The OCR parameter must be variable';
        this.TEXT_MESSAGE_IS_MISSING = 'Message text is required';
        this.INVALID_TEXT_MESSAGE = 'Message text should contain ' + MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER;
        this.TEMPLATE_INVALID_INPUT = 'Mobile Invoice Template is not valid';
        this.PROVIDER_INVALID_INPUT = 'Mobile Invoice Providers are not valid';
        this.CURRENCY_INVALID_INPUT = 'Mobile Invoice Currency is not valid';
        this.CURRENCY_IS_MISSING = 'The Mobile Invoice Currency is required';
        this.TEMPLATE_IS_MISSING = 'The Mobile Invoice Template is required';
        this.SKIP_LANDING_PAGE_IS_NOT_SUPPORTED = 'Selected payment providers do not support direct payment';
        this.PAYMENT_PROVIDER_IS_MISSING = 'Mobile Invoice Providers are required';
        this.LANGUAGE_INVALID_INPUT = 'Mobile Invoice language is not valid';
        this.INVALID_INVOICE_SETTINGS = 'Failed to load mobile invoice settings';
        this.INVALID_EXPIRATION_DATE = 'Expiration time should be after the current time';
        this.EXPECTED_LENGTH_OF_LINK = 90;
    }
    validate(mobileInvoiceNode) {
        const errorMessages = new Array();
        if (this.isNullOrEmpty(mobileInvoiceNode.amountParameter)) {
            errorMessages.push(this.AMOUNT_PARAMETER_IS_MISSING);
        }
        else if (!this.isDecimalNumber(mobileInvoiceNode.amountParameter) && !this.isVariable(mobileInvoiceNode.amountParameter)) {
            errorMessages.push(this.AMOUNT_PARAMETER_INVALID_INPUT);
        }
        if (!this.isNullOrEmpty(mobileInvoiceNode.ocrParameter) && !this.isVariable(mobileInvoiceNode.ocrParameter)) {
            errorMessages.push(this.OCR_PARAMETER_INVALID_INPUT);
        }
        this.verifyExpirationDate(mobileInvoiceNode, errorMessages);
        return errorMessages;
    }
    getProviders(paymentProviders) {
        const providers = [];
        if (paymentProviders === null || paymentProviders === undefined) {
            return providers;
        }
        paymentProviders.forEach(paymentProvider => {
            providers.push(paymentProvider.paymentProvider);
        });
        return providers;
    }
    validateMobileInvoiceSettings(mobileInvoiceNode, mobileInvoiceInfoSettings) {
        const errorMessages = new Array();
        if (mobileInvoiceInfoSettings === undefined || mobileInvoiceInfoSettings === null) {
            errorMessages.push(this.INVALID_INVOICE_SETTINGS);
            return errorMessages;
        }
        this.validateMobileInvoiceTemplate(mobileInvoiceNode.template, mobileInvoiceInfoSettings.templates, errorMessages);
        this.validateMobileInvoiceProviders(mobileInvoiceNode.providers, this.getProviders(mobileInvoiceInfoSettings.providers), errorMessages);
        this.validateSkipLandingPage(mobileInvoiceNode.skipLandingPage, mobileInvoiceNode.providers, mobileInvoiceInfoSettings.providers, errorMessages);
        this.validateMobileInvoiceCurrency(mobileInvoiceNode.currency, mobileInvoiceInfoSettings.currencies, errorMessages);
        if (this.isInvalid(mobileInvoiceNode.language, mobileInvoiceInfoSettings.languages)) {
            errorMessages.push(this.LANGUAGE_INVALID_INPUT);
        }
        this.verifyMessageText(mobileInvoiceNode, errorMessages);
        return errorMessages;
    }
    validateMobileInvoiceTemplate(template, possibleTemplates, errorMessages) {
        if (this.isNullOrEmpty(template)) {
            errorMessages.push(this.TEMPLATE_IS_MISSING);
        }
        else if (this.isInvalid(template, possibleTemplates)) {
            errorMessages.push(this.TEMPLATE_INVALID_INPUT);
        }
    }
    validateSkipLandingPage(skipLandingPage, providers, possibleProviders, errorMessages) {
        if (!skipLandingPage) {
            return;
        }
        else if (!this.isSupportsLandingPage(providers, possibleProviders)) {
            errorMessages.push(this.SKIP_LANDING_PAGE_IS_NOT_SUPPORTED);
        }
    }
    providerSupportsLandingLandingPage(provider, possibleProviders) {
        let supportsLandingPage = false;
        possibleProviders.forEach(paymentProvider => {
            if (paymentProvider.paymentProvider === provider && paymentProvider.supportsDirectPayment) {
                supportsLandingPage = true;
            }
        });
        return supportsLandingPage;
    }
    isSupportsLandingPage(providers, possibleProviders) {
        if (providers === null || providers === undefined) {
            return false;
        }
        if (providers.length === 1 && this.providerSupportsLandingLandingPage(providers[0], possibleProviders)) {
            return true;
        }
        return false;
    }
    validateMobileInvoiceProviders(providers, possibleProviders, errorMessages) {
        if (this.isNullOrEmptyList(providers)) {
            errorMessages.push(this.PAYMENT_PROVIDER_IS_MISSING);
        }
        else if (this.isListInvalid(providers, possibleProviders)) {
            errorMessages.push(this.PROVIDER_INVALID_INPUT);
        }
    }
    isNullOrEmptyList(providers) {
        if (!providers) {
            return true;
        }
        if (providers.length === 0) {
            return true;
        }
        return false;
    }
    validateMobileInvoiceCurrency(currency, currencies, errorMessages) {
        if (this.isNullOrEmpty(currency)) {
            errorMessages.push(this.CURRENCY_IS_MISSING);
        }
        else if (this.isInvalid(currency, currencies)) {
            errorMessages.push(this.CURRENCY_INVALID_INPUT);
        }
    }
    isValidMessageLength(messageText, length) {
        const regExp = new RegExp(MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER, 'g');
        const regexCount = (messageText.match(regExp) || []).length;
        const expectedLength = messageText.length - (regexCount * MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER.length)
            + (regexCount * this.EXPECTED_LENGTH_OF_LINK);
        return expectedLength < length;
    }
    verifyMessageText(mobileInvoiceNode, errorMessages) {
        const regex = new RegExp(MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER, 'g');
        const messageText = mobileInvoiceNode && mobileInvoiceNode.text ? mobileInvoiceNode.text.en : null;
        if (!messageText || messageText.length === 0) {
            errorMessages.push(this.TEXT_MESSAGE_IS_MISSING);
        }
        else if (messageText.search(regex) < 0) {
            errorMessages.push(this.INVALID_TEXT_MESSAGE);
        }
    }
    verifyExpirationDate(mobileInvoiceNode, errorMessages) {
        const now = Math.round(Date.now() / 1000);
        const expiryDate = mobileInvoiceNode.expiryUTC;
        if (expiryDate != null && expiryDate <= now) {
            errorMessages.push(this.INVALID_EXPIRATION_DATE);
        }
    }
    isListInvalid(values, possibleValues) {
        if (values === null || values === undefined) {
            return false;
        }
        let found = true;
        values.forEach(value => {
            if (this.isInvalid(value, possibleValues)) {
                found = false;
            }
        });
        return !found;
    }
    isInvalid(value, possibleValues) {
        if (value === undefined || value === null) {
            return false;
        }
        if (possibleValues === undefined || possibleValues === null) {
            return true;
        }
        else {
            const found = possibleValues.some(possibleValue => {
                return possibleValue === value;
            });
            return !found;
        }
    }
    isNullOrEmpty(value) {
        if (value === undefined || value === null) {
            return true;
        }
        if (value.trim() === '') {
            return true;
        }
        return false;
    }
    isVariable(value) {
        const regex = /^\${([a-zA-Z]\w*)}$/;
        return regex.test(value);
    }
    isDecimalNumber(value) {
        const regex = /^(?!^(0|0\.0|0\.00)$)(0|[1-9][0-9]{0,5})(\.\d{1,2})?$/;
        return regex.test(value);
    }
}
MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER = '{{InvoiceLinkUrl}}';
MobileInvoiceValidationService.ngInjectableDef = i0.defineInjectable({ factory: function MobileInvoiceValidationService_Factory() { return new MobileInvoiceValidationService(); }, token: MobileInvoiceValidationService, providedIn: "root" });
