<button mat-icon-button class="close-button" [mat-dialog-close]="true" data-testid=cancelMobileInvoice]>
  <mat-icon class="close-icon" color="primary">close</mat-icon>
</button>
<h2 mat-dialog-title>Warning</h2>
<div class="container">
  <div class="tableCellRed">
    <p class="alignTop">
    <i class="fa fa-exclamation-triangle"></i>
    </p>
  </div>
<div class="tableCell">
<p class="alignLeft">
Preview will generate real mobile invoice link.
If you enter the credit card information on
payment page, an actual transaction is made!
</p>
</div>
</div>
<mat-dialog-content>
  <button class="button" mat-raised-button data-testid="generateMobileInvoice" *ngIf="isVisible"
    (click)="sendTestRequest()">{{data.confirmText}}</button>
  <div class="invoice" *ngIf="httpRequestInfo.responseBody && (httpRequestInfo.responseBody !== '') && !model.skipLandingPage">
    <div class="text">Scan code or open link&nbsp;
      <a [href]="httpRequestInfo.responseBody" data-testid="previewMobileInvoiceLink" target="_blank">here</a>
    </div>
    <qrcode [qrdata]="httpRequestInfo.responseBody" [size]="130" [level]="'M'"></qrcode>
  </div>
  
  <div class="invoice" *ngIf="httpRequestInfo.responseBody && (httpRequestInfo.responseBody !== '') && model.skipLandingPage">
    <div class="textAlignCenter">
      Scan code
   </div>
    <qrcode [qrdata]="httpRequestInfo.responseBody" [size]="130" [level]="'M'"></qrcode>
  </div>
</mat-dialog-content>
<mat-spinner class="spinner" diameter="22" *ngIf="requestIsInProgress"></mat-spinner>
