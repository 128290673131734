/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./socket.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "./socket.component";
var styles_SocketComponent = [i0.styles];
var RenderType_SocketComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SocketComponent, data: {} });
export { RenderType_SocketComponent as RenderType_SocketComponent };
export function View_SocketComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { socketRef: 0 }), (_l()(), i1.ɵeld(1, 16777216, [[1, 0], ["socket", 1]], null, 3, "div", [["class", "socket"], ["matTooltip", "Click to connect nodes"]], [[1, "data-testid", 0]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { showDelay: [0, "showDelay"], message: [1, "message"] }, null), i1.ɵdid(3, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(4, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.env.tooltipDelay; var currVal_2 = "Click to connect nodes"; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = "socket"; var currVal_4 = _ck(_v, 4, 0, _co.type, _co.getVerifiedClass()); _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.type, "Socket"); _ck(_v, 1, 0, currVal_0); }); }
export function View_SocketComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-socket", [], null, null, null, View_SocketComponent_0, RenderType_SocketComponent)), i1.ɵdid(1, 4243456, null, 0, i10.SocketComponent, [], null, null)], null, null); }
var SocketComponentNgFactory = i1.ɵccf("app-socket", i10.SocketComponent, View_SocketComponent_Host_0, { type: "type", inputOutput: "inputOutput", bindSocket: "bindSocket" }, {}, []);
export { SocketComponentNgFactory as SocketComponentNgFactory };
