import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReportResult, ReportFilter, Country } from 'flow-model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private reportsSubject: BehaviorSubject<ReportResult>;
  reports$: Observable<ReportResult>;

  private reportsErrorSubject: BehaviorSubject<HttpErrorResponse>;
  reportsError$: Observable<HttpErrorResponse>;

  private countriesSubject: BehaviorSubject<Country[]>;
  countries$: Observable<Country[]>;

  constructor(private httpService: HttpService, private router: Router) {
    this.reportsSubject = new BehaviorSubject<ReportResult>(null);
    this.reports$ = this.reportsSubject.asObservable();

    this.reportsErrorSubject = new BehaviorSubject<HttpErrorResponse>(null);
    this.reportsError$ = this.reportsErrorSubject.asObservable();

    this.countriesSubject = new BehaviorSubject<Country[]>(null);
    this.countries$ = this.countriesSubject.asObservable();

    this.fetchCountries();
  }

  showReport() {
    this.router.navigate(['/report']);
  }

  fetchReports(reportFilter: ReportFilter): void {
    this.httpService.loadReports(reportFilter).subscribe(
      reports => {
        this.reportsSubject.next(reports);
      },
      (response: HttpErrorResponse) => {
        if (response.status !== 401) {
          this.reportsErrorSubject.next(response);
        }
      });
  }

  clearReports(): void {
    this.reportsSubject.next(null);
  }

  fetchCountries(): void {
    this.httpService.loadCountries().subscribe(
      countries => {
        this.countriesSubject.next(countries);
      },
      (response: HttpErrorResponse) => {
        if (response.status !== 401) {
          throw response;
        }
      });
  }
}
