<mat-form-field class="full-width">
  <input matInput id="variable" type="text"
    [(ngModel)]="model.outputVariableName"
    (ngModelChange)="onChange()"
    placeholder="Output Variable (optional)"
    matTooltip="Enter a variable name to enable data collecting for this node"
    [matTooltipShowDelay]="env.tooltipDelay"
    appDragStopPropagation
    data-testid="outputVariableName"/>
</mat-form-field>
