/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./suggestion.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@angular/material/icon";
import * as i11 from "@angular/common";
import * as i12 from "@angular/forms";
import * as i13 from "../../../../../../directives/click-stop-propagation.directive";
import * as i14 from "@angular/cdk/drag-drop";
import * as i15 from "../../../socket/socket.component.ngfactory";
import * as i16 from "../../../socket/socket.component";
import * as i17 from "./suggestion.component";
import * as i18 from "../../../../../../services/flow-editor.service";
var styles_SuggestionComponent = [i0.styles];
var RenderType_SuggestionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuggestionComponent, data: {} });
export { RenderType_SuggestionComponent as RenderType_SuggestionComponent };
function View_SuggestionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "mat-icon", [["class", "type-icon mat-icon notranslate"], ["data-testid", "actionIcon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 147456, null, 0, i3.MatTooltip, [i4.Overlay, i1.ElementRef, i5.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { showDelay: [0, "showDelay"], message: [1, "message"] }, null), i1.ɵdid(3, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.env.tooltipDelay; var currVal_3 = _co.suggestionData.suggestionType.label; _ck(_v, 2, 0, currVal_2, currVal_3); _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.suggestionData.suggestionType.icon; _ck(_v, 4, 0, currVal_4); }); }
export function View_SuggestionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "suggestion"]], null, [[null, "mousedown"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.offNodeTranslate() !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (_co.onNodeTranslate() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "suggestion-inline-button delete-button mat-icon notranslate"], ["data-testid", "deleteSuggestion"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "mousedown"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.deleteSuggestion() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, [" cancel "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SuggestionComponent_1)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "fill_space"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "input", [["appDragStopPropagation", ""], ["data-testid", "suggestionInput"], ["placeholder", "Suggestion"], ["required", ""], ["type", "text"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "mousedown"], [null, "mouseup"], [null, "pointermove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("mousedown" === en)) {
        var pd_4 = (i1.ɵnov(_v, 15).onMouseDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("mouseup" === en)) {
        var pd_5 = (i1.ɵnov(_v, 15).onMouseUp($event) !== false);
        ad = (pd_5 && ad);
    } if (("pointermove" === en)) {
        var pd_6 = (i1.ɵnov(_v, 15).onMouseMove($event) !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.suggestionData.suggestion.template["en"] = $event) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = (_co.onChange() !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i12.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i12.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(9, 16384, null, 0, i12.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i12.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i12.RequiredValidator]), i1.ɵprd(1024, null, i12.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i12.DefaultValueAccessor]), i1.ɵdid(12, 671744, null, 0, i12.NgModel, [[8, null], [6, i12.NG_VALIDATORS], [8, null], [6, i12.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i12.NgControl, null, [i12.NgModel]), i1.ɵdid(14, 16384, null, 0, i12.NgControlStatus, [[4, i12.NgControl]], null, null), i1.ɵdid(15, 16384, null, 0, i13.ClickStopPropagationDirective, [], null, null), (_l()(), i1.ɵeld(16, 16777216, null, null, 4, "mat-icon", [["cdkDragHandle", ""], ["class", "reorder-button mat-icon notranslate cdk-drag-handle"], ["matTooltip", "Move suggestion"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 17)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(17, 147456, null, 0, i3.MatTooltip, [i4.Overlay, i1.ElementRef, i5.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { showDelay: [0, "showDelay"], message: [1, "message"] }, null), i1.ɵdid(18, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], null, null), i1.ɵdid(19, 147456, null, 0, i14.CdkDragHandle, [i1.ElementRef, [2, i14.ɵb]], null, null), (_l()(), i1.ɵted(-1, 0, [" drag_indicator "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "app-socket", [["data-testid", "suggestionSocket"], ["type", "output"]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_SocketComponent_0, i15.RenderType_SocketComponent)), i1.ɵdid(22, 4243456, null, 0, i16.SocketComponent, [], { type: [0, "type"], inputOutput: [1, "inputOutput"], bindSocket: [2, "bindSocket"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = (_co.suggestionData.suggestionType && (_co.suggestionData.suggestionType.label !== "Text")); _ck(_v, 5, 0, currVal_2); var currVal_11 = ""; _ck(_v, 9, 0, currVal_11); var currVal_12 = _co.suggestionData.suggestion.template["en"]; _ck(_v, 12, 0, currVal_12); var currVal_15 = _co.env.tooltipDelay; var currVal_16 = "Move suggestion"; _ck(_v, 17, 0, currVal_15, currVal_16); _ck(_v, 18, 0); var currVal_17 = "output"; var currVal_18 = _co.suggestionData.output; var currVal_19 = _co.bindSocket; _ck(_v, 22, 0, currVal_17, currVal_18, currVal_19); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 9).required ? "" : null); var currVal_4 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 14).ngClassValid; var currVal_9 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_13 = i1.ɵnov(_v, 18).inline; var currVal_14 = (((i1.ɵnov(_v, 18).color !== "primary") && (i1.ɵnov(_v, 18).color !== "accent")) && (i1.ɵnov(_v, 18).color !== "warn")); _ck(_v, 16, 0, currVal_13, currVal_14); }); }
export function View_SuggestionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-suggestion", [], null, null, null, View_SuggestionComponent_0, RenderType_SuggestionComponent)), i1.ɵdid(1, 49152, null, 0, i17.SuggestionComponent, [i18.FlowEditorService], null, null)], null, null); }
var SuggestionComponentNgFactory = i1.ɵccf("app-suggestion", i17.SuggestionComponent, View_SuggestionComponent_Host_0, { suggestionData: "suggestionData", bindSocket: "bindSocket" }, { changedSuggestion: "changedSuggestion", delete: "delete" }, []);
export { SuggestionComponentNgFactory as SuggestionComponentNgFactory };
