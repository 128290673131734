import { NodeHelper } from 'src/app/services/node-helper.service';
import { ChannelFeatureset } from 'conversation-domain';
import { ReteOutput } from '../../rete/controls/extended-output';
import { OnInit, ViewChild } from '@angular/core';
import { Input as ReteInput, IO, Node as ReteNode, NodeEditor } from 'rete';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { AbstractNode, OnFreeText, Position } from 'flow-model';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { FlowEditorComponent } from '../../flow-editor.component';
import { FlowEditorService } from 'src/app/services/flow-editor.service';

export abstract class BasicNode implements OnInit {
  bindControl: (el, value) => void;
  bindSocket: (el: HTMLElement, type: string, io: IO) => void;

  protected editor: NodeEditor;
  featureSet: ChannelFeatureset;

  freeTextOutput: ReteOutput = null;
  directOutput: ReteOutput = null;
  directInput: ReteInput = null;
  model: AbstractNode = null;
  isValid = true;
  validityErrorMessages: string = null;
  selected = false;

  private node: ReteNode;
  private errorReasons = new Set<string>();
  private nativeNodeElement: HTMLDivElement;

  private MISSING_OUTPUT_CONNECTIONS = 'An outgoing path is not connected';
  private NO_OUTGOING_EDGE = 'There has to be at least one outgoing connection';
  private MISSING_INPUT_CONNECTIONS = 'This message is missing an incoming connection';
  private NODENAME_TOO_LONG = 'Node name too long, please do not use more than 20 characters';

  @ViewChild(ContextMenuComponent)
  public basicMenu: ContextMenuComponent;

  protected constructor(protected userProfileService: UserProfileService) {
    if (userProfileService) {
      userProfileService.updateFeatureset.subscribe((featureSet: ChannelFeatureset) => {
        this.featureSet = featureSet;
        this.withFeatureset(featureSet);
        this.verifyNode();
      });
    }
  }

  public loadFeatureSet(): void {
    const selectedChannels = this.userProfileService.loadSelectedChannels();
    if (selectedChannels) {
      this.userProfileService.fetchSelectedFeatureset(selectedChannels);
    } else {
      this.userProfileService.fetchFeatureset();
    }

    this.userProfileService.featureSet$.subscribe(featureSet => {
      if (featureSet) {
        this.featureSet = featureSet;
        this.withFeatureset(featureSet);
        this.verifyNode();
      }
    });
  }

  protected withFeatureset(featureSet: ChannelFeatureset) {
    this.featureSet = featureSet;
  }

  ngOnInit() {
    this.directInput = this.node.inputs.get(NodeHelper.DEFAULT_INPUT_KEY);
    // verify node might change the state of the component, which has
    // to be moved to the next change detection run using setTimeout
    setTimeout(() => {
      this.loadFeatureSet();
      this.updateReteNode();
      return this.verifyNode();
    });
  }

  public loadModel(model: AbstractNode) {
    this.model = model;
    this.loadFromModel(model);
  }

  abstract loadFromModel(model: AbstractNode);

  abstract getModelObject(): AbstractNode;

  protected getPosition(): Position {
    const pos = new Position();
    pos.x = this.node.position[0];
    pos.y = this.node.position[1];
    return pos;
  }

  deleteNode() {
    this.editor.removeNode(this.node);
  }

  public setSelected(selected: boolean) {
    this.selected = selected;
    if (this.selected) {
      this.nativeNodeElement.classList.add('in_front');
    } else {
      this.nativeNodeElement.classList.remove('in_front');
    }
  }

  public getId(): number {
    return this.node.id;
  }

  protected hasAnyOutputs(): boolean {
    return this.node.outputs.size !== 0;
  }
  protected addOutput(output: ReteOutput) {
    this.node.addOutput(output);
  }
  protected getDirectOutput(): ReteOutput {
    return this.directOutput;
  }
  protected addDirectOutput(optional = false): ReteOutput {
    this.directOutput = optional ? ReteOutput.createOptionalDirectOutput() : ReteOutput.createDirectOutput();
    this.addOutput(this.directOutput);
    return this.directOutput;
  }
  protected addDirectOutputIfNoOtherExists(optional = false) {
    if (!this.hasAnyOutputs()) {
      this.addDirectOutput(optional);
    }
  }
  protected removeDirectOutput() {
    this.removeOutputWithConnections(this.directOutput);
    this.directOutput = null;
    this.onChange();
  }
  protected getDirectlyConnectedNodeId(): number {
    return this.directOutput ? this.directOutput.getConnectedNodeId() : null;
  }

  public addFreeTextOutput(): ReteOutput {
    this.freeTextOutput = ReteOutput.createFreeTextOutput();
    this.addOutput(this.freeTextOutput);
    this.onEnabledFreeText();
    this.onChange();
    return this.freeTextOutput;
  }
  protected onEnabledFreeText() {
    // override this method in the node component if you want to control the behaviour by your own
    // by default it removes the direct output
    this.removeDirectOutput();
  }
  public getFreeTextOutput(): ReteOutput {
    return this.freeTextOutput;
  }

  public removeFreeTextOutput() {
    this.removeOutputWithConnections(this.freeTextOutput);
    this.freeTextOutput = null;
    this.onDisabledFreeText();
    this.onChange();
  }
  protected onDisabledFreeText() {
    // override this method in the node component if you want to control the behaviour by your own
    // by default it should add a direct output if no other outputs exists
    this.addDirectOutputIfNoOtherExists();
  }

  protected getOnFreeText(): OnFreeText {
    if (this.freeTextOutput) {
      const onFreeText = new OnFreeText();
      onFreeText.nextMessageId = this.freeTextOutput.getConnectedNodeId();
      return onFreeText;
    }
  }

  public removeOutputWithConnections(output: ReteOutput) {
    if (output) {
      output.connections.forEach(c => this.editor.removeConnection(c));
      this.node.removeOutput(output);
      this.editor.trigger('iochanged');
    }
    this.onChange();
  }

  protected getErrorReasons(): Set<string> {
    return this.errorReasons;
  }


  public verifyNode() {
    if (!this.node) {
      return;
    }
    const valid = this.isValid;
    this.isValid = true;
    this.errorReasons.clear();

    if (this.directInput && !this.directInput.hasConnection()) {
      this.addErrorReason(this.MISSING_INPUT_CONNECTIONS);
    }
    if (this.directOutput && !this.directOutput.optional && !this.directOutput.hasConnection()) {
      this.addErrorReason(this.MISSING_OUTPUT_CONNECTIONS);
    }
    if (this.freeTextOutput && !this.freeTextOutput.optional && !this.freeTextOutput.hasConnection()) {
      this.addErrorReason(this.MISSING_OUTPUT_CONNECTIONS);
    }

    this.verifyOutgoingEdge();
    this.verifyNodeSpecific();
    this.verifyNodeName();
    this.updateErrorMessages();
    if (valid !== this.isValid) {
      this.editor.trigger('validate');
    }
  }

  protected addErrorReason(error: string, value?: number) {
    if (value) {
      error = error.replace('%d', `${value}`);
    }
    this.errorReasons.add(error);
    this.isValid = false;
    this.updateErrorMessages();
  }

  protected removeErrorReason(error: string) {
    this.errorReasons.delete(error);
    this.updateErrorMessages();
    if (this.errorReasons.size === 0) {
      this.isValid = true;
    }
  }

  protected updateErrorMessages() {
    this.validityErrorMessages = Array.from(this.errorReasons).join('\n\n');
    if (this.validityErrorMessages && this.validityErrorMessages.trim() === '') {
      this.validityErrorMessages = null;
    }
  }

  protected abstract verifyNodeSpecific();

  public onChange() {
    this.editor.trigger('flowchanged');
    this.updateReteNode();
    this.verifyNode();
  }

  public updateReteNode() {
    setTimeout(() => this.editor.trigger('nodetranslated', { node: this.node }));
  }

  public inputConnectionUpdated() {

  }

  public outputConnectionUpdated() {

  }

  protected verifyOutgoingEdge() {
    let anyOutputConnected = false;
    this.node.outputs.forEach(out => {
      if (out.hasConnection()) {
        anyOutputConnected = true;
      }
    });

    if (!anyOutputConnected) {
      this.addErrorReason(this.NO_OUTGOING_EDGE);
    }
  }

  private verifyNodeName() {
    if (this.model && this.model.nodeName) {
      if (this.model.nodeName.length > 20) {
        this.addErrorReason(this.NODENAME_TOO_LONG);
      }
    }
  }
}
