<div [contextMenu]="basicMenu" data-testid="CarouselNode" class="node" [ngClass]="{'selected': selected, error: !isValid}">
  <div class="title">

    <div *ngIf="directInput" class="input">
      <app-socket
        [bindSocket]="bindSocket"
        [inputOutput]="directInput"
        type="input">
      </app-socket>
    </div>
    <mat-icon class="node-type-icon">view_carousel</mat-icon>
    <div data-testid="CarouselNode-node-name" class="text">Carousel</div>

    <div class="status">
      <mat-icon [matTooltip]="validityErrorMessages" [ngClass]="isValid ? 'ok' : 'error'" data-testid="nodeStatusIcon">
        error_outline
      </mat-icon>
    </div>

    <div class="fill_space"></div>

    <div *ngIf="directOutput" class="output">
      <app-socket [bindSocket]="bindSocket" [inputOutput]="directOutput" type="output"></app-socket>
    </div>
  </div>

  <app-free-text [messageNode]="this"></app-free-text>
  <app-card-output-list [carouselNode]="this"></app-card-output-list>

  <div class="spacer"></div>

  <context-menu>
    <ng-template (execute)="deleteNode()" contextMenuItem>
      Delete Node
    </ng-template>
  </context-menu>
</div>
