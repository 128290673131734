<div [contextMenu]="basicMenu"
     data-testid="TextMessageNode"
     class="node"
     [ngClass]="{'selected': selected, error: !isValid}">

  <div class="title">
    <app-socket *ngIf="directInput" [bindSocket]="bindSocket" [inputOutput]="directInput" type="input"></app-socket>
    <mat-icon class="node-type-icon">message</mat-icon>
    <div data-testid="TextMessageNode-node-name" class="text">Text Message</div>

    <div class="status">
      <mat-icon [matTooltip]="validityErrorMessages" [ngClass]="isValid ? 'ok' : 'error'" data-testid="nodeStatusIcon">
        error_outline
      </mat-icon>
    </div>

    <div class="fill_space"></div>
    <app-socket *ngIf="directOutput" [bindSocket]="bindSocket" [inputOutput]="directOutput" type="output"></app-socket>
  </div>

  <div class="control">
    <mat-form-field *ngIf="model">
      <textarea matInput placeholder="Message text"
                [(ngModel)]="model.template['en']"
                (ngModelChange)="onChange()"
                cdkTextareaAutosize
                appDragStopPropagation
                data-testid="messageInput">
      </textarea>
    </mat-form-field>
  </div>

  <app-free-text [messageNode]="this"></app-free-text>
  <app-suggestions-list [messageNode]="this"></app-suggestions-list>

  <div class="spacer"></div>

  <context-menu>
    <ng-template (execute)="deleteNode()" contextMenuItem>
      Delete Node
    </ng-template>
  </context-menu>
</div>
