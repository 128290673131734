import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';

@Component({
  selector: 'app-flow-upload-dialog',
  templateUrl: './flow-upload-dialog.component.html',
})
export class FlowUploadDialogComponent {

  fileInput: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<FlowUploadDialogComponent>) {
    this.fileInput = data.fileInput;
  }

  file: File;

  selectFile() {
    this.fileInput.nativeElement.click();
    this.dialogRef.close();
  }

}
