import * as tslib_1 from "tslib";
import { Flow, FlowDefinition, Position } from 'flow-model';
import { HttpService } from './http.service';
import { ApplicationRef } from '@angular/core';
import { Node as ReteNode, NodeEditor } from 'rete';
import { ReteTextMessageNode } from '../flow-application/editor/rete/components/text-message-node';
import { ReteHttpRequestMessageNode } from '../flow-application/editor/rete/components/http-request-message-node';
import { ReteStartNode } from '../flow-application/editor/rete/components/start-node';
import ConnectionPlugin from 'rete-connection-plugin';
import AreaPlugin from 'rete-area-plugin';
import { FlowRendererService } from './flow-renderer.service';
import { NodeHelper } from './node-helper.service';
import { ReteEndNode } from '../flow-application/editor/rete/components/end-node';
import { serialize } from 'class-transformer';
import { NodeDetailsService } from './node-details.service';
import { BehaviorSubject } from 'rxjs';
import { SnackBarService } from './snackbar.service';
import { ReteKeywordsNode } from '../flow-application/editor/rete/components/keywords-node';
import { nodeToReteNodeJson } from '../model/conversion';
import '../model/events';
import { ReteMediaMessageNode } from '../flow-application/editor/rete/components/media-message-node';
import { ReteRichCardNode } from '../flow-application/editor/rete/components/rich-card-node';
import { ReteCarouselNode } from '../flow-application/editor/rete/components/carousel-node';
import { ReteOutput } from '../flow-application/editor/rete/controls/extended-output';
import { ReteMobileInvoiceNode } from '../flow-application/editor/rete/components/mobile-invoice-node';
import { UserProfileService } from './user-profile.service';
import * as i0 from "@angular/core";
import * as i1 from "./flow-renderer.service";
import * as i2 from "./http.service";
import * as i3 from "./snackbar.service";
import * as i4 from "./node-details.service";
import * as i5 from "./user-profile.service";
const FLOW_VERSION = 'flow@0.1.0';
export class FlowEditorService {
    constructor(flowRendererService, applicationRef, httpService, snackBar, nodeDetailsService, userProfileService) {
        this.flowRendererService = flowRendererService;
        this.applicationRef = applicationRef;
        this.httpService = httpService;
        this.snackBar = snackBar;
        this.nodeDetailsService = nodeDetailsService;
        this.userProfileService = userProfileService;
        this.mobileInvoiceEnabled = false;
        this.dirty = false;
        this.stateSubject = new BehaviorSubject({ dirty: false, valid: false, canRun: false });
        this.flowState$ = this.stateSubject.asObservable();
        this.isLoading = false;
        this.canTranslate = true;
        this.MOBILE_INVOICE_ENABLED = 'MOBILE_INVOICE_ENABLED';
        this.SELECTED_CHANNELS = 'SELECTED_CHANNELS';
        this.loadSelectedChannels();
        this.loadMobileInvoiceLocalStorage();
        if (userProfileService) {
            userProfileService.updateFeatureset.subscribe((featureSet) => {
                this.featureSet = featureSet;
            });
        }
        userProfileService.channels$.subscribe(channels => {
            if (channels && !this.selectedChannels) {
                for (const channel of channels) {
                    if (this.selectedChannels) {
                        this.selectedChannels += ',' + channel.toString();
                    }
                    else {
                        this.selectedChannels = channel.toString();
                    }
                }
            }
        });
    }
    static connectionUpdate(connection) {
        const inputNode = connection.input.node.data['nodeComponent'];
        if (inputNode) {
            inputNode.verifyNode();
            inputNode.inputConnectionUpdated();
        }
        const outputNode = connection.output.getNodeComponent();
        if (outputNode) {
            outputNode.verifyNode();
            outputNode.outputConnectionUpdated();
        }
    }
    static verifyNode(node) {
        if (node.data) {
            node.data['nodeComponent'].verifyNode();
        }
    }
    initializeEditor(nativeElement) {
        if (this.nodeEditor) {
            this.nodeEditor.clear();
            this.nodeEditor.destroy();
        }
        this.nodeEditor = new NodeEditor(FLOW_VERSION, nativeElement);
        this.setupPlugins();
        this.registerEvents();
        this.registerComponents();
        this.nodeEditor.view.resize();
    }
    createStartNode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const position = new Position();
            position.x = 400;
            position.y = 200;
            const startNode = NodeHelper.createStartNode(1, position);
            const reteStartNode = ReteNode.fromJSON(nodeToReteNodeJson(startNode));
            return this.nodeEditor.getComponent(ReteStartNode.nodeName).build(reteStartNode).then(startNodeInstance => {
                this.nodeEditor.addNode(startNodeInstance);
            });
        });
    }
    clear(flowId) {
        this.flow = new Flow();
        this.flow.flowDefinition = new FlowDefinition();
        this.flow.id = flowId;
        this.flow.name = 'Unnamed Flow';
        ReteNode.resetId();
        if (this.nodeEditor) {
            this.nodeEditor.clear();
            this.createStartNode().then(() => this.setClean());
        }
    }
    registerEvents() {
        this.nodeEditor.bind('iochanged');
        this.nodeEditor.bind('flowchanged');
        this.nodeEditor.bind('validate');
        this.nodeEditor.on('mousemove', (e) => {
            this.mousePosition = [e.x, e.y];
        });
        this.nodeEditor.on('nodetranslate', e => this.onNodeTranslate(e.node, e.x, e.y));
        const changeEvents = [
            'nodecreated',
            'noderemoved',
            'connectioncreated',
            'connectionremoved',
            'flowchanged'
        ];
        this.nodeEditor.on('validate', () => {
            this.emitStateChange();
        });
        this.nodeEditor.on(changeEvents.join(' '), () => this.onAnyChange());
        this.nodeEditor.on('iochanged', node => FlowEditorService.verifyNode(node));
        this.nodeEditor.on(['connectioncreated', 'connectionremoved'], FlowEditorService.connectionUpdate);
        this.nodeEditor.on('nodeselected', this.onSelectReteNodeEvent.bind(this));
        this.nodeEditor.on('noderemoved', this.onReteNodeRemoved.bind(this));
        this.nodeEditor.on('error', (err) => {
            console.error('Editor error! ' + err.message);
        });
    }
    onNodeTranslate(node, x, y) {
        return this.canTranslate;
    }
    setupPlugins() {
        this.nodeEditor.use(ConnectionPlugin);
        this.nodeEditor.use(AreaPlugin, {
            background: true,
            scaleExtent: true,
            translateExtent: true,
            snap: true
        });
        this.nodeEditor.use(this.flowRendererService);
    }
    getNodes() {
        if (this.nodeEditor) {
            return this.nodeEditor.nodes;
        }
        else {
            return null;
        }
    }
    getFlowJSON(flowId) {
        const result = this.flow;
        result.flowDefinition = new FlowDefinition();
        result.id = flowId;
        const nodes = this.getNodes();
        if (nodes && nodes.length > 0) {
            result.flowDefinition.messages = [];
            nodes.forEach((node) => {
                const basicNode = node.data.nodeComponent;
                result.flowDefinition.messages.push(basicNode.getModelObject());
            });
        }
        return serialize(result);
    }
    getNodeById(id) {
        const nodes = this.getNodes();
        let nodeById = null;
        if (nodes && nodes.length > 0) {
            nodes.forEach((node) => {
                const basicNode = node.data.nodeComponent;
                if (basicNode.getId() === id) {
                    nodeById = basicNode.getModelObject();
                }
            });
        }
        return nodeById;
    }
    updateNodes() {
        const nodes = this.getNodes();
        if (nodes && nodes.length > 0) {
            nodes.forEach((node) => {
                const basicNode = node.data.nodeComponent;
                basicNode.loadFeatureSet();
            });
        }
    }
    getEditor() {
        return this.nodeEditor;
    }
    getMousePosition() {
        return this.mousePosition;
    }
    setClean() {
        this.dirty = false;
        this.emitStateChange();
    }
    loadFlow(flowId) {
        this.isLoading = true;
        this.httpService.loadFlow(flowId).subscribe((flow) => this.loadFlowData(flow).then(() => {
            this.isLoading = false;
            this.setClean();
        }), (error) => {
            this.isLoading = false;
            if (error.status === 404) {
                this.clear(flowId);
                this.snackBar.showSuccess('New flow created.');
            }
            else {
                console.error(error);
                this.snackBar.showError('Error while loading flow!');
            }
        });
    }
    loadFlowData(flow) {
        this.flow = flow;
        if (!this.flow.name) {
            this.flow.name = 'Unnamed Flow';
        }
        this.nodeEditor.clear();
        return this.loadNodes(flow).then(nodeRegistry => {
            this.applicationRef.tick();
            this.connectNodes(flow, nodeRegistry);
        });
    }
    loadNodes(flow) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const nodeRegistry = new Map();
            const nodePromises = flow.flowDefinition.messages.map((flowNode) => {
                const component = this.nodeEditor.getComponent(flowNode['@type']);
                const reteNode = ReteNode.fromJSON(nodeToReteNodeJson(flowNode));
                return component.build(reteNode).then(node => {
                    this.nodeEditor.addNode(node);
                    nodeRegistry.set(flowNode.id, node);
                    node.data.nodeComponent.loadModel(flowNode);
                });
            });
            return Promise.all(nodePromises).then(() => Promise.resolve(nodeRegistry));
        });
    }
    connectNodes(flow, nodeRegistry) {
        flow.flowDefinition.messages.forEach((msg) => {
            this.connectDirectOutputIfPresent(msg, nodeRegistry);
            if (msg['@type'] === ReteTextMessageNode.nodeName || msg['@type'] === ReteMediaMessageNode.nodeName || msg['@type'] === ReteRichCardNode.nodeName) {
                const msgNode = msg;
                this.connectOnFreeTextIfPresent(msgNode, nodeRegistry);
                this.connectSuggestionsIfPresent(msgNode, nodeRegistry);
            }
            else if (msg['@type'] === ReteCarouselNode.nodeName) {
                const carouselNode = msg;
                this.connectOnFreeTextIfPresent(carouselNode, nodeRegistry);
                this.connectCarouselToRichCards(carouselNode, nodeRegistry);
            }
            else if (msg['@type'] === ReteKeywordsNode.nodeName) {
                const msgNode = msg;
                this.connectKeywords(msgNode, nodeRegistry);
            }
            else if (msg['@type'] === ReteMobileInvoiceNode.nodeName) {
                const mobileInvoiceNode = msg;
                this.connectMobileInvoiceStatus(mobileInvoiceNode, nodeRegistry);
            }
        });
    }
    connectDirectOutputIfPresent(msg, nodeRegistry) {
        if (msg.nextMessageId) {
            this.connectOutputInput(msg.id, ReteOutput.DEFAULT_NEXT_KEY, msg.nextMessageId, nodeRegistry);
        }
    }
    connectOnFreeTextIfPresent(msg, nodeRegistry) {
        if (msg.onFreeText && msg.onFreeText.nextMessageId) {
            this.connectOutputInput(msg.id, ReteOutput.FREE_TEXT_KEY, msg.onFreeText.nextMessageId, nodeRegistry);
        }
    }
    connectSuggestionsIfPresent(msg, nodeRegistry) {
        if (msg.suggestions) {
            msg.suggestions.forEach((suggestion, idx) => {
                if (suggestion.nextMessageId) {
                    const key = ReteOutput.createIndexedKey(ReteOutput.SUGGESTION_KEY, idx);
                    this.connectOutputInput(msg.id, key, suggestion.nextMessageId, nodeRegistry);
                }
            });
        }
    }
    connectMobileInvoiceStatus(mobileInvoiceNode, nodeRegistry) {
        if (mobileInvoiceNode.statusOutputs) {
            for (const key in mobileInvoiceNode.statusOutputs) {
                if (mobileInvoiceNode.statusOutputs.hasOwnProperty(key)) {
                    const reteKey = ReteOutput.MOBILE_INVOICE_STATUS_KEY + key;
                    if (mobileInvoiceNode.statusOutputs[key]) {
                        this.connectOutputInput(mobileInvoiceNode.id, reteKey, mobileInvoiceNode.statusOutputs[key], nodeRegistry);
                    }
                }
            }
        }
    }
    connectKeywords(keywordsNode, nodeRegistry) {
        if (keywordsNode.keywords) {
            keywordsNode.keywords.forEach((keyword, idx) => {
                if (keyword.nextMessageId) {
                    const key = ReteOutput.createIndexedKey(ReteOutput.KEYWORD_KEY, idx);
                    this.connectOutputInput(keywordsNode.id, key, keyword.nextMessageId, nodeRegistry);
                }
            });
        }
    }
    connectCarouselToRichCards(msg, nodeRegistry) {
        if (msg.cardIDs) {
            msg.cardIDs
                .forEach((cardId, idx) => {
                const key = ReteOutput.createIndexedKey(ReteOutput.CAROUSEL_RICH_CARD_KEY, idx);
                if (cardId !== null) {
                    this.connectOutputInput(msg.id, key, cardId, nodeRegistry);
                }
            });
        }
    }
    connectOutputInput(srcMsgId, outputKey, dstMsgId, nodeRegistry) {
        const srcOutput = nodeRegistry.get(srcMsgId).outputs.get(outputKey);
        const dstInput = nodeRegistry.get(dstMsgId).inputs.get(NodeHelper.DEFAULT_INPUT_KEY);
        if (srcOutput && dstInput) {
            this.nodeEditor.connect(srcOutput, dstInput, {});
        }
    }
    save(flowId) {
        const flowJson = this.getFlowJSON(flowId);
        this.saveJsonFlow(flowJson);
    }
    saveJsonFlow(flowJson) {
        this.httpService.saveFlow(flowJson)
            .subscribe(() => {
            this.setClean();
            this.snackBar.showSuccess('Flow was saved successfully');
        }, error => {
            console.log(error);
            this.snackBar.showError('Error while saving flow! ' + error.error);
        });
    }
    renameCurrentFlow(newName) {
        this.flow.name = newName;
        this.onAnyChange();
    }
    renameFlow(newName, flow) {
        this.httpService.saveNewFlowName(flow.name, newName, flow.id).subscribe(() => {
        }, error => {
            console.log(error);
            this.snackBar.showError('Error while updating flow name!');
        });
    }
    selectStartNode() {
        for (const node of this.getNodes()) {
            if (node.name === 'StartNode') {
                const startNodeComponent = node.data['nodeComponent'];
                startNodeComponent.setSelected(true);
                this.selectedNode = node;
                this.nodeDetailsService.changeNode(startNodeComponent);
                break;
            }
        }
        return this.selectedNode;
    }
    registerComponents() {
        const components = [
            new ReteStartNode(),
            new ReteTextMessageNode(),
            new ReteMediaMessageNode(),
            new ReteKeywordsNode(),
            new ReteRichCardNode(),
            new ReteEndNode(),
            new ReteCarouselNode(),
            new ReteMobileInvoiceNode(),
            new ReteHttpRequestMessageNode()
        ];
        components.forEach(c => {
            this.nodeEditor.register(c);
        });
    }
    onSelectReteNodeEvent(newSelectedNode) {
        if (this.selectedNode) {
            if (newSelectedNode.id === this.selectedNode.id) {
                return;
            }
            this.selectedNode.data['nodeComponent'].setSelected(false);
        }
        this.selectedNode = newSelectedNode;
        const selectedComponent = newSelectedNode.data['nodeComponent'];
        selectedComponent.setSelected(true);
        this.nodeDetailsService.changeNode(selectedComponent);
    }
    onReteNodeRemoved(removedNode) {
        if (removedNode && this.selectedNode && removedNode.id === this.selectedNode.id) {
            this.selectedNode = null;
            this.nodeDetailsService.changeNode(null);
        }
    }
    isValid() {
        const nodes = this.getNodes();
        if (nodes) {
            for (const node of nodes) {
                if (!node.data['nodeComponent'].isValid) {
                    return false;
                }
            }
        }
        return true;
    }
    onAnyChange() {
        if (this.isLoading) {
            return;
        }
        // TODO try to find another way to detect changes in the details panel on suggestion deletion.
        if (this.selectedNode) {
            this.nodeDetailsService.changeNode(this.selectedNode.data['nodeComponent']);
        }
        this.dirty = true;
        this.emitStateChange();
    }
    emitStateChange() {
        const valid = this.isValid();
        this.stateSubject.next({ dirty: this.dirty, valid: valid, canRun: !this.dirty && valid });
    }
    fetchMobileInvoice() {
        this.httpService.loadMobileInvoice().subscribe(mobileInvoice => {
            if (mobileInvoice) {
                this.mobileInvoiceEnabled = mobileInvoice.enabled;
                localStorage.setItem(this.MOBILE_INVOICE_ENABLED, this.mobileInvoiceEnabled ? 'true' : 'false');
                if (this.mobileInvoiceEnabled) {
                    this.userProfileService.fetchMobileInvoiceSettings();
                }
            }
        }, (response) => {
            if (response.status !== 401) {
                throw response;
            }
        });
    }
    clearLocalStorage() {
        localStorage.removeItem(this.MOBILE_INVOICE_ENABLED);
        localStorage.removeItem(this.SELECTED_CHANNELS);
    }
    loadMobileInvoiceLocalStorage() {
        this.mobileInvoiceEnabled = false;
        const mobileInvoice = localStorage.getItem(this.MOBILE_INVOICE_ENABLED);
        if (mobileInvoice) {
            if (mobileInvoice === 'true') {
                this.mobileInvoiceEnabled = true;
            }
        }
    }
    loadSelectedChannels() {
        this.selectedChannels = '';
        const selectedChannels = localStorage.getItem(this.SELECTED_CHANNELS);
        if (selectedChannels) {
            this.selectedChannels = selectedChannels;
        }
    }
    getMobileInvoiceEnabled() {
        return this.mobileInvoiceEnabled;
    }
    getSelectedChannels() {
        return this.selectedChannels;
    }
    setSelectedChannels(channels) {
        this.selectedChannels = channels;
        localStorage.setItem(this.SELECTED_CHANNELS, channels);
    }
}
FlowEditorService.ngInjectableDef = i0.defineInjectable({ factory: function FlowEditorService_Factory() { return new FlowEditorService(i0.inject(i1.FlowRendererService), i0.inject(i0.ApplicationRef), i0.inject(i2.HttpService), i0.inject(i3.SnackBarService), i0.inject(i4.NodeDetailsService), i0.inject(i5.UserProfileService)); }, token: FlowEditorService, providedIn: "root" });
