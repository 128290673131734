import { ReteOutput } from '../flow-application/editor/rete/controls/extended-output';
import { AbstractSuggestion, CalendarEntrySuggestedAction, DialSuggestedAction, OpenUrlSuggestedAction, ShareLocationSuggestedAction, TextSuggestion, ViewLocationSuggestedAction } from 'flow-model';
import { OpenMobileInvoiceUrlSuggestionAction } from 'flow-model';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

export class SuggestionData {

  constructor(
    public suggestion: AbstractSuggestion,
    public suggestionType: SuggestionType<AbstractSuggestion>,
    public output: ReteOutput) {
  }
}

export class SuggestionType<T extends AbstractSuggestion> {

  constructor(
    public icon: string,
    public label: string,
    public isAction: boolean,
    public suggestionClassRef: new () => T) {
  }
}

export const SUGGESTION_TYPES = new Map<string, SuggestionType<AbstractSuggestion>>(
  [
    ['TextSuggestion', new SuggestionType('text_fields', 'Text', false, TextSuggestion)],
    ['OpenUrlSuggestedAction', new SuggestionType('public', 'Open URL', true, OpenUrlSuggestedAction)],
    ['OpenMobileInvoiceUrlSuggestionAction', new SuggestionType('credit_card', 'Mobile invoice link', true, OpenMobileInvoiceUrlSuggestionAction)],
    ['DialSuggestedAction', new SuggestionType('call', 'Dial', true, DialSuggestedAction)],
    ['ViewLocationSuggestedAction', new SuggestionType('place', 'View location', true, ViewLocationSuggestedAction)],
    ['ShareLocationSuggestedAction', new SuggestionType('location_searching', 'Share location', true, ShareLocationSuggestedAction)],
    ['CalendarEntrySuggestedAction', new SuggestionType('event', 'Calendar', true, CalendarEntrySuggestedAction)]
  ]
);

export const ALL_SUGGESTIONS = Array.from(SUGGESTION_TYPES.entries());

export function isShareLocationSuggestedAction(suggestion: [string, SuggestionType<AbstractSuggestion>]) {
  return suggestion[1].suggestionClassRef === ShareLocationSuggestedAction;
}
