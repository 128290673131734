import { AfterViewInit, Component, QueryList, ViewChildren, ViewChild, ElementRef, OnDestroy, AfterViewChecked } from '@angular/core';
import { NodeDetailsService } from './../../../services/node-details.service';
import { BasicNode } from '../../editor/renderer/node/basic-node';
import { HttpRequestMessageNodeComponent } from '../../editor/renderer/node/http-request-message-node/http-request-message-node.component';
import { HttpRequestMessageNode, HttpAction, HttpHeader, HttpBodyType, HttpFormData, HttpVariables, HttpRequestInfo, ResponseVariable } from 'flow-model';
import { environment } from 'src/environments/environment';
import { HttpRequestTypeData, getAllAction, HttpRequestBodyType, getAllHttpRequestBodyType, getHttpVariables, getAction } from 'src/app/model/requests';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { HttpService } from 'src/app/services/http.service';
import { deserialize } from 'class-transformer';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { Subscription } from 'rxjs';
import { UserProfileService } from 'src/app/services/user-profile.service';


@Component({
    selector: 'app-details-http-request-message',
    templateUrl: './details-http-request-message.component.html',
    styleUrls: ['./details-http-request-message.component.css']
  })
export class DetailsHttpRequestMessageComponent implements AfterViewInit {

  basicNode: BasicNode;

  model: HttpRequestMessageNode = null;

  env = environment;

  actions: HttpRequestTypeData[];

  httpBodyTypes: HttpRequestBodyType[];

  HttpAction = HttpAction;

  HttpBodyType = HttpBodyType;

  requestIsInProgress = false;

  sub: Subscription;

  httpRequestInfo: HttpRequestInfo;

  constructor(private nodeDetailsService: NodeDetailsService, private dialogService: DialogService,
    private userProfileService: UserProfileService,
    private httpService: HttpService, private snackBarService: SnackBarService) {
  }

  ngAfterViewInit(): void {
    this.nodeDetailsService.node$.subscribe(
      nodeComponent => {
        this.basicNode = nodeComponent;
        this.updateModel(nodeComponent);
      });

    this.actions = getAllAction();
    this.httpBodyTypes = getAllHttpRequestBodyType();
  }

  changeAction(actionData: HttpRequestTypeData) {
    this.model.action = actionData.httpAction;
    this.basicNode.onChange();
  }

  public isMobileInvoiceActive(): boolean {
    return this.userProfileService.isMobileInvoiceActive();
  }

  private updateModel(node: BasicNode) {
    let prevNodeId = -1;
    let newNodeId = -2;
    if (this.model) {
      prevNodeId = this.model.id;
    }
    this.model = null;
    if (node instanceof HttpRequestMessageNodeComponent) {
      this.model = node.model;
      newNodeId = this.model.id;
      if (this.model.httpBodyType == null) {
        this.model.httpBodyType = HttpBodyType.NONE;
      }
      if (prevNodeId !== newNodeId) {
        this.httpRequestInfo = new HttpRequestInfo();
        this.httpRequestInfo.message = '\ n';
        this.httpRequestInfo.responseBody = '\n';
        this.httpRequestInfo.statusCode = '';
      }
      this.updateHttpBodyType();
    }
    this.updateView(prevNodeId !== newNodeId);
  }

  private updateView(nodeChanged: boolean) {
    if (nodeChanged) {
      if (this.requestIsInProgress) {
        this.sub.unsubscribe();
        this.requestIsInProgress = false;
      }
    }
  }

  private updateHttpBodyType() {
    if (this.model.httpBodyType === HttpBodyType.NONE) {
      this.model.formDataEntries = null;
      this.model.messageBody = null;
    } else if (this.model.httpBodyType === HttpBodyType.FORM_DATA) {
      this.model.messageBody = null;
    } else if (this.model.httpBodyType === HttpBodyType.RAW) {
      this.model.formDataEntries = null;
    }
  }


  addHeader() {
    const httpHeader: HttpHeader = new HttpHeader();
    httpHeader.key = '';
    httpHeader.value = '';
    if (this.model.httpHeaders == null) {
        this.model.httpHeaders = [];
    }
    this.model.httpHeaders.push(httpHeader);
    this.basicNode.onChange();
  }

  onChange() {
    this.basicNode.onChange();
  }

  addFormData() {
    const formData: HttpFormData = new HttpFormData();
    formData.key = '';
    formData.value = '';
    if (this.model.formDataEntries == null) {
      this.model.formDataEntries = [];
    }
    this.model.formDataEntries.push(formData);
    this.basicNode.onChange();
  }

  removeHeader(idx: number) {
    this.model.httpHeaders.splice(idx, 1);
    this.basicNode.onChange();
  }


  removeFormDataEntry(idx: number) {
    this.model.formDataEntries.splice(idx, 1);
    this.basicNode.onChange();
  }

  manageVariables() {
    const httpVariables: HttpVariables[] = getHttpVariables(this.model);
    if (httpVariables.length === 0) {
      this.snackBarService.showSuccessOnDetailsPanel('There are no variables in request');
      return;
    }
    this.dialogService.variables('Enter Variables', 'OK',
    httpVariables, confirmed => {
      if (confirmed) {
        this.model.testVariables = httpVariables;
      }
    });
  }

  manageResponseVariables() {
    const responseVariables: ResponseVariable[] = [];
    const modelVariables: ResponseVariable[] = this.model.responseVariables;
    if (modelVariables) {
      modelVariables.forEach(modelVariable => {
        const responseVariable: ResponseVariable = new ResponseVariable();
        responseVariable.name = modelVariable.name;
        responseVariable.expression = modelVariable.expression;
        responseVariables.push(responseVariable);
      });
    }

    this.dialogService.responseVariables('Enter Response Variables', 'OK', responseVariables, confirmed => {
      if (confirmed) {
        this.model.responseVariables = confirmed;
        this.basicNode.onChange();
      }
    });

  }


  testNode() {
    const httpVariables: HttpVariables[] = getHttpVariables(this.model);
    const emptyValueFound = httpVariables.some(httpVariable => httpVariable.value.trim() === '');
    if (emptyValueFound) {
      this.manageVariables();
    } else {
      this.sendTestRequest();
    }
  }

  getHttpAction(value: string): string {
     return getAction(value);
  }

  private sendTestRequest() {
    this.requestIsInProgress = true;
    this.httpRequestInfo.statusCode = '';
    this.httpRequestInfo.responseBody = '\n';
    this.httpRequestInfo.httpVariables = null;
    this.sub = this.httpService.sendTestHttpRequestMessage(this.model)
    .subscribe(
      (data: string) => {
        this.requestIsInProgress = false;
        const httpRequestInfo: HttpRequestInfo = deserialize(HttpRequestInfo, data);
        this.httpRequestInfo.statusCode = httpRequestInfo.statusCode;
        this.httpRequestInfo.responseBody = httpRequestInfo.responseBody;
        if (this.model.action === HttpAction.GET) {
            this.httpRequestInfo.httpVariables = httpRequestInfo.httpVariables;
        }
      },
      (error) => {
        this.requestIsInProgress = false;
        try {
          const httpRequestInfo: HttpRequestInfo = deserialize(HttpRequestInfo, error.error);
          this.snackBarService.showError(httpRequestInfo.message);
        } catch (e) {
          this.snackBarService.showError(error.error);
        }
      }
    );
  }

}



