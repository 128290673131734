import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AbstractNode } from 'flow-model';

@Component({
  selector: 'app-output-variable',
  templateUrl: './output-variable.component.html',
})
export class OutputVariableComponent {

  @Input()
  model: AbstractNode;
  env = environment;

  @Output()
  changed = new EventEmitter<boolean>();

  onChange() {
    this.changed.emit(true);
  }
}
