<div class="rich-card-collection">
  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <div cdkDrag *ngFor="let cardConnectionOutput of carouselNode.cardOutputs" class="drop-rich-card">
      <app-card-output [output]="cardConnectionOutput" [bindSocket]="carouselNode.bindSocket"
                       (delete)="carouselNode.removeCardOutput($event)" data-testid="card-output"></app-card-output>
    </div>
  </div>

  <div class="card-output" *ngIf="carouselNode.showAddCardConnectionButton" data-testid="addCardOutput"
    (click)="carouselNode.addCardOutput()">
    <mat-icon class="card-output-inline-button"
      data-testid="addCardOutputButton">
      add</mat-icon>
    <input disabled type="text" placeholder="New Card Output" />
    <div class="card-output-spacer-add"></div>
  </div>
</div>
