import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { sha256 } from 'js-sha256';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { LoginResult } from 'flow-model';

const TOKEN = 'TOKEN';
const ACCOUNT_NAME = 'ACCOUNT_NAME';
const USER_NAME = 'USER_NAME';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private httpService: HttpService, private router: Router) {
  }

  static authenticated(auth: LoginResult, username: string): void {
    localStorage.setItem(TOKEN, auth.token);
    localStorage.setItem(ACCOUNT_NAME, auth.accountName);
    localStorage.setItem(USER_NAME, username);
  }

  static getToken(): string {
    return localStorage.getItem(TOKEN);
  }

  static getUserName(): string {
    return localStorage.getItem(USER_NAME);
  }

  static getAccountName(): string {
    return localStorage.getItem(ACCOUNT_NAME);
  }

  static isLoggedIn() {
    return localStorage.getItem(TOKEN) != null;
  }

  doLogin(userName: string, password: string): Observable<LoginResult> {
    return this.httpService.sendAuthRequest(userName, sha256(password));
  }

  logout(goToLogin = true) {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(USER_NAME);
    localStorage.removeItem(ACCOUNT_NAME);
    if (goToLogin) {
      this.router.navigate(['/login']);
    }
  }
}
