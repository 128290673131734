<div>
  <h2>Start Conversation</h2>
  <div class="flex">
    <mat-form-field>
      <input (ngModelChange)="msisdnChanged($event)" [ngModel]="msisdn" data-testid="msisdn" matInput placeholder="MSISDN" type="tel">
    </mat-form-field>
    <span [matTooltipDisabled]="runConversationEnabled"
          [matTooltip]="disabledTooltipText"
          data-testid="triggerConversationButtonWrapper"
          class="trigger-conversation-wrapper">

      <button (click)="triggerConversation()"
              [disabled]="!runConversationEnabled"
              class="trigger-button"
              data-testid="runConversationButton"
              matTooltip="Start conversation"
              [matTooltipShowDelay]="env.tooltipDelay"
              mat-icon-button>
        <mat-icon>play_circle_filled</mat-icon>
      </button>
    </span>
  </div>
  <div class="flex">
    <h3 style="flex-grow: 10">Variables</h3>
    <button (click)="addVariable()" [matTooltipShowDelay]="env.tooltipDelay" mat-icon-button matTooltip="Add Variable">
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>

  <mat-list class="variable-list">
    <mat-list-item class="variable-item" *ngFor="let v of variables; let i=index">
      <mat-form-field>
        <input matInput [(ngModel)]="v.name" placeholder="Name">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="v.value" placeholder="Value">
      </mat-form-field>
      <button mat-icon-button (click)="removeVariable(i)" title="Remove Variable">
        <mat-icon class="delete-button">cancel</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</div>
