import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { BasicNode } from '../basic-node';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { AbstractNode, EndNode } from 'flow-model';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { NodeHelper } from 'src/app/services/node-helper.service';

@Component({
  selector: 'app-end-node',
  templateUrl: './end-node.component.html',
  styleUrls: ['./end-node.component.css']
})
export class EndNodeComponent extends BasicNode implements OnInit {

  model: EndNode = null;

  @ViewChild(ContextMenuComponent)
  basicMenu: ContextMenuComponent;

  constructor(@Inject(UserProfileService) userProfileService: UserProfileService) {
    super(userProfileService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.model = NodeHelper.createEndNode(this.getId(), this.getPosition());
  }

  loadFromModel(node: AbstractNode) {
    this.model = node as EndNode;
  }

  getModelObject(): AbstractNode {
    this.model.position = this.getPosition();
    return this.model;
  }

  verifyOutgoingEdge() {
    // ignore in end node
  }

  verifyNodeSpecific() {
    if (this.model && this.model.callbackUrl && !(this.model.callbackUrl.toLocaleLowerCase().startsWith('http://') || this.model.callbackUrl.toLocaleLowerCase().startsWith('https://'))) {
      this.addErrorReason('Callback URL has to start with http:// or https://');
    }
  }
}
