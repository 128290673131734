import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  public showError(msg: string, duration: number = 5000) {
    this.snackBar.open(msg, 'Dismiss', {
      verticalPosition: 'top',
      panelClass: 'snackbar-error',
      duration: duration,
      data: 'data-testid=snackbar'
    });
  }

  public showWarning(msg: string, duration: number = 5000) {
    this.snackBar.open(msg, 'Dismiss', {
      verticalPosition: 'top',
      panelClass: 'snackbar-warning',
      duration: duration,
      data: 'data-testid=snackbar'
    });
  }

  public showSuccessOnDetailsPanel(msg: string) {
    this.snackBar.open(msg, 'Dismiss', {
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: 'snackbar-success',
      duration: 3000,
      data: 'data-testid=snackbar'
    });
  }

  public showErrorOnDetailsPanel(msg: string) {
    this.snackBar.open(msg, 'Dismiss', {
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: 'snackbar-error',
      duration: 3000,
      data: 'data-testid=snackbar'
    });
  }

  public showSuccess(msg: string) {
    this.snackBar.open(msg, 'Dismiss', {
      verticalPosition: 'top',
      panelClass: 'snackbar-success',
      duration: 3000
    });
  }

  showErrorPermanently(msg: any) {
    this.showError(msg, 0);
  }
}
