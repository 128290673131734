import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SuggestionData } from 'src/app/model/suggestions';
import { FlowEditorService } from 'src/app/services/flow-editor.service';
import { SuggestionEnabledNode } from '../../node/suggestion-enabled-node';

@Component({
  selector: 'app-suggestions-list',
  templateUrl: './suggestions-list.component.html',
  styleUrls: ['./suggestions-list.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SuggestionsListComponent {

  @Input()
  messageNode: SuggestionEnabledNode;

  constructor(
    private flowService: FlowEditorService) {
  }

  public addSuggestion($event) {
    if ($event.target.value) {
      this.addNewSuggestion($event.target.value);
      $event.target.value = '';
    }
  }

  public addNewSuggestion(text: string) {
    this.messageNode.addNewSuggestion(text);
  }

  onChange($event) {
    this.messageNode.onChange();
  }

  drop(event: CdkDragDrop<SuggestionData[]>) {
    moveItemInArray(this.messageNode.suggestions, event.previousIndex, event.currentIndex);
    this.onChange(null);
    this.flowService.canTranslate = true;
  }
}


