import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { SUGGESTION_TYPES, ALL_SUGGESTIONS, SuggestionData } from '../../../model/suggestions';
import { AbstractSuggestion, CalendarEntrySuggestedAction, DialSuggestedAction, OpenMobileInvoiceUrlSuggestionAction } from 'flow-model';
import { OpenUrlSuggestedAction, TextSuggestion, ViewLocationSuggestedAction } from 'flow-model';
import { NodeHelper } from 'src/app/services/node-helper.service';
import { ChannelFeatureset } from 'conversation-domain';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { FlowEditorService } from 'src/app/services/flow-editor.service';

@Component({
  selector: 'app-suggestion-details-list',
  templateUrl: './suggestion-details-list.component.html',
  styleUrls: ['./suggestion-details-list.component.css']
})
export class SuggestionDetailsListComponent implements OnInit {

  @Input()
  suggestionTypes = Array.from(SUGGESTION_TYPES.entries());

  @Input()
  suggestions: SuggestionData[];

  @Output()
  changed = new EventEmitter<SuggestionData[]>();

  featureSet: ChannelFeatureset;

  constructor(private flowService: FlowEditorService, private userProfileService: UserProfileService) {
    if (userProfileService) {
      userProfileService.updateFeatureset.subscribe((featureSet: ChannelFeatureset) => {
        this.featureSet = featureSet;
        this.configureSuggestionTypes();
      });
  }
}

  changeType(data: SuggestionData, newType: string) {
    const type = SUGGESTION_TYPES.get(newType);
    const newSuggestion: AbstractSuggestion = new (type.suggestionClassRef)();
    newSuggestion['@type'] = newSuggestion.constructor.name as any;
    newSuggestion.template = data.suggestion.template;
    if (!newSuggestion.template) {
      newSuggestion.template = NodeHelper.emptyTemplate();
    }
    data.suggestionType = type;
    data.suggestion = newSuggestion;
    data.output.optional = !(newSuggestion instanceof TextSuggestion) && !(newSuggestion instanceof OpenMobileInvoiceUrlSuggestionAction);

    this.onChange();
  }

  onChange() {
    this.changed.emit(this.suggestions);
  }

  asOpenUrl(suggestion: AbstractSuggestion): OpenUrlSuggestedAction {
    return suggestion as OpenUrlSuggestedAction;
  }

  asDialAction(suggestion: AbstractSuggestion): DialSuggestedAction {
    return suggestion as DialSuggestedAction;
  }

  asViewLocation(suggestion: AbstractSuggestion): ViewLocationSuggestedAction {
    return suggestion as ViewLocationSuggestedAction;
  }

  asCalendarAction(suggestion: AbstractSuggestion): CalendarEntrySuggestedAction {
    return suggestion as CalendarEntrySuggestedAction;
  }

  ngOnInit(): void {
    this.loadFeatureSet();
  }

  private configureSuggestionTypes(): void {
    if (this.featureSet && this.featureSet.suggestionsFeatureset) {
      this.suggestionTypes = Array.from(SUGGESTION_TYPES.entries());
      if (this.featureSet.suggestionsFeatureset) {
        if (this.featureSet.suggestionsFeatureset.suggestionOpenUrlLength <= 0) {
          this.removeSuggestionTypes('OpenUrlSuggestedAction');
        }
        if (this.featureSet.suggestionsFeatureset.suggestionPayloadLength <= 0) {
          this.removeSuggestionTypes('DialSuggestedAction');
        }
        if (this.featureSet.suggestionsFeatureset.suggestionViewLocationDescriptionLength <= 0) {
          this.removeSuggestionTypes('ViewLocationSuggestedAction');
        }
        if (this.featureSet.suggestionsFeatureset.suggestionViewLocationDescriptionLength <= 0) {
          this.removeSuggestionTypes('ShareLocationSuggestedAction');
        }
        if (this.featureSet.suggestionsFeatureset.suggestionCalendarTitleLength <= 0) {
          this.removeSuggestionTypes('CalendarEntrySuggestedAction');
        }
      }
    }
    if (!this.userProfileService.isMobileInvoiceActive()) {
      this.removeSuggestionTypes('OpenMobileInvoiceUrlSuggestionAction');
    }
  }

  private loadFeatureSet(): void {
    if (this.userProfileService && this.flowService && this.flowService.getSelectedChannels()) {
        this.userProfileService.fetchSelectedFeatureset(this.flowService.getSelectedChannels());
    }
  }

  private removeSuggestionTypes(type: string): void {
    let index = 0;
    for (const suggestionType of this.suggestionTypes) {
      if (suggestionType[0] === type) {
        this.suggestionTypes.splice(index, 1);
        break;
      }
      index++;
    }
  }

}
