import { NodeHelper } from 'src/app/services/node-helper.service';
import { UserProfileService } from '../../../../../services/user-profile.service';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { BasicNode } from '../basic-node';
import { AbstractNode, MobileInvoiceNode, MobileInvoiceInfoSettings, MobileInvoiceStatus } from 'flow-model';
import { environment } from '../../../../../../environments/environment';
import { MobileInvoiceValidationService } from 'src/app/services/mobile-invoice.validation.service';
import { ChannelFeatureset } from 'conversation-domain';
import { ReteOutput } from '../../../rete/controls/extended-output';
import { FlowEditorService } from 'src/app/services/flow-editor.service';
import { Connection } from 'rete/types/connection';

@Component({
  selector: 'app-mobile-invoice-node',
  templateUrl: './mobile-invoice-node.component.html',
  styleUrls: ['./mobile-invoice-node.component.css']
})
export class MobileInvoiceNodeComponent extends BasicNode implements OnInit, AfterViewInit {
  private MOBILE_INVOICE_INACTIVE = 'Current user has no active mobile invoce settings';
  private MESSAGE_TOO_LONG = 'Message text too long, please do not use more than %d characters';

  model: MobileInvoiceNode = null;
  env = environment;
  mobileInvoiceValidationService: MobileInvoiceValidationService;
  mobileInvoiceInfoSettings: MobileInvoiceInfoSettings;
  textHelpMessages: string = 'Text message that user will receive' + '\n' + 'should contain placeholder ' + MobileInvoiceValidationService.MOBILE_INVOICE_URL_PLACEHOLDER + '\n'
    + 'When message is sent to the end user, the placeholder will be replaced with a link from the Mobile Invoice';

  statusOutputs: { [index: string]: number };

  reteStatusOutputs: { [index: string]: ReteOutput } = {};

  MobileInvoiceStatus = MobileInvoiceStatus;

  mobileInvoiceStatuses;

  constructor(@Inject(UserProfileService) userProfileService: UserProfileService,
              @Inject(MobileInvoiceValidationService) mobileInvoiceValidationService: MobileInvoiceValidationService) {
    super(userProfileService);
    this.loadMobileInvoiceSettings();
    this.mobileInvoiceValidationService = mobileInvoiceValidationService;
    this.mobileInvoiceStatuses = Object.values(MobileInvoiceStatus);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.model = NodeHelper.newMediaInvoiceNode(this.getId(), this.getPosition());
  }

  isTextMessage(): boolean {
    return !this.model.linkMessage;
  }

  ngAfterViewInit(): void {
    this.addStatusOutput();
  }

  loadFromModel(node: AbstractNode) {
    this.model = node as MobileInvoiceNode;
    if (!this.model.text) {
      this.model.text = NodeHelper.emptyTemplate();
    }
    if (!this.model.description) {
      this.model.description = NodeHelper.emptyTemplate();
    }
    if (this.model.synchronous) {
      this.removeDirectOutput();
    } else {
      this.addDirectOutput();
    }
    if (this.model.statusOutputs && Object.keys(this.model.statusOutputs).length) {
      for (const key in this.model.statusOutputs) {
        if (this.model.statusOutputs.hasOwnProperty(key) && !this.hasOutput(key)) {
          const reteOutput = ReteOutput.createMobileInvoiceStatusOutput(key);
          this.reteStatusOutputs[key] = reteOutput;
          this.addOutput(reteOutput);
          if (this.directOutput) {
            this.removeDirectOutput();
          }
        }
      }
    } else {
      this.removeStatusOutputs();
      this.reteStatusOutputs = {};
    }
  }

  protected withFeatureset(featureSet: ChannelFeatureset) {
    super.withFeatureset(featureSet);
  }

  protected verifyNodeSpecific() {
    if (!this.userProfileService.isMobileInvoiceActive()) {
      this.addErrorReason(this.MOBILE_INVOICE_INACTIVE);
    } else {
      this.verifyMobileInvoiceSettings();
    }
    this.verifyParams();
    this.verifyMessageText();
    if (this.directInput && this.directInput.hasConnection() && this.isTextMessage) {
        const connections: Connection[] = this.directInput.connections;
        connections.forEach (connection => {
        const inputNode = connection.output.node.data['nodeComponent'] as BasicNode;
        if (inputNode) {
          inputNode.verifyNode();
        }
        });
     }
  }

  public getDirectOutput(): ReteOutput {
    return super.getDirectOutput();
  }

  public addDirectOutput(optional = false): ReteOutput {
    return super.addDirectOutput();
  }

  private loadMobileInvoiceSettings() {
    this.userProfileService.mobileInvoiceInfoSettings$.subscribe(mobileInvoiceInfoSettings => {
      if (mobileInvoiceInfoSettings) {
        this.mobileInvoiceInfoSettings = mobileInvoiceInfoSettings;
        this.verifyNode();
      }
    });
  }

  verifyMobileInvoiceSettings() {
    const errorReasons: Array<string> = this.mobileInvoiceValidationService.validateMobileInvoiceSettings(this.model, this.mobileInvoiceInfoSettings);
    errorReasons.forEach(error => {
      this.addErrorReason(error);
    });
  }

  private verifyMessageText() {
    const messageText = this.model && this.model.template ? this.model.text.en : null;
    if (messageText && messageText.length > 0 && this.featureSet) {
      const valid: boolean = this.mobileInvoiceValidationService.isValidMessageLength(messageText, this.featureSet.textMessageFeatureset.length);
      if (!valid) {
        this.addErrorReason(this.MESSAGE_TOO_LONG, this.featureSet.textMessageFeatureset.length);
      }
    }
  }

  verifyParams() {
    const errorReasons: Array<string> = this.mobileInvoiceValidationService.validate(this.model);
    errorReasons.forEach(error => {
      this.addErrorReason(error);
    });
  }

  getModelObject(): AbstractNode {
    this.model.nextMessageId = this.getDirectlyConnectedNodeId();
    this.model.position = this.getPosition();
    if (Object.keys(this.reteStatusOutputs).length !== 0) {
      for (const key in this.reteStatusOutputs) {
        if (this.reteStatusOutputs.hasOwnProperty(key)) {
          const reteOutput = this.reteStatusOutputs[key];
          if (reteOutput.hasConnection()) {
            this.model.statusOutputs[key] = reteOutput.getConnectedNodeId();
          } else {
            this.model.statusOutputs[key] = null;
          }
        }
      }
    } else {
      this.model.statusOutputs = {};
    }
    return this.model;
  }

  hasOutput(value: string): boolean {
    return this.reteStatusOutputs.hasOwnProperty(value);
  }

  getOutput(value: string): ReteOutput {
    return this.reteStatusOutputs[value];
  }

  addStatusOutput(): ReteOutput {
    const values = this.mobileInvoiceStatuses;
    for (const value in values) {
      if (!this.reteStatusOutputs.hasOwnProperty(values[value])) {
        const reteOutput = ReteOutput.createMobileInvoiceStatusOutput(values[value]);
        this.addOutput(reteOutput);
        this.reteStatusOutputs[values[value]] = reteOutput;
        if (this.directOutput) {
          this.removeDirectOutput();
        }
        if (!this.model.statusOutputs) {
          this.model.statusOutputs = {};
        }
        this.model.statusOutputs[values[value]] = null;
        return reteOutput;
      }
    }
    return null;
  }

  public removeDirectOutput() {
    super.removeDirectOutput();
  }

  removeStatusOutputs() {
    const values = this.mobileInvoiceStatuses;
    for (const value in values) {
      if (this.reteStatusOutputs.hasOwnProperty(values[value])) {
        const output: ReteOutput = this.reteStatusOutputs[values[value]];
        this.removeOutputWithConnections(output);
        delete this.reteStatusOutputs[values[value]];
        delete this.model.statusOutputs[values[value]];
        this.onChange();
      }
    }
  }

  removeStatusOutput(output: ReteOutput) {
    delete this.reteStatusOutputs[output.name];
    delete this.model.statusOutputs[output.name];
    this.removeOutputWithConnections(output);
    this.checkForShowDirectOutput();
    this.model.nextMessageId = null;
    this.onChange();
  }

  checkForShowDirectOutput() {
    if (Object.keys(this.reteStatusOutputs).length === 0) {
      this.addDirectOutput();
    }
  }

  showAddStatusConnectionButton(): boolean {
    const values = this.mobileInvoiceStatuses;
    for (const value in values) {
      if (!this.reteStatusOutputs.hasOwnProperty(values[value])) {
        return true;
      }
    }
    return false;
  }

}
