import * as tslib_1 from "tslib";
import { Component } from 'rete';
export class ReteStartNode extends Component {
    constructor() {
        super(ReteStartNode.nodeName);
    }
    builder(node) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
        });
    }
    worker(node, inputs, outputs, ...args) {
    }
}
ReteStartNode.nodeName = 'StartNode';
