import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlowEditorService, FlowState } from '../../../services/flow-editor.service';
import { HttpService } from '../../../services/http.service';
import { SnackBarService } from '../../../services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { CampaignDialogComponent } from 'src/app/dialogs/campaign-dialog/campaign-dialog.component';
import { AccountSettings } from 'flow-model';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { CampaignsComponent } from '../campaigns/campaigns.component';

@Component({
  selector: 'app-conversation-trigger',
  templateUrl: './conversation-trigger.component.html',
  styleUrls: ['./conversation-trigger.component.css']
})
export class ConversationTriggerComponent implements OnInit {

  @Input()
  flowId: string;

  @Output()
  campaignTriggered = new EventEmitter<void>();

  msisdn: string;
  variables: { name: string, value: string }[] = [];
  disabledTooltipText: string;

  msisdnValid = false;
  runConversationEnabled = false;
  private launchInProgress = false;
  private flowCanRun = false;
  private accountSettings: AccountSettings;

  private msisdnRE = new RegExp('^\\+[1-9]\\d{8,14}$');

  env = environment;

  constructor(private httpService: HttpService, private snackbarService: SnackBarService,
    private dialogService: DialogService, private flowService: FlowEditorService, private userProfileService: UserProfileService) {
      this.loadAccountSettings();
  }

  ngOnInit(): void {
    this.updateDisabledTooltipText();

    this.flowService.flowState$.subscribe((state: FlowState) => {
      this.flowCanRun = state.canRun;
      this.updateDisabledState();
    });
  }

  private loadAccountSettings() {
    this.userProfileService.accountSettings$.subscribe(accountSettings => {
      if (accountSettings) {
        this.accountSettings = accountSettings;
      } else {
        this.accountSettings = new AccountSettings();
      }
    });
  }


  triggerConversationWithDisabledForceStop(campaignData: Map<string, string>) {
   this.triggerConversationWithDialogCampaign(campaignData, false);
  }

  public triggerConversation() {
    const campaignData = new Map(
      this.variables.map(x => [x.name.trim(), x.value.trim()] as [string, string])
    );
    this.launchInProgress = true;
    this.updateDisabledState();
    if (this.accountSettings && this.accountSettings.disableForceStartCampaign) {
      this.triggerConversationWithDisabledForceStop(campaignData);
    } else {
      this.triggerConversationWithDialogCampaign(campaignData, true);
    }
  }

  triggerConversationWithDialogCampaign(campaignData: Map<string, string>, hasForceOption: boolean) {
    const campaignDialogComponent: CampaignDialogComponent = this.dialogService.campaign('Ready to run?',
    `<p>Once a campaign has been started, it cannot be launched again. Are you sure you want to start the campaign <b>${this.msisdn}</b>?</p>`,
    'Launch campaign',
    this.flowService.getSelectedChannels(), hasForceOption, false, 0, null, false,
    confirmed => {
      if (confirmed) {
        if (campaignDialogComponent.startNow) {
          this.startCampaign(campaignData, hasForceOption && campaignDialogComponent.forceStart, campaignDialogComponent.hideFreeText, campaignDialogComponent.text);
        } else {
            const startTime = CampaignsComponent.getScheduledTime(campaignDialogComponent.localDateTime);
            this.scheduleTriggerFlow(campaignData, startTime, this.flowService.getSelectedChannels(), campaignDialogComponent.text, campaignDialogComponent.hideFreeText);
          }
        } else {
        this.launchInProgress = false;
        this.updateDisabledState();
        }
    });
  }

  scheduleTriggerFlow(campaignData: Map<String, String>, startTime: string, channels: string, finalMessage: string, hideFreeText: boolean) {
    this.httpService.scheduleTriggerFlow(this.msisdn, campaignData, this.flowId, startTime, channels, finalMessage, hideFreeText)
    .pipe(finalize(() => {
      this.launchInProgress = false;
      this.updateDisabledState();
      return this.campaignTriggered.emit();
    }))
    .subscribe(
      () => {
        this.snackbarService.showSuccess('Conversation successfully scheduled');
      },
      e => {

        if (e.status === 406) {
          this.snackbarService.showErrorPermanently('Could not schedule campaign: ' + e.error.message);
        } else if (e.error) {
          this.snackbarService.showErrorPermanently(e.error.message);
        } else {
          this.snackbarService.showError('Failed to schedule conversation');
        }
      });
  }

  startCampaign(campaignData: Map<string, string>, forceStart: boolean, hideFreeText: boolean, message: string) {
    if (!forceStart) {
        this.triggerCampaign(campaignData, hideFreeText);
      } else {
        this.forceTriggerCampaign(campaignData, message, hideFreeText);
      }
  }

  forceTriggerCampaign(campaignData: Map<string, string>, message: string, hideFreeText: boolean) {
    this.httpService.forceTriggerFlow(this.msisdn, campaignData, this.flowId, this.flowService.getSelectedChannels(), message, hideFreeText)
      .pipe(finalize(() => {
        this.launchInProgress = false;
        this.updateDisabledState();
        return this.campaignTriggered.emit();
      }))
      .subscribe(
        () => {
          this.snackbarService.showSuccess('Conversation successfully executed');
        },
        e => {

          if (e.status === 406) {
            this.snackbarService.showErrorPermanently('Could not start campaign: ' + e.error.message);
          } else if (e.error) {
            this.snackbarService.showErrorPermanently(e.error.message);
          } else {
            this.snackbarService.showError('Failed to execute conversations');
          }
        });
  }

  triggerCampaign(campaignData: Map<string, string>, hideFreeText: boolean) {
    this.httpService.triggerFlow(this.msisdn, campaignData, this.flowId, this.flowService.getSelectedChannels(), hideFreeText)
      .pipe(finalize(() => {
        this.launchInProgress = false;
        this.updateDisabledState();
        return this.campaignTriggered.emit();
      }))
      .subscribe(
        () => {
          console.log('Conversation triggered:', this.msisdn);
          this.snackbarService.showSuccess('Conversation successfully executed');
        },
        e => {

          if (e.status === 406) {
            this.snackbarService.showErrorPermanently('Could not start campaign: ' + e.error.message);
          } else if (e.error) {
            this.snackbarService.showErrorPermanently(e.error.message);
          } else {
            this.snackbarService.showError('Failed to execute conversations');
          }
        });
  }

  addVariable() {
    this.variables.push({name: '', value: ''});
  }

  removeVariable(idx: number) {
    this.variables.splice(idx, 1);
  }

  updateDisabledTooltipText(): void {
    if (!this.msisdnValid) {
      this.disabledTooltipText = 'Phone number is invalid please use E.164 format, e.g. +4479xxxxxxxx';
    } else {
      this.disabledTooltipText = 'Flow is not saved or is invalid!';
    }
  }

  msisdnChanged(newMsisdn) {
    this.msisdn = newMsisdn;
    this.msisdnValid = this.msisdnRE.test(this.msisdn);
    this.updateDisabledState();
  }

  private updateDisabledState() {
    this.runConversationEnabled = this.flowCanRun && this.msisdnValid && !this.launchInProgress;
    this.updateDisabledTooltipText();
  }

}
